import React, { useEffect, useState } from 'react'
import { Tooltip, Button, Dropdown, Menu, Radio, Row, Col, TreeSelect } from 'antd';
import { DescListItem } from './type';
import { productApi } from '@utils/util';
import { lqGetHeaderInfoByCompanyName, lqGetCorpInfo, lqGetCloudInfoByCompanyName } from '@api/index'
import { Link, useHistory } from 'react-router-dom';
import { CreateBrief, DescriptionsList, UpdateCompanyInfo } from '.';
import { AddHelper, CustomerToCluePoolButton, CustomerToImportant, TransferCustomer } from '../Customer/List';
import { AddContact } from './cpnts/Contact';
import { AddPool, ClueToCustomerButton, GetCluesButton } from '../Customer/CluesPool';
import { MyCluesToCustomerButton, MyCluesToPoolButton, TransferClue } from '../Customer/MyPool';
import { stringify } from 'qs';
import { Ellipsis } from '@components/index';
import { useDictEnumHooks, useHasAuthCode } from '@hooks/index';
import { useSelector } from 'react-redux';
import { StateType } from '@store/index';

const getHeaderInfoApi = productApi(lqGetHeaderInfoByCompanyName) 
const getLqCorpInfoApi = productApi(lqGetCorpInfo)
const getLqCloudInfoApi = productApi(lqGetCloudInfoByCompanyName)

export const contactWayType = [
    {
        text: '微信',
        value: 'wechat'
    },
    {
        text: '邮箱',
        value: 'email'
    },
    {
        text: '手机号',
        value: 'mobile'
    }
]

export const homePages = [
    {
        logo: 'https://i9.taou.com/maimai/p/29722/5465_6_1bCfGRhVI8d3xW',
        name: '脉脉',
        queryUrl: (name: string) => `https://maimai.cn/web/search_center?type=contact&query=${name}&highlight=true`
    },
    // {
    //     logo: 'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/-380016366_392653622_512_512.jpg',
    //     name: '领英',
    //     queryUrl: (name: string) => ``
    // },
    {
        logo: 'https://dss0.bdstatic.com/-0U0bnSm1A5BphGlnYG/tam-ogel/1235927892_-447491088_121_121.jpg',
        name: 'Boss直聘',
        queryUrl: (name: string) => `https://www.zhipin.com/job_detail/?query=${name}`
    },
]

// 股东信息
export const holdersColumns = [
    {
        title: '股东名称',
        dataIndex: 'holderCompany',
        key: 'holderCompany'
    },
    {
        title: '持股比例',
        dataIndex: 'proportion',
        key: 'proportion'
    },
    // {
    //     title: '股东类型',
    //     dataIndex: 'companyName',
    //     key: 'companyName'
    // },
    {
        title: '认缴出资额',
        dataIndex: 'contributionAmount',
        key: 'contributionAmount'
    },
    // {
    //     title: '实缴出资额',
    //     dataIndex: 'companyName',
    //     key: 'companyName'
    // },
]

// 主要人员
export const mainStuffColumns = [
    {
        title: '序号',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: '姓名',
        dataIndex: 'staffName',
        key: 'staffName'
    },
    {
        title: '职位',
        dataIndex: 'staffPosition',
        key: 'staffPosition'
    },
]

// 工商信息
export const businessDescList: DescListItem[] = [
    {
        label: '法人代表',
        param: 'leaderPerson',
        span: 1
    },
    {
        label: '经营状态',
        param: 'businessStatus',
        span: 1
    },
    {
        label: '成立日期',
        param: 'registDate',
        span: 1
    },
    {
        label: '注册资本',
        param: 'registCapital',
        span: 1
    },
    {
        label: '实缴资本',
        param: 'contributCapital',
        span: 1
    },

    {
        label: '企业类型',
        param: 'companyType',
        span: 1
    },
    {
        label: '营业期限',
        param: 'businessTerm',
        span: 1
    },
    {
        label: '人员规模',
        param: 'staffSize',
        span: 1
    },
    {
        label: '参保人数',
        param: 'companyCbzz',
        span: 1
    },
    {
        label: '曾用名',
        param: 'oldName',
        span: 1
    },
    {
        label: '英文名',
        param: 'enName',
        span: 1
    },
    {
        label: '所属行业',
        param: 'companyIndustry',
        span: 1
    },

    {
        label: '登记机关',
        param: 'registAuthority',
        span: 1
    },
    {
        label: '统一社会信用代码',
        param: 'creditCode',
        span: 1
    },
    {
        label: '组织机构代码',
        param: 'organizationCode',
        span: 1
    },
    {
        label: '纳税人识别号',
        param: 'taxpayId',
        span: 1
    },
    {
        label: '工商注册号',
        param: 'registCode',
        span: 1
    },
    {
        label: '注册地址',
        param: 'registAdress',
        span: 1
    },
    {
        label: '经营范围',
        param: 'businessScope',
        span: 3
    },
]

// 对外投资
export const foreignInvestmentColumns = [
    {
        title: '被投资企业名称',
        dataIndex: 'investName',
        key: 'investName'
    },
    {
        title: '法定代表人',
        dataIndex: 'investLeader',
        key: 'investLeader'
    },
    {
        title: '成立日期',
        dataIndex: 'registDate',
        key: 'registDate'
    },
    {
        title: '投资数额',
        dataIndex: 'investAmount',
        key: 'investAmount'
    },
    {
        title: '投资比例',
        dataIndex: 'investProportion',
        key: 'investProportion'
    },
    {
        title: '经营状态',
        dataIndex: 'investState',
        key: 'investState'
    },
    {
        title: '关联产品',
        dataIndex: 'investProduct',
        key: 'investProduct'
    },
    {
        title: '关联机构',
        dataIndex: 'investOrganization',
        key: 'investOrganization'
    }
]

// 园区信息
export const areaInfo: DescListItem[] = [
    {
        label: '园区名称',
        param: 'parkName',
        span: 2
    },
    {
        label: '地区',
        value: ({province, city}: any) => `${province||''}${city||''}`,
        span: 2
    },
    {
        label: '占有面积',
        param: 'areas',
        span: 2
    },
    {
        label: '园区企业数',
        param: 'companyNumber',
        span: 2
    },
]

// 地图信息
export const mapColumns = [
    {
        title: '企业地址',
        dataIndex: 'address',
        key: 'address'
    },
    {
        title: '省份',
        dataIndex: 'province',
        key: 'province'
    },
    {
        title: '城市',
        dataIndex: 'city',
        key: 'city'
    },
    {
        title: '区县',
        dataIndex: 'area',
        key: 'area'
    },
    {
        title: '街道id',
        dataIndex: 'streetId',
        key: 'streetId'
    },
    {
        title: '纬度',
        dataIndex: 'lat',
        key: 'lat'
    },
    {
        title: '经度',
        dataIndex: 'lng',
        key: 'lng'
    }
]

// -----------------------------------
// 招聘信息
export const bossColumns = [
    {
        title: '发布日期',
        dataIndex: 'releaseTime',
        key: 'releaseTime'
    },
    {
        title: '招聘职位',
        dataIndex: 'positionName',
        key: 'positionName'
    },
    {
        title: '月薪',
        dataIndex: 'salary',
        key: 'salary'
    },
    {
        title: '学历',
        dataIndex: 'degreename',
        key: 'degreename'
    },
    {
        title: '工作经验',
        dataIndex: 'experiencename',
        key: 'experiencename'
    },
    {
        title: '办公地点',
        dataIndex: 'locationdesce',
        key: 'locationdesce'
    },
]

// 招投标
export const biddingInfoColumns = [
    {
        title: '项目名称',
        dataIndex: 'bidTitle',
        key: 'bidTitle',
        width: 200,
        ellipsis: true,
        render: (text: string, {bidTitleUrl}: any) =>
            <Tooltip title={text}>
                <a href={bidTitleUrl} target="_blank" rel="noopener noreferrer">{text}</a>
            </Tooltip>
    },
    {
        title: '发布日期',
        dataIndex: 'bidDate',
        key: 'bidDate'
    },
    {
        title: '省份地区',
        dataIndex: 'bidProvince',
        key: 'bidProvince'
    },
    {
        title: '招标类型',
        dataIndex: 'bidType',
        key: 'bidType'
    },
    {
        title: '采购人',
        dataIndex: 'bidBuyer',
        key: 'bidBuyer'
    },
    {
        title: '供应商',
        dataIndex: 'bidSupplier',
        key: 'bidSupplier'
    },
    {
        title: '中标金',
        dataIndex: 'bidAmount',
        key: 'bidAmount'
    },
]

// 客户信息
export const customerInformationColumns = [
    // {
    //     title: '序号',
    //     render:(text,record,index)=>`${index+1}`,
    // },
    {
        title: '客户',
        dataIndex: 'customerName',
        key: 'customerName'
    },
    {
        title: '销售占比',
        dataIndex: 'customerRelation',
        key: 'customerRelation'
    },
    {
        title: '销售金额',
        dataIndex: 'customerMoney',
        key: 'customerMoney'
    },
    {
        title: '报告期',
        dataIndex: 'customerDate',
        key: 'customerDate'
    },
    {
        title: '数据来源',
        dataIndex: 'customerSource',
        key: 'customerSource',
    },
    {
        title: '关联关系',
        dataIndex: 'customerRelation',
        key: 'customerRelation',
    }
]

// 企业税务评级
export const taxRateColumns = [
    {
        title: '评价年度',
        dataIndex: 'taxYear',
        key: 'taxYear'
    },
    {
        title: '纳税人识别号',
        dataIndex: 'taxNumber',
        key: 'taxNumber'
    },
    {
        title: '纳税人信用级别',
        dataIndex: 'taxTaxpayer',
        key: 'taxTaxpayer'
    },
    {
        title: '类型',
        dataIndex: 'taxType',
        key: 'taxType'
    },
    {
        title: '评价单位',
        dataIndex: 'taxOrganization',
        key: 'taxOrganization'
    }
]

// 企业供应商
export const supplierColumns = [
    {
        title: '供应商',
        dataIndex: 'suppliesPerson',
        key: 'suppliesPerson'
    },
    {
        title: '采购占比',
        dataIndex: 'suppliesRatio',
        key: 'suppliesRatio'
    },
    {
        title: '采购金额',
        dataIndex: 'suppliesAmount',
        key: 'suppliesAmount'
    },
    {
        title: '报告期',
        dataIndex: 'suppliesDate',
        key: 'suppliesDate'
    },
    {
        title: '数据来源',
        dataIndex: 'suppliesSource',
        key: 'suppliesSource'
    },
    {
        title: '关联关系',
        dataIndex: 'suppliesRelation',
        key: 'suppliesRelation'
    },
]

// 公开研报  
export const publicReportColumns = [
    {
        title: '发布日期',
        dataIndex: 'relatedDate',
        key: 'relatedDate'
    },
    {
        title: '发布类型',
        dataIndex: 'relatedType',
        key: 'relatedType'
    },
    {
        title: '研报内容',
        dataIndex: 'relateContent',
        key: 'relateContent',
        width: 200,
        ellipsis: true,
        render: (text: string, {relateContentUrl}: any) =>
            <Tooltip title={text}>
                <a href={relateContentUrl} target="_blank" rel="noopener noreferrer">{text}</a>
            </Tooltip>
    },
]

// 融资信息
export const moneyFromColumns = [
    // {
    //     title: '公司名称',
    //     dataIndex: 'companyName',
    //     key: 'companyName'
    // },
    {
        title: '披露日期',
        dataIndex: 'rzDate',
        key: 'rzDate'
    },
    {
        title: '融资金额',
        dataIndex: 'rzAmount',
        key: 'rzAmount'
    },
    {
        title: '融资轮次',
        dataIndex: 'rzFinanceRound',
        key: 'rzFinanceRound'
    },
    {
        title: '估值金额',
        dataIndex: 'rzAssessment',
        key: 'rzAssessment'
    },
    {
        title: '比例',
        dataIndex: 'rzProportion',
        key: 'rzProportion'
    },
    {
        title: '投资方',
        dataIndex: 'rzInvestor',
        key: 'rzInvestor'
    },
    {
        title: '新闻来源',
        dataIndex: 'rzNewsSourcd',
        key: 'rzNewsSourcd',
        width: 200,
        ellipsis: true,
        render: (text: string, record: {rzNewsUrl: string}) =>
            <Tooltip title={text}>
                <a href={(record||{}).rzNewsUrl||''} target="_blank" rel="noopener noreferrer">{text}</a>
            </Tooltip>
    }
]

// 新闻
export const newsColumns = [
    {
        title: '新闻标题',
        dataIndex: 'newsTitle',
        key: 'newsTitle',
        render: (text: any, {newsTitle, newsAbstracts, newstime, websiteSource, url}: any) => {
           return (<Row gutter={24}>
               <Col span={24}><a href={url} style={{fontSize: '16px'}}>{newsTitle|| ''}</a></Col>
               <Col span={24} style={{margin: '5px 0'}}>{newsAbstracts||''}</Col>
               <Col span={24} style={{color: 'rgba(0, 0, 0, 0.45)'}}><span style={{marginRight: 30}}>{websiteSource||''}</span><span>{newstime||''}</span></Col>
           </Row>)
        }
    },
]

// 核心团队
export const mainTeamColumns = [
    {
        title: '姓名',
        dataIndex: 'tmName',
        key: 'tmName'
    },
    {
        title: '职位',
        dataIndex: 'tmPosition',
        key: 'tmPosition'
    },
    {
        title: '简介',
        dataIndex: 'tmAbout',
        key: 'tmAbout'
    },
]

// ---------------------
// 企业专利信息
export const patentInfoColumns = [
    {
        title: '专利名称',
        dataIndex: 'patentName',
        key: 'patentName'
    },
    {
        title: '专利类型',
        dataIndex: 'patentType',
        key: 'patentType'
    },
    {
        title: '申请日期',
        dataIndex: 'registDate',
        key: 'registDate'
    },
    {
        title: '公开日期',
        dataIndex: 'patentDate',
        key: 'patentDate'
    },
    {
        title: '申请号',
        dataIndex: 'patentNumber',
        key: 'patentNumber'
    },
    {
        title: '公开号',
        dataIndex: 'patentReleased',
        key: 'patentReleased'
    },
    // {
    //     title: '专利详情',
    //     dataIndex: 'patentUrl',
    //     key: 'patentUrl',
    //     width: 200,
    //     ellipsis: true,
    //     render: (text: string, record: object) =>
    //         <Tooltip title={text}>
    //             <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>
    //         </Tooltip>
    // }
]

// 企业作品著作权
export const copyrightColumns = [
    {
        title: '作品名称',
        dataIndex: 'worksName',
        key: 'worksName'
    },
    {
        title: '登记号',
        dataIndex: 'worksNumber',
        key: 'worksNumber'
    },
    {
        title: '作品类别',
        dataIndex: 'worksType',
        key: 'worksType'
    },
    {
        title: '创作完成日期',
        dataIndex: 'worksFinishdate',
        key: 'worksFinishdate'
    },
    {
        title: '登记日期',
        dataIndex: 'worksRegisterDate',
        key: 'worksRegisterDate'
    },
    {
        title: '首次发表日期',
        dataIndex: 'worksFirstDate',
        key: 'worksFirstDate'
    },
]

// 企业商标
export const brandColumns = [
    // {
    //     title: '商标',
    //     dataIndex: 'tminfoName',
    //     key: 'tminfoName'
    // },
    {
        title: '商标名称',
        dataIndex: 'tminfoName',
        key: 'tminfoName'
    },
    {
        title: '注册号',
        dataIndex: 'tminfoNumber',
        key: 'tminfoNumber'
    },
    // {
    //     title: '国际分类',
    //     dataIndex: 'tminfoNumber',
    //     key: 'tminfoNumber'
    // },
    {
        title: '商标状态',
        dataIndex: 'tminfoType',
        key: 'tminfoType'
    },
    {
        title: '申请日期',
        dataIndex: 'tminfoDate',
        key: 'tminfoDate'
    },
]

// 资质证书
export const certificatesColumns = [
    {
        title: '证书类型',
        dataIndex: 'certificateType',
        key: 'certificateType'
    },
    {
        title: '证书名称',
        dataIndex: 'productName',
        key: 'productName'
    },
    {
        title: '发证日期',
        dataIndex: 'beginDate',
        key: 'beginDate'
    },
    {
        title: '截止日期',
        dataIndex: 'endDate',
        key: 'endDate'
    },
]

// 微信公众号 v4.6.0 todo
export const weixinColumns = [
    {
        title: '微信公众号',
        dataIndex: 'wechatPublic',
        key: 'wechatPublic'
    },
    {
        title: '微信号',
        dataIndex: 'wechatNumber',
        key: 'wechatNumber'
    },
    // {
    //     title: '二维码',
    //     dataIndex: 'beginDate',
    //     key: 'beginDate'
    // },
    {
        title: '简介',
        dataIndex: 'wechatIntroduce',
        key: 'wechatIntroduce'
    },
]


// --------------------------
// 开庭公告表
export const courtNoticeColumns = [
    {
        title: '开庭日期',
        dataIndex: 'courtDate',
        key: 'courtDate'
    },
    {
        title: '案号',
        dataIndex: 'courtNumber',
        key: 'courtNumber'
    },
    {
        title: '案由',
        dataIndex: 'courtReason',
        key: 'courtReason'
    },
    {
        title: '当事人',
        dataIndex: 'courtCase',
        key: 'courtCase'
    },
    {
        title: '受理法院',
        dataIndex: 'courtCourt',
        key: 'courtCourt'
    }
]

// 企业法律诉讼
export const legalActionColumns = [
    {
        title: '审判日期',
        dataIndex: 'lawsuitRefereeDate',
        key: 'lawsuitRefereeDate'
    },
    {
        title: '案件名称',
        dataIndex: 'lawsuitName',
        key: 'lawsuitName'
    },
    {
        title: '案号',
        dataIndex: 'lawsuitNumber',
        key: 'lawsuitNumber'
    },
    {
        title: '案由',
        dataIndex: 'lawsuitReason',
        key: 'lawsuitReason'
    },
    {
        title: '案件身份',
        dataIndex: 'lawsuitIdentity',
        key: 'lawsuitIdentity'
    },
    {
        title: '裁判结果',
        dataIndex: 'lawsuitResult',
        key: 'lawsuitResult'
    },
    // {
    //     title: '更多信息',
    //     dataIndex: 'lawsuitOperate',
    //     key: 'lawsuitOperate',
    //     width: 200,
    //     ellipsis: true,
    //     render: (text: string, record: object) =>
    //         <Tooltip title={text}>
    //             <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>
    //         </Tooltip>
    // }
]

// 企业立案信息
export const recordInfoColumns = [
    {
        title: '立案日期',
        dataIndex: 'courtDate',
        key: 'courtDate'
    },
    {
        title: '案号',
        dataIndex: 'courtNumber',
        key: 'courtNumber'
    },
    {
        title: '案件身份',
        dataIndex: 'courtCase',
        key: 'courtCase'
    },
    {
        title: '法院',
        dataIndex: 'courtCourt',
        key: 'courtCourt'
    }
]

// 企业行政处罚
export const punishColumns = [
    {
        title: '处罚日期',
        dataIndex: 'punishDate',
        key: 'punishDate'
    },
    {
        title: '决定书文号',
        dataIndex: 'punishDocumentNumber',
        key: 'punishDocumentNumber'
    },
    {
        title: '处罚事由/违法行为类型',
        dataIndex: 'punishReason',
        key: 'punishReason'
    },
    {
        title: '处罚法结果/内容',
        dataIndex: 'punishContent',
        key: 'punishContent'
    },
    {
        title: '处罚单位',
        dataIndex: 'punishOrganization',
        key: 'punishOrganization'
    },
    {
        title: '数据来源',
        dataIndex: 'punishSource',
        key: 'punishSource'
    }
]

// ---------------------------
// app 信息
export const appInfoColumns = [
    {
        title: 'APP名称',
        dataIndex: 'appName',
        key: 'appName'
    },
    {
        title: 'APP类型',
        dataIndex: 'categoryname',
        key: 'categoryname'
    },
    {
        title: '当前版本',
        dataIndex: 'versionname',
        key: 'versionname'
    },
    // {
    //     title: 'APP排名',
    //     dataIndex: 'rankId',
    //     key: 'rankId'
    // },
    // {
    //     title: 'APP活跃度',
    //     dataIndex: 'activeDegree',
    //     key: 'activeDegree',
    // },
    {
        title: '更新时间',
        dataIndex: 'updatetime',
        key: 'updatetime'
    },
    {
        title: '简介',
        dataIndex: 'editorrecommend',
        key: 'editorrecommend'
    },
    // {
    //     title: '产品介绍',
    //     dataIndex: 'appdesc',
    //     key: 'appdesc'
    // },
]

// 自身产品
export const selfProductColumns = [
    {
        title: '发布日期',
        dataIndex: 'fpDate',
        key: 'fpDate'
    },
    {
        title: '产品名称',
        dataIndex: 'fpName',
        key: 'fpName'
    },
    {
        title: '当前融资轮次',
        dataIndex: 'fpLunci',
        key: 'fpLunci'
    },
    {
        title: '所属行业',
        dataIndex: 'fpTag',
        key: 'fpTag'
    },
    {
        title: '所属地',
        dataIndex: 'fpAdress',
        key: 'fpAdress'
    },
    {
        title: '产品简介',
        dataIndex: 'fpAbout',
        key: 'fpAbout'
    }
]

// 竞品信息
export const otherProductColumns = [
    {
        title: '发布日期',
        dataIndex: 'competeDate',
        key: 'competeDate'
    },
    {
        title: '产品名称',
        dataIndex: 'competeName',
        key: 'competeName'
    },
    {
        title: '当前融资轮次',
        dataIndex: 'competeLunci',
        key: 'competeLunci'
    },
    {
        title: '估值',
        dataIndex: 'competeGuzhi',
        key: 'competeGuzhi'
    },
    {
        title: '所属地',
        dataIndex: 'competeAdress',
        key: 'competeAdress'
    },
    {
        title: '简介',
        dataIndex: 'competeAbout',
        key: 'competeAbout'
    },
    {
        title: '所属公司',
        dataIndex: 'competeCompany',
        key: 'competeCompany'
    },
    {
        title: '产品标签',
        dataIndex: 'competeTag',
        key: 'competeTag'
    },
]

// 云服务产品 -- cdn官网
export const cdnColumns = [
    {
        title: 'CDN厂商',
        dataIndex: 'company',
        key: 'company'
    },
    // {
    //     title: 'CDN关键字',
    //     dataIndex: 'cdnDomain',
    //     key: 'cdnDomain'
    // },
    {
        title: 'cname信息',
        dataIndex: 'cname',
        key: 'cname'
    },
    {
        title: '二级域名信息',
        dataIndex: 'secondDomain',
        key: 'secondDomain'
    },
    // {
    //     title: '是否官网',
    //     dataIndex: 'isOfficial',
    //     key: 'isOfficial',
    //     render: (text: string, record:object) => <span>{text ? '是' : '否'}</span>
    // }
]

// 云服务产品 -- waf
export const wafColumns = [
    {
        title: 'WAF厂商',
        dataIndex: 'company',
        key: 'company'
    },
    // {
    //     title: 'WAF关键字',
    //     dataIndex: 'wafDomain',
    //     key: 'wafDomain'
    // },
    {
        title: 'CNAME信息',
        dataIndex: 'cname',
        key: 'cname'
    },
    // {
    //     title: '是否官网',
    //     dataIndex: 'isOfficial',
    //     key: 'isOfficial',
    //     render: (text: string, record: object) => <span>{text ? '是' : '否'}</span>
    // }
]

// 云服务产品 -- ddos
export const ddosColumns = [
    {
        title: 'DDOS厂商',
        dataIndex: 'ddosCompany',
        key: 'ddosCompany'
    },
    // {
    //     title: 'DDOS关键字',
    //     dataIndex: 'ddosDomain',
    //     key: 'ddosDomain'
    // },
    {
        title: 'CNAME信息',
        dataIndex: 'cname',
        key: 'cname'
    },
    // {
    //     title: '是否官网',
    //     dataIndex: 'isOfficial',
    //     key: 'isOfficial',
    //     render: (text: string, record: object) => <span>{text ? '是' : '否'}</span>
    // }
]

// 云服务产品 -- icp
export const icpColumns = [
    {
        title: '网站名称',
        dataIndex: 'icpName',
        key: 'icpName'
    },
    {
        title: '域名',
        dataIndex: 'companyDomain',
        key: 'companyDomain'
    },
    {
        title: '运营商',
        dataIndex: 'icp',
        key: 'icp'
    },
    {
        title: 'ip',
        dataIndex: 'ip',
        key: 'ip'
    },
    {
        title: '备案号',
        dataIndex: 'icpCode',
        key: 'icpCode'
    },
    {
        title: '审核时间',
        dataIndex: 'icpDate',
        key: 'icpDate'
    },
    // {
    //     title: '是否官网',
    //     dataIndex: 'isOfficial',
    //     key: 'isOfficial',
    //     render: (text: string, record: object) => <span>{text ? '是' : '否'}</span>
    // }
]

// 企业信用等级
export const creditRateColumns = [
    {
        title: '评级公司',
        dataIndex: 'creditCompany',
        key: 'creditCompany'
    },
    {
        title: '主体等级',
        dataIndex: 'creditLevel',
        key: 'creditLevel'
    },
    {
        title: '债券信用等级',
        dataIndex: 'creditBond',
        key: 'creditBond'
    },
    {
        title: '评级展望',
        dataIndex: 'creditRate',
        key: 'creditRate'
    },
    {
        title: '评级时间',
        dataIndex: 'creditDate',
        key: 'creditDate'
    }
]

export const contactColumnsFn: (operate?: Function) => any[] = (operate) => [
    // {
    //     title: '主要联系人',
    //     dataIndex: 'primaryContact',
    //     key: 'primaryContact',
    //     width: 120,
    //     render: (text: string) => <Radio disabled checked={text === '1'}></Radio> 
    // },
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        width: 100,
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
        width: 180,
        render: (text: string, record: any) => <div>
            {text} 
            {/* {text ? <Icon type="icon-icon_yulan" style={{cursor: 'pointer'}} onClick={() => operate && operate('查看号码', record)} /> : null} */}
        </div>
    },
    {
        title: '固话',
        dataIndex: 'fxPhone',
        key: 'fxPhone',
    },
    {
        title: '电子邮箱',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: '微信号码',
        dataIndex: 'wechat',
        key: 'wechat',
    },
    // {
    //     title: 'QQ号码',
    //     dataIndex: 'qq',
    //     key: 'qq',
    // },
    {
        title: '钉钉号',
        dataIndex: 'dingNumber',
        key: 'dingNumber',
    },
    // {
    //     title: '联系结果反馈',
    //     dataIndex: 'feedbackStr',
    //     key: 'feedbackStr',
    // },
    {
        title: '所属职位',
        dataIndex: 'positionStr',
        key: 'positionStr',
    },
    {
        title: '来源',
        dataIndex: 'sourceStr',
        key: 'sourceStr',
    },
    {
        title: '备注',
        dataIndex: 'remarks',
        key: 'remarks',
    },
    {
        title: '操作',
        dataIndex: 'operate',
        key: 'operate',
        readOnly: true,
        render: (text: string, record: object) => [
         <a key="update" onClick={() => operate && operate('编辑联系人', record)}>编辑</a>
        ]
    }
]

// 跟进记录
export const followRecordColumnsFn: (operate?: Function) => any[] = (operate) => [
    {
        title: '本次沟通联系人',
        dataIndex: 'contactsName',
        key: 'contactsName',
        width: 120,
    },
    {
        title: '跟进方式',
        dataIndex: 'followUpMethod',
        key: 'followUpMethod',
        width: 120,
    },
    {
        title: '商机标题',
        dataIndex: 'businessOpportunityTitle',
        key: 'businessOpportunityTitle',
    },
    {
        title: '本次跟进时间',
        dataIndex: 'followUpTime',
        key: 'followUpTime',
        width: 160
        // sorter: true
    },
    {
        title: '本次跟进协同人',
        dataIndex: 'synergName',
        // hideInTable: true,
        order: 17,
    },
    {
        title: '项目阶段',
        dataIndex: 'projectStage',
        key: 'projectStage',
        width: 160,
    },
    {
        title: '跟进内容',
        dataIndex: 'followUpContent',
        // ellipsis: true,
        key: 'followUpContent',
        width: 240,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.followUpContent}</div>
    },
    {
        title: '待突破的卡点',
        dataIndex: 'stickingPoint',
        key: 'stickingPoint',
        width: 160,
    },
    {
        title: '求助点',
        dataIndex: 'helpPoint',
        key: 'helpPoint',
        width: 160,
    },
    {
        title: '下一步动作',
        dataIndex: 'nextActionContent',
        hideInSearch: true,
        // width: 120,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextActionContent}</div>
        // order: 6,
    },
    {
        title: '账号是否注册',
        dataIndex: 'accountRegister',
        width: 100,
        // hideInSearch: true,
        order: 16,
        render: (text: any, record: any) => record.accountRegister == 0 ? '否' : '是'
    },
    {
        title: '建立沟通群',
        dataIndex: 'createGroup',
        width: 100,
        // hideInSearch: true,
        order: 15
    },
    {
        title: '是否80%以上概率落单',
        dataIndex: 'makeSuccess',
        width: 100,
        hideInSearch: true,
        render: (text: any, record: any) => record.makeSuccess == 0 ? '否' : '是'
    },
    {
        title: '是否poc测试',
        dataIndex: 'isPocTestStr',
        key: 'isPocTestStr',
        width: 160,
        render: (text: any, record: any) => record.pocTest == 0 ? '否' : '是'
    },
    {
        title: '采购方式',
        dataIndex: 'procurementMethod',
        width: 100,
        // hideInSearch: true,
        order: 13
    },
    {
        title: '下单方式',
        dataIndex: 'orderMethod',
        width: 100,
        order: 12
        // hideInSearch: true,
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 160,
        sorter: true,
    },
    {
        title: '创建人',
        dataIndex: 'creator',
        key: 'creator',
        width: 80
    },
    
    // {
    //     title: '操作',
    //     dataIndex: 'operate',
    //     key: 'operate',
    //     width: 120,
    //     render: (text: string, record: object) => [
    //      <a key="update" onClick={() => operate?.('编辑跟进', record)}>编辑</a>,
    //      <a key="del" style={{marginLeft: 20}} onClick={() => operate?.('删除跟进', record)}>删除</a>
    //     ]
    // }
]

// 商机记录
export const businessRecordColumnsFn: (operate?: Function) => any[] = (operate) => {
    const history = useHistory()
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({dictId: 13 }, false)
    const [, hasAuthCode] = useHasAuthCode()
    return [
        {
            title: '商机标题',
            dataIndex: 'businessOpportunityTitle',
            key: 'businessOpportunityTitle',
            render: (text: any, {businessOppId: id, customerId, customerName, emphasisProject}: any) => {
                const onClick = () => {
                    hasAuthCode(2180) ? history.push(`/customer/businessDetail?${stringify({id, customerId, customerName, emphasisProject})}`) : null
                }
                return <a onClick={onClick}><Ellipsis text={text as any||'-'} /></a>
            }
        },
        // {
        //     title: '关注业务线',
        //     dataIndex: 'followBusinessLine',
        //     key: 'followBusinessLine',
        // },
        {
            title: '创建人',
            dataIndex: 'creator',
            order: 50,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              treeData: deptWithUserTreeData
            },
            // renderFormItem: (props) => <TreeSelect />,
            // search: {
            //   transform: (ids) => {
            //     const creator = (ids||[]).map((v: string) => v.slice(1))
            //     return { creator }
            //   },
            // },
        },
        {
            title: (_: any, type: any) => type === 'form' ? '业务线' : '关注业务线',
            dataIndex: 'followBusinessLine',
            order: 43,
            valueType: 'select',
            fieldProps: { mode: 'multiple' },
            params: { dictId: 19, pageCurrent: 1, pageSize: 1000},
            request
        },
        {
            title: '项目机会点',
            dataIndex: 'opportunityPoint',
            order: 19,
            hideInSearch: true,
        },
        {
            title: '项目阶段',
            dataIndex: 'salesStage',
            order: 42,
            width: 130,
            valueType: 'select',
            fieldProps: { mode: 'multiple' },
            params: { dictId: 20, pageCurrent: 1, pageSize: 1000},
            search: {
                transform: (salesStage: any) => {
                  return { 
                    salesStageList: salesStage
                  };
                },
            },
            request
        },
        {
            title: (_: any, type: any) => type === 'form' ? '预计签单' : '预计签单日期',
            dataIndex: 'estimatedSigningDate',
            order: 41,
            sorter: true,
            valueType: 'dateRange',
            width: 160,
            fieldProps: { allowEmpty: [true, true]},
            search: {
                transform: (estimatedSigningDates: any) => {
                    return { estimatedSigningDates};
                },
            },
            render: (text: any, record: any) => `${(record||{}).estimatedSigningDate||''}`
        },
        {
              title: '预计Cash收入(元)',
              valueType: 'digit',
              dataIndex: 'estimatedCashIncome',
              order: 41,
              // hideInSearch: true,
              sorter: true,
              fieldProps: {precision: 0},
            //   renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
        },
        {
            title: '预计差价收入(元)',
            valueType: 'digit',
            dataIndex: 'estimatedBetweenIncome',
            order: 33,
            // hideInSearch: true,
            sorter: true,
            fieldProps: {precision: 0},
            //   renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
        },
        {
            title: '意向产品',
            dataIndex: 'productStr',
            order: 18,
            hideInSearch: true,
            render: (text: any, record: any) => (record.productStr||'').toString()
        },
        {
            title: '采购方式',
            valueType: 'select',
            dataIndex: 'buyMethod',
            fieldProps: { mode: 'multiple' },
            order: 20,
            render: (text: any, record: any) => record?.buyMethodStr
        },
        {
            title: '下单方式',
            valueType: 'select',
            dataIndex: 'orderMethod',
            fieldProps: { mode: 'multiple' },
            order: 12,
            render: (text: any, record: any) => (record.orderMethodStr||'-')
        },
        {
            title: '订单类型',
            valueType: 'select',
            dataIndex: 'orderType',
            fieldProps: { mode: 'multiple' },
            order: 11,
            render: (text: any, record: any) => (record.orderTypeStr||'-')
        },
        {
            title: '账号是否注册',
            valueType: 'select',
            dataIndex: 'accountIsNew',
            order: 22,
            // hideInSearch: true,
        },
        {
            title: '是否80%以上概率落单',
            dataIndex: 'isChanceOfBeingAloneStr',
            order: 17,
            hideInSearch: true,
        },
        {
            title: '是否poc测试',
            valueType: 'select',
            dataIndex: 'isPocTestStr',
            order: 21,
            // hideInSearch: true,
        },
        {
            title: '建立沟通群',
            dataIndex: 'setUpGroup',
            order: 23,
            // hideInSearch: true,
        },
        {
            title: '协同人(售前)',
            dataIndex: 'synergy',
            order: 30,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              treeData: deptWithUserTreeData
            },
            render: (text: any, record: any) => (record.synergy||'无')
            // renderFormItem: (props) => <TreeSelect />,
            // search: {
            //   transform: (ids) => {
            //     const synergys = (ids||[]).map((v: string) => v.slice(1))
            //     return { synergys }
            //   },
            // },
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            order: 32,
            sorter: true,
            valueType: 'dateRange',
            fieldProps: { allowEmpty: [true, true]},
            // search: {
            //   transform: (createTimes) => {
            //     return {
            //       createTimes
            //     };
            //   },
            // },
            render: (text: any, record: any) => `${(record||{}).createTime||''}`
        },
        // // {
        // //     title: '主要联系人',
        // //     dataIndex: 'primaryContact',
        // //     key: 'primaryContact',
        // // },
        // {
        //     title: '关注业务线',
        //     dataIndex: 'followBusinessLine',
        //     key: 'followBusinessLine',
        // },
        // // {
        // //     title: '预计销售金额',
        // //     dataIndex: 'estimatedSalesAmount',
        // //     key: 'estimatedSalesAmount',
        // // },
        // {
        //     title: '预计签单日期',
        //     dataIndex: 'estimatedSigningDate',
        //     key: 'estimatedSigningDate',
        //     sorter: true
        // },
        // {
        //     title: '销售阶段',
        //     dataIndex: 'salesStage',
        //     key: 'salesStage',
        // },
        // {
        //     title: '协同人',
        //     dataIndex: 'synergy',
        //     key: 'synergy',
        // },
        // {
        //     title: '创建时间',
        //     dataIndex: 'createTime',
        //     key: 'createTime',
        //     sorter: true,
        // },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            readOnly: true,
            render: (text: string, record: any) => [
                <a key="update" style={{marginRight: 16}} onClick={() => operate?.('新建跟进', record)}>跟进</a>,
                <Link key="update" to={`/customer/businessAdd?${stringify({id: record.businessOppId, customerId: record.customerId, routeTitName: record.businessOpportunityTitle})}`}>编辑</Link>,
                
            ]
        }
    ]
}


// 客户详情descList
export const mainDesList: DescListItem[] = [
    {
        label: '客户类型',
        param: 'customerTypeStr',
        span: 1
    },
    {
        label: '客户状态',
        param: 'stateStr',
        span: 1
    },
    {
        label: '客户来源',
        param: 'customerSourceStr',
        span: 1
    },
    {
        label: '客户属性',
        param: 'customerPropertyStr',
        span: 1
    },
    {
        label: '关注业务线',
        param: 'businessLinesStr',
        span: 1,
    },
    {
        label: '所属行业',
        value: (record: any) => <Ellipsis text={((record||{}).customerLabelStr||[]).toString()} />,
        span: 1
    },
    {
        label: '归属人',
        param: 'ownerName',
        span: 1
    },
    {
        label: '协同人',
        param: 'synnames',
        span: 1,
        value: (record: any) => record?.synnames|| '无'
    },
    {
        label: '项目经理',
        param: 'projectManager',
        span: 1,
        value: (record: any) => record?.projectManagerStr||'无',
    },

    // {
    //     label: '客户等级',
    //     param: 'customerLevelStr',
    //     span: 1
    // },
    // {
    //     label: '客户标签',
    //     value: (record: any) => <Ellipsis text={((record||{}).customerLabelStr||[]).toString()} />,
    //     span: 1
    // },
    // {
    //     label: '所属地区',
    //     param: 'location',
    //     span: 1
    // },
    // {
    //     label: '合作模式',
    //     param: 'accountModes',
    //     span: 1,
    // },
    
]

// 线索池-线索详情descList
export const cluesDesList: DescListItem[] = [
    {
        label: '线索来源',
        param: 'customerSourceStr',
        span: 1
    },
    {
        label: '线索池',
        param: 'cluePoolName',
        span: 1
    },
    {
        label: '所属地区',
        param: 'location',
        span: 1
    },
    {
        label: '详细地址',
        param: 'detailAddress',
        span: 1
    },
    {
        label: '前归属人',
        param: 'formerOwner',
        span: 1
    },
    {
        label: '转入时间',
        param: 'inputTime',
        span: 1
    },
]

// 线索池-线索详情descList
export const myCluesDesList: DescListItem[] = [
    // {
    //     label: '线索来源',
    //     param: 'customerSourceStr',
    //     span: 1
    // },
    // {
    //     label: '客户标签',
    //     value: (record: any) => <Ellipsis text={`${(record||{}).customerLabelStr}`} /> ,
    //     span: 1
    // },
    // {
    //     label: '所属地区',
    //     param: 'location',
    //     span: 1
    // },
    // {
    //     label: '详细地址',
    //     param: 'detailAddress',
    //     span: 1
    // },
    // {
    //     label: '归属人',
    //     param: 'ownerName',
    //     span: 1
    // },
    // {
    //     label: '转入时间',
    //     param: 'inputTime',
    //     span: 1
    // },

    {
        label: '客户类型',
        param: 'customerTypeStr',
        span: 1
    },
    {
        label: '客户状态',
        param: 'stateStr',
        span: 1
    },
    {
        label: '客户来源',
        param: 'customerSourceStr',
        span: 1
    },
    {
        label: '客户属性',
        param: 'customerPropertyStr',
        span: 1
    },
    {
        label: '关注业务线',
        param: 'businessLinesStr',
        span: 1,
    },
    {
        label: '所属行业',
        value: (record: any) => <Ellipsis text={((record||{}).customerLabelStr||[]).toString()} />,
        span: 1
    },
    {
        label: '归属人',
        param: 'ownerName',
        span: 1
    },
    {
        label: '协同人',
        param: 'synnames',
        span: 1,
        value: (record: any) => record?.synnames|| '无'
    },

]

export const useBaseInfo = (param: any = {}) => {
    const [baseInfo, setBaseInfo] = useState({})
    const getHeaderInfo = async (param: any) => {
        await getHeaderInfoApi(param, false, (data: any) => {
            setBaseInfo(data)
        })
    }

    useEffect(() => {
        getHeaderInfo(param)
        return () => {
            setBaseInfo({})
        }
    }, [])

    return [baseInfo, setBaseInfo]
}

export const useCorpInfo = (param: any = {}, type: number) => {
    const [baseInfo, setBaseInfo] = useState<any>({})
    const getCorpInfo = async (param: any) => {
        await getLqCorpInfoApi(param, false, (data: any) => {
            setBaseInfo(data)
        })
    }

    useEffect(() => {
        getCorpInfo({...param, type})
        return () => {
            setBaseInfo({})
        }
    }, [])

    return [baseInfo, setBaseInfo]
}

export const useCloundInfo = (param: any = {}) => {
    const [baseInfo, setBaseInfo] = useState<any>({})
    const getCloundInfo = async (param: any) => {
        await getLqCloudInfoApi(param, false, (data: any) => {
            setBaseInfo(data)
        })
    }

    useEffect(() => {
        getCloundInfo(param)
        return () => {
            setBaseInfo({})
        }
    }, [])

    return [baseInfo, setBaseInfo]
}

type ActionsProps = {
    operate: Function, 
    id: string, 
    name: string, 
    record: any,
    detailObj: any;
    reFresh: () => void
}

// 来源Map
export const SourceMaps: any = {
    0: {
        title: '客户详情',
        from: '客户列表',
        Actions: ({operate, id, name, detailObj, reFresh}: ActionsProps) => {
            const [, hasAuthCode] = useHasAuthCode()
            const { isSyn, introduction, isEmphasisCustomer } = detailObj || {}
            return [
                // <CreateBrief key="addDesc" introduction={introduction} params={{companyName: name}} onSuccess={reFresh} />,
                // <UpdateCompanyInfo key="update" params={{name}} onSuccess={reFresh} />,
                hasAuthCode(2489) && !isEmphasisCustomer ? <CustomerToImportant key="转重点" title="转重点" selectedRowKeys={[id]} selectedRows={[detailObj]} isCanToImportantParams={{type: 1}} params={{type: 1}} /> : null,
                hasAuthCode(2034) && [0].includes(isSyn) ? <Button key="编辑客户" type="primary" style={{margin: '0 10px'}}><Link to={`/customer/add?${stringify({customerId: id, sourceType: 0, routeName: '编辑客户', routeTitName: name, fromPath: '/customer/list'})}`}>编辑客户</Link></Button> : null,
                hasAuthCode(2043) && [0].includes(isSyn) ? <AddHelper key="bnt1" title="添加协同人" selectedRowKeys={[id]} trigger={<Button style={{marginRight: 10, marginLeft: 10}}>添加协同人</Button>} onSuccess={() => null} /> : null,
                hasAuthCode(2023) && [0].includes(isSyn) ? <TransferCustomer key="bnt2" title="转移客户" selectedRowKeys={[id]} trigger={<Button style={{marginRight: 10}}>转移客户</Button>} onSuccess={() => null} /> : null,
                hasAuthCode(2112) && [0].includes(isSyn) ? <CustomerToCluePoolButton key="btn3" style={{marginRight: 10}} params={{customerIds: [id], type: 1}} onSuccess={() => null}>移入线索池</CustomerToCluePoolButton>  : null,
                <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                    {[0].includes(isSyn) ? <Menu.Item><AddContact key="添加联系人" title="添加联系人" customerId={id} trigger={<a style={{marginLeft: 10}}>新建联系人</a>} /></Menu.Item> : null}
                    <Menu.Item><a key="新建跟进" onClick={() => operate('添加跟进')} style={{marginLeft: 10}}>新建跟进</a></Menu.Item>
                    {[0].includes(isSyn) ? <Menu.Item><Link to={`/customer/businessAdd?${stringify({customerName: name, customerId: id, fromPath: '/customer/list'})}`} style={{marginLeft: 10}}>新建商机</Link></Menu.Item> : null}
                </Menu>}>
                    <Button style={{marginLeft: 0}}>更多操作</Button>
                </Dropdown>,
            ]
        },
        Descriptions: ({detailObj}: any) => <DescriptionsList data={detailObj} descList={mainDesList} />,
    },
    1: {
        title: '线索详情',
        from: '线索池',
        Actions: ({operate, id, name, detailObj, reFresh}: ActionsProps) => {
            const [, hasAuthCode] = useHasAuthCode()
            const { isManager, introduction } = detailObj || {}
            return [
                // <CreateBrief key="addDesc" introduction={introduction} params={{companyName: name}} onSuccess={reFresh} />,
                // <UpdateCompanyInfo key="update" params={{name}} onSuccess={reFresh} />,
                hasAuthCode(2147) ? <GetCluesButton key="领取线索" style={{marginLeft: 10}} params={{clueIds: [id]}} onSuccess={() => null}>领取线索</GetCluesButton> : null,
                hasAuthCode(2149) ? <ClueToCustomerButton key="转为客户" style={{marginLeft: 10}} params={{clueIds: [id]}} onSuccess={() => null}>转为客户</ClueToCustomerButton> : null,
                hasAuthCode(2148) && [1].includes(isManager) ? <AddPool key="bnt3" title="转移线索" selectedRowKeys={[id]} trigger={<Button style={{marginLeft: 10}}>转移线索</Button>} onSuccess={() => null} /> : null,
            ]
        },
        Descriptions: ({detailObj}: any) => <DescriptionsList data={detailObj} descList={cluesDesList} />
    },
    2: {
        title: '线索详情',
        from: '我的线索',
        Actions: ({operate, id, name, detailObj, reFresh}: ActionsProps) => {
            const [, hasAuthCode] = useHasAuthCode()
            const { introduction } = detailObj || {}
            return [
                // <CreateBrief key="addDesc" introduction={introduction} params={{companyName: name}} onSuccess={reFresh} />,
                // <UpdateCompanyInfo key="update" params={{name}} onSuccess={reFresh} />,
                hasAuthCode(2197) ? <Button key="编辑线索" style={{marginLeft: 10}}><Link key="update" to={`/customer/add?${stringify({customerId: id, sourceType: 1, routeName: '编辑线索', routeTitName: name, fromPath: '/customer/myPool'})}`}>编辑线索</Link></Button> : null,
                hasAuthCode(2169) ? <MyCluesToCustomerButton key="转为客户" style={{marginLeft: 10}} params={{clueIds: [id]}} onSuccess={() => null}>转为客户</MyCluesToCustomerButton> : null,
                hasAuthCode(2168) ? <TransferClue key="bnt2" title="转移线索" selectedRowKeys={[id]} trigger={<Button style={{marginLeft: 10}}>转移线索</Button>} onSuccess={() => null} /> : null,
                hasAuthCode(2170) ? <MyCluesToPoolButton key="移入线索池" style={{marginLeft: 10}} params={{clueIds: [id]}} onSuccess={() => null}>移入线索池</MyCluesToPoolButton> : null,
        ]},
        Descriptions: ({detailObj}: any) => <DescriptionsList data={detailObj} descList={myCluesDesList} />
    }
}
