import React from "react"


export const salesStageColumns = [
    {
        title: '项目阶段',
        key: 'stage',
        dataIndex: 'stage'
    },
    {
        title: '里程碑标识',
        key: 'desc',
        dataIndex: 'desc',
        render: (text: any) => <div dangerouslySetInnerHTML={{__html: text}}></div>
    },
]

export const salesStageDataSource = [
    {
        stage: '10% 潜在商机',
        desc: `1、有明确的联系人 <br/>
               2、客户存在需求 <br/>
               3、有交流意向，可约拜访`
    },
    {
        stage: '20% 商机确认',
        desc: `
            1、已上门沟通交流 <br/>
            2、现网调研明确客户业务、IT架构等<br/>
            3、需求清晰明确<br/>
            4、清楚项目预算<br/>
            5、项目有时间节点要求<br/>
            6、对接到项目的负责人    
          `
    },
    {
        stage: '40% 方案沟通',
        desc: `
        1、提交并汇报对应的方案<br/>
        2、项目决策链路清晰，并接触到关键人<br/>
        3、客户对方案没有提出明确的异议，并存在支持方<br/>
        4、在客户方对接到两个以上的人员<br/>
        5、客户有测试意向，并投入一定的精力了解产品和方案
       `
    },
    {
        stage: '60% 商务谈判',
        desc: `
        1、确定项目报价<br/>
        2、明确存在的竞争对手，并了解其方案情况<br/>
        3、同项目决策人做过明确的工作交流<br/>
        4、明确客户方采购流程
        `
    },
    {
        stage: '80% 签约完成',
        desc: `
        1、完成招投标或者业务相关决策性动作<br/>
        2、拿到中标通知书<br/>
        3、签订合同<br/>
        4、客户方有明确的项目启动动作
        `
    },
    {
        stage: '100% 收入确认',
        desc: '完成收款或部分收款'
    },
    {
        stage: '持续运营阶段',
        desc: '客户由新拓落单转为存量后，进入到持续运营阶段'
    },
    {
        stage: '丢单',
        desc: '项目机会已明确被竞对拿下'
    },
    {
        stage: '挂起',
        desc: '项目遇到种种情况暂时搁置，后续会再重启'
    },
]