import React from "react";
import { TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import moment from "moment";

// ts--types
export type TableListItem = {
    key: number;
    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '行点击' 

export const columnsFn1: (operate: Function, type: any) => ProColumns<TableListItem>[] = (operate, type) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    return [
        {
            title: '报告类型',
            dataIndex: 'type',
            hideInSearch: true,
            render:(text: any, record: any) => ['-','日报', '周报'][record.type]
        },
        {
            title: '提交人',
            dataIndex: 'username',
            order: 1,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                  const userIds = (ids||[]).map((v: string) => v.slice(1))
                  return { userIds }
                },
            },
        },
        {
            title: '统计时间',
            dataIndex: type == 2 ? 'month' : 'period',
            order: 2,
            valueType: type == 2 ? 'dateMonth' : 'dateRange',
            fieldProps: { 
                allowEmpty: [true, true], 
                defaultValue: type == 2 ? moment() : [moment().startOf('month'), moment()]
            },
            formItemProps: {
                initialValue: type == 2 ? moment() : [moment().startOf('month'), moment()],  
            },
            search: {
              transform: (value) => {
                return type == 2 ? {week: value} : {
                  startTime: value[0],
                  endTime: value[1],
                };
              },
            },
            render: (text, record: any) => <>{record.period}</>
        },
        {
            title: '应提交',
            dataIndex: 'expectedSubmits',
            hideInSearch: true,
        },
        {
            title: '已提交',
            dataIndex: 'submitted',
            hideInSearch: true,
        },
        {
            title: '未提交',
            dataIndex: 'submitted',
            hideInSearch: true,
            render: (text, record: any) => <>{record.expectedSubmits - record.submitted}</>
        },
        {
            title: '合格',
            dataIndex: 'passed',
            hideInSearch: true,
        },
        {
            title: '不合格',
            dataIndex: 'rejected',
            hideInSearch: true,
        },
        {
            title: '完成率',
            dataIndex: 'pending',
            hideInSearch: true,
            render: (text: any, record: any) => ((record.submitted * 100) / record.expectedSubmits).toFixed(0) + '%'
        },
        // {
        //     title: '待批阅',
        //     dataIndex: 'pending',
        //     hideInSearch: true,
        // },
    ]
}

export const columnsFn2: (operate: Function, type: any) => ProColumns<TableListItem>[] = (operate, type) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    return [
        {
            title: '报告类型',
            dataIndex: 'type',
            hideInSearch: true,
            render:(text: any, record: any) => ['-','日报', '周报'][record.type]
        },
        {
            title: '批阅人',
            dataIndex: 'username',
            order: 1,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData
            },
            renderFormItem: (props: any) => <TreeSelect />,
            search: {
                transform: (ids: any[]) => {
                  const userIds = (ids||[]).map((v: string) => v.slice(1))
                  return { userIds }
                },
            },
        },
        {
            title: '统计时间',
            dataIndex: type == 2 ? 'month' : 'period',
            order: 2,
            valueType: type == 2 ? 'dateMonth' : 'dateRange',
            fieldProps: { 
                allowEmpty: [true, true], 
                // defaultValue: type == 2 ? moment() : [moment().startOf('month'), moment()]
            },
            formItemProps: {
                initialValue: type == 2 ? moment() : [moment().startOf('month'), moment()],  
            },
            search: {
              transform: (value) => {
                return type == 2 ? {month: value} : {
                  startTime: value[0],
                  endTime: value[1],
                };
              },
            },
            render: (text, record: any) => <>{record.period}</>
        },
        {
            title: '应批阅',
            dataIndex: 'expectedSubmits',
            hideInSearch: true,
        },
        {
            title: '已批阅',
            dataIndex: 'submitted',
            hideInSearch: true,
        },
        {
            title: '待批阅',
            dataIndex: 'pending',
            hideInSearch: true,
        },
        {
            title: '合格',
            dataIndex: 'passed',
            hideInSearch: true,
        },
        {
            title: '不合格',
            dataIndex: 'rejected',
            hideInSearch: true,
        },
    ]
}
