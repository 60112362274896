import React, { lazy, ReactNode, useEffect } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { KeepaliveRouterSwitch ,KeepaliveRoute ,addKeeperListener } from 'react-keepalive-router'
import { KeepAliveProvider, WithKeepAlive } from '@components/Common/Cache'; // react 组件缓存
import { isDef, parseSearch } from '@utils/index';

import { 
    LazyLoad,
    Login, 
    NotFond, 
    Authorized,
    CustomerList,
    AddCustomer,
    CustomerCluesPool,
    WorkReport,
    Organization,
    Home,
    Workbench,
    Roles,
    Rules,
    CluesPoolManage,
    DataDictionary,
    CustomTags,
    User,
    MyPool,
    Business,
    AddBusiness,
    BusinessDetail,
    EnterpriseDetail,
    LqEnterpriseDetail,
    Test,
    BasicLayout,
    NoPermission,
    ContactAll,
    FollowRecordAll,
    Product,
    LqSearch,
    LqMySearch,
    LqRecommend,
    LqRules,
    LqOperateLog,
    RebateList,
    RebateRules,
    PayeeSummany,
    RebateConfirm,
    RebateDetail,
    KnowledgeHome,
    KnowledgeMine,
    KnowledgeDocList,
    KnowledgeDocAudit,
    KnowledgeIssueList,
    // 备选方案
    CreateDocument,
    DocumentDetail,
    Answered,

    TaskCenter,
    MyTask,
    AddTask,
    TaskDetail,
    MessageCenter,
    MyMessage,
    AddMessage,
    MessageDetail,

    BillManage,
    BillAudit,
    Contract,
    AddBill,
    BillDetail,
    Log,
    ContractAudit,
    RebackMoney,
    RebackMoneyAudit,
    FollowDetail,
    SysDiydoFunction,
    SysDiydoField
} from '@components/index'
import { SaleContractDetail } from '@components/Finance/Contract/cpnts/SaleContract/cpnts';
import { PurchaseContractDetail } from '@components/Finance/Contract/cpnts/PurchaseContract/cpnts';
import { RebackDetail } from '@components/Finance/RebackMoney/cpnts/RebackList/cpnts';

export type MenuItem = {
    path: string; // 路由
    exact?: boolean;
    render?: () => ReactNode;
    parentPath?: string; // 所属父级菜单
    name?: ReactNode | string; // 菜单名称
    component?: ReactNode; // 路由组件
    custComponent?: ReactNode;  // 自定义缓存路由组件
    cacheId?: () => string | string; // 缓存组件的 id
    hideInMenu?: Boolean; // 是否显示在菜单栏
    noCache?: Boolean; // 是否不缓存该组件
    icon?: ReactNode | string; // 菜单icon
    authCode?: string | number; // -1 为常量权限(不受角色控制)
    children?: MenuItem[]
}

// 客户管理模块
const CustomerMenuRouters: MenuItem[] = [
    {
        path: '/customer/list',
        name: '客户列表',
        authCode: 2005,
        component: CustomerList
    },
    {
        path: '/customer/detail',
        name: '客户详情',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        parentPath: '/customer/list',
        authCode: 2005,
        component: EnterpriseDetail
    },
    {
        path: '/customer/cluesPool',
        name: '线索池',
        authCode: 2120,
        component: CustomerCluesPool
    },
    {
        path: '/customer/myPool',
        name: '我的线索',
        authCode: 2124,
        component: MyPool
    },
    {
        path: '/customer/business',
        name: '商机管理',
        authCode: 2126,
        component: Business,
    },
    {
        path: '/customer/contact',
        name: '联系人',
        authCode: 2215,
        component: ContactAll,
    },
    {
        path: '/customer/followRecord',
        name: '跟进记录',
        authCode: 2198,
        component: FollowRecordAll,
    },
    {
        path: '/customer/followDetail',
        name: '跟进详情',
        authCode: -1,
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        component: FollowDetail,
    },
    {
        path: '/customer/add',
        name: '新建客户',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: 2005,
        component: AddCustomer
    },
    {   
        path: '/customer/businessAdd',
        parentPath: '/customer/business',
        name: '创建商机',
        authCode: 2126,
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        component: AddBusiness
    },
    {
        path: '/customer/businessDetail',
        parentPath: '/customer/business',
        name: '商机详情',
        authCode: 2126,
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        component: BusinessDetail
    },
    
]

// 协同办公模块
const OaMenuRouters: MenuItem[] = [
    {
        path: '/oa/workReport',
        name: '工作报告',
        noCache: true, // 不缓存该组件
        authCode: 2114,
        component: WorkReport,
    },
    {
        path: '/oa/task',
        name: '任务中心',
        authCode: 2370,
        component: TaskCenter,
    },
    {
        path: '/oa/myTask',
        name: '我的任务',
        authCode: 2376,
        component: MyTask,
    },
    {
        path: '/oa/addTask',
        name: '新建任务',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: -1,
        component: AddTask,
    },
    {
        path: '/oa/taskDetail',
        name: '任务详情',
        hideInMenu: true,
        // noCache: true, // 不缓存该组件
        authCode: -1,
        component: TaskDetail,
        cacheId: () => {
            const { search } = window.location
            const { id } = parseSearch(search)
            const cacheId = `/oa/taskDetail/${id}`
            return cacheId
        }
    },
    {
        path: '/oa/message',
        name: '消息中心',
        authCode: 2377,
        component: MessageCenter,
    },
    {
        path: '/oa/myMessage',
        name: '我的消息',
        authCode: 2384,
        component: MyMessage,
    },
    {
        path: '/oa/addMessage',
        name: '新建公告',
        hideInMenu: true,
        noCache: true,
        authCode: -1,
        component: AddMessage,
    },
    {
        path: '/oa/messageDetail',
        name: '消息详情',
        hideInMenu: true,
        noCache: true,
        authCode: -1,
        component: MessageDetail,
    },
]

// 产品管理模块
const ProductRouters: MenuItem[] = [
    {
        path: '/product',
        name: '产品管理',
        authCode: 2202,
        component: Product
    }
]

// 系统管理模块
const SystemMenuRouters: MenuItem[] = [
    {
        path: '/system/organization',
        name: '组织架构',
        authCode: 100,
        component: Organization
    },
    {
        path: '/system/roles',
        name: '角色管理',
        authCode: 101,
        component: Roles
    },
    {
        path: '/system/rules',
        name: '规则管理',
        authCode: 2119,
        component: Rules
    },
    {
        path: '/system/cluesPoolManage',
        name: '线索池管理',
        authCode: 2163,
        component: CluesPoolManage
    },
    {
        path: '/system/dataDictionary',
        name: '数据字典',
        authCode: 2121,
        component: DataDictionary
    },
    {
        path: '/system/tags',
        name: '自定义标签',
        authCode: 2012,
        component: CustomTags
    },
    // {
    //     path: '/system/sysDiydoFunction',
    //     name: '功能字段管理',
    //     authCode: -1,
    //     // authCode: 888,
    //     component: SysDiydoFunction
    // },
    // {
    //     path: '/system/sysDiydoField',
    //     name: '字段管理',
    //     authCode: -1,
    //     hideInMenu: true,
    //     // authCode: 888,
    //     component: SysDiydoField
    // },
]

// 用户个人中心模块
const UserMenuRouters: MenuItem[] = [
    {
        path: '/user/personal',
        name: '个人中心',
        hideInMenu: true,
        authCode: -1,
        component: User
    }
]

// 猎奇引擎模块
const LqMenuRouters: MenuItem[] = [
    {
        path: '/lq/search',
        name: '企业查询',
        authCode: 2238,
        component: LqSearch
    },
    {
        path: '/lq/companyDetail',
        name: '企业详情',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: -1,
        component: LqEnterpriseDetail
    },
    {
        path: '/lq/mySearch',
        name: '批量查询',
        authCode: 2219,
        component: LqMySearch
    },
    {
        path: '/lq/recommend',
        name: '智能推荐',
        authCode: 2220,
        component: LqRecommend
    },
    {
        path: '/lq/rules',
        name: '规则设置',
        authCode: 2244,
        component: LqRules
    }, 
    {
        path: '/lq/operateLog',
        name: '操作日志',
        authCode: 2250,
        component: LqOperateLog
    }
]

// 客户返点
const CustomerRebateRouters: MenuItem[] =[
    {
        path: '/rebate/list',
        name: '返点列表',
        authCode: 2253,
        component: RebateList
    },
    {
        path: '/rebate/confirm',
        name: '确认信息',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: -1,
        component: RebateConfirm
    },
    {
        path: '/rebate/detail',
        name: '返点详情',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: -1,
        component: RebateDetail
    },
    {
        path: '/rebate/payeeSummany',
        name: '收款人汇总',
        authCode: 2295,
        component: PayeeSummany
    },
    {
        path: '/rebate/rules',
        name: '规则设置',
        authCode: 2275,
        component: RebateRules
    },
]

// 知识社区
export const KnowledgeCommunityRouters: MenuItem[] = [
    {
        path: '/knowledge/home',
        noCache: true, // 不缓存该组件
        name: '社区首页',
        authCode: 2362,
        component: KnowledgeHome
    },
    {
        path: '/knowledge/mine',
        name: '我的知识库',
        noCache: true, // 不缓存该组件
        authCode: 2349,
        component: KnowledgeMine
    },
    {
        path: '/knowledge/docList',
        name: '文档列表',
        authCode: 2332,
        component: KnowledgeDocList
    },
    {
        path: '/knowledge/docAudit',
        name: '文档审核',
        authCode: 2320,
        component: KnowledgeDocAudit
    },
    {
        path: '/knowledge/issue',
        name: '问题列表',
        authCode: 2336,
        component: KnowledgeIssueList
    },
    {
        path: '/knowledge/answered',
        name: '已答问卷',
        authCode: 2410,
        component: Answered
    },
    {
        path: '/knowledge/create',
        name: '创建文档',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: -1,
        component: CreateDocument
    },
    {
        path: '/knowledge/DocDetail',
        name: '文档详情',
        hideInMenu: true,
        noCache: true, // 不缓存该组件
        authCode: -1,
        component: DocumentDetail
    },
]

// 发票管理
const FinanceMenuRouters: MenuItem[] = [
    {
        path: '/finance/bill',
        name: '发票管理',
        authCode: 2394,
        component: BillManage
    },
    {
        path: '/finance/billAudit',
        name: '发票审批',
        authCode: 2395,
        component: BillAudit
    },
    {
        path: '/finance/contract',
        name: '合同管理',
        authCode: 2438,
        component: Contract
    },
    {
        path: '/finance/contractAudit',
        name: '合同审批',
        authCode: 2439,
        component: ContractAudit
    },
    {
        path: '/finance/saleContractDetail',
        name: '销售合同详情',
        authCode: -1,
        hideInMenu: true,
        noCache: true,
        component: SaleContractDetail
    },
    {
        path: '/finance/purchaseContractDetail',
        name: '采购合同详情',
        authCode: -1,
        hideInMenu: true,
        noCache: true,
        component: PurchaseContractDetail
    },
    {
        path: '/finance/rebackDetail',
        name: '回款记录详情',
        authCode: -1,
        hideInMenu: true,
        noCache: true,
        component: RebackDetail
    },
    {
        path: '/finance/rebackMoney',
        name: '回款管理',
        authCode: 2440,
        component: RebackMoney
    },
    {
        path: '/finance/rebackMoneyAudit',
        name: '回款审批',
        authCode: 2441,
        component: RebackMoneyAudit
    },
    {
        path: '/finance/billDetail',
        name: '发票详情',
        authCode: -1,
        hideInMenu: true,
        noCache: true,
        component: BillDetail
    },
    {
        path: '/finance/billAdd',
        name: '申请开票',
        authCode: -1,
        hideInMenu: true,
        noCache: true,
        component: AddBill
    },
]

// 项目菜单数据(生成菜单用)
export const MenuRouter: MenuItem[] = [
    {
        path: '/home',
        name: '工作台',
        icon: 'icon-shouye',
        authCode: -1,
        component: Home,
    },
    {
        path: '/customer',
        name: '客户管理',
        icon: 'icon-kehuguanli',
        authCode: 2003,
        children: [...CustomerMenuRouters]
    },
    {
        path: '/oa',
        name: '协同办公',
        icon: 'icon-xietongbangong',
        authCode: 2123,
        children: [...OaMenuRouters]
    },
    {
        path: '/product',
        name: '产品管理',
        icon: 'icon-chanpinmobanguanli',
        authCode: 2202,
        component: Product
    },
    {
        path: '/system',
        name: '系统管理',
        icon: 'icon-xitongguanli',
        authCode: 1,
        children: [...SystemMenuRouters]
    },
    {
        path: '/lq',
        name: '猎奇引擎',
        icon: 'icon-lieqiyinqing',
        authCode: 2218,
        children: [...LqMenuRouters]
    },
    {
        path: '/rebate',
        name: '客户返点',
        icon: 'icon-kehufandian',
        authCode: 2252,
        children: [...CustomerRebateRouters]
    },
    {
        path: '/finance',
        name: '账务管理',
        icon: 'icon-yewutubiao_caiwuguanlixitong',
        authCode: 2393,
        children: [...FinanceMenuRouters]
    },
    {
        path: '/knowledge',
        name: '知识社区',
        icon: 'icon-zhishishequ1',
        authCode: 2314,
        children: [...KnowledgeCommunityRouters]
    },
    {
        path: '/user',
        name: '个人中心',
        authCode: -1,
        hideInMenu: true,
        children: [...UserMenuRouters]
    }
]

// 项目所有路由表
const dynamicConfig = [
    {
        path: '/',
        component: Authorized,
        children: [
            {
                path: '/home',
                noCache: true,
                component: Home,
            },
            ...CustomerMenuRouters,
            ...OaMenuRouters,
            ...ProductRouters,
            ...SystemMenuRouters,
            ...UserMenuRouters,
            ...LqMenuRouters,
            ...CustomerRebateRouters,
            ...KnowledgeCommunityRouters,
            ...FinanceMenuRouters
        ]
    }
]

const RouterFn = (config: any[]) => {
    return (<Switch>
        <Route path={'/login'} component={Login}></Route>
        <Route path={'/noPermission'} component={NoPermission}></Route>
        <Route path={'/404'} component={NotFond}></Route>
        <Route path={'/log'} component={Log}></Route>
        <Route path={'/test'} component={Test}></Route>
        <KeepaliveRouterSwitch withoutRoute >
            {
                config.map((route: any, idx: number) => {
                    const { children, component: Component, noCache, redirect } = route
                    const RouterView = children && RouterFn(children)
                    return children ? <Component key={idx} RouterView={RouterView} /> : noCache ? <Route {...route} key={idx} /> : <KeepaliveRoute {...route} key={idx} />
                })
            }
        </KeepaliveRouterSwitch>
        {/* <Redirect from="*" to="/404" /> */}
        {/* <Redirect from="/customer" to="/customer/list" /> */}
        {/* <Redirect from="/oa" to="/oa/workReport" /> */}
        {/* <Redirect from="/system" to="/system/organization" /> */}
    </Switch>)
}
// export default RouterFn(dynamicConfig)

type RouterTreeProps = {
    config: any[]
}
const RouterTree = ({config}: RouterTreeProps) => {
   return (<>
    {
        (config||[]).map((route: any, idx: number) => {
            const { children, component: Component, noCache, custComponent, cacheId: calcCacheId, redirect, path } = route
            const RouterView = children && <RouterTree config={children} />
            const cacheId = (!isDef(calcCacheId) || typeof calcCacheId === 'string') ? (calcCacheId || route.path) : calcCacheId()
            return children ? <Component key={path} RouterView={RouterView} /> : noCache ? <Route {...route} key={path} /> : <KeepaliveRoute {...route} cacheId={cacheId} key={path} />
            // const calcComponent = noCache ? Component : custComponent ? custComponent() : WithKeepAlive(Component, {cacheId})
            // return children ? <Component key={idx} RouterView={RouterView} /> : <Route {...route} component={ calcComponent } key={idx} />
        })
    }
   </>)
}

export default <KeepaliveRouterSwitch withoutRoute>
    <Switch>
        {/* 项目静态路由(不需要权限管控) */}
        <Route path={'/login'} component={Login}></Route>
        <Route path={'/noPermission'} component={NoPermission}></Route>
        <Route path={'/404'} component={NotFond}></Route>
        <Route path={'/log'} component={Log}></Route>
        <Route path={'/test'} component={Test}></Route>
        {/* 动态菜单(需要权限管控的) */}
        <RouterTree config={dynamicConfig} />
        <Redirect from="*" to="/404" />
        <Redirect from="/customer" to="/customer/list" />
        <Redirect from="/oa" to="/oa/workReport" />
        <Redirect from="/system" to="/system/organization" />
    </Switch>
</KeepaliveRouterSwitch>