import { useTableHooks, useDictEnumHooks, useCantactsByCustomerIdHooks } from "@hooks/index"
import { Button, Col, Row, Table, Image, Modal } from "antd"
import React, { Key, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { customerAffix } from '@api/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { ModalForm, ProFormText, ProFormSelect} from '@ant-design/pro-form';
import { productApi, parseSearch } from "@utils/util"
import { StateType } from "@store/index";
import { useLocation } from "react-router"
import { EnterpriseDetailProps } from "../../type"
import { columnsFn } from './data'
import ProTable from "@ant-design/pro-table";
import { PreviewPlus } from "@components/index";
import { request } from "@utils/index";
import { saveAs } from 'file-saver'

const getApi = productApi(customerAffix)


// url 预览模式
// const imgSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/9E6B5DD9C75D400F9EF4B515C6E409D7_5294d977d96773e6ea9a81d3119b51c9.jpg'
// const imgSrc1 = 'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp'
// const imgSrc2 = 'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp'
// const imgSrc3 =  'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp'
// const wordSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A74090AF17F146D8BD667F5FF8188D12_云罟使用指南（精简）.docx'
// const excelSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/30B167B900F04769878916CBB4E3AF16_星辰数智产品报价模板v1.0_0513.xlsx'
// const pptSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A8ADCCE12F3E410FAD2AF860F4905666_新建 PPTX 演示文稿.pptx'
// const pdfSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/D86E4B42D89F411982CE150C644C7EBC_云罟云安全产品介绍.pdf'
// const vedioSrc = ''

export default ({id: customerId,Collaborator,searchType,businessOppId, name, ...rest}: EnterpriseDetailProps) => {
    const [visible, setVisible] = useState(false)
    const [item, setItem] = useState<any>({})
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
       
    }

    const operate = async (type: any, file: any) => {
        if (type === '预览') {
            setVisible(true)
            setTimeout(() => {
                setItem(file)
            }, 200)
        } else if (type === '下载') {
            saveAs(file.url, file?.name)
        }
    }
   
    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
            <ProTable<any>
                request={getListApi}
                columns={columnsFn(operate)}
                params={{customerId}}
                options={{reload: false, density: false, setting: false, fullScreen: false}}
                toolbar={{
                    actions: [],
                }}
                rowKey="id"
                search={false}
                pagination={{defaultPageSize: 10}}
                // actionRef={ref as any}
            />
            <Modal width={860} visible={visible} title={item?.name} onCancel={() => setVisible(false)} footer={false}>
                <PreviewPlus src={item?.url} style={{width: '100%'}} />
            </Modal>
    </div>)
}
