import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL, LQBASEURL } from './baseUrl'
/*-----------------------------------------工作台----------------------------------*/
// 查询数据简报的数量的数据
export const workBenchCount = (data?: any) => request({
    url: `${BASEURL}/workbench/getDataPaperCount`,
    // method: 'GET',
    data,
})

// 业务统计 线索查询
export const workBenchClueQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewClue`,
    // method: 'GET',
    data,
})

// 业务统计 客户查询
export const workBenchCustomerQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewCustomer`,
    // method: 'GET',
    data,
})

// 业务统计 商机查询
export const workBenchBusinessQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewBusinessOpp`,
    // method: 'GET',
    data,
})

// 业务统计 联系人
export const workBenchContactQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewContact`,
    // method: 'GET',
    data,
})

// 业务统计 客户数
export const workBenchCustomerOrClueNum = (data?: any) => request({
    url: `${BASEURL}/workbench/getFollowUp`,
    // method: 'GET',
    data,
})

// 销售漏斗
export const workBenchBusinessStic = (data?: any) => request({
    url: `${BASEURL}/workbench/getDailyBusinessStatistics`,
    // method: 'GET',
    data,
})

// 销售漏斗
export const workBenchFunnel = (data?: any) => request({
    url: `${BASEURL}/workbench/getSalesFunnelData`,
    // method: 'GET',
    data,
})

// 我的任务分页列表
export const workBenchTaskPageList = (data?: any) => request({
    url: `${BASEURL}/workbench/myTask`,
    data,
})

// 工作报告
export const workbenchReportList = (data?: any) => request({
    url: `${BASEURL}/workbench/searchByConditions`,
    // method: 'GET',
    data,
})