import React, { Key, useEffect, useRef, useState } from "react"
import { ExportButton, FormDragSetting, getFormDragSettingOptions, ImportButton, ProFormTreeSelect, ProTableWithFormSetting, setColumnsByOptions } from "@components/index"
import { columnsFn, customerViewOptions, DataSourcePayerType, payerColumnsFn, rebateListItem, sonTableColumnsFn1, sonTableColumnsFn2, mockPayerItem, editPayerColumnsFn, ReviewRebateInfoColumns, ReviewGetMoneyColumns, resultOptions } from './data'
import type { TableListItem } from './data'
import ProTable, { ActionType, EditableProTable } from '@ant-design/pro-table';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { ModalForm, ProFormDigit, ProFormUploadButton, ProFormText, ProFormRadio, ProFormCascader, ProFormUploadDragger, ProFormSelect, ProFormTextArea, ProFormDependency } from '@ant-design/pro-form';
import { Button, Modal, Tabs, Select, Table, Cascader, Tag, message, ButtonProps, Image, Dropdown, Menu, Alert, Form } from "antd";
import { Link, useHistory } from "react-router-dom";
import { customerList, customerDel, customerAttention, customerTransferToWho, customerBackToPool, customerFollowAdd, customerHelperList, customerHelperDel, customerHelperSave, BASEURL, ossImport, customerCluePoolCanAdd, customerHasBussiness, customerCanAdd, rebateClaim, rebateRecover, rebateSurePay, rebateUpdatePayerList, rebateRecoverAudit, rebateClaimAudit, rebateStop, rebateMuchSure, rebateMuchReview, rebateMuchUndo, rebateReview, rebateList, rebateClaimAuditInfo, rebateRecoverAuditInfo, rebateReviewInfo, rebateAdd} from '@api/index'
import { treeDataDisabledRules, getToken, productApi, toFormData, treeDataToFlatArr, isImageFile, handleUploadFiles, acceptImg, CLOUD_VENDOR, acceptExcel, getCheckedColumnsStateByColumns } from "@utils/index";
import { useCommonApiHooks, useHasAuthCode } from "@hooks/index";
import { stringify } from "qs";
import { useDispatch, useSelector } from "react-redux";
import { PreviewProps } from "@components/Common/Preview";
import { calcNewRecord, handleSubmitParams, RefundOrders, RefundOrderSummary, sum } from '@components/CustomerRebate/RebateConfirm/cpnts/Ali'
import { asyncSetGetConsumeOrderProcess } from "@store/actions"
import { StateType } from "@store/index";
import { useOperateHooks, OperateActionType } from "../hooks";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";
import { debounce } from "lodash";

type CustomerViewProps = {
    view?: string;
    scope?: string;
    onChange?: (type: string, val: any) => void;
}

const { TabPane } = Tabs;

const getApi = productApi(customerList) // 获取客户列表
const customerDelApi = productApi(customerDel)
const customerAttentionApi = productApi(customerAttention)
export const customerCluePoolCanAddApi = productApi(customerCluePoolCanAdd)
export const customerHasBusinessApi = productApi(customerHasBussiness)
export const customerTransferToWhoApi = productApi(customerTransferToWho)
export const customerBackToPoolApi = productApi(customerBackToPool)
export const customerFollowApi = productApi(customerFollowAdd)
export const customerHelpersApi = productApi(customerHelperList)
export const customerHelperDelApi = productApi(customerHelperDel)
export const customerHelperSaveApi = productApi(customerHelperSave)
const ossImportApi = productApi(ossImport)
const customerCanAddApi = productApi(customerCanAdd)

// mock api
const listPageApi = productApi(rebateList) // 分页列表
const claimApi = productApi(rebateClaim)  // 认领api
const claimAuditApi = productApi(rebateClaimAudit) // 认领审核
const claimAuditInfoApi = productApi(rebateClaimAuditInfo) // 认领审核详情
const recoverApi = productApi(rebateRecover) // 追回
const recoverAuditApi = productApi(rebateRecoverAudit) // 追回审核
const recoverAuditInfoApi = productApi(rebateRecoverAuditInfo) // 追回审核详情
const infoByIdApi = productApi(rebateClaim) // 根据id 获取详情信息
const surePayApi = productApi(rebateSurePay) // 确认打款
const updatePayerListApi = productApi(rebateUpdatePayerList) // 修改打款人信息
const stopApi = productApi(rebateStop) // 截至确认
const muchSureApi = productApi(rebateMuchSure) // 批量确认
const muchReviewApi = productApi(rebateMuchReview) //批量复核
export const reviewInfoApi = productApi(rebateReviewInfo) // 复核信息详情
const reviewApi = productApi(rebateReview) // 复核信息
const rebateAddApi = productApi(rebateAdd) // 追加返点
const muchUndoApi = productApi(rebateMuchUndo) // 批量撤销

const persistenceKey = 'customerRebate/list'

// 客户视图
export const CustomerView = ({view, scope, onChange}: CustomerViewProps) => {
    return <Tabs onChange={(val) => onChange && onChange('scope', val)} tabBarExtraContent={{left: <Select options={customerViewOptions} bordered={false} defaultValue="" onChange={(val) => onChange && onChange('view', val)} style={{minWidth: 80, marginRight: 30}}></Select>}} style={{background: '#fff'}}>
        <TabPane tab="当月返点" key="1"></TabPane>
        <TabPane tab="全部返点" key="2"></TabPane>
    </Tabs>
}

// 追回
export interface RecoverProps extends ModalFormProps{
    params?: any;
    disabled?: boolean;
    onSuccess?: () => void;
}
export const Recover = ({ children, params, onSuccess, disabled, ...rest}: RecoverProps) => {
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
        }
    }
    const onValuesChange = (values: any, allValues: any) => {
        const { consumeAmount, backAmount } = allValues
        const newRestAmount = ((consumeAmount||0) + (backAmount||0)).toFixed(2)
        formRef.current?.setFieldsValue({restAmount: newRestAmount})
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const onFinish = async ({files: filesArr, ...rest}: any) => {
        const files = handleUploadFiles(filesArr||[])
        const { success } = await recoverApi({...rest, files, ...params}, true, onSuccess)
        return success
    }
    return (<><ModalForm<RecoverProps> 
            layout="horizontal" 
            width={600} 
            labelCol={{span: 5}} 
            onFinish={onFinish} 
            onVisibleChange={onVisibleChange} 
            onValuesChange={onValuesChange}
            formRef={formRef} 
            key={params?.id}
            {...rest}>
            <ProFormText label="订单消费金额" name="consumeAmount" disabled rules={[{ required: true, message: '请填写订单消费金额',}]} />
            <ProFormDigit label="追回消费金额" name="backAmount" disabled={disabled} min={0.01} rules={[{ required: true, message: '请填写追回消费金额',}]} />
            <ProFormDigit label="追回返点金额" name="backRebateAmount" disabled={disabled} min={0.01} rules={[{ required: true, message: '请填写追回返点金额',}]} />
            <ProFormDigit label="剩余消费金额" name="restAmount" disabled rules={[{ required: true, message: '请填写剩余消费金额',}]} />
            <ProFormUploadButton label="上传凭证" name="files" tooltip="请上传客户退回返点金额的截图凭证" disabled={disabled} max={10} fieldProps={{ disabled, onPreview, name: 'file', accept: acceptImg, listType: "picture-card", headers: {Authorization: getToken()} }} action={`${BASEURL}/upload`} extra="" rules={[{ required: true, message: '请上传凭证',}]} />
            {children}
    </ModalForm>
    <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </>)
}

// 追回审核 | 追回审核详情: disabled
export const RecoverAudit = ({disabled, params, onSuccess, ...rest}: RecoverProps) => {
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = async (visible: boolean) => {
        const {data} = await recoverAuditInfoApi(params, false)
        const { consumeAmount, backAmount, backRebateAmount, restAmount, files, backResult, backRemark} = data || {}
        formRef.current?.setFieldsValue({
            consumeAmount, 
            backAmount, 
            backRebateAmount, 
            files, 
            restAmount,
            backResult: disabled ? backResult : 1,
            backRemark
        })
    }
    const onValuesChange = (values: any, allValues: any) => {
        const { consumeAmount, backAmount } = allValues
        const newRestAmount = ((consumeAmount||0) + (backAmount||0)).toFixed(2)
        formRef.current?.setFieldsValue({restAmount: newRestAmount})
    }
    const onFinish = async (values: any) => {
        if (disabled) {
            onSuccess && onSuccess()
            return true
        }
        const { success } = await recoverAuditApi({...values, ...params}, false, onSuccess)
        return success
    }
    return (<Recover disabled={disabled} params={params} key={params?.id} formRef={formRef} onVisibleChange={onVisibleChange} onValuesChange={onValuesChange} onFinish={onFinish} initialValues={{backResult: 1}} {...rest}>
        <ProFormRadio.Group label="审核结果" name="backResult" disabled={disabled} options={resultOptions} rules={[{ required: true, message: '请选择审核结果',}]} />
        <ProFormDependency name={['backResult']}>
            {({backResult}) => {
            return <ProFormTextArea label="审核备注" name="backRemark" disabled={disabled} fieldProps={{showCount: true,  maxLength: 300}} rules={[{ required: !backResult, message: '请填写审核备注',}]} />
            }}
        </ProFormDependency>
    </Recover>)
}

const RelationCustomerTooltip = () => {
    return(<div>
        1.关联客户：<br />
        （1）将客户账号直接关联至本人已有客户名下； <br />
        （2）或者新建客户来关联此客户账号。<br />
        2.认领账号：<br />
          认领账号前，请务必确认该客户账号在APS阿里云平台报备客户时已与本人关联，否则，审核将不通过，认领不成功。
    </div>)
}

const FileTooltip = () => {
    return(<div>认领账号时请务必上传在第三方云平台的客户报备截图，截图中需包含注册日期和账号信息。</div>)
}

const HuaWeiRelationCustomerTooltip = () => {
    return(<div>
       1.认领账号前，请务必确认该客户账号在报备客户时已与本人关联，否则，审核将不通过，认领不成功。<br/>
       2.关联客户是指将客户账号（返点订单）与系统中已创建的客户进行关联。若系统中不存在此客户，请先创建客户再来认领账号。
    </div>)
}

const ClaimTooltips = {
    [CLOUD_VENDOR.ALI]: {
        RelationCustomerTooltip,
        FileTooltip
    },
    [CLOUD_VENDOR.HUAWEI]: {
        RelationCustomerTooltip: HuaWeiRelationCustomerTooltip,
        FileTooltip
    },
    [CLOUD_VENDOR.TENCENT]: {
        RelationCustomerTooltip: HuaWeiRelationCustomerTooltip,
        FileTooltip
    },
}
// 认领
export interface ClaimProps extends ModalFormProps{
    cloudVendor?: CLOUD_VENDOR;
    params?: any;
    onSuccess?: () => void
}
export const Claim = ({ children, params, cloudVendor, onSuccess, ...rest}: ClaimProps) => {
    const history = useHistory()
    const [hideFields, setHideFields] = useState<string[]>([])
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    // 获取客户的下拉框
    const [options, setOptions, getOptionsApi] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`, method: 'GET'}, {}, false)
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            onValuesChange(null, {relationCustomer: 0})
            getOptionsApi({})
        }
    }
    const onSearchCustomer = (customerName: string) => {
        getOptionsApi({customerName})
    }
    const onValuesChange = (value: any, allValues: any) => {
        const hideFields = allValues['relationCustomer'] ? ['relationCustomer'] : []
        setHideFields(hideFields)
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const onFinish = async ({files: filesArr,...rest}: any) => {
        const files = handleUploadFiles(filesArr||[])
        const {success} = await claimApi({...rest, files, ...params}, false, onSuccess)
        if (success && hideFields.includes('relationCustomer')) {
            history.push(`/customer/add?${stringify({sourceType: 0, routeName: '新建客户', fromPath: '/customer/list'})}`)
        }
        return success
    }

    const { RelationCustomerTooltip, FileTooltip } = ClaimTooltips[cloudVendor||CLOUD_VENDOR.ALI]

    return (<><ModalForm<RecoverProps> 
            layout="horizontal" 
            width={600} 
            labelCol={{span: 5}} 
            submitter={{
                searchConfig: {submitText: hideFields.includes('relationCustomer') ? '保存并新建客户' : '确认'}
            }} 
            onFinish={onFinish} 
            onVisibleChange={onVisibleChange} 
            onValuesChange={onValuesChange} 
            formRef={formRef} 
            key={params?.id}
            {...rest}>
            <ProFormText label="客户账号" name="customerAccount" required disabled /> 
            {/* <ProFormRadio.Group label="关联客户" name="relationCustomer" initialValue={0} options={[{label: '已有客户', value: 0}, {label: '新建客户', value: 1}]} /> */}
            {!hideFields.includes('relationCustomer') ? 
                    <ProFormSelect 
                        label="关联客户" 
                        name="relationCustomerId" 
                        tooltip={RelationCustomerTooltip} 
                        options={(options||[]).map(({customerOrClueName: label, customerId: value}: any) => ({label, value}))} 
                        fieldProps={{showSearch: true, filterOption: false, onSearch: debounce(onSearchCustomer, 600)}}
                        rules={[{required: true, message: '请选择客户'}]} 
                    /> : null}
            <ProFormUploadButton label="上传凭证" name="files" max={10} tooltip={FileTooltip} fieldProps={{ name: 'file', accept: acceptImg, listType: 'picture-card', onPreview, headers: {Authorization: getToken()}}} action={`${BASEURL}/upload`} rules={[{ required: true, message: '请上传凭证',}]}/>
            {children}
    </ModalForm>
    <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </>)
}

// 认领审核
export interface ClaimAuditProps extends ModalFormProps{
    params?: any;
    onSuccess?: () => void
}
export const ClaimAudit = ({ children, params, onSuccess, ...rest}: ClaimAuditProps) => {
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const [max, setMax] = useState<number>(0)
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
        }
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const request = async (params: object) => {
        const {data} = await claimAuditInfoApi(params, false)
        const { customerAccount, relationCustomerName, files: filesArr } = data || {}
        setMax((filesArr||[]).length)
        return({
            customerAccount, 
            relationCustomerName, 
            files: filesArr
        })
    }
    const onFinish = async (values: any) => {
        const { success } = await claimAuditApi({...values, ...params}, true, onSuccess)
        return success
    }

    // console.log('----params', params)
    return (<><ModalForm<RecoverProps> key={params?.id} params={params} request={request as any} layout="horizontal" width={600} labelCol={{span: 5}} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
            <ProFormText label="客户账号" name="customerAccount" required disabled /> 
            <ProFormText label="关联客户" name="relationCustomerName" required disabled /> 
            <ProFormUploadButton label="上传凭证" name="files" disabled listType="picture-card" max={max} action={`${BASEURL}/upload`} extra="" fieldProps={{accept: acceptImg, onPreview, onRemove:() => false, showUploadList: {showRemoveIcon: false}}}/>
            <ProFormRadio.Group label="审核结果" name="recoverAuditResult" options={resultOptions} rules={[{required: true, message: '请选择审核结果'}]} />
            <ProFormDependency name={['recoverAuditResult']}>
                {({recoverAuditResult}) => {
                return <ProFormTextArea label="审核备注" name="recoverAuditRemark" fieldProps={{showCount: true,  maxLength: 300}} rules={[{ required: !recoverAuditResult, message: '请填写审核备注',}, {max: 300, type:'string', message: '300字以内'}]} />
                }}
            </ProFormDependency>
    </ModalForm>
    <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </>)
}

// 认领审核详情
export interface ClaimAuditDetailProps extends ModalFormProps{
    params?: any;
    onSuccess?: () => void
}
export const ClaimAuditDetail = ({params, onSuccess, ...rest}: ClaimAuditDetailProps) => {
    const formRef = useRef<ProFormInstance>()
    const [max, setMax] = useState<number>(0)
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
        }
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const request = async (params: object) => {
        const {data} = await claimAuditInfoApi(params, false)
        const { customerAccount, relationCustomerName, files, claimAuditResult, claimAuditRemark } = data || {}
        setMax((files||[]).length)
        return({
            customerAccount, 
            relationCustomerName, 
            files,
            claimAuditResult, 
            claimAuditRemark
        })
    }
    const onFinish = async (values: any) => true
    return (<ModalForm<RecoverProps> key={params?.id} params={params} request={request as any} layout="horizontal" width={600} labelCol={{span: 5}} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
            <ProFormText label="客户账号" name="customerAccount" disabled />
            <ProFormText label="关联客户" name="relationCustomerName" disabled />
            <ProFormUploadButton label="上传凭证" name="files" listType="picture-card" max={max} action={`${BASEURL}/upload`} extra="" fieldProps={{accept: acceptImg, onPreview, onRemove:() => false, showUploadList: {showRemoveIcon: false}}}/>
            <ProFormRadio.Group label="审核结果" name="claimAuditResult" disabled options={resultOptions} />
            <ProFormTextArea label="审核备注" name="claimAuditRemark" disabled />
            <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </ModalForm>)
}

// 确认打款
export interface ConfirmPayProps extends ModalFormProps{
    params?: Record<string, any>;
    onSuccess?: () => void;
    dataSource?: DataSourcePayerType[];
    type?: 'sure' | 'edit' // sure: 确认打款; edit: 编辑打款人信息
}
export const ConfirmPay = ({type, dataSource: pDataSource, params, onSuccess, ...rest}: ConfirmPayProps) => {
    const formRef = useRef<ProFormInstance>()
    const [confirmFormRef] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>([])

    const onFinish = async (values: any) => {
        const ok = await confirmFormRef.validateFields().catch(err => false)
        if (ok) {
           const whichApi = type === 'sure' ? surePayApi : updatePayerListApi
           const { success } = await whichApi({req: dataSource, ...params}, true, onSuccess)
           return success
        }
    }

    useEffect(() => {
        setDataSource((pDataSource||[]).map((v: any) => ({...v, payAmount: 0, remark: ''})))
    }, [pDataSource])

    return (<ModalForm<RecoverProps> layout="horizontal" key={params?.id} width={1200} onFinish={onFinish} formRef={formRef} {...rest}>
        <EditableProTable<DataSourcePayerType>
          className="noPadding"
          columns={type === 'edit' ? editPayerColumnsFn() : payerColumnsFn()}
          rowKey="id"
          value={dataSource}
          recordCreatorProps={false}
          controlled
          editable={{
            form: confirmFormRef,
            type: 'multiple',
            deleteText: null,
            editableKeys: (dataSource||[]).map(v => v.id),
            actionRender: (row, config, defaultDoms) => {
              return [defaultDoms.delete];
            },
            onValuesChange: (_, editableRows, ...rest) =>  {
                confirmFormRef.validateFields()
                const newDataSource = editableRows.map(({payAmount, payResult, ...rest}: any) => ({...rest, payResult, payAmount: payResult ? payAmount : 0}))
                setDataSource(newDataSource)
            },
          }}
       />
    </ModalForm>)
}

const RebateInfoSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell index={2}>{sum(data, 'consumeAmount')}</Table.Summary.Cell>
          <Table.Summary.Cell index={3}>{sum(data, 'freeConsumeAmount')}</Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}>{sum(data, 'expectRebateAmount')}</Table.Summary.Cell>
          {/* <Table.Summary.Cell index={7}>{sum(data, 'realRebateAmount')}</Table.Summary.Cell> */}
        </Table.Summary.Row>
    </Table.Summary>)
}
  
export const GetMoneySummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={3}>{sum(data, 'expectRebateAmount')}</Table.Summary.Cell>
          <Table.Summary.Cell index={4}>{sum(data, 'realRebateAmount')}</Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}
// 复核信息
export interface ReviewInfoProps extends ModalFormProps{
    params?: Record<string, any>;
    onSuccess?: () => void
}
export const ReviewInfo = ({params, onSuccess, ...rest}: ReviewInfoProps) => {
    const [refundOrderDataSource, setRefundOrderDataSource] = useState<any[]>([])
    const [rebateInfoDataSource, setRebateInfoDataSource] = useState<any[]>([])
    const [getMoneyDataSource, setGetMoneyDataSource] = useState<any[]>([])
    // const [hasPaper, setHasPaper] = useState(0)
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            const { data } = await reviewInfoApi({...(params ||{})}, false)
            const { 
                confirmRefundOrderResponses: refundOrderDataSource, 
                confirmCollectionResponses: getMoneyDataSource, 
                confirmReturnOrderResponses: rebateInfoDataSource,
                hasPaper,
            } = data || {};
            (rebateInfoDataSource||[]).forEach((v: any) => calcNewRecord(v, rebateInfoDataSource||[], hasPaper))
            setRebateInfoDataSource([...rebateInfoDataSource])
            setRefundOrderDataSource(refundOrderDataSource||[])
            setGetMoneyDataSource(getMoneyDataSource||[])
            // setHasPaper(hasPaper)
        }
    }
    const onFinish = async (values: any) => {
        const submitParams = handleSubmitParams({
            refundOrderDataSource,
            rebateInfoDataSource,
            getMoneyDataSource
        })
        const { success } = await reviewApi({...submitParams, ...values, ...params}, true, onSuccess)
        return success
    }
    useEffect(() => {
       const totalRealRebateAmount = sum(rebateInfoDataSource, 'expectRebateAmount')
       formRef.current?.setFieldsValue({totalRealRebateAmount})
    }, [rebateInfoDataSource])

    return (<ModalForm<RecoverProps> layout="horizontal" key={params?.id} width={1200} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
        <div className="zen-ban" style={{margin: '20px 0 0 20px'}}>退款订单</div>
        <RefundOrders dataSource={refundOrderDataSource} />
        <div className="zen-ban" style={{margin: '20px 0 0 20px'}}>返点信息</div>
        <EditableProTable<DataSourcePayerType>
          columns={ReviewRebateInfoColumns(() => null)}
          rowKey="id"
          value={rebateInfoDataSource}
          recordCreatorProps={false}
          controlled
          summary={() => <RebateInfoSummary data={rebateInfoDataSource} />}
          editable={{
            type: 'multiple',
            deleteText: null,
            editableKeys: rebateInfoDataSource.map(v => v.id),
            actionRender: (row, config, defaultDoms) => {
              return [defaultDoms.delete];
            },
            onValuesChange: (_, editableRows) =>  {
                setRebateInfoDataSource(editableRows)
            },
          }}
        />
        <div className="zen-ban" style={{margin: '20px 0 0 20px'}}>收款信息</div>
        <EditableProTable<DataSourcePayerType>
          columns={ReviewGetMoneyColumns(() => null)}
          rowKey="id"
          value={getMoneyDataSource}
          recordCreatorProps={false}
          controlled
          summary={() => <GetMoneySummary data={getMoneyDataSource} />}
          editable={{
            type: 'multiple',
            deleteText: null,
            editableKeys: getMoneyDataSource.map(v => v.id),
            actionRender: (row, config, defaultDoms) => {
              return [defaultDoms.delete];
            },
            onValuesChange: (_, editableRows) =>  {
                setGetMoneyDataSource(editableRows)
            },
          }}
       />
       {/* <ProFormDigit name="totalRealRebateAmount" label="实际返点金额" rules={[{required: true, message: '请填写实际返点金额'}]}/> */}
       <ProFormText name="payer" label="打款负责人" rules={[{required: true, message: '请填写打款负责人'}]} />
       <ProFormTextArea name="recheckMark" label="备注" fieldProps={{showCount: true,  maxLength: 300}} rules={[{max: 300, type:'string', message: '300字以内'}]} />
    </ModalForm>)
}

// SonTable
export const SonTable = (props: any) => {
    const { rebatesMessageRespList, proceedsMessageRespList } = props || {}
    return (<div style={{background: '#FAFAFA'}}>
       <Table columns={sonTableColumnsFn1()} dataSource={rebatesMessageRespList||[]} pagination={false} rowKey="id" />
       <Table columns={sonTableColumnsFn2()} dataSource={proceedsMessageRespList||[]} pagination={false} rowKey="id" />
    </div>)
}

export default () => {
    const [view, setView] = useState<string>('')
    const [scope, setScope] = useState<string>('1')

    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    const dispatch = useDispatch();

    const {
        history,
        hasAuthCode,
        downLoadUrlMap,
        selectedRowKeys,
        setSelectedRowKeys,
        calcDisabled,
        recoverProps,
        setRecoverProps,
        recoverAuditProps,
        setRecoverAuditProps,
        claimProps,
        setClaimProps,
        claimAuditProps,
        setClaimAuditProps,
        claimAuditDetailProps,
        setClaimAuditDetailProps,
        confirmPayProps,
        setConfirmPayProps,
        reviewInfoProps,
        setReviewInfoProps,
        createAction,
        createOperate
    } = useOperateHooks()

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await listPageApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values,
            total,
            success: true,
        }
    }
    const customerViewOnChange = (type: string, val: string) => {
        type === 'view' ? setView(val) : setScope(val)
    }
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const action = createOperate(record, reFush)
        action(type, {ids: selectedRowKeys})
    }

    const columns = columnsFn(scope, operate)

    const params = {view, scope, orderSource: CLOUD_VENDOR.ALI}

    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue(), 
        ...params
    })

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            ids: selectedRowKeys,
            fields,
            ...params
        })
    }

    const reFush = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }
    const onSuccess = () => {
        dispatch(asyncSetGetConsumeOrderProcess())
    }
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <CustomerView view={view} scope={scope} onChange={customerViewOnChange} />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 2400}}
                scroll={{x: 'max-content'}}
                columns={columnsFn(scope, operate)}
                params={params}
                request={getListApi}
                options={{reload: false, density: false}}
                expandable={{
                    // childrenColumnName: 'mouth',
                    expandedRowRender: (props: any) => <SonTable {...props} />
                }}
                toolbar={{
                    actions: ['1'].includes(scope) ? [
                        hasAuthCode(2264) ? <Button key="btn1" type="primary" danger onClick={() => operate && operate('截至确认')}>截止确认</Button> : null,
                        <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                            { hasAuthCode(2294) ? <Menu.Item key="btn2">
                                <ImportButton action={`${BASEURL}/rebates/importExcelConsumeOrder`} accept={acceptExcel} params={{orderSource: CLOUD_VENDOR.ALI}} templateUrl={downLoadUrlMap['返点列表-导入消费订单']} onSuccess={onSuccess} title="导入消费订单" trigger={<a>导入消费订单</a>}>
                                  <Alert message="提示：每月只能导入一次消费订单，请谨慎操作" type="warning" showIcon style={{marginBottom: '10px'}} />
                                </ImportButton>
                            </Menu.Item> : null }
                            { hasAuthCode(2301) ? <Menu.Item key="btn3"><ImportButton params={{orderSource: CLOUD_VENDOR.ALI}} accept={acceptExcel} action={`${BASEURL}/rebates/importExcelSpecialRebate`} templateUrl={downLoadUrlMap['返点列表-导入特殊返点']} title="导入特殊返点" trigger={<a>导入特殊返点</a>}></ImportButton></Menu.Item> : null }
                            { hasAuthCode(2261) ? <Menu.Item key="btn4"><ImportButton params={{orderSource: CLOUD_VENDOR.ALI}} accept={acceptExcel} action={`${BASEURL}/rebates/importPayOrders`} templateUrl={downLoadUrlMap['返点列表-导入打款表']} title="导入打款表" trigger={<a>导入打款表</a>}></ImportButton></Menu.Item> : null }
                            { hasAuthCode(2302) ? <Menu.Item key="btn5"><ImportButton params={{orderSource: CLOUD_VENDOR.ALI}} accept={acceptExcel} action={`${BASEURL}/rebates/importReviewOrder`} title="导入复核表" trigger={<a>导入复核表</a>}></ImportButton></Menu.Item> : null}
                        </Menu>}>
                            <Button>更多操作</Button>
                        </Dropdown>,
                        // <Button key="btn6" disabled={calcDisabled()} onClick={() => operate && operate('批量确认信息')}>确认信息</Button>,
                        hasAuthCode(2259) ? <Button key="btn7" disabled={calcDisabled()} onClick={() => operate && operate('批量复核信息')}>复核信息</Button> : null,
                        hasAuthCode(2262) ? <Button key="btn8" disabled={calcDisabled()} onClick={() => operate && operate('批量撤销')}>批量撤销</Button> : null,
                        hasAuthCode(2260) ? <ExportButton key="btn9" btnText="导出待打款表" param={{orderSource: CLOUD_VENDOR.ALI, scope}} action={`${BASEURL}/rebates/exportPay`} /> : null,
                        // hasAuthCode(2291) ? <ExportButton key="btn10" onSuccess={onSuccess} btnText="全部导出" param={getQueryParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null
                        hasAuthCode(2291) ? <ExportButtonWithBatch key="btn10" onSuccess={onSuccess} disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null
                    ] : [
                        hasAuthCode(2260) ? <ExportButton key="btn9" btnText="导出待打款表" param={{orderSource: CLOUD_VENDOR.ALI, scope}} action={`${BASEURL}/rebates/exportPay`} /> : null,
                        hasAuthCode(2261) ? <ImportButton key="btn1" title="导入打款表" accept={acceptExcel} params={{orderSource: CLOUD_VENDOR.ALI}} action={`${BASEURL}/rebates/importPayOrders`} templateUrl={downLoadUrlMap['返点列表-导入打款表']} trigger={<Button>导入打款表</Button>}></ImportButton> : null,
                        // hasAuthCode(2291) ? <ExportButton key="btn2" onSuccess={onSuccess} btnText="全部导出" param={getQueryParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null,
                        hasAuthCode(2291) ? <ExportButtonWithBatch key="btn10" onSuccess={onSuccess} disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null
                    ],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)},
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: `${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
            />
            <Recover modalProps={{onCancel: () => setRecoverProps({...recoverProps, visible: false}), maskClosable: false}} {...recoverProps} />
            <RecoverAudit modalProps={{onCancel: () => setRecoverAuditProps({...recoverAuditProps, visible: false}), maskClosable: false}} {...recoverAuditProps} />
            <Claim modalProps={{onCancel: () => setClaimProps({...claimProps, visible: false}), maskClosable: false}} {...claimProps} />
            <ClaimAudit modalProps={{onCancel: () => setClaimAuditProps({...claimAuditProps, visible: false}), maskClosable: false, destroyOnClose: true}} {...claimAuditProps} />
            <ClaimAuditDetail modalProps={{onCancel: () => setClaimAuditDetailProps({...claimAuditDetailProps, visible: false}), maskClosable: false}} {...claimAuditDetailProps} />
            <ConfirmPay modalProps={{onCancel: () => setConfirmPayProps({...confirmPayProps, visible: false}), maskClosable: false}} {...confirmPayProps} />
            <ReviewInfo modalProps={{onCancel: () => setReviewInfoProps({...reviewInfoProps, visible: false}), maskClosable: false}} {...reviewInfoProps} />
        </div>
    </div>
}
