import { Alert, Button, Col, Dropdown, Modal, Row, Image } from "antd"
import ProForm, { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDatePicker, ProFormDateTimePicker } from '@ant-design/pro-form';
import { columns as columnsFn } from "./data";
import { useMemo } from "react";
import { useRequest } from "ahooks";
import React from "react";
import { parseSearch, productApi, request } from "@utils/index";
import { usePlusTableHook } from "@hooks/plusTableHook";
import ProTable from "@ant-design/pro-table";
import { BASEURL } from "@api/index";
import { diyDo2Get } from "@api/customer";
import { useLocation } from "react-router-dom";
import { EnterpriseDetailProps } from "@components/EnterpriseDetail/type";
import demo1 from '@/assets/images/demo1.png';

// 接口请求的另一种写法，方便 dvm 代码生成
const getApi = productApi((params: any) => request({
    url: `${BASEURL}/business/jnsBusinessMap/getPage`,
    // method: 'GET',
    data: params
}))

const getPageListApi = async (params: any, sorter: any) => {
    const { data } = await getApi({...params, pageSize: 10000, sorter})
    const { values, total } = data
    return {
        data: values,   // 列表数据
        total: total,   // 列表总条数
        success: true   // 请求成功，关闭表格 loading 等待
    }
}

const delApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMap/deleteBatch`,
    // method: 'GET',
    data,
}))
const addApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMap/add`,
    // method: 'GET',
    data,
}))
const updateApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMap/edit`,
    // method: 'GET',
    data,
}))
// 返回表单需要的数据
const getInfoApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/business/jnsBusinessMap/getById`,
        method: 'GET',
        params,
    }) as any
    return data
}

// 获取页面枚举数据
const getInitDataApi = async () => {
    const { data } = await diyDo2Get({functionAlias: 'jnsBusinessMap'}) as any
    return data
}

// 组件区域
const JnsBusinessMap = ({id, ...rest}:EnterpriseDetailProps) => {
    // 获取地址栏参数
    const { search } = useLocation();
    const { id : customerId} = parseSearch(search);
    // 定义各类操作
    const operate = async (action: any, record: any) => {
        const { id : id } = record || {}
        const ids = id ? [id] : selectedRowKeys
        switch (action) {
            case '新增':
                addAction({params: {customerId }})
                break;
            case '编辑':
                editAction({params: {customerId, id}})
                break;
            case '删除':
                delAction({ids: ids})
                break;
        }
    }

    const { data } = useRequest(getInitDataApi)
    const columns = columnsFn(operate, data)  // 可以将 data 枚举值 透传到 columns 中
    const {
        selectedRowKeys,
        tableProps,
        modalProps,
        addAction,
        editAction,
        delAction,
        getDvmExportParams,
        setModalProps,
        onRefresh,
    } = usePlusTableHook({
        delApi,
        updateApi,
        addApi,
        getInfoApi,
        columns
    })
    const calcDisabled = () => !selectedRowKeys.length

    return (<>
        <Alert
            message="商务地图，通过收集客户所使用的所有IT资源的情况（包括卓见云公司账号下的），分析客户的IT资源分布情况和需求特征。（销售填写）"
            type="warning"
            closable
        />
        <ProTable
            request={getPageListApi}
            scroll={{x: 1300, y: 688}}
            toolbar={{
                actions: [
                    <Button type="primary" key="新增" onClick={() => operate?.('新增', null)}>新增</Button>,
                ]
            }}
            rowKey="id"
            params={{customerId:id}}
            search={false}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            {...tableProps}
            pagination={false}
            rowSelection={false}
        />
        <ModalForm<any>
            layout="horizontal"
            labelCol={{span: 9}}
            width={800}
            {...modalProps}
            // rowSelection={false}        // 行选
            // params={{businessId}}       // 新增传入参数
            // grid={true}                 // 开启栅格布局，一行允许有多列
            // initialValues={{            // 可用于新增时的默认值
            //     // roleType: 0
            // }}
        >
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <ProFormText 
                        label="云相关业务" 
                        name="cloudBusiness" 
                        rules={[{ message: '请填写'}]} 
                    />
                    <ProFormText 
                        label="消费体量" 
                        name="consumerVolume" 
                        fieldProps={{suffix: '万元'}}
                        rules={[{ message: '请填写'}]} 
                    />
                    <ProFormTextArea
                        label="合作的原厂/服务商" 
                        name="cooperator" 
                        fieldProps={{
                            placeholder: '如有，请列出是哪家原厂/服务商',
                            maxLength: 1000,
                            showCount: true,
                        }}
                    />
                    <ProFormTextArea
                        label="是否与服务商有高层关系" 
                        name="highLevelRelationship" 
                        tooltip={<>与云厂商或服务商是否有高层关系，如有，请说明是什么层面的</>}
                        fieldProps={{
                            placeholder: '与云厂商或服务商是否有高层关系，如有，请说明是什么层面的',
                            maxLength: 1000,
                            showCount: true,
                        }}
                    />

                    <ProFormTextArea 
                        label="有无商务折扣" 
                        name="discount" 
                        fieldProps={{
                            placeholder: '如有，请具体说明',
                            maxLength: 1000,
                            showCount: true,
                        }}
                        rules={[{required: false, message: '请填写'}]} 
                    />
                    <ProFormTextArea
                        label="是否补贴" 
                        name="subsidy" 
                        fieldProps={{
                            placeholder: '如有，请具体说明',
                            maxLength: 1000,
                            showCount: true,
                        }}
                    />
                    <ProFormText
                        label="合作时间" 
                        name="cooperationTime" 
                        fieldProps={{
                            placeholder: '请具体描述合作时间段'
                        }}
                    />
                    {/* <ProFormRadio.Group
                        label="是否有服务商合作" 
                        name="cooperator" 
                        fieldProps={{ options: yesNoOptions }}
                    />
                    <ProFormRadio.Group 
                        label="是否补贴" 
                        name="subsidy" 
                        fieldProps={{ options: yesNoOptions }}
                    />
                    <ProFormRadio.Group 
                        label="是否保底" 
                        name="minimum" 
                        fieldProps={{ options: yesNoOptions }}
                    />
                    <ProFormRadio.Group 
                        label="是否返券" 
                        name="coupon" 
                        fieldProps={{ options: yesNoOptions }}
                    /> */}
                    <ProFormTextArea
                        label="备注" 
                        name="remark" 
                        fieldProps={{
                            placeholder: '请描述业务来源',
                            maxLength: 1000,
                            showCount: true,
                        }}
                    />
                </Col>
                <Col span={8}>
                   <h2 style={{textAlign:'center'}}>示例图:</h2>
                   <Image src={demo1}/>
                </Col>
            </Row>
        </ModalForm>
    </>)
}

export default JnsBusinessMap