export const SET_USER_INFO = 'SET_USER_INFO' // 用户信息
export const SET_USER_ROLE_DATA = 'SET_USER_ROLE_DATA'
export const SET_COMMON_DEPTWITHUSERDATR = 'SET_COMMON_DEPTWITHUSERDATR' // 组织架构树带人员
export const SET_CITY_TREEDATA = 'SET_CITY_TREEDATA' // 省市区
export const SET_LABEL_TREEDATA = 'SET_LABEL_TREEDATA' // 客户标签
export const SET_USER_PROCESS = 'SET_USER_PROCESS' // 导出、导入的list
export const SET_USER_MESSAGES = 'SET_USER_MESSAGES' // 设置消息队列
export const SET_USER_MESSAGE_NUMBER = 'SET_USER_MESSAGE_NUMBER' // 设置消息数量
export const SET_DOWNLOAD_URLMAP = 'SET_DOWNLOAD_URLMAP' // 设置url 下载模版地址
// 猎奇模块
export const SET_LQ_SEARCHCONDITION = 'SET_LQ_SEARCHCONDITION' // 猎奇搜索条件枚举
