import React from "react";
import { Input, Select, DatePicker, TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { ModalForm, ProFormText, ProFormMoney } from '@ant-design/pro-form';
import { Double, Ellipsis } from "@components/index";
import { Link, useHistory } from "react-router-dom";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { stringify } from "qs";
import { queryDeptTreeData } from "@api/index";
import { isNumber } from "lodash";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { yesNoOptions } from "@utils/enum";
import { CustomerNameLinkRender } from "../List/data";

const { RangePicker } = DatePicker
// ts--types
export type TableListItem = {
    key: number;
    isSyn: number;
    businessOpportunityTitle: string;
    customerName: string;
    estimatedSalesAmount: string;
    followBusinessLine: string;
    estimatedSigningDate: string;
    salesStage: string;
    creator: string;
    synergy: string;
    businessOppId: string;
    customerId: string;
    emphasisProject?: boolean;
    ownerName?: any;
    lqId?: any;
};

export const customerViewOptions = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '我的',
    value: '1'
  },
  {
    label: '下属的',
    value: '2'
  },
  {
    label: '我协作的',
    value: '3'
  },
]

export type OperateActionType = '新建商机' | '添加协同人' | '转移线索' | '批量导出' | '批量删除' | '添加跟进' | '转为重点项目'

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const history = useHistory()
  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  const [, , request] = useDictEnumHooks({dictId: 13 }, false)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '商机标题',
        dataIndex: 'businessOpportunityTitle',
        fixed: 'left',
        // hideInSearch: true,
        order: 52,
        width: 120,
        render: (text, {businessOppId: id, businessOpportunityTitle: name, customerId, customerName, emphasisProject}: any) => {
          const onClick = () => {
            hasAuthCode(2180) ? history.push(`/customer/businessDetail?${stringify({id, name, customerId, customerName, emphasisProject})}`) : null
          }
          return <a onClick={onClick}><Ellipsis text={text as any||'-'} /></a>
        }
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        fixed: 'left',
        width: 150,
        order: 51,
        // render: (text) => <Ellipsis text={text as any||'-'} />
        render: (text: any, {customerId: id, lqId, customerName: name, ownerName}: TableListItem) => <CustomerNameLinkRender text={text} hasAuth={hasAuthCode(2016)} search={{id, name, ownerName, lqId, type: 0, routeName: '客户详情', fromPath: '/customer/list'}} />
    },
    {
      title: '重点客户',
      dataIndex: 'isEmphasisCustomerStr',
      order: 53,
      valueType: 'select',
      fieldProps: { options: yesNoOptions },
      // hideInSearch: true,
      // render: (text: any, record: any) => record.isEmphasisCustomer ? '是' : '否'
    },
    {
      title: '客户类型',
      valueType: 'select',
      dataIndex: 'customerType',
      // fixed: 'left',
      width: 150,
      order: 10,
      params: {dictId: 275 },
      fieldProps: { mode: 'multiple' },
      request,
    },
    {
        title: '创建人',
        dataIndex: 'creator',
        order: 50,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const creator = (ids||[]).map((v: string) => v.slice(1))
            return { creator }
          },
        },
    },
    {
        title: (_, type) => type === 'form' ? '业务线' : '关注业务线',
        dataIndex: 'followBusinessLine',
        order: 43,
        valueType: 'select',
        fieldProps: { mode: 'multiple' },
        params: { dictId: 19, pageCurrent: 1, pageSize: 1000},
        request,
        search: {
          transform: (followBusinessLine) => {
            return { 
              followBusinessLine: (followBusinessLine&&followBusinessLine.length) ? followBusinessLine : undefined
            };
          },
       },
    },
    {
        title: '项目机会点',
        dataIndex: 'opportunityPoint',
        order: 19,
        hideInSearch: true,
    },
    {
      title: '项目阶段',
      dataIndex: 'salesStage',
      order: 42,
      width: 130,
      valueType: 'select',
      fieldProps: { mode: 'multiple' },
      params: { dictId: 20, pageCurrent: 1, pageSize: 1000},
      search: {
          transform: (salesStage) => {
            return { 
              salesStageList: salesStage
            };
          },
      },
      request
    },
    {
        title: (_, type) => type === 'form' ? '预计签单' : '预计签单日期',
        dataIndex: 'estimatedSigningDate',
        order: 41,
        sorter: true,
        valueType: 'dateRange',
        width: 160,
        fieldProps: { allowEmpty: [true, true]},
        search: {
            transform: (estimatedSigningDates) => {
              return { estimatedSigningDates};
            },
        },
        render: (text, record: any) => `${(record||{}).estimatedSigningDate||''}`
    },
    {
        title: '预计Cash收入(元)',
        valueType: 'digit',
        dataIndex: 'estimatedCashIncome',
        order: 41,
        // hideInSearch: true,
        sorter: true,
        fieldProps: {precision: 0},
        renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
    },
    {
        title: '预计差价收入(元)',
        valueType: 'digit',
        dataIndex: 'estimatedBetweenIncome',
        order: 33,
        // hideInSearch: true,
        sorter: true,
        fieldProps: {precision: 0},
        renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
    },
    {
        title: '意向产品',
        dataIndex: 'productStr',
        order: 18,
        hideInSearch: true,
        render: (text: any, record: any) => (record.productStr||'').toString()
    },
    {
        title: '采购方式',
        valueType: 'select',
        dataIndex: 'buyMethod',
        fieldProps: { mode: 'multiple' },
        order: 20,
        params: { dictId: 267, pageCurrent: 1, pageSize: 1000},
        request,
        render: (text: any, record: any) => record?.buyMethodStr
    },
    {
        title: '下单方式',
        valueType: 'select',
        dataIndex: 'orderMethod',
        fieldProps: { mode: 'multiple' },
        order: 12,
        params: { dictId: 279, pageCurrent: 1, pageSize: 1000},
        request,
        render: (text: any, record: any) => record?.orderMethodStr
    },
    {
        title: '订单类型',
        valueType: 'select',
        dataIndex: 'orderType',
        fieldProps: { mode: 'multiple' },
        order: 11,
        params: { dictId: 254, pageCurrent: 1, pageSize: 1000},
        request,
        render: (text: any, record: any) => record?.orderTypeStr
    },
    {
        title: '账号是否注册',
        valueType: 'select',
        dataIndex: 'accountIsNew',
        order: 22,
        fieldProps: {
          options: yesNoOptions
        }
        // hideInSearch: true,
    },
    {
        title: '是否80%以上概率落单',
        dataIndex: 'isChanceOfBeingAloneStr',
        order: 17,
        hideInSearch: true,
    },
    {
        title: '是否poc测试',
        valueType: 'select',
        dataIndex: 'isPocTest',
        order: 21,
        fieldProps: {
          options: yesNoOptions
        },
        render: (text: any, record: any) => record?.isPocTestStr
        // hideInSearch: true,
    },
    {
        title: '建立沟通群',
        dataIndex: 'setUpGroup',
        valueType: 'select',
        order: 23,
        fieldProps: { mode: 'multiple' },
        params: { dictId: 266, pageCurrent: 1, pageSize: 1000},
        request,
        // render: (text: any, record: any) => record?.orderTypeStr
        // hideInSearch: true,
    },
    {
        title: '协同人(售前)',
        dataIndex: 'synergy',
        order: 30,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const synergys = (ids||[]).map((v: string) => v.slice(1))
            return { synergys }
          },
        },
        render: (text: any, record: any) => record?.synergy||'无'
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        order: 32,
        sorter: true,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (createTimes) => {
            return {
              createTimes
            };
          },
        },
        render: (text, record: any) => `${(record||{}).createTime||''}`
    },
    {
        title: '最后跟进',
        dataIndex: 'followUpTime',
        order: 31,
        width: 160,
        sorter: true,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (followUpTimes) => {
            return {
              followUpTimes
            };
          },
        },
        render: (text, record: any) => `${(record||{}).followUpTime||''}`
    },


    // {
    //     title: '跟进内容',
    //     dataIndex: 'followupRecord',
    //     order: 5,
    //     // hideInSearch: true,
    //     width: 300
    // },
    // {
    //     title: '下一步动作',
    //     dataIndex: 'nextStepContent',
    //     order: 4,
    //     hideInSearch: true,
    // },
    // {
    //     title: '求助点',
    //     dataIndex: 'helpPoint',
    //     order: 7,
    //     hideInSearch: true,
    // },
    
   
    // {
    //     title: '未跟进天数',
    //     dataIndex: 'notFollowDays',
    //     valueType: 'digit',
    //     sorter: true,
    //     order: 0,
    //     fieldProps: {precision: 0},
    //     renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
    //     render: (text, record: any) => typeof record.notFollowDays === 'number' ? Number(record.notFollowDays).toFixed(0) : '-'
    //     // hideInSearch: true,
    // },

    // {
    //     title: '合作模式',
    //     dataIndex: 'cooperationMode',
    //     order: 26,
    //     valueType: 'select',
    //     params: {dictId: 25 },
    //     fieldProps: { mode: 'multiple' },
    //     request,
    //     render: (text, record: any) => `${(record||{}).cooperationModeStr || ''}`
    // },
    
    // {
    //     title: '是否新客户',
    //     dataIndex: 'whetherNewCustomer',
    //     // hideInSearch: true,
    //     order: 24,
    //     valueType: 'select',
    //     fieldProps: { options: isNewCustomerOptions },
    //     render: (text: any, record: any) => record.whetherNewCustomer
    // },
    // {
    //     title: '主要联系人',
    //     dataIndex: 'primaryContact',
    //     order: 23,
    //     hideInSearch: true,
    // },
    // {
    //     title: '上门拜访',
    //     dataIndex: 'whetherVisit',
    //     order: 22,
    //     hideInSearch: true,
    // },
    
    // {
    //     title: '预估下单账号',
    //     dataIndex: 'accountStr',
    //     order: 16,
    //     hideInSearch: true,
    // },
    
    // {
    //     title: (_, type) => type === 'form' ? '预计销售额' : '预计销售额(元)',
    //     dataIndex: 'estimatedSalesAmount',
    //     order: 14,
    //     sorter: true,
    //     search: {
    //       transform: (estimatedSalesAmounts) => {
    //         return { estimatedSalesAmounts};
    //       },
    //     },
    //     renderFormItem: (props) => <Double props1={{ }} props2={{}} />
    // },
    

    // {
    //   title: '跟进反馈',
    //   dataIndex: 'followUpFeedback',
    //   // hideInSearch: true,
    //   valueType: 'select',
    //   order: 6,
    //   params: { dictId: 18, pageCurrent: 1, pageSize: 1000},
    //   fieldProps: { mode: 'multiple' },
    //   request,
    //   render: (text: any, record: any) => record.followUpFeedbackStr
    // },
   
    
    // v4.8.0 新增
    // {
    //   title: '预计销售金额',
    //   dataIndex: 'followupRecord',
    //   order: 14,
    //     // hideInSearch: true,
    // },
    
    // {
    //   title: '预计签单日期',
    //   dataIndex: 'followupRecord',
    //   order: 9,
    //     // hideInSearch: true,
    // },
    
    // {
    //   title: '跟进内容',
    //   dataIndex: 'followupRecord',
    //   order: 5,
    //     // hideInSearch: true,
    // },
    
   
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text, record) => [
          hasAuthCode(2185) ? <a key="add" onClick={() => operate && operate('添加跟进', record)}>跟进</a> : null,
          hasAuthCode(2128) && [0].includes(record.isSyn) ? <Link key="update" to={`/customer/businessAdd?${stringify({id: record.businessOppId, customerId: record.customerId, routeName: '编辑商机', routeTitName: record.businessOpportunityTitle})}`}>编辑</Link> : null,
        ],
    },
]
}
