import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import ProForm, { ProFormText, ProFormSelect, FormProps } from '@ant-design/pro-form';
import { Tabs, Button, message } from 'antd';
import { userUpdateUserPwd } from '@api/user'
import { userAuth } from '@api/index'
import { useSelector } from "react-redux";
import { useDeptDataHooks, useRoleListHooks } from "@hooks/index";
import {  ProFormTreeSelect, ProFormWithCache } from '@components/index'
import { productApi } from '@utils/util';
import { pwdReg } from "@utils/util";
import './index.less'
const { TabPane } = Tabs;

const UpdateUserPwd = productApi(userUpdateUserPwd)
const useUserListByDeptId = productApi(userAuth)

// 用户信息板块
const UserData = () => {
    const formRef = useRef<any >()
    // 获取组织架构树
    const [treeData ] = useDeptDataHooks({type:1}, true)
    // 获取角色list
    const [userTreeData ] = useRoleListHooks({type:2}, true)
    // 获取redux
    const { userInfo } = useSelector((state) => state) as {userMenuRoute: any; userInfo: any};
    const { user } = userInfo || {}
    const { id: userId } = user || {}
    // 获取用户信息
    const getUserInfoApi = async (param: Record<string, any>) => {
        await useUserListByDeptId(param, false, (data: any) => {
            const { userName, name, mobilePhoneNumber, email, roleIds, lastLoginTime, deptId } = data || {}
            formRef.current?.setFieldsValue({ userName, name, mobilePhoneNumber, email, roleIds, lastLoginTime, deptId })
        })
    }
    useEffect(() => {
        getUserInfoApi({userId})
    }, [])
    return (<div className="content">
        <p className="title">基本资料</p>
        <div className="userDataDiv" >
            <ProFormWithCache<any>
                layout="horizontal"
                formRef={formRef}
                labelCol={{ span: 5 }}
                submitter={false}
            >
                <ProFormText width="md" disabled name="userName" label="登录账号" rules={[{ required: true, message: '请输入登录账号', }]} placeholder="请选择" />
                <ProFormText width="md" disabled name="name" label="姓名" rules={[{ required: true, message: '请选择主要联系人', }]} placeholder="请选择" />
                <ProFormText width="md" disabled name="mobilePhoneNumber" label="手机号码" rules={[{ required: true, message: '请选择主要联系人', }]} placeholder="请选择" />
                <ProFormText width="md" disabled name="email" label="邮箱" rules={[{ required: true, message: '请选择主要联系人', }]} placeholder="请选择" />
                <ProFormTreeSelect name="deptId" disabled label="所属部门" fieldProps={{treeData}} rules={[{required: true, message: '请选择所属部门'}]} />
                <ProFormSelect name="roleIds" disabled label="所属角色" fieldProps={{options: userTreeData, mode: 'multiple'}}   rules={[{required: true, message: '请选择所属角色'}]} />
                <ProFormText width="md" disabled name="lastLoginTime" label="最近登录" rules={[{ required: true, message: '', }]} placeholder="请选择" />
            </ProFormWithCache>
        </div>
    </div>)
}

//用户修改密码板块 
type FormDataType = {
    oldPwd?: string;
    newPwd?: string;
    newPwdAgain?: string;
}
interface UserPasswordUpdate extends FormProps{
    onSuccess?: () => void;
}
const UserPasswordUpdate = ({onSuccess, ...rest}: UserPasswordUpdate) => {
    // 修改密码的事件
    const onFinish = async (values: FormDataType) => {
        const { oldPwd, newPwd, newPwdAgain } = values || {}
        if (newPwd && newPwd === newPwdAgain) {
            const { success } = await UpdateUserPwd({ oldPwd, newPwd }, true, onSuccess)
            return success
        } else {
            message.error('两次输入的密码不一致')
            return false
        }
    }
    return (<div className="changePwdContent">
        <p className="title">修改密码</p>
        <div className="userDataDiv heightCenter">
            <ProForm<FormDataType>
                layout="horizontal"
                labelCol={{ span: 8 }}
                onFinish={ onFinish as any }
                submitter={{ render: (props, doms) => {
                    return (
                        <div className="submitButton">
                            <Button type="primary" onClick={() => props.form?.submit?.()}>确认修改</Button>
                        </div>
                    )
                }}}
                {...rest}
            >
                <ProFormText width="md" name="oldPwd" label="原密码" rules={[{ required: true, message: '请输入原登录密码', }]} placeholder="输入原登录密码" />
                <ProFormText width="md" name="newPwd" label="新密码" fieldProps={{maxLength: 16}} rules={[{ required: true, message: '', },{ pattern: pwdReg , message: '新密码必须包含数字、字母，6-16位' }]} placeholder="输入新密码（6-16位数字、字母组合）" />
                <ProFormText width="md" name="newPwdAgain" label="确认密码" fieldProps={{maxLength: 16}} rules={[{ required: true, message: '请再次输入新登录密码', }]} placeholder="再次输入新登录密码" />
            </ProForm>
        </div>
    </div>)
}
export default () => {
    const history = useHistory();
    return (
        <div className="userCenterDiv">
            <div className="allDiv">
                <Tabs tabPosition={'left'} style={{ height: '100%' }}>
                    <TabPane tab="基本资料" key="1">
                        <UserData></UserData>
                    </TabPane>
                    <TabPane tab="修改密码" key="2">
                        <UserPasswordUpdate onSuccess={() => history.push("/login")}></UserPasswordUpdate>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}