export const columns = () => {
    return [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName'
        },
        {
            title: '归属人',
            dataIndex: 'owner',
            key: 'owner',
            render: (text: any, record: any) => record.owner ? `${record.owner}(${record.dept})` : ''
        },
        {
            title: '客户所在位置',
            dataIndex: 'module',
            key: 'module'
        },
        {
            title: '线索池名称',
            dataIndex: 'poolName',
            key: 'poolName'
        },
        {
            title: '未跟进天数',
            dataIndex: 'daysSinceFollowup',
            key: 'daysSinceFollowup'
        },
    ]
}