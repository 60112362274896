import React, { useEffect, useRef, useState } from "react"
import { columnsFn } from './data'
import type { TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { oaGetmyMsg } from '@api/index' 
import { productApi } from "@utils/util";
import { ProTableWithFormSetting } from "@components/index";
import { useHasAuthCode } from "@hooks/index";
import { asyncSetMessageNumber } from "@store/actions/common";
import { useDispatch } from "react-redux";

const getApi = productApi(oaGetmyMsg)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const dispatch = useDispatch();
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    
    const operate = async (record?: TableListItem | null, values?: any) => {
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
     
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTable<TableListItem>
                columns={columnsFn()}
                request={getListApi}
                options={false}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ],
                }}
                tableStyle={{marginTop: '15px'}}
                toolbar={{
                    actions: [],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={false}
                columnsState={{ persistenceKey: 'oa/myMessage', persistenceType: 'localStorage'}}
            />
        </div>
    </div>
}
