import React, { useRef, useState } from "react"
import { BackBan, Icon, ProFormAutoComplete, ProFormTreeSelect, ProFormWithCache, SearchBox } from "@components/index"
import type { ProFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ProForm, ProFormText, ProFormDatePicker, ProFormUploadButton, ProFormDependency, ProFormMoney, ProFormDigit, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import { AutoComplete, Button, Form, message, Tooltip, Image } from "antd";
import { EditableProTable } from "@ant-design/pro-table";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { MyTabs } from "@components/EnterpriseDetail";
import { acceptImg, emailReg, getToken, handleUploadFiles, isImageFile, parseSearch, phoneReg, productApi, validatorTel } from "@utils/util";
import { BASEURL, customerListByName, invoiceAddQuickly, invoiceAddUpdate, invoiceGetLatestInfo, invoiceInfo, invoiceSearchCompanyName } from "@api/index";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { mailedOptions, paidOptions, typeOptions } from "../../data";
import { useDictEnumHooks } from "@hooks/index";
import { PreviewProps } from "@components/Common/Preview";

const invoiceSearchCompanyNameapi = productApi(invoiceSearchCompanyName)

interface AddTaskProps extends ProFormProps{
    disabledFields?: string[];
    onSuccess?: () => void;
}

const addUpdateApi = productApi(invoiceAddUpdate)  // 新增、编辑发票
const addQuicklyApi = productApi(invoiceAddQuickly) // 一键开票
const infoApi = productApi(invoiceInfo)
const invoiceGetLatestInfoApi = productApi(invoiceGetLatestInfo)

const { Fields } = SearchBox
const labelCol = { span: 4 }
const layout = {
    labelCol,
    wrapperCol: { span: 20 },
};

const percentValidator = (rules: any, val: number, callback: Function) => {
    if (!val) {
        return Promise.reject('此项是必填项')
    }
    if (val > 100 || val < 0) {
        return Promise.reject('开票比例需要 大于 0 且不超过 100')
    } else {
        return Promise.resolve()
    }
}

const format = "YYYY-MM-DD"

const disabledTaskEndTime = (current: any) => {
    return current && current < moment()
}

const PercentTooptip = () => {
    return(<div>
        开票比例 = 开票金额/合同金额 * 100%
    </div>)
}


export default () => {
    const history = useHistory() 
    const { search } = history.location
    const { id: fpId, status, originId, contractNumber} = parseSearch(search)
    const id = fpId || originId  // fpId: 元记录id，originId 是提供用于一键开票的原数据 id
    const formRef = useRef<ProFormInstance>()
    const [companyNameOptions, setCompanyNameOptions] = useState<any>([])
    const [bussinessTypeOptions ] = useDictEnumHooks({dictId: 263 }, true)
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const request = async(params: any) => {
        if (!id) {
            return {mailed: 1, type: 1, paid: 1, percent: 100, contractNumber}
        }
        const { data: resData } = await infoApi(params)
        const {proof, ...data} = resData
        const filesArr = proof ? proof.split(',') : []
        const files = filesArr.map((url: any) => ({url, status: 'done', uid: url, format: 'jpg'}))
        return {
            ...(data||{}),
            files
        }
    }
    const onFinish = async ({files: filesArr, ...rest}: any) => {
        const proof = filesArr ? handleUploadFiles(filesArr||[]).map((v: any) => v.url).toString() : undefined
        const params = originId ? {...rest, originId, status, proof} : {...rest, id, status, proof}
        const { success } = await addUpdateApi(params, true, (data: any) => {
            history.goBack()
        })
        return success
    }
    const onValuesChange = async ({companyName}: any, allValues: any) => {
        if (companyName) {
            await invoiceSearchCompanyNameapi({companyName, ownedByMe: true}, false, (data: any) => {
                const options = (data || []).map((v: string) => ({label: v, value: v}))
               setCompanyNameOptions(options || [])
            })
        }
    }
    const onSelect = async (companyName: any) => {
        const { data } = await invoiceGetLatestInfoApi({companyName}) as any
        const { companyTaxNumber, bank, bankAccount, taxAddress, taxPhone, email,
            mailed, receiverName, mailPhone, receiverAddress, mailRemark // 
        } = data || {}
        // formRef.current?.setFieldsValue({companyTaxNumber, bank, bankAccount, taxAddress, taxPhone, email})
        formRef.current?.setFieldsValue({companyTaxNumber, bank, bankAccount, taxAddress, taxPhone, email, mailed, receiverName, mailPhone, receiverAddress, mailRemark})
    }
    const calcTitle = () => {
        const str = status === '3' ? '申请开票' : id ? '编辑发票' : '申请开票'
        return str
    }
    return(<div className="oa-task-add" style={{background: '#fff'}}>
        <BackBan title={calcTitle()} />
        <ProFormWithCache<any> 
            key={location.href}
            layout="horizontal" 
            {...layout} 
            submitter={{render: (props: any, doms: any) => [
                <div className="jns-fix-submit" key="submit">
                    <Button 
                        type="primary" 
                        style={{ margin: 6 }} 
                        onClick={() => props.form?.submit?.()}
                    >提交
                    </Button>
                </div>
            ]}}
            formRef={formRef}
            params={{invoiceId: id}}
            request={request as any}
            onValuesChange={onValuesChange}
            onFinish={onFinish}>
            <h3 style={{margin: '20px 0 10px 7%'}}>开票信息</h3>
            <ProFormText width="lg" name="contractNumber" label="合同编号" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: false, message: '请填写合同编号',}]} placeholder="请输入关联的合同编号" />
            <ProFormSelect width="lg" name="businessType" label="业务类型" fieldProps={{ options: bussinessTypeOptions }} rules={[{ required: true, message: '请选择开票业务类型' }]} />
            <ProFormRadio.Group width="xl" name="paid" label="是否付款" options={paidOptions} rules={[{ required: true, message: '请选择是否付款'}]} />
            <ProFormDependency name={['paid']}>
                {({paid}) => {
                    return (paid === 0 ? 
                    <ProFormDatePicker width="lg" name="returnTime" label="回款时间" fieldProps={{showTime: false, format, disabledDate: disabledTaskEndTime}} rules={[{ required: true, message: '请选择回款时间'}]} /> : 
                    <ProFormUploadButton label="付款凭证" name="files" max={10} fieldProps={{ name: 'file', accept: ".png, .jpg, .jpeg, .bmp, .gif", multiple: true, listType: 'picture-card', onPreview, headers: {Authorization: getToken()}}} action={`${BASEURL}/upload`} rules={[{required: true, message: '请上传凭证'}]}/>
                    )
                }}
            </ProFormDependency>

            <ProFormMoney width="lg" name="amount" label="开票金额" fieldProps={{ 
                formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                defaultValue: ''
                }} max={999999999} min={0.01} rules={[{ required: true, message: '请填写开票金额' }]} />
            <ProFormDigit width={300} name="percent" label="开票比例" tooltip={<PercentTooptip />} fieldProps={{addonAfter: '%' }} rules={[{required: true, validator: percentValidator }]} />
            <ProFormRadio.Group width="xl" name="type" label="开票类型" options={typeOptions} rules={[{ required: true, message: '请选择开票类型'}]} />
            <ProFormDependency name={['type']}>
                {({type}) => {
                    const clacRequired = type === 2
                    return (<>
                        <ProFormAutoComplete name="companyName" label="企业名称" fieldProps={{options: companyNameOptions, showCount: true,  maxLength: 50, style: { width: 440 }, placeholder: "请输入企业名称", onSelect}} rules={[{required: true, message: '请填写企业名称',}]} />
                        <ProFormText width="lg" name="companyTaxNumber" label="企业税号" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: true, message: '请填写企业税号',}]} placeholder="请输入企业税号" />
                        {/* <ProFormText width="lg" name="taxPayerNumber" label="纳税人识别号" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: clacRequired, message: '请填写纳税人识别号',}]} placeholder="请输入纳税人识别号" /> */}
                        <ProFormText width="lg" name="bank" label="开户银行" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: clacRequired, message: '请填写开户银行',}]} placeholder="请输入开户银行" />
                        <ProFormText width="lg" name="bankAccount" label="银行账号" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: clacRequired, message: '请填写银行账号',}]} placeholder="请输入银行账号" />
                        <ProFormText width="lg" name="taxAddress" label="税票地址" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: clacRequired, message: '请填写税票地址',}]} placeholder="请输入税票地址" />
                        <ProFormText width="lg" name="taxPhone" label="税票电话" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: clacRequired, message: '请填写税票电话',}]} placeholder="请输入税票电话" />
                        <ProFormText width="lg" name="email" label="电子邮箱" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: true, message: '请填写电子邮箱',}, { pattern: emailReg, message: '请填写正确的邮箱格式' }]} placeholder="请输入电子邮箱" />
                        <ProFormTextArea width="lg" name="remark" label="备注" placeholder="若代付业务开票金额≧50000时，请填写客户的账户余额" fieldProps={{showCount: true, maxLength: 300}} rules={[{ required: false,  }]} />
                    </>)
                }}
            </ProFormDependency>
            {/* v4.11.0 版本说：收货信息不要了 */}
            {/* <h3 style={{margin: '20px 0 10px 7%'}}>收货信息</h3>
            <ProFormRadio.Group width="xl" name="mailed" label="是否邮寄" options={[{label: '邮寄',value: 1},{label: '不邮寄',value: 0}]} rules={[{ required: true, message: '请选择任务完成指标'}]} />
            <ProFormDependency name={['mailed']}>
                {({mailed}) => {
                    return (mailed === 1 ? <>
                        <ProFormText width="lg" name="receiverName" label="收件人姓名" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: true, message: '请填写收件人姓名',}]} placeholder="请输入收件人姓名" />
                        <ProFormText width="lg" name="mailPhone" label="手机号码" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: true, validator: validatorTel}]} placeholder="请输入手机号" />
                        <ProFormText width="lg" name="receiverAddress" label="收件人地址" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: true, message: '请填写收件人地址',}]} placeholder="请输入收件人地址" />
                    </> : <ProFormTextArea width="lg" name="mailRemark" label="备注" fieldProps={{showCount: true,  maxLength: 300}} rules={[{ required: true, message: '请填写邮寄原因'}]} />)
                }}
            </ProFormDependency> */}
            <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
        </ProFormWithCache>
    </div>)
}