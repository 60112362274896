import React, { useState, useEffect } from 'react';
import { Anchor, Button, Descriptions, Modal, Table, Tabs, Tag, Tooltip } from 'antd';
import { CompanyBaseInfo, CompanyCondition, CompanyDevelopment, CompanyRights, CompanyRisk, CompanyProduct, HomePages } from './cpnts/CompanyInfo';
import Contact from './cpnts/Contact';
import FollowRecord from './cpnts/FollowRecord';
import BusinessRecord from './cpnts/BusinessRecord';
import CustomerRebate from './cpnts/CustomerRebate';
import CustomerAccount from './cpnts/CustomerAccount';
import SurveySheet from './cpnts/SurveySheet';
import ServiceMap from './cpnts/ServiceMap';
// import BusinessMap from './cpnts/BusinessMap';
// import DecisionChainMap from './cpnts/DecisionChainMap';
import JnsBusinessDecisionChain from './cpnts/JnsBusinessDecisionChain'
import JnsBusinessMap from './cpnts/JnsBusinessMap';
import Knowledge from './cpnts/Knowledge';
import { DescriptionsListProps, MyTabsProps, EnterpriseDetailProps } from './type'
import ProForm, { ModalForm,  ProFormTextArea, } from '@ant-design/pro-form';
import { useMemo } from 'react';
import { BackBan, Icon, OrganizationChart } from '@components/index';
import BaseInfo from './cpnts/BaseInfo';
import { useBaseInfo, SourceMaps } from './data'
import { isFunction, parseSearch, productApi } from '@utils/util';
import { useHistory, useLocation } from 'react-router-dom'
import { AddFollowRecord, AddFollowRecordProps, customerHasBusinessApi} from '../Customer/List';
import { customerGetInfoById, lqGetNewestInfo, lqUpdateDesc } from '@api/index';
import { ClueToCustomerButton, GetCluesButton } from '@components/Customer/CluesPool';
import { lqCompanyToCustomerApi, lqGetClueToJnsApi } from '@components/Lq/Search';
import './index.less';
import { useHasAuthCode } from '@hooks/index';
import OperationRecord from './cpnts/OperationRecord';
import Affix from './cpnts/Affix'

const { Link } = Anchor;
const { TabPane } = Tabs;
const tableStyle = {textAlign: 'center'};

const customerInfoApi = productApi(customerGetInfoById)
const lqGetNewestInfoApi = productApi(lqGetNewestInfo)
const updateDescApi = productApi(lqUpdateDesc)

export const BaseTable = ({title, ...rest}: any) => {
    return <>
                <div className="zen-ban" style={{ margin: '18px 0' }}>{title}</div>
                <Table rowKey='id' {...rest} />
            </>
}


export const WithAnchorTables = ({tabPanes}: MyTabsProps) => {
    const getContainer = () => document.getElementsByClassName('customScrollBar')[0] || window 
    return (
        <div className="company-info">
            <div className="content-warps">
                {tabPanes.map((v, i) => {
                    const { href, Content } = v;
                    return <div id={href} style={{ marginBottom: '40px' }} key={i}>
                        { Array.isArray(Content) ? Content.map((RC, i) => isFunction(RC) ? RC() : RC) : isFunction(Content) ? Content() : Content }
                        </div>
                })}
            </div>
            <Anchor getContainer={getContainer as any}>
                {tabPanes.map((v, i) => (
                    <Link title={isFunction(v.Tab) ? v.Tab() : v.Tab} href={`#${v.href}`} key={i} />
                ))}
            </Anchor>
        </div>
    );
}

export const MyTabs = ({tabPanes, ...rest}: MyTabsProps) => {
    return (
        <Tabs style={{marginTop: '16px' }} {...rest}>
            {tabPanes.map((v, i) => (
                !v?.hidden ?
                <TabPane tab={isFunction(v.Tab) ? v.Tab() : v.Tab} key={v.key||i}>
                    { Array.isArray(v.Content) ? v.Content.map((RC, i) => isFunction(RC) ? RC() : RC) : isFunction(v.Content) ? v.Content() : v.Content }
                </TabPane> : null
            ))}
        </Tabs>
    )
}

const companyTabPans = (props: EnterpriseDetailProps, detailObj: any) => {
    return [
        {
            Tab: () => <div style={tableStyle as any}>基本信息</div>,
            Content: () => <CompanyBaseInfo {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>经营情况</div>,
            Content: () => <CompanyCondition {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>企业发展</div>,
            Content: () => <CompanyDevelopment {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>知识产权</div>,
            Content: () => <CompanyRights {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>风险信息</div>,
            Content: () => <CompanyRisk {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>产品信息</div>,
            Content: () => <CompanyProduct {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>联系方式</div>,
            Content: () => <Contact {...props} readOnly={true} />
        },
        {
            Tab: () => <div style={tableStyle as any}>员工人脉</div>,
            Content: () => <HomePages {...props} />
        },
        {
            Tab: () => <div style={tableStyle as any}>组织架构</div>,
            Content: () => <OrganizationChart {...props} value={detailObj?.customerDeptJson} />
        },
    ]
} 

const mainTabPans = ({detailObj, ...rest}: EnterpriseDetailProps) => {
    const { search } = useLocation()
    const [, hasAuthCode] = useHasAuthCode()
    const [baseInfo] = useBaseInfo(rest)
    const { isSyn, isManager } = detailObj || {}
    const { type: num, ownerName } = parseSearch(search)
    const calcContactAuth = () => {
        if (['0'].includes(num)) { // 客户列表 的联系人
            return [0].includes(isSyn)
        } else if (['1'].includes(num)) { // 线索池
            return false
        } else if (['2'].includes(num)) { // 我的线索
            return true
        }
    }

    const calcFollowRecordAuth = () => !['1'].includes(num) // 线索池进来的没权限
    const calcNoCheck = () => ['2'].includes(num) //  我的线索 的 跟进 不做校验
    const clacBusinessRecordAuth = () => {
        if (['1', '2'].includes(num)) { // 线索池，我的线索进来的只读
            return false
        } else if (['0'].includes(num)){ // 客户列表进来的，判断是否是协同人，协同人只读
            return ![1].includes(isSyn) 
        }
    }

    return [
        {
            Tab: () => <div style={tableStyle as any}>企业资料</div>,
            Content: () => <div style={{background: '#fff'}}>
                <BaseInfo baseInfo={baseInfo} /> 
                { useMemo(() => <MyTabs tabPanes={companyTabPans(rest, detailObj)} tabBarStyle={{paddingLeft: 20}} />, [rest]) }
            </div>
        },
        {
            Tab: () => <div style={tableStyle as any}>客户业务调研表</div>,
            Content: () => <SurveySheet {...rest} />
        },
        {
            Tab: () => <div style={tableStyle as any}>决策链地图</div>,
            Content: () => <JnsBusinessDecisionChain {...rest} />
        },
        {
            Tab: () => <div style={tableStyle as any}>商务地图</div>,
            Content: () => <JnsBusinessMap {...rest} />
        },
        {
            Tab: () => <div style={tableStyle as any}>业务地图</div>,
            Content: () => <ServiceMap {...rest} />
        },
        {
            Tab: () => <div style={tableStyle as any}>跟进记录</div>,
            Content: () => <FollowRecord {...rest} readOnly={!calcFollowRecordAuth()} noCheck={calcNoCheck()} />
        },
        {
            Tab: () => <div style={tableStyle as any}>商机列表</div>,
            // Content: () => <BusinessRecord {...rest} readOnly={!clacBusinessRecordAuth()} />  
            Content: () => <BusinessRecord {...rest} />  // 又说给去掉, 来回瞎折腾
        },
        {
            Tab: () => <div style={tableStyle as any}>联系人</div>,
            Content: () => <Contact {...rest} readOnly={!calcContactAuth()} whichApi='jns' />
        },
        {
            Tab: () => <div style={tableStyle as any}>客户账号</div>,
            Content: () => <CustomerAccount {...rest} Collaborator={![1].includes(isSyn)} />,
            hidden: !ownerName
        },
        {
            Tab: () => <div style={tableStyle as any}>消费信息</div>,
            Content: () => <CustomerRebate {...rest} Collaborator={![1].includes(isSyn)} />,
            hidden: !ownerName
        },
        {
            Tab: () => <div style={tableStyle as any}>附件</div>,
            Content: () => <Affix {...rest} />
        },
        {
            Tab: () => <div style={tableStyle as any}>知识库</div>,
            Content: () => <Knowledge {...rest} readOnly={[1].includes(isSyn)} />,
            hidden: !ownerName
        },
        // {
        //     Tab: () => <div style={tableStyle as any}>组织架构</div>,
        //     Content: () => <OrganizationChart {...rest} value={detailObj?.customerDeptJson} />
        // },
        
        {
            Tab: () => <div style={tableStyle as any}>操作记录</div>,
            Content: () => <OperationRecord {...rest} searchType={1} Collaborator={![1].includes(isSyn)} />,
            // hidden: !ownerName
        },
    ]
};


export const DescriptionsList = ({descList, data, ...rest}: DescriptionsListProps) => {
    return (<Descriptions bordered style={{boxSizing: 'border-box', padding: 20, background: '#fff'}} {...rest}>
        {
            (descList||[]).map(({value, param, ...rest}, i) => <Descriptions.Item key={i} {...rest}>
                {value ? isFunction(value) ? value(data||{})||'' : value||''  : (data||{})[param as string]||''}
            </Descriptions.Item>)
        }
        </Descriptions>)
}

// 更新企业信息
export const UpdateCompanyInfo = ({params, onSuccess}: any) => {
    const getNewest = async () => {
        await lqGetNewestInfoApi(params, false, onSuccess)
    }
    return <Button key="update" type="primary" style={{marginLeft: 10}} onClick={getNewest}>刷新企业信息</Button>
}

// 创建简介
export const CreateBrief = ({introduction, params, onSuccess}: any) => {
    const [, hasAuthCode] = useHasAuthCode()
    const updateDesc = async (values: any) => {
        const { success } = await updateDescApi({...values, ...params}, true, onSuccess)
        return success
    }
    return  <ModalForm 
                key="addDesc" 
                title={introduction ? '编辑简介' : '创建简介'} 
                modalProps={{maskClosable: false}} 
                layout="horizontal" 
                // trigger={<Button type="primary" style={{marginRight: 10}}>{ introduction ? '编辑简介' : '创建简介'}</Button>}
                trigger={<Tooltip title={ introduction ? '编辑简介' : '创建简介'}> <Icon type="icon-web-icon-" style={{ color: '#0479FE', marginLeft: 6, cursor: 'pointer'}} /> </Tooltip> }
                onFinish={updateDesc}
                params={{introduction}}
                request={async({introduction}) => ({introduction})}
            >
                <ProFormTextArea name="introduction" label="简介内容" placeholder="请自定义公司简介" rules={[{required: true, message: '请填写简介'}, {max: 300, type:'string', message: '300字以内'}]} />
            </ModalForm>
}

// 猎奇企业详情
export const LqEnterpriseDetail = ({}: EnterpriseDetailProps) => {
    const [, hasAuthCode] = useHasAuthCode()
    const { search } = useLocation()
    const { id, lqId, name, type: num, module } = parseSearch(search)
    const [baseInfo] = useBaseInfo({name, id, lqId})
    const { isReceive, introduction } = baseInfo || {} as any // 领过的不能再领
    const reFresh = () => location.reload()
    const calcGetCluesButtonAuth = (type: any) => {
        if (type == 1) {
           return hasAuthCode(2241)
        } else if (type == '2-1') {
            return hasAuthCode(2224)
        } else if (type == '2-2') {
            return hasAuthCode(2235)
        } else if (type == 3) {
            return hasAuthCode(2228)
        } else {
            return false
        }
    }

    const calcClueToCustomerButtonAuth = (type: any) => {
        if (type == 1) {
           return hasAuthCode(2242)
        } else if (type == '2-1') {
            return hasAuthCode(2225)
        } else if (type == '2-2') {
            return hasAuthCode(2236)
        } else if (type == 3) {
            return hasAuthCode(2229)
        } else {
            return false
        }
    }
    return (<div className="jns-enterprise-detail">
                <BackBan title={name} actions={[
                    <CreateBrief key="addDesc" introduction={introduction} params={{companyName: name}} onSuccess={reFresh} />,
                    calcGetCluesButtonAuth(num) ? <GetCluesButton key="primary" disabled={!isReceive} onOk={() => lqGetClueToJnsApi({ids: [lqId], module}, true, reFresh)}>领取线索</GetCluesButton> : null,
                    calcClueToCustomerButtonAuth(num) ? <ClueToCustomerButton key="btn1" disabled={!isReceive} style={{marginLeft: 10}} onOk={() => lqCompanyToCustomerApi({ids: [lqId], module}, true, reFresh)}>转为客户</ClueToCustomerButton> : null,
                    <UpdateCompanyInfo key="update" params={{name}} onSuccess={reFresh} />
                ]} />
                <BaseInfo baseInfo={baseInfo} /> 
                { useMemo(() => <MyTabs tabPanes={companyTabPans({id, name, lqId}, baseInfo)} tabBarStyle={{paddingLeft: 20}} style={{background: '#fff', marginTop: '16px'}} />, [search]) }
            </div>)
}

type CustomerDetailProps = {
    id: any;
    name?: string;
    lqId?: any;
    type?: '0' | '1' | '2'
}

// todo 待优化 减少参数传递
export const CustomerDetail = ({id, name: customerName, lqId, type: num}: CustomerDetailProps) => {
    const { search } = useLocation()
    const history = useHistory()
    const [detailObj, setDetalObj] = useState<any>({})
    const name = customerName || detailObj?.customerName
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: id, customerName: name, onSuccess: undefined})

    const getCustomerInfoApi = async (param: any) => {
        await customerInfoApi(param, false, (data: any) => {
            setDetalObj(data)
        })
    }

    const operate = async (type: string, record: any, values?: any) => {
        if (num === '0') {
            if (type === '添加跟进') {
                // const { data } = await customerHasBusinessApi({id}, false)
                // data ? Modal.confirm({
                //     title: '温馨提示',
                //     content: '此客户已创建了商机，请在商机中写跟进小记',
                //     okText: '前往',
                //     cancelText: '取消',
                //     onOk: async() => {
                //         history.push('/customer/business')
                //     },
                // })
                // : 
                const { data } = await customerHasBusinessApi({customerId: id, type: 2}, false);
                !data ? Modal.confirm({
                    title: '温馨提示',
                    content: <>录入跟进记录前，请先<a key="update" onClick={() => {
                        Modal.destroyAll()
                        history.push('/customer/businessAdd')
                    }}>创建商机</a>。</>,
                    okText: '前往',
                    cancelText: '取消',
                    onOk: async() => {
                        history.push('/customer/businessAdd')
                    },
                })
                : 
                setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId: id,
                    customerName: name,
                    onSuccess: () => {
                        setAddFollowProps({...addFollowProps, visible: false})
                    }
                })
            }
        }
    }

    const reFresh = () => location.reload()
 
    const { Actions, Descriptions } = SourceMaps[num||0]

    useEffect(() => {
        getCustomerInfoApi({id})
    }, [])

    return <div className="jns-enterprise-detail">
            <BackBan title={detailObj?.isEmphasisCustomer ?  <>{name} <Tag color="#f50">重点客户</Tag></> : name} actions={<Actions {...{operate, id, name, detailObj, reFresh}} /> as any } />
            <Descriptions detailObj={detailObj} />
            <MyTabs tabPanes={mainTabPans({id, name, lqId, detailObj})} type="card" />
            <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
        </div>
};


// 客户，线索详情
export default () => {
    const { search } = useLocation()
    const { id, lqId, name, type } = parseSearch(search)

    return (<CustomerDetail id={id} name={name} lqId={lqId} type={type} />)
};

