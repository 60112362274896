import { CustomerNameLinkRender, TableListItem } from "@components/Customer/List/data"
import { taskStatusOptions } from "@components/Oa/TaskCenter/data"
import { useHasAuthCode } from "@hooks/index"
import { Progress } from "antd"
import { stringify } from "qs"
import React from "react"
import { Link, useHistory } from "react-router-dom"

export const dataReportList = [
    {
        label: '新增线索',
        param: 'newClueCount',
        value: 200
    },
    {
        label: '新增客户',
        param: 'newCustomerCount',
        value: 200
    },
    {
        label: '新增商机',
        param: 'newBusinessOppCount',
        value: 200
    },
    {
        label: '新增联系人',
        param: 'newContactCount',
        value: 200
    },
    {
        label: '跟进客户数',
        param: 'followCustomerCount',
        value: 200
    },
    {
        label: '跟进商机数',
        param: 'followBusinessOppCount',
        value: 200
    }
]

export const dataSelectOptions = [
    {
        label: '仅本人',
        value: 0
    },
    {
        label: '仅本部门',
        value: 3
    },
    {
        label: '本部门及下级部门',
        value: 4
    },
]

export const rangeOptions: any[] = [
    {
        label: '我的报告',
        value: 1
    },
    {
        label: '下属的报告',
        value: 2
    },
    {
        label: '提交给我的',
        value: 3
    },
]

export const reportStatus: any[] = [
    {
        label: '待批阅',
        value: 1
    },
    {
        label: '合格',
        value: 2
    },
    {
        label: '不合格',
        value: 3
    },
    {
        label: '已批阅',
        value: 4
    }
]

export const taskColumns = () => {
    return [
        {
          title: '任务标题',
          dataIndex: 'taskName',
          key: 'taskName',
          fixed: 'left',
          align: 'center',
          render: (text: any, record: any) => <Link to={`/oa/taskDetail?${stringify({id: record.id, fromPath: '/oa/task'})}`}>{text}</Link>
        },
        {
          title: '发起人',
          dataIndex: 'taskLauncher',
          key: 'taskLauncher',
          order: 7,
          render: (text: any, record: any) => `${(record.taskLauncher||[]).toString()}`
        },
        {
          title: '结束时间',
          dataIndex: 'taskEndTime',
          key: 'taskEndTime',
          valueType: 'dateRange',
          fieldProps: { allowEmpty: [true, true]},
          render: (text: any, record: any) => `${record.taskEndTime||''}`
        },
        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          key: 'taskStatus',
          align: 'center',
          valueType: 'select',
          render: (text: any, record: any) => ((taskStatusOptions||[]).find(v => v.value == text)||{}).label
        },
  
        {
          title: '任务进度',
          dataIndex: 'taskProgress',
          key: 'taskProgress',
          render: (text: any, record: any) => <Progress percent={text} size="small" />
       }
      ]
}


export const oaColumns = () => {
    const history = useHistory()
    return [
      {
          title: '报告时间',
          dataIndex: 'time',
          order: 2,
          render: (text: any, record: any) => <div>{record.time}</div>
      },
      {
          title: '报告标题',
          dataIndex: 'title',
          order: 100,
          render: (text: any, record: any) => {
              const {id: reportId, type} = record || {}
              const onClick = () => {
                history.push(`/oa/workReport?${stringify({reportId, type})}`)
              }
              return <a onClick={onClick}>{text}</a>
          }
      },
      {
          title: '提交人',
          dataIndex: 'creator',
          hideInTable: true,
          order: 5,
      },
      {
          title: '批阅人',
          dataIndex: 'parentName',
          hideInTable: true,
          order: 4,
      },
      {
          title: '状态',
          dataIndex: 'status',
          order: 3,
          render: (text: any, record: any) => ((reportStatus||[]).find(v => v.value == text) || {}).label 
      },
    ]
}

export const newCustomerNumColumnFn = () => {
    const [, hasAuthCode] = useHasAuthCode()
    return [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            order: 5,
            width: 120,
            fixed: 'left',
            render: (text: any, {customerId:id, lqId, customerName: name, ownerName}: TableListItem) => <CustomerNameLinkRender text={text} hasAuth={hasAuthCode(2016)} search={{id, name, ownerName, lqId, type: 0, routeName: '客户详情', fromPath: '/customer/list'}} />
        },
        {
            title: '跟进方式',
            dataIndex: 'followUpMethod',
            hideInSearch: true,
            order: 5,
            render: (text: any, record: any) => `${record.followUpMethodStr||''}`
        },
        // {
        //     title: '跟进反馈',
        //     dataIndex: 'followUpFeedbackStr',
        //     hideInSearch: true,
        //     order: 5,
        //     render: (text: any, record: any) => `${record.followUpFeedbackStr||''}`
        // },
        {
            title: '跟进内容',
            dataIndex: 'followUpContent',
            hideInSearch: true,
            order: 5,
            render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.followUpContent}</div>
        },
        {
            title: '下一步动作',
            dataIndex: 'nextStepContent',
            hideInSearch: true,
            // width: 120,
            render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextStepContent}</div>
            // order: 6,
        },
        {
            title: '创建时间',
            dataIndex: 'followUpTime',
            order: 5,
            valueType: 'dateRange',
            search: {
              transform: (value: any[]) => {
                  return {
                      followUpTimeFrom: value[0],
                      followUpTimeTo: value[1],
                  };
              },
            },
            render: (text: any, record: any) => `${record.followUpTime||''}`
        },
    ]
}

export const newBusinessNumColumnFn = () => {
    const [, hasAuthCode] = useHasAuthCode()
    return [
        {
            title: '商机标题',
            dataIndex: 'businessOpportunityTitle',
            fixed: 'left',
            // hideInSearch: true,
            width: 120,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            order: 5,
            width: 120,
            render: (text: any, {customerId:id, lqId, customerName: name, ownerName}: TableListItem) => <CustomerNameLinkRender text={text} hasAuth={hasAuthCode(2016)} search={{id, name, ownerName, lqId, type: 0, routeName: '客户详情', fromPath: '/customer/list'}} />
        },
        {
            title: '跟进方式',
            dataIndex: 'followUpMethod',
            hideInSearch: true,
            order: 5,
            render: (text: any, record: any) => `${record.followUpMethodStr||''}`
        },
        // {
        //     title: '跟进反馈',
        //     dataIndex: 'followUpFeedbackStr',
        //     hideInSearch: true,
        //     order: 5,
        //     render: (text: any, record: any) => `${record.followUpFeedbackStr||''}`
        // },
        {
            title: '跟进内容',
            dataIndex: 'followUpContent',
            hideInSearch: true,
            order: 5,
            render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.followUpContent}</div>
        },
        {
            title: '下一步动作',
            dataIndex: 'nextStepContent',
            hideInSearch: true,
            // width: 120,
            order: 6,
            render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextStepContent}</div>
         },
        {
            title: '创建时间',
            dataIndex: 'followUpTime',
            order: 5,
            valueType: 'dateRange',
            render: (text: any, record: any) => `${record.followUpTime||''}`
        },
    ]
}

export const FunnelData = [
    {
      stage: '10%潜在商机',
      number: 170,
    },
    {
      stage: '20%商机确认',
      number: 150,
    },
    {
      stage: '40%方案沟通',
      number: 130,
    },
    {
      stage: '60%商务谈判',
      number: 110,
    },
    {
      stage: '80%签约完成',
      number: 90,
    },
    {
      stage: '100%收入确认',
      number: 70,
    },
    {
      stage: '0%项目失败',
      number: 50,
    },
  ];
