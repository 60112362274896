import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn, dispatchColumnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType, EditableProTable } from '@ant-design/pro-table';
import { ModalForm, ProFormSelect } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { Button, ButtonProps, message, Modal, Table } from "antd";
import { AddStaff, BatchImport } from "../List";
import { customerClueDel, customerClueList, customerClueReceive, customerClueToCustomer, customerClueTransferToPool, customerCluesExport, customerClueDispatch, BASEURL } from '@api/index'
import { treeDataToFlatArr, productApi, getCheckedColumnsStateByColumns } from "@utils/util";
import { ExportButton, FormDragSetting, getFormDragSettingOptions, ProFormTreeSelect, ProTableWithFormSetting, setColumnsByOptions } from "@components/index";
import { useCluesPoolDataHooks, useDeptDataHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";

const getApi = productApi(customerClueList)
export const clueGetApi = productApi(customerClueReceive)
export const clueToCustomerApi = productApi(customerClueToCustomer)
export const clueTransferToPoolApi = productApi(customerClueTransferToPool)
export const clueDelApi = productApi(customerClueDel)
export const clueExportApi = productApi(customerCluesExport)
export const clueDispatchApi = productApi(customerClueDispatch)

const persistenceKey = 'customer/cluesPool'

// 转线索
interface AddPoolProps extends ModalFormProps{
    selectedRowKeys?: Key[];
    params?: Record<string, any>;
    onSuccess?: () => void;
}
export const AddPool = ({selectedRowKeys, params, onSuccess, ...rest}: AddPoolProps) => {
    const [options] = useCluesPoolDataHooks({}, true)
    
    const onFinish = async (values: any) => {
        const { success } = await clueTransferToPoolApi({...values, clueIds: selectedRowKeys, ...(params||{})}, true, onSuccess)
        return success
    }

    return (<ModalForm<AddPoolProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} onFinish={onFinish} {...rest}>
        <ProFormSelect 
            name="cluePoolId" 
            label="线索池" 
            fieldProps={{showSearch: true}} 
            options={options} 
            rules={[{required: true, message: '请选择线索池'}]} 
        />
    </ModalForm>)
}

// 分配线索
interface DispatchCluesProps extends ModalFormProps{
    selectedRowKeys?: Key[];
    onSuccess?: () => void;
}
const DispatchClues = ({selectedRowKeys, onSuccess, ...rest}: DispatchCluesProps) => {
    const { deptWithUserTreeData: treeData } = useSelector((state: StateType) => state)
    const [dataSource, setDataSource] = useState<any[]>([])
    const onValuesChange = (value: any[]) => {
        const allArr = treeDataToFlatArr(treeData as any[])
        const dataSource = allArr.filter(v => value.find(s => s === v.id && v.whetherUser)).map(v => {v.amount = 0; return v})
        setDataSource(dataSource as [])
    }
    const onFinish = async (value: any) => {
        const members = dataSource.map(({id, amount}) => ({userId: id.slice(1), amount}))
        const { success } = await clueDispatchApi({clueIds:selectedRowKeys, members}, false, onSuccess)
        return success
    }
    const calcEditableKeys = () => {
        return dataSource.map(v => v.id)
    }
    const columns = dispatchColumnsFn()
    return (<ModalForm<DispatchCluesProps>  modalProps={{maskClosable: false}} layout="horizontal" width={600} onFinish={onFinish} {...rest}>
            <ProFormTreeSelect width="md" name="deptId" label="人员" fieldProps={{treeData, showSearch: true, treeCheckable: true, treeNodeFilterProp: 'title', onChange: onValuesChange}} rules={[{required: true, message: '请选择人员'}]} />
            <EditableProTable 
               columns={columns} 
               rowKey="id" 
               value={dataSource} 
               onChange={(val) => {console.log(val)}}
               editable={{
                   editableKeys: calcEditableKeys(),
                   actionRender: (row, config, defaultDoms) => {
                    return [defaultDoms.delete];
                  },
                  onValuesChange: (_, editableRows) =>  {
                    setDataSource(editableRows as any)
                  }
                }} 
               recordCreatorProps={false} 
            />
    </ModalForm>)
}

// 领取线索
interface GetCluesButtonProps extends ButtonProps{
    params?: Record<string, any>;
    onOk?: () => void;
    onSuccess?: () => void;
}
export const GetCluesButton = ({children, params, onOk, onSuccess, ...rest}: GetCluesButtonProps) => {
    const onClick = async () => {
        Modal.confirm({
            title: '领取线索',
            content: '确认领取已选中的线索？领取后请及时跟进，否则系统按照自动划入规则将线索移入线索池!',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                onOk ? onOk() : await clueGetApi(params, true, onSuccess)
            },
        });
    }
    return <Button key="primary" type="primary" onClick={onClick} {...rest}>{children}</Button>
}

// 线索转客户
interface ClueToCustomerButtonProps extends ButtonProps{
    params?: Record<string, any>;
    onOk?: () => void;
    onSuccess?: () => void;
}
export const ClueToCustomerButton = ({children, params, onOk, onSuccess, ...rest}: ClueToCustomerButtonProps) => {
    const onClick = async () => {
        Modal.confirm({
            title: '转为客户',
            content: '已选中的线索确定转为客户？',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                onOk ? onOk() : await clueToCustomerApi(params, true, onSuccess)
            },
        });
    }
    return <Button key="btn1" onClick={onClick} {...rest}>转为客户</Button>
}

export default () => {
    const ref = useRef<ActionType>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRows, setSelectedRows] = useState<TableListItem[]>([])
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()

    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        if (type === '批量删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await clueDelApi({clueIds: selectedRowKeys}, true, () => {
                        setTimeout(() => {
                            reFresh()
                        }, 1000)
                    })
                },
            });
        } 
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, type: 0}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate)
    const params = {type: 0}

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            clueIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    const calcDisabled = (auth?: string) => {
        const hasSyn = selectedRows.some(v => [0].includes(v.isManager)) // 判断所选的数据中是否有非管理员数据
        const auths = hasSyn ? ['领取线索', '转为客户'] : ['领取线索', '转为客户', '分配线索', '转移线索', '批量导入', '批量导出', '批量删除']
        if (selectedRowKeys.length) {
            return !auths.includes(auth||'' as string)
        }
        return true
    }

    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTableWithFormSetting<TableListItem>
                scroll={{x: 1300}}
                columns={columns}
                params={params}
                request={getListApi}
                options={{reload: false, density: false}}
                toolbar={{
                    actions: [
                       hasAuthCode(2147) ? <GetCluesButton key="primary" type="primary" disabled={calcDisabled('领取线索')} params={{clueIds: selectedRowKeys}} onSuccess={reFresh}>领取线索</GetCluesButton> : null,
                       hasAuthCode(2149) ? <ClueToCustomerButton key="btn1" disabled={calcDisabled('转为客户')} params={{clueIds: selectedRowKeys}} onSuccess={reFresh}>转为客户</ClueToCustomerButton> : null,
                       hasAuthCode(2150) ? <DispatchClues key="btn2" title="分配线索" selectedRowKeys={selectedRowKeys} onSuccess={reFresh} trigger={<Button key="btn2" disabled={calcDisabled('分配线索')}>分配线索</Button>} /> : null,
                       hasAuthCode(2148) ? <AddPool key="bnt3" title="转移线索" selectedRowKeys={selectedRowKeys} trigger={<Button disabled={calcDisabled('转移线索')}>转移线索</Button>} onSuccess={reFresh} /> : null,
                       hasAuthCode(2187) ? <BatchImport key="bnt4" title="批量导入" params={{type: 1}} showFields={['poolId']} trigger={<Button>批量导入</Button>} onSuccess={reFresh} /> : null,
                    //    hasAuthCode(2154) ? <ExportButton key="btn5" disabled={calcDisabled('批量导出')} param={{clueIds: selectedRowKeys, type: 0}} action={`${BASEURL}/clue/export`}></ExportButton> : null,
                       hasAuthCode(2154) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled('批量导出')} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/clue/export`} /> : null,
                       hasAuthCode(2151) ? <Button key="btn6" disabled={calcDisabled('批量删除')} onClick={() => operate('批量删除')}>批量删除</Button> : null,
                    ],
                }}
                rowKey="id"
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRows(selectedRows)
                        setSelectedRowKeys(selectedRowKeys)
                    },
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: `${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
            />
        </div>
    </div>
}

