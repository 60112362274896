import Icon, { IssueTooltip } from "@components/Common/Icon"
import { StateType } from "@store/index"
import { IsArray, productApi } from "@utils/index"
import { Badge, Button, Card, Col, DatePicker, Divider, Modal, Row, Segmented, Select, SelectProps, Statistic, Table, Tag, TreeSelect, TreeSelectProps } from "antd"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { dataReportList, dataSelectOptions, oaColumns, taskColumns, FunnelData, reportStatus, rangeOptions, newCustomerNumColumnFn, newBusinessNumColumnFn } from "./data"
import { Column, Funnel, FUNNEL_CONVERSATION_FIELD } from '@ant-design/plots';
import { Chart } from '@antv/g2';
import { workBenchBusinessQuery, workBenchBusinessStic, workBenchClueQuery, workBenchContactQuery, workBenchCount, workBenchCustomerOrClueNum, workBenchCustomerQuery, workBenchFunnel, workbenchReportList, workBenchTaskPageList } from "@api/workbench"
import { useTableHooks } from "@hooks/index"
import { useHistory } from "react-router-dom"
import ProForm, { ModalForm,  ProFormUploadDragger, } from '@ant-design/pro-form';
import { ProTableWithFormSetting } from "@components/Common"
import { columnsFn, TableListItem } from "@components/Customer/MyPool/data"
import { columnsFn as customerColumnsFn } from "@components/Customer/List/data"
import { columnsFn as businessColumnsFn } from "@components/Customer/Business/data"
import { columnsFn as contactColumnsFn } from "@components/Customer/ContactAll/data"
import { ProTableProps } from "@ant-design/pro-table"

const getClueApi = productApi(workBenchClueQuery)
const getCustomerApi = productApi(workBenchCustomerQuery) // 获取客户列表
const getBusinessApi = productApi(workBenchBusinessQuery)
const getContactApi = productApi(workBenchContactQuery)
const getCountQueryApi = productApi(workBenchCustomerOrClueNum)
const getBusinessSticApi = productApi(workBenchBusinessStic)
const getFunnelApi = productApi(workBenchFunnel)


const { Option, OptGroup } = Select
const { RangePicker } = DatePicker as any;
const workBenchCountApi = productApi(workBenchCount)
const getTaskListApi = productApi(workBenchTaskPageList)
const getReportApi = productApi(workbenchReportList)

const ranges = {
  '今天': [moment(), moment()],
  '昨天': [moment().add(-1, 'days'), moment().add(-1, 'days')],
  '近7天': [moment().add(-7, 'days'), moment()],
  '本周': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
  '上周': [moment().startOf('isoWeek').add(-7, 'days'), moment().endOf('isoWeek').add(-7, 'days')],
  '本月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().startOf('month').add(-1, 'month'), moment().endOf('month').add(-1, 'month')],
  '本季': [moment().startOf('quarter'), moment().endOf('quarter')],
  '上季': [moment().startOf('quarter').add(-1, 'quarter'), moment().endOf('quarter').add(-1, 'quarter')],
  '本年': [moment().startOf('year'), moment().endOf('year')],
  '上年': [moment().startOf('year').add(-1, 'year'), moment().endOf('year').add(-1, 'year')],
}

const handleDataScope = (dataScope: number | string[]) => {
  return typeof dataScope === 'number' ? {dataScope} : {
    dataScope: 2,
    dataPaperUserIds: (dataScope||[]).map((v: string) => v.startsWith('s') ? v.slice(1) : undefined).filter(Boolean)
  }
}

const handleDateRange = (dateRange: any[]) => {
  return { dataPaperDateRange: (dateRange||[]).map(v => v.format('YYYY-MM-DD')) }
}

const handleSubmitParams = ({dataScope, dateRange, ...rest}: any) => {
  return {
    ...handleDataScope(dataScope),
    ...handleDateRange(dateRange),
    ...rest
  }
}

const CustOptions = ({options, value, onChange}: any) => {
  return(<>
    {options?.map((v: any) => <Col key={v.label} className={`ant-select-item ant-select-item-option ${value === v.value ? 'ant-select-item-option-selected' : undefined}`} style={{width: '100%'}} onClick={() => onChange && onChange(v.value)}>{v.label}</Col>)}
  </>
  )
}

export interface CustTreeSelectProps extends Omit<TreeSelectProps, 'onChange'>{
  custOptions?: SelectProps['options'];
  onChange?: (value: any, type?: 'treeSelect' | 'cust') => void;
}

export const CustTreeSelect = ({value: pvalue, onChange: pOnCahnge, custOptions, ...restProps}: CustTreeSelectProps) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [value, setValue] = useState<any>(pvalue)
  const [custValue, setCustValue] = useState<any>(pvalue)
  const onChange = (value: any, type: string) => {
      if (type === 'treeSelect') {
        const newVal = value.filter((v: any) => typeof v === 'string')
        setValue(newVal)
        setCustValue('')
        pOnCahnge?.(newVal, 'treeSelect')
      } else {
        setValue([value])
        setCustValue(value)
        pOnCahnge?.(value, 'cust')
      }
  }
  const tagRender = ({label, ...rest}: any) => {
    return typeof label === 'string' ? <Tag closable {...rest} key={rest?.value}>{label}</Tag> : <span key={rest?.value}>{(custOptions?.find(v => v.value === label)||{}).label}</span>
  }
  return(
  <TreeSelect 
    treeData={deptWithUserTreeData} 
    maxTagCount={4} 
    value={value} 
    onChange={(val) => onChange(val, 'treeSelect')}
    treeCheckable 
    showSearch 
    treeNodeFilterProp='title' 
    style={{width: 180}} 
    className="jns-cust-select-content"
    tagRender={tagRender}
    dropdownRender={menu => {
        return(<>
          <CustOptions options={custOptions} value={custValue} onChange={(custValue: number) => onChange(custValue, 'cust')} />
          {custOptions?.length ? <div className="ant-select-item ant-select-item-group">自定义</div> : null}
          {menu}
          {/* <TreeSelect treeData={deptWithUserTreeData} maxTagCount={4} treeCheckable showSearch treeNodeFilterProp='title' style={{width: '100%', padding: '0 8px'}} />
          <div style={{textAlign: 'right', margin: '5px 10px', width: '100%'}}>
            <Button size="small">取消</Button>
            <Button size="small" type="primary" style={{marginLeft: 10}}>确认</Button>
          </div> */}
        </>)
    }}
    {...restProps}
  >
  </TreeSelect>)
}

const CardStuffSelect = ({...restProps}: CustTreeSelectProps) => {
  return (<CustTreeSelect
    custOptions={dataSelectOptions}
    {...restProps}
    />)
}

// const CardStuffSelect = ({value: pvalue, onChange: pOnCahnge}: CustTreeSelectProps) => {
//     const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
//     const [value, setValue] = useState<any>(pvalue)
//     const [custValue, setCustValue] = useState<any>(pvalue)
//     const onChange = (value: any, type: string) => {
//         if (type === 'treeSelect') {
//           const newVal = value.filter((v: any) => typeof v === 'string')
//           setValue(newVal)
//           setCustValue('')
//           pOnCahnge && pOnCahnge(newVal, 'treeSelect')
//         } else {
//           setValue([value])
//           setCustValue(value)
//           pOnCahnge && pOnCahnge(value, 'cust')
//         }
//     }
//     const tagRender = (props: any) => {
//       return typeof props.label === 'string' ? <Tag closable>{props.label}</Tag> : <span>{(dataSelectOptions.find(v => v.value === props.value)||{}).label}</span>
//     }
//     return(
//     <TreeSelect 
//       treeData={deptWithUserTreeData} 
//       maxTagCount={4} 
//       value={value} 
//       onChange={(val) => onChange(val, 'treeSelect')}
//       treeCheckable 
//       showSearch 
//       treeNodeFilterProp='title' 
//       style={{width: 180}} 
//       className="jns-cust-select-content"
//       tagRender={tagRender}
//       dropdownRender={menu => {
//           return(<>
//             <CustOptions options={dataSelectOptions} value={custValue} onChange={(custValue: number) => onChange(custValue, 'cust')} />
//             <div className="ant-select-item ant-select-item-group">自定义</div>
//             {menu}
//             {/* <TreeSelect treeData={deptWithUserTreeData} maxTagCount={4} treeCheckable showSearch treeNodeFilterProp='title' style={{width: '100%', padding: '0 8px'}} />
//             <div style={{textAlign: 'right', margin: '5px 10px', width: '100%'}}>
//               <Button size="small">取消</Button>
//               <Button size="small" type="primary" style={{marginLeft: 10}}>确认</Button>
//             </div> */}
//           </>)
//     }}
//     >
//     </TreeSelect>)
// }

// 新增线索
const NewCluesQuery = ({...rest}: ProTableProps<TableListItem, any>) => {
  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getClueApi({...params, sorter, whetherDataPaper: true}, false)
    const { values, total } = data || {}
    return {
        data: values || [],
        success: true,
        total
    }
  }
  return(<ProTableWithFormSetting<TableListItem>
    // scroll={{x: 1300}}
    scroll={{x: 'max-content'}}
    columns={columnsFn(() => null).filter(v => !['option'].includes(v.key as string)) }
    request={getListApi}
    options={{reload: false, density: false, setting: false}}
    rowKey="id"
    // actionRef={ref as any}
    pagination={{defaultPageSize: 10}}
    // columnsState={{ persistenceKey: 'customer/myPool', persistenceType: 'localStorage'}}
    // searchState={{ persistenceKey: 'customer/myPool:searchState', persistenceType: 'localStorage' }}
    {...rest}
/>)
}

// 新增客户
const NewCustomerQuery = ({...rest}: ProTableProps<TableListItem, any>) => {
  const [dynamicFields, setDynamicFields] = useState<any>({})
  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getCustomerApi({...params, sorter, whetherDataPaper: true}, false)
    const { values, total, dynamicFields } = data || {}
    setDynamicFields(dynamicFields)
    return {
        data: values || [],
        total,
        success: true,
    }
  }
  return(<ProTableWithFormSetting<TableListItem>
    // scroll={{x: 1300}}
    scroll={{x: 'max-content'}}
    columns={customerColumnsFn(() => null, dynamicFields).filter(v => !['isAttention','option'].includes(v.key as string)) as any}
    request={getListApi}
    options={{reload: false, density: false, setting: false}}
    rowKey="id"
    // actionRef={ref as any}
    pagination={{defaultPageSize: 10}}
    // columnsState={{ persistenceKey: 'customer/myPool', persistenceType: 'localStorage'}}
    // searchState={{ persistenceKey: 'customer/myPool:searchState', persistenceType: 'localStorage' }}
    {...rest}
/>)
}

// 新增商机
const NewBusinessQuery = ({...rest}: ProTableProps<TableListItem, any>) => {
  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getBusinessApi({...params, sorter, whetherDataPaper: true}, false)
    const { values, total } = data || {}
    return {
        data: values || [],
        success: true,
        total
    }
  }
  return(<ProTableWithFormSetting<TableListItem>
    // scroll={{x: 1300}}
    scroll={{x: 'max-content'}}
    columns={businessColumnsFn(() => null).filter(v => !['option'].includes(v?.key as string)) as any}
    request={getListApi}
    options={{reload: false, density: false, setting: false}}
    rowKey="id"
    // actionRef={ref as any}
    pagination={{defaultPageSize: 10}}
    // columnsState={{ persistenceKey: 'customer/myPool', persistenceType: 'localStorage'}}
    // searchState={{ persistenceKey: 'customer/myPool:searchState', persistenceType: 'localStorage' }}
    {...rest}
/>)
}

// 新增联系人
const NewContactQuery = ({...rest}: ProTableProps<TableListItem, any>) => {
  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getContactApi({...params, sorter, whetherDataPaper: true}, false)
    const { values, total } = data || {}
    return {
        data: values || [],
        success: true,
        total
    }
  }
  return(<ProTableWithFormSetting<TableListItem>
    // scroll={{x: 1300}}
    scroll={{x: 'max-content'}}
    columns={contactColumnsFn(() => null) as any}
    request={getListApi}
    options={{reload: false, density: false, setting: false}}
    rowKey="id"
    // actionRef={ref as any}
    pagination={{defaultPageSize: 10}}
    // columnsState={{ persistenceKey: 'customer/myPool', persistenceType: 'localStorage'}}
    // searchState={{ persistenceKey: 'customer/myPool:searchState', persistenceType: 'localStorage' }}
    {...rest}
/>)
}

// 跟进客户数
const NewCustomerNumQuery = ({...rest}: ProTableProps<TableListItem, any>) => {
  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getCountQueryApi({...params, whetherDataPaper: true, dataPaperFollowUpType: 1}, false)
    const { values, total } = data || {}
    return {
        data: values || [],
        success: true,
        total
    }
  }
  return(<ProTableWithFormSetting<TableListItem>
    // scroll={{x: 1300}}
    scroll={{x: 'max-content'}}
    columns={newCustomerNumColumnFn() as any}
    request={getListApi}
    options={{reload: false, density: false, setting: false}}
    rowKey="id"
    // actionRef={ref as any}
    pagination={{defaultPageSize: 10}}
    // columnsState={{ persistenceKey: 'customer/myPool', persistenceType: 'localStorage'}}
    // searchState={{ persistenceKey: 'customer/myPool:searchState', persistenceType: 'localStorage' }}
    {...rest}
/>)
}

// 跟进商机数
const NewBusinessNumQuery = ({...rest}: ProTableProps<TableListItem, any>) => {
  const getListApi = async (params: any, sorter: any, filter: any) => {
    const { data } = await getCountQueryApi({...params, whetherDataPaper: true, dataPaperFollowUpType: 2}, false)
    const { values, total } = data || {}
    return {
        data: values || [],
        success: true,
        total
    }
  }
  return(<ProTableWithFormSetting<TableListItem>
    // scroll={{x: 1300}}
    scroll={{x: 'max-content'}}
    columns={newBusinessNumColumnFn() as any}
    request={getListApi}
    options={{reload: false, density: false, setting: false}}
    rowKey="id"
    // actionRef={ref as any}
    pagination={{defaultPageSize: 10}}
    // columnsState={{ persistenceKey: 'customer/myPool', persistenceType: 'localStorage'}}
    // searchState={{ persistenceKey: 'customer/myPool:searchState', persistenceType: 'localStorage' }}
    {...rest}
/>)
}

const DataReport = () => {
    const history = useHistory()
    const [dataObj, setDataObj] = useState<any>({})
    const [dataScope, setDataScope] = useState<any>(0)
    const [dateRange, setDateRange] = useState<any>(ranges['本周'])
    // const [modelProps, setModelProps] = useState<ModalFormProps>({title: '', visible: false, idx: 0} as any)

    const getApi = async(params: Record<string, any>) => {
      await workBenchCountApi(params, false, setDataObj)
    }
    useEffect(() => {
      const params = handleSubmitParams({dataScope, dateRange})
      getApi(params)
    }, [dataScope, dateRange])

    return(<Card title="数据简报" 
        extra={<>
            <CardStuffSelect value={dataScope} onChange={setDataScope} />
            <RangePicker ranges={ranges} value={dateRange} onChange={setDateRange} style={{marginLeft: 20}} />
        </>}>
        <div style={{ display: 'flex', justifyContent: 'space-around'}}>
          {dataReportList.map((v, i) => (
            <Statistic 
              key={v.label}
              style={{width: 180, background: '#F6F7F9',borderRadius:'4px', textAlign: 'center', padding: 24}}  
              valueStyle={{color: '#0479FE', cursor: 'pointer'}}
              title={v.label}  
              formatter={() => {
                const Comp = [NewCluesQuery, NewCustomerQuery, NewBusinessQuery, NewContactQuery, NewCustomerNumQuery, NewBusinessNumQuery][i]
                return (<ModalForm 
                          title={v.label} 
                          width={'88%'} 
                          key={v.value}
                          modalProps={{maskClosable: false}} 
                          layout="horizontal" 
                          labelCol={{span: 4}} 
                          trigger={<span>{dataObj[v.param]||0}</span>} >
                       <Comp params={handleSubmitParams({dataScope, dateRange})} />
                </ModalForm>)
              }}
            />))}
        </div>
  </Card>)
}

// 业务统计
const BusinessCard = () => {
  const [type, setType] = useState<'month' | 'year'>('month')
  const [dataScope, setDataScope] = useState<any>(0)
  const [date, setDate] = useState<any>(moment())
  const [data, setData] = useState<any>([])
  const config = {
    // data: columnData,
    isGroup: true,
    xField: 'time',
    yField: '数量',
    seriesField: 'name',
    slider: {
      start: 0,
      end: 1,
    },
  }
  const getApi = async ({dataScope, date, type, ...rest}: any) => {
    const submitParams = {
      ...handleDataScope(dataScope),
      date: moment(date).format(type === 'month' ? 'YYYY-MM-DD' : 'YYYY-MM-DD'),
      type: type === 'month' ? 1 : 2,
      ...rest
    }
    await getBusinessSticApi(submitParams, false, setData)
  }
  const calcColumnData = (data: any[]) => {
    const arr: any[] = []
    data.forEach(v => {
       arr.push({
        name: '新增客户',
        time: v.time,
        数量: v.newCustomerCount,
      }, {
        name: '新增商机',
        time: v.time,
        数量: v.newBusinessOppCount,
      })
    })
    return arr
  }
  useEffect(() => {
    getApi({dataScope, date, type})
  }, [dataScope, date, type])

  return(<Card 
    title={<>业务统计 <IssueTooltip title="对所选时间段内的新增客户数、新增商机数进行汇总分析。" /></>} 
    extra={<>
      <CardStuffSelect value={dataScope} onChange={setDataScope} />
      <Segmented options={[{label: '按日', value: 'month'}, {label: '按月', value: 'year'}]} onChange={setType as any} style={{marginLeft: 8}} />
      <DatePicker value={date} onChange={setDate} picker={type} />
    </>}
    >
      <Column {...config} data={calcColumnData(data)} />
  </Card>)
}

function getContainer(chart: any) {
  let container = chart.getLayer('fore').findById('annotation-group');
  if (!container) {
    container = chart.getLayer('fore').addGroup({ id: 'annotation-group' });
  } else {
    container.clear();
  }
  return container
}

// 销售漏斗
const SalesCard = () => {
  const [dataScope, setDataScope] = useState<any>(0)
  const [data, setData] = useState<any>({})
  const dataArr = (data.businessOppDataResponses||[]).map((v: any, i: any) => ({...v, number: (10 - i) * 20}))
  
  const renderAnntations = (chart: any) => {
    const coord = chart.getCoordinate();
    const elements = chart.geometries[0].elements;
    const parsePoints: any = [];
    const container = getContainer(chart)
    const myPoints: any[] = []
    elements.forEach((ele: any, idx: number) => {
      const { data } = ele
      const { points } = ele.shape.get('origin');
      const startPoint = {
        x: (points[2].x + points[1].x) / 2,
        y: (points[1].y + points[2].y) / 2
      }
      const endPoint = {
        x: startPoint.x + 30,
        y: startPoint.y
      }
      myPoints.push([startPoint, endPoint, data])
    });
    const fontSize = 10;
    const offset = 5;
    const len = myPoints.length
    myPoints.forEach((point, idx) => {
      const p0 = coord.convert(point[0]);
      const p3 = coord.convert(point[1]);
      const data = point[2]
      const path = [
        ['M', p0.x - ((idx == (len - 1)) ? 0 : 6), p0.y],
        ['L', p0.x + fontSize, p0.y],
        ['M', p3.x, p3.y],
        ['L', p3.x + 15, p3.y],
      ];
      container.addShape('path', {
        attrs: {
          path,
          stroke: '#d3d3d3',
          lineWidth: 1,
        },
      });
      container.addShape('text', {
        attrs: {
          x: (path[0][1] + path[1][1]) / 2 + offset,
          y: (path[0][2] + path[1][2] + fontSize) / 2,
          text: ` ${data.salesStage}, ${data.businessOppCount}`,
          fontSize,
          fill: '#333',
        },
      });
    })
  }

  const config = {
    data: dataArr,
    xField: 'salesStage',
    yField: 'number',
    padding: [16,8,16,0],
    legend: false,
    label: {
      formatter: (datum: any) => {
        return `${(datum['proportion']).toFixed(2)}%`;
      }
    },
    conversionTag: false,
    tooltip: {
      showMarkers: false,
      enterable: true,
      domStyles: {
        'g2-tooltip': {
          width: '180px',
          padding: 0,
        },
      },
      customContent: (title: string, items: any) => {
        const item = items[0]
        const { color, data } = item || {}
        const {businessOppCount, estimatedSalesAmount, proportion} = data || {}
        return(<Row gutter={[16, 16]} style={{padding: 10, }}>
          <Col style={{width: '100%'}}><Badge color={color} /> {title}</Col>
          <Col style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}><span style={{marginLeft: 20}}>商机数量</span><span>{businessOppCount}</span></Col>
          <Col style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}><span style={{marginLeft: 20}}>预计销售金额</span><span>{estimatedSalesAmount}</span></Col>
          <Col style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}><span style={{marginLeft: 20}}>占比</span><span>{proportion + '%'}</span></Col>
        </Row>)
      },
    },
  };
  const getApi = async({dataScope, ...rest}: any) => {
    const submitParams = {
      ...handleDataScope(dataScope),
      ...rest
    }
    await getFunnelApi(submitParams, false, setData)
  }
  const onEvent = ({chart}: any, {type}: any) => {
    if (type === 'afterrender') {
      renderAnntations(chart)
    }
  }
  useEffect(() => {
     getApi({dataScope})
  }, [dataScope])
  return(<Card 
      title={<>销售漏斗 <IssueTooltip title="数据来源于商机管理版块，汇总处于不同阶段的商机的数量和金额对比." /></>} 
      extra={<CardStuffSelect value={dataScope} onChange={setDataScope} />}
      >
      <Funnel {...config as any} onEvent={onEvent} />
      <Row justify="center" align="middle">商机数量: {data.businessOppCount} <Divider type="vertical" style={{color: '#e5e5e5'}} /> 预计销售金额: {data.totalEstimatedSalesAmount}</Row>
  </Card>)
}

//待办任务
const TodoListCard = () => {
  const history = useHistory()
  const { dataPaperDateRange: taskStartTime} = handleDateRange(ranges['近7天'])
  const {tableProps, fetchApi } = useTableHooks(getTaskListApi, {dataScope: 1, taskStartTime, pageSize: 50, taskProgress: [0, 99.99]}, true)
  const onChange = (val: any) => {
    const { dataPaperDateRange: taskStartTime} = handleDateRange(val)
    fetchApi({taskStartTime})
  }
  return(<Card title="待办任务" 
    extra={<>
      <RangePicker defaultValue={ranges['近7天']} ranges={ranges} onChange={onChange} />
      <a onClick={() => history.push(`/oa/task`)} style={{color: 'rgba(0, 0, 0, 0.45)', marginLeft: 10}}>
        更多 <Icon type="icon-jiantou_xiangyouliangci" />
      </a>
    </>}>
    <Table scroll={{y: 600 }} columns={taskColumns() as any} {...tableProps} pagination={false} rowKey="id" />
  </Card>)
}

// 工作报告
const WorkReportCard = () => {
  const history = useHistory()
  const [reportType, setReportType] = useState<any>(1)
  const [range, setRange] = useState<any>(1)
  const [status, setStatus] = useState<any>(1)
  const [data, setData] = useState<any>([])
  const [dateRange, setDateRange] = useState<any>([])

  const getApi = async ({range, status, type, dateRange}: any) => {
    const dailyTimeFrom = dateRange[0] && dateRange[0].format('YYYY-MM-DD')
    const dailyTimeTo = dateRange[1] && dateRange[1].format('YYYY-MM-DD')
    await getReportApi({range, status, type, dailyTimeFrom, dailyTimeTo, pageSize: 50}, false, (data: any) => {
      setData(data||[])
    })
  }
  useEffect(() => {
    getApi({range, status, type: reportType, dateRange})
  }, [range, status, reportType, dateRange])
  
  return(<Card title="工作报告" 
    extra={<>
      <RangePicker ranges={ranges} value={dateRange} onChange={setDateRange} />
      <a onClick={() => history.push(`/oa/workReport`)} style={{color: 'rgba(0, 0, 0, 0.45)', marginLeft: 10}}>
        更多 <Icon type="icon-jiantou_xiangyouliangci" />
      </a>
    </>}>
    <Row justify="space-between" style={{marginBottom: 10}}>
      <Col>
        <Segmented value={reportType} options={[{label: '日报', value: 1}, {label: '周报', value: 2}]} onChange={setReportType as any} style={{marginLeft: 8}} />
      </Col>
      <Col>
        <Select value={range} onChange={setRange} options={rangeOptions} style={{width: 120}} />
        <Select value={status} onChange={setStatus} options={reportStatus} style={{width: 88, marginLeft: 10}} />
      </Col>
    </Row>
    <Table scroll={{y: 600 }} columns={oaColumns() as any} dataSource={data} pagination={false} rowKey="id" />
  </Card>)
}

export default () => {
   return(<div>
      <DataReport />
      <Row style={{display: 'flex', marginTop: 20}} gutter={[12,12]}>
        <Col span={12}><BusinessCard /></Col>
        <Col span={12}><SalesCard /></Col>
        <Col span={12}><TodoListCard /></Col>
        <Col span={12}><WorkReportCard /></Col>
      </Row>
   </div>)
}