import React, { Key, useRef, useState, useEffect } from "react"
import { solutionColumns, customerCaseColumns, qsTemplateColumns, surveyColumns } from './data'
import type { OperateActionType, TableListItem } from './data'
import { Button, DatePicker, Tabs, Radio } from "antd";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { treeDataDisabledRules, productApi, parseSearch, handleUploadFiles } from "@utils/index";
import { useHasAuthCode, dimensionList, useCommonApiHooks } from "@hooks/index";
import { ProTableWithFormSetting } from "@components/index";
import { EnterpriseDetailProps } from "../../type"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { knowledgeGetDocListByCustomer } from "@api/knowledge";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";

const getApi = productApi(knowledgeGetDocListByCustomer)
const { TabPane } = Tabs

export default ({ id: customerId, name, Collaborator, readOnly, ...rest }: EnterpriseDetailProps) => {
    const history = useHistory()
    const [, hasAuthCode] = useHasAuthCode()

    const ref = useRef<ActionType>(null);

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
       
    }
    const operate = (type: string, record: any) => {
        const { customerId, customerName, userId, id } = record || {}
        if (type === '编辑问卷') {
            history.push(`/knowledge/create?${stringify({type: 3.1, customerId,customerName,userId, docId: id, routeName: '修改问卷'})}`)
        }
    }
    return <div>
        <div style={{ boxSizing: 'border-box', background: '#fff', paddingTop: 25 }}>
        <Tabs type="card">
            <TabPane tab="解决方案" key="1">
                <ProTable<TableListItem>
                    params={{type: 1, customerId}}
                    request={customerId ? getListApi : null as any}
                    columns={solutionColumns()}
                    options={{reload: false, density: false, setting: false, fullScreen: false}}
                    toolbar={{
                        actions: [
                           !readOnly ? <Button key="create" type="primary" onClick={() => history.push(`/knowledge/create?${stringify({type: 1, customerId, customerName: name})}`)}>创建文档</Button> : null
                        ],
                    }}
                    rowKey="id"
                    pagination={{defaultPageSize: 10}}
                    actionRef={ref as any}
                />
            </TabPane>
            <TabPane tab="客户案例" key="2">
                <ProTable<TableListItem>
                    params={{type: 2, customerId}}
                    request={customerId ? getListApi : null as any}
                    columns={customerCaseColumns()}
                    options={{reload: false, density: false, setting: false, fullScreen: false}}
                    toolbar={{
                        actions: [
                            !readOnly ? <Button key="create" type="primary" onClick={() => history.push(`/knowledge/create?${stringify({type: 2, customerId, customerName: name})}`)}>创建文档</Button> : null
                        ],
                    }}
                    rowKey="id"
                    pagination={{defaultPageSize: 10}}
                    actionRef={ref as any}
                />
            </TabPane>
            <TabPane tab="问卷模版" key="3">
                <ProTable<TableListItem>
                    params={{type: 3, customerId}}
                    request={customerId ? getListApi : null as any}
                    columns={qsTemplateColumns()}
                    options={{reload: false, density: false, setting: false, fullScreen: false}}
                    toolbar={{
                        actions: [
                            !readOnly ? <Button key="create" type="primary" onClick={() => history.push(`/knowledge/create?${stringify({type: 3, })}`)}>创建模板</Button> : null
                        ],
                    }}
                    rowKey="id"
                    pagination={{defaultPageSize: 10}}
                    actionRef={ref as any}
                />
            </TabPane>
            <TabPane tab="已答问卷" key="4">
                <ProTable<TableListItem>
                    params={{type: 4, customerId}}
                    request={customerId ? getListApi : null as any}
                    columns={surveyColumns(operate)}
                    options={{reload: false, density: false, setting: false, fullScreen: false}}
                    toolbar={{
                        actions: []
                    }}
                    rowKey="id"
                    pagination={{defaultPageSize: 10}}
                    actionRef={ref as any}
                />
            </TabPane>
        </Tabs>
        </div>
    </div>
}