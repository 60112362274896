import { useTableHooks, useDictEnumHooks, useCantactsByCustomerIdHooks } from "@hooks/index"
import { Button, Col, Modal, Row, Table } from "antd"
import React, { Key, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { CustomerNumberColumnsFn } from './data'
import { customerGetCustomerAccount, customerAddAccount, customerUpdateAccount, rebateCustomerDel } from '@api/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { ModalForm, ProFormText, ProFormSelect} from '@ant-design/pro-form';
import type { OperateActionType, TableListItem } from './data'
import { productApi, parseSearch } from "@utils/util"
import { StateType } from "@store/index";
import { useLocation } from "react-router"
import { EnterpriseDetailProps } from "../../type"
import ProTable from "@ant-design/pro-table";

const getApi = productApi(customerGetCustomerAccount)
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

interface AddCustomerProps extends ModalFormProps {
    id?: string;
    record: any;
    disabledFields?: string[];
    onSuccess?: () => void;
}

const addApi = productApi(customerAddAccount)
const updateApi = productApi(customerUpdateAccount)
const delApi = productApi(rebateCustomerDel)
// 新增客户账号
const AddUpdateCustomer = ({ id, disabledFields, record, onSuccess, ...rest }: AddCustomerProps) => {
    const { search } = useLocation()
    const userId = parseSearch(search).id
    const [typeOptions,] = useDictEnumHooks({dictId: 24}, true)
    const [patternOptions,] = useDictEnumHooks({dictId: 25}, true)
    const [accountSourceOptions,] = useDictEnumHooks({dictId: 274}, true)
    //  新增客户账号
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
           if (id){
               const { type, customerAccount, cooperationMode, accountSource, realNameAuthentication} = record || {}
                formRef.current?.setFieldsValue({ type, customerAccount, cooperationMode, accountSource, realNameAuthentication})
           }
        }
    }
    const onFinish = async (values: any) => {
        const { success } = await (id ? updateApi : addApi)({ ...values,id, customerId:userId }, true, onSuccess)
        return success
    }
    return (<ModalForm<any> layout="horizontal" width={500} labelCol={{ span: 5 }} formRef={formRef} onVisibleChange={onVisibleChange} onFinish={onFinish} {...rest}>
        <ProFormSelect width="md" name="type" label="账号类型" options={typeOptions} rules={[{required: true, message: '请选择账号类型'}]} />
        <ProFormText width="md" name="customerAccount" label="客户账号" rules={[{required: true, message: '请选择客户账号'}]} />
        <ProFormSelect width="md" name="accountSource" label="账号来源" options={accountSourceOptions} rules={[{required: true, message: '请选择账号来源'}]} />
        <ProFormSelect width="md" name="cooperationMode" label="合作模式" options={patternOptions} rules={[{required: true, message: '请选择合作模式'}]} />
        <ProFormText width="md" name="realNameAuthentication" label="实名认证名称" rules={[{required: true, message: '请填写实名认证名称'}]} />
    </ModalForm>)
}
export default ({id: customerId,Collaborator, name, ...rest}: EnterpriseDetailProps) => {
    // const {tableProps, fetchApi } = useTableHooks(customerGetCustomerAccount, {customerId, pageCurrent: 1, pageSize: 10})
    const [addCustomer, setAddCustomer] = useState<AddCustomerProps>({ title: '新增客户账号', visible: false, id: undefined, onSuccess: undefined, record: [] })
    const ref = useRef<ActionType>(null);
    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id } = record || {}
        if (['新建客户账号'].includes(type)) {
            setAddCustomer({
                ...addCustomer,
                title: type,
                visible: true,
                id: undefined,
                record: [],
                disabledFields: [],
                onSuccess: () => {
                    reFush()
                    setAddCustomer({ ...addCustomer, visible: false })
                }
            })
        } else if (['编辑'].includes(type)) {
            setAddCustomer({
                ...addCustomer,
                title: type,
                visible: true,
                id: id,
                record,
                disabledFields: [],
                onSuccess: () => {
                    reFush()
                    setAddCustomer({ ...addCustomer, visible: false })
                }
            })
        } else if (['删除'].includes(type)) {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后，会影响返点订单的绑定关系',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids: [id]}, true, () => {
                        reFush()
                    })
                },
            });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}) as any
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
   

    const columns = CustomerNumberColumnsFn(operate, Collaborator)
    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
        <ProTable<any>
            request={getListApi}
            params={{customerId}}
            columns={columns}
            search={false}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    Collaborator ? <Button type="primary" onClick={() => operate && operate('新建客户账号')}>新建账号</Button> : null
                ],
            }}
            rowKey="id"
            actionRef={ref as any}
        />
        <AddUpdateCustomer {...addCustomer} modalProps={{ onCancel: () => setAddCustomer({ ...addCustomer, visible: false }), maskClosable: false }} />
    </div>)
}
