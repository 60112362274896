import React from "react";
import { TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import moment from "moment";
import { yesNoOptions } from "@utils/enum";
import { CustomerNameLinkRender } from "../List/data";

// ts--types
export type TableListItem = {
    key: number;
    isSyn: number;
    businessOpportunityTitle: string;
    customerName: string;
    estimatedSalesAmount: string;
    followBusinessLine: string;
    estimatedSigningDate: string;
    salesStage: string;
    creator: string;
    synergy: string;
    businessOppId: string;
    customerId: string;
    lqId?: string;
    ownerName?: any
};

export type OperateActionType = '新建商机' | '添加协同人' | '转移线索' | '批量导出' | '批量删除' | '添加跟进' | '新建跟进' | '跟进详情'

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  const [, hasAuthCode] = useHasAuthCode()
  const [, , request] = useDictEnumHooks({dictId: 13 }, false)
  return [
    {
        title: '客户名称',
        dataIndex: 'customerName',
        key: 'customerName',
        width: 100,
        order: 100,
        fixed: 'left',
        render: (text: any, {customerId: id, lqId, customerName: name, ownerName}: TableListItem) => <CustomerNameLinkRender text={text} hasAuth={hasAuthCode(2016)} search={{id, name, ownerName, lqId, type: 0, routeName: '客户详情', fromPath: '/customer/list'}} />
    },
    {
        title: '重点客户',
        dataIndex: 'emphasisProject',
        width: 80,
        order: 101,
        valueType: 'select',
        fixed: 'left',
        fieldProps: { options: yesNoOptions },
        render: (text: any, record: any) => record.emphasisProject ? '是' : '否'
    },
    {
        title: '本次沟通联系人',
        dataIndex: 'contactsName',
        key: 'contactsName',
        width: 120,
        order: 22
    },
    {
        title: '跟进方式',
        dataIndex: 'followUpMethod',
        key: 'followUpMethod',
        width: 100,
        order: 21,
        valueType: 'select',
        params: {dictId: 17},
        request,
        render: (text: any, record: any) => `${record.followUpMethodStr}`
    },
    {
        title: '商机标题',
        dataIndex: 'businessOpportunityTitle',
        width: 100,
        hideInSearch: true,  // cx 说 去掉
        order: 20
    },
    {
        title: '本次跟进时间',
        dataIndex: 'followTime',
        key: 'followTime',
        valueType: 'dateRange',
        width: 160,
        order: 18,
        fieldProps: { allowEmpty: [true, true]},
        sorter: true,
        hideInSearch: true,  // cx 说 去掉
        search: {
            transform: (value) => {
              return {
                followTimeFrom: value[0],
                followTimeTo: value[1],
              };
            },
        },
        render: (text: any, record: any) => `${record.followUpTime||'-'}`
    },
    {
        title: '本次跟进协同人',
        dataIndex: 'synergyStr',
        hideInSearch: true,  // cx 说 去掉
        order: 17,
        render: (text: any, record: any) => `${record.synergName||'无'}`
    },
    {
        title: '项目阶段',
        dataIndex: 'stage',
        hideInSearch: true,  // cx 说 去掉
        order: 19,
        render: (text: any, record: any) => `${record.projectStage||'-'}`
    },
    {
        title: '跟进内容',
        dataIndex: 'followUpContent',
        hideInSearch: true,  // cx 说 去掉
        key: 'followUpContent',
        // render: (text: any) => <div style={{width: 200}}>{text}</div>
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.followUpContent}</div>
    },
    {
        title: '待突破的卡点',
        dataIndex: 'stickingPoint',
        width: 100,
        hideInSearch: true,
    },
    {
        title: '求助点',
        dataIndex: 'helpPoint',
        width: 100,
        hideInSearch: true,
    },
    {
        title: '下一步动作',
        dataIndex: 'nextStepContent',
        hideInSearch: true,
        // width: 120,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextStepContent}</div>
        // order: 6,
    },
    {
        title: '账号是否注册',
        dataIndex: 'accountRegister',
        width: 100,
        hideInSearch: true,  // cx 说 去掉
        order: 16,
        render: (text: any, record: any) => record?.accountRegister ? '是' : '否'
    },
    {
        title: '建立沟通群',
        dataIndex: 'createGroup',
        width: 100,
        hideInSearch: true,  // cx 说 去掉
        order: 15
    },
    {
        title: '是否80%以上概率落单',
        dataIndex: 'makeSuccess',
        width: 100,
        hideInSearch: true,  // cx 说 去掉
        render: (text: any, record: any) => record?.makeSuccess ? '是' : '否'
    },
    {
        title: '是否poc测试',
        dataIndex: 'pocTest',
        width: 100,
        hideInSearch: true,  // cx 说 去掉
        order: 14,
        render: (text: any, record: any) => record?.pocTest ? '是' : '否'
    },
    {
        title: '采购方式',
        dataIndex: 'procurementMethod',
        width: 100,
        hideInSearch: true,  // cx 说 去掉
        order: 13,
    },
    {
        title: '下单方式',
        dataIndex: 'orderMethod',
        width: 100,
        order: 12,
        hideInSearch: true,  // cx 说 去掉
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        valueType: 'dateRange',
        width: 160,
        order: 10,
        fieldProps: { 
            allowEmpty: [true, true], 
            defaultValue: [moment().add(-30, 'days'), moment()]
        },
        formItemProps: {
            initialValue: [moment().add(-30, 'days'), moment()],  
        },
        search: {
            transform: (value) => {
                return {
                    createTimeFrom: value[0] ? value[0] : null,
                    createTimeTo: value[1] ? value[1] : null,
                };
            },
        },
        sorter: true,
        render: (text: any, record: any) => `${record.createTime||'-'}`
    },
    {
        title: '创建人',
        dataIndex: 'creator',
        order: 11,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const userIds = (ids||[]).map((v: string) => v.slice(1))
            return { userIds }
          },
        },
    },
    // {
    //     title: '跟进反馈',
    //     dataIndex: 'followUpFeedbackStr',
    //     key: 'followUpFeedbackStr',
    //     width: 100,
    //     hideInSearch: true
    // },
  

    // {
    //     title: '跟进记录',
    //     dataIndex: 'followupInfo',
    //     hideInTable: true,
    //     order: 2,
    // },
    // {
    //     title: '下次跟进',
    //     dataIndex: 'nextFollowTime',
    //     key: 'nextFollowTime',
    //     valueType: 'dateRange',
    //     width: 160,
    //     order: 1,
    //     fieldProps: { allowEmpty: [true, true]},
    //     sorter: true,
    //     search: {
    //         transform: (value) => {
    //           return {
    //             nextFollowTimeFrom: value[0],
    //             nextFollowTimeTo: value[1],
    //           };
    //         },
    //     },
    //     render: (text: any, record: any) => `${record.nextFollowTime||'-'}`
    // },
    // {
    //     title: '跟进人',
    //     dataIndex: 'creator',
    //     key: 'creator',
    //     width: 80,
    //     order: 4,
    //     fieldProps: {
    //         maxTagCount: 4,
    //         treeCheckable: true,
    //         showSearch: true,
    //         treeNodeFilterProp: 'title',
    //         treeData: deptWithUserTreeData
    //     },
    //     renderFormItem: (props) => <TreeSelect />,
    //     search: {
    //         transform: (ids) => {
    //             const userIds = (ids||[]).map((v: string) => v.slice(1))
    //             return { userIds }
    //         },
    //     },
    // },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text, record) => [
          <a key="add" onClick={() => operate && operate('跟进详情', record)}>跟进详情</a>,
        ],
    },
  ]
}
