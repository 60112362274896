import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import { Button, ButtonProps, Dropdown, Menu, message, Modal, Select, Tabs } from "antd";
import { AddCustomerOrClueButton, AddFollowRecord, AddFollowRecordProps, BatchImport, CustomerViewProps, customerCluePoolCanAddApi } from "../List";
import { getCheckedColumnsStateByColumns, productApi } from "@utils/util";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { customerClueDel, customerClueList, customerClueToCustomer, customerClueBackToPool, customerClueTransferToWho } from "@api/customer";
import { stringify } from "qs";
import { useDeptDataHooks, useHasAuthCode } from "@hooks/index";
import { ExportButton, ImportButton, ProFormTreeSelect, ProTableWithFormSetting } from "@components/index";
import { BASEURL } from "@api/index";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";
import { MyTabs } from "@components/EnterpriseDetail";
import { customerViewOptions } from "../List/data";

const getApi = productApi(customerClueList)
export const clueToCustomerApi = productApi(customerClueToCustomer)
export const clueDelApi = productApi(customerClueDel)
export const clueBackToPoolApi = productApi(customerClueBackToPool)
export const clueTransferToWhoApi = productApi(customerClueTransferToWho)

const persistenceKey = 'customer/myPool'

const tabPanes = [
    {
        Tab: '全部',
        key: ' ',
        Content: null
    },
    {
        Tab: '我的',
        key: '1',
        Content: null
    },
    {
        Tab: '下属的',
        key: '2',
        Content: null
    },
]

// 客户视图
const CustomerView = ({view, scope, onChange}: CustomerViewProps) => {
    return <Tabs 
            onChange={(val) => onChange && onChange('scope', val)} 
            tabBarExtraContent={{
                left: <Select options={customerViewOptions} bordered={false} defaultValue="" onChange={(val) => onChange && onChange('view', val)} style={{minWidth: 80, marginRight: 30}}></Select>
            }}
            style={{background: '#fff'}}>
    </Tabs>
}


// 转移线索
interface TransferClueProps extends ModalFormProps{
    selectedRowKeys?: Key[];
    onSuccess?: () => void;
}
export const TransferClue = ({children, selectedRowKeys, onSuccess, ...rest}: TransferClueProps) => {
    const [treeData,] = useDeptDataHooks({type: 2}, true, (v: any) => !v.whetherUser)
    
    const onFinish = async (values: any) => {
        const { success } = await clueTransferToWhoApi({...values, clueIds: selectedRowKeys}, true, onSuccess)
        return success
    }

    return (<ModalForm<TransferClueProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} onFinish={onFinish} {...rest}>
            <ProFormTreeSelect 
                width="md" 
                name="userId" 
                label="人员" 
                fieldProps={{
                    treeData, 
                    showSearch: true, 
                    treeNodeFilterProp: 'title',
                }} 
                rules={[{required: true, message: '请选择人员'}]} />
            {children}
    </ModalForm>)
}

// 我的线索转为客户
interface MyCluesToCustomerButtonProps extends ButtonProps{
    params?: Record<string, any>;
    onSuccess?: () => void;
}
export const MyCluesToCustomerButton = ({children, params, onSuccess, ...rest}: MyCluesToCustomerButtonProps) => {
    const onClick = async () => {
        Modal.confirm({
            title: '转为客户',
            content: '已选中的线索确定转为客户？',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                await clueToCustomerApi(params||{}, true, onSuccess)
            },
        })
    }
    return <Button onClick={onClick} {...rest}>{children}</Button>
}

// 我的线索移入线索池
interface MyCluesToPoolButtonProps extends ButtonProps{
    params?: Record<string, any>;
    onSuccess?: () => void;
}
export const MyCluesToPoolButton = ({children, params, onSuccess, ...rest}: MyCluesToPoolButtonProps) => {
    const onClick = async () => {
        // 应苏柄真要求不要校验(之前记得产品说移入线索池要先判断能不能领再操作)
        // const { data, msg } = await customerCluePoolCanAddApi(params||{}, false)
        // data ? 
        Modal.confirm({
            title: '确认要移入线索池吗?',
            content: '线索转移到线索池后，将属于公共资源，原归属人不能再维护跟进和更新此客户数据',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                await clueBackToPoolApi(params, true, onSuccess)
            },
        })
        // : message.error(msg)
    }
    return <Button onClick={onClick} {...rest}>{children}</Button>
}

export default () => {
    const ref = useRef<ActionType>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: undefined})
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [view, setView] = useState<any>(' ')
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const {id, customerName} = record || {}
        if(type === '批量删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await clueDelApi({clueIds: selectedRowKeys}, true, () => {
                        reFresh()
                    })
                },
            });
        } else if (type === '添加跟进') {
            setAddFollowProps({
                ...addFollowProps,
                title: type,
                visible: true,
                customerId: id,
                customerName,
                onSuccess: () => {
                    reFresh()
                    setAddFollowProps({...addFollowProps, visible: false})
                    return true
                }
            })
        }
    }
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, type: 1, sorter}, false)
        const { total, values } = data || {}
         return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    const columns = columnsFn(operate)
    const params = { type: 1, view }
    
    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            clueIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    const calcDisabled = () => !selectedRowKeys.length

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <MyTabs 
                tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
                style={{background: '#fff'}} 
                tabPanes={tabPanes} 
                activeKey={view} 
                onChange={setView}
            />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 1300}}
                scroll={{x: 'max-content'}}
                columns={columnsFn(operate)}
                params={params}
                request={getListApi}
                options={{reload: false, density: false}}
                toolbar={{
                    actions: [
                       hasAuthCode(2171) ? <AddCustomerOrClueButton params={{type: 2}} btnText="新建线索" url={`/customer/add?${stringify({sourceType: 1, routeName: '新建线索', fromPath: '/customer/myPool'})}`} /> : null,
                       hasAuthCode(2169) ? <MyCluesToCustomerButton key="btn1" disabled={calcDisabled()} params={{clueIds: selectedRowKeys}} onSuccess={reFresh}>转为客户</MyCluesToCustomerButton> : null,
                       hasAuthCode(2168) ? <TransferClue key="bnt2" title="转移线索" selectedRowKeys={selectedRowKeys} trigger={<Button disabled={calcDisabled()}>转移线索</Button>} onSuccess={reFresh} /> : null,
                       hasAuthCode(2170) ? <MyCluesToPoolButton key="btn2" disabled={calcDisabled()} params={{clueIds: selectedRowKeys}} onSuccess={reFresh}>移入线索池</MyCluesToPoolButton> : null,
                       hasAuthCode(2188) ? <BatchImport title="导入线索" params={{type: 1}} showFields={[]} trigger={<Button>导入线索</Button>} onSuccess={reFresh} /> : null,
                    //    <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                    //         { hasAuthCode(2188) ? <Menu.Item key="btn1"><BatchImport title="导入线索" params={{type: 1}} showFields={['source']} trigger={<a>导入线索</a>} onSuccess={reFresh} /></Menu.Item> : null }
                    //         {/* { hasAuthCode(-1) ? <Menu.Item key="btn2"><ImportButton action={`${BASEURL}/followup/importFollowups`} params={{type: 2}} templateUrl='https://jns-crm.oss-cn-hangzhou.aliyuncs.com/B12756D58B344904AAD57A727304A417_导入跟进记录-客户列表&我的线索.xlsx' title="导入跟进" trigger={<a>导入跟进</a>}></ImportButton></Menu.Item> : null } */}
                    //     </Menu>}>
                    //         <Button>批量导入</Button>
                    //     </Dropdown>,
                    //    hasAuthCode(2189) ? <ExportButton key="btn5" disabled={calcDisabled()} param={{clueIds: selectedRowKeys, type: 1}} action={`${BASEURL}/clue/export`}></ExportButton> : null,
                       hasAuthCode(2189) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/clue/export`}></ExportButtonWithBatch> : null,
                       hasAuthCode(2190) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate && operate('批量删除')}>批量删除</Button> : null,
                    ],
                }}
                rowKey="id"
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                }}}
                onChange={(pageInfo,_, columnStatus,{action}) => {
                    if (action === 'sort') {
                        ref.current?.reload(1 as any)
                    }
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: `${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
            />
            <AddFollowRecord {...addFollowProps} noCheck={true} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
        </div>
    </div>
}
