import React, { useEffect } from 'react'
import homeImg from '@assets/images/home.png';
import './index.less'
import { Spin, Table } from 'antd';
import { columns } from './data';
import { SearchEmpty } from '@components/Common/404';
import { useLocation } from 'react-router-dom';
import { parseSearch, productApi } from '@utils/index';
import { useTableHooks } from '@hooks/index';
import { customerHomeSearch } from '@api/index';
import { Workbench } from '@components/index'

const getSearchApi = productApi(customerHomeSearch)

const NoSearch = () => {
    return (
        <div className='bodyDiv'>
            <div className='leftImgDiv'>
                <img src={homeImg} alt="" />
            </div>
            <div className='rightTitleDiv'>
                <p>欢迎登录</p>
                <p>聚能石客户运营系统</p>
            </div>
        </div>
    )
}

type SearchResultProps = {
    params?: Record<string, any>;
}
const SearchResult = ({params}: SearchResultProps) => {
    const {tableProps, fetchApi } = useTableHooks(getSearchApi, params, false)
    const calcIsEmpty = () => {
        return tableProps.dataSource.length
    }

    const calcTableProps = () => {
        const { pagination, ...rest } = tableProps
        return {...rest, pagination: {...pagination, showTotal: (total: number) => `共${total}条`}}
    }

    useEffect(() => {
       fetchApi(params)
    }, [params])

    return calcIsEmpty() ? <Spin spinning={tableProps?.loading} style={{background: '#fff', minHeight: '80vh', padding: 20}}><Table columns={columns()} {...calcTableProps()} rowKey="id" /> </Spin> : <SearchEmpty />
}
export default () => {
    const { search } = useLocation()
    const { searchType, keyWord } = parseSearch(search)
    return keyWord ? <SearchResult params={{searchType: searchType||1, keyWord}} /> : <Workbench />
}