import React, { useEffect, useRef, useState } from "react"
import { columnsFn, customerViewOptions } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { Button, Dropdown, Menu, Modal, Select, Tabs } from "antd";
import { Link } from "react-router-dom";
import { AddFollowRecord, AddFollowRecordProps, AddHelper, AddStaff, customerFollowApi } from "../List";
import { customerBusinessList, customerBusinessDel, customerBusinessExport, BASEURL, customerBusinessToImportant } from '@api/index' 
import { productApi } from "@utils/util";
import { ExportButton, FormDragSetting, getFormDragSettingOptions, ImportButton, ProTableWithFormSetting, setColumnsByOptions } from "@components/index";
import { useHasAuthCode } from "@hooks/index";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";

type BusinessViewProps = {
    salesStages?: string;
    dataScope?: any;
    onChange?: (type: string, val: any) => void;
}

// Table 默认展示字段
const columnStateDefaultValue: any = {
    accountIsNew: {show: false},
    businessOpportunityTitle: {show: true, fixed: "left"},
    buyMethod: {show: false},
    cooperationMode: {show: false},
    createTime: {show: true},
    creator: {show: true},
    customerName: {show: true, fixed: "left"},
    estimatedBetweenIncome: {show: true},
    estimatedCashIncome: {show: true},
    estimatedSigningDate: {show: true},
    followBusinessLine: {show: true},
    followUpTime: {show: false},
    followupRecord: {show: true},
    helpPoint: {show: true},
    nextStepContent: {show: true},
    notFollowDays: {show: true},
    opportunityPoint: {show: true},
    option: {show: true, fixed: "right"},
    poc: {show: true},
    primaryContact: {show: false},
    productStr: {show: false},
    salesStage: {show: true},
    setUpGroup: {show: false},
    synergy: {show: true},
    whetherNewCustomer: {show: false},
    whetherVisit: {show: false}
}

const { TabPane } = Tabs
const getApi = productApi(customerBusinessList)
const delBusnessApi = productApi(customerBusinessDel)
const businessExportApi = productApi(customerBusinessExport)
const toImportantApi = productApi(customerBusinessToImportant)

const BusinessView = ({salesStages, dataScope, onChange}: BusinessViewProps) => {
    return <Tabs 
            style={{background: '#fff'}} 
            tabBarStyle={{paddingLeft: 20}}
            onChange={(val) => onChange && onChange('dataScope', val)} 
            // onChange={(val) => onChange && onChange('salesStages', val)} 
            // tabBarExtraContent={{
            //     left: <Select options={customerViewOptions} bordered={false} value={dataScope} onChange={(val) => onChange && onChange('dataScope', val)} style={{minWidth: 80, marginRight: 30}}></Select>
            // }}
        >
        {/* <TabPane tab="全部" key="0"></TabPane>
        <TabPane tab="进行中" key="1"></TabPane>
        <TabPane tab="赢单" key="2"></TabPane>
        <TabPane tab="输单" key="3"></TabPane> */}
        { customerViewOptions?.map(v => <TabPane tab={v.label} key={v.value}></TabPane>) }
    </Tabs>
}

export default () => {
    const [summary, setSummary] = useState({estimatedCashIncome: 0, estimatedBetweenIncome: 0})
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [salesStages, setSalesStages] = useState<string>('0')
    const [dataScope, setDataScope] = useState<string>('')
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: undefined, businessOppId: undefined,})
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);

    const customerViewOnChange = (type: string, val: string) => {
        type === 'salesStages' ? setSalesStages(val) : setDataScope(val)
    }
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const { customerId, customerName, businessOppId, businessOpportunityTitle} = record || {}
        switch (type) {
            case '批量删除':
                Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await delBusnessApi({ids: selectedRowKeys}, true, () => {
                            reFresh()
                        })
                    },
                })
                break;
            case '添加跟进':
                setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId,
                    customerName,
                    businessOppId,
                    businessOpportunityTitle,
                    onSuccess: () => {
                        ref.current?.reload()
                        setAddFollowProps({...addFollowProps, visible: false})
                        return true
                    }
                })
                break;
            case '转为重点项目':
                Modal.confirm({
                    title: '确认要转为重点项目？',
                    content: '转为重点项目之后不能再转为普通项目。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await toImportantApi({businessOppIds: selectedRowKeys}, true, () => {
                            reFresh()
                        })
                    },
                });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter: sorter['daysSinceFollowup'] ? {followupTime: sorter['daysSinceFollowup']} : sorter}, false)
        const { values, total, ...rest } = data || {}
        setSummary(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }
    const calcDisabled = () => !selectedRowKeys.length
    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue(), 
        salesStages, 
        businessOppDataScope: dataScope, 
        type: 1
    })

    const batchParams = () => {
        return ({
            salesStages, 
            businessOppDataScope: dataScope, 
            type: 1,
            businessOppIds: selectedRowKeys
        })
    }
     
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <BusinessView salesStages={salesStages} dataScope={dataScope} onChange={customerViewOnChange} />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 1800}}
                scroll={{x: 'max-content'}}
                columns={columnsFn(operate)}
                params={{salesStages, businessOppDataScope: dataScope, type: 1}}
                request={getListApi}
                options={{reload: false, density: false}}
                headerTitle={<h5 style={{fontSize: '14px', margin: 0}}>预计Cash收入 <span style={{color: 'red'}}>{summary.estimatedCashIncome}</span> ,预计差价收入 <span style={{color: 'red'}}>{summary.estimatedBetweenIncome}</span> （汇总列表查询结果）</h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2127) ? <Button key="primary" type="primary"><Link to={'/customer/businessAdd'}>新建商机</Link></Button> : null,
                        // hasAuthCode(2127) ? <Button key="primary" type="primary" disabled={calcDisabled()} onClick={() => operate('转为重点项目')}>转为重点项目</Button> : null,
                        hasAuthCode(2186) ? <AddHelper key="bnt1" title="添加协同人" selectedRowKeys={selectedRowKeys} type={2} params={{type: 2}} trigger={<Button disabled={calcDisabled()}>添加协同人</Button>} onSuccess={reFresh} /> : null,
                        // hasAuthCode(2130) ? <ExportButton key="btn5" disabled={calcDisabled()} param={{businessOppIds: selectedRowKeys}} action={`${BASEURL}/business/export`} /> : null,
                        // hasAuthCode(2130) ? <ExportButton key="btn6" param={getQueryParams} btnText="全部导出" action={`${BASEURL}/business/export`} /> : null,
                        hasAuthCode(2130) ? <ExportButtonWithBatch key="btn6" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/business/export`} /> : null,
                        // <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/exportBackClaim`} /> : null
                        // <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                        //     { hasAuthCode(-1) ? <Menu.Item key="btn2"><ImportButton action={`${BASEURL}/followup/importFollowups`} params={{type: 3}} templateUrl='https://jns-crm.oss-cn-hangzhou.aliyuncs.com/9864A70905F24FD4901A4B0F8A3DFB8E_导入跟进记录-商机标题.xlsx' title="导入跟进" trigger={<a>导入跟进</a>}></ImportButton></Menu.Item> : null }
                        // </Menu>}>
                        //     <Button>批量导入</Button>
                        // </Dropdown>,
                        hasAuthCode(2129) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate('批量删除')}>批量删除</Button> : null,
                    ],
                }}
                rowKey="businessOppId"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
                    getCheckboxProps: (record: TableListItem) => ({
                        disabled: record.isSyn === 1,
                    })
                }}
                onChange={(pageInfo,_, columnStatus,{action}) => {
                    if (action === 'sort') {
                        ref.current?.reload(1 as any)
                    }
                }}
                columnsState={{ persistenceKey: 'customer/business', persistenceType: 'localStorage', defaultValue: columnStateDefaultValue as any}}
                searchState={{ persistenceKey: 'customer/business:searchState', persistenceType: 'localStorage' }}
            />
            <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
        </div>
    </div>
}
