import { useTableHooks, useDictEnumHooks, useCantactsByCustomerIdHooks } from "@hooks/index"
import { Button, Col, Row, Table } from "antd"
import React, { Key, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { customerGetOperateLog } from '@api/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { ModalForm, ProFormText, ProFormSelect} from '@ant-design/pro-form';
import type { OperateActionType, TableListItem } from './data'
import { productApi, parseSearch } from "@utils/util"
import { StateType } from "@store/index";
import { useLocation } from "react-router"
import { EnterpriseDetailProps } from "../../type"
import { columnsFn } from './data'
import ProTable from "@ant-design/pro-table";
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

interface AddCustomerProps extends ModalFormProps {
    id?: string;
    record: any;
    disabledFields?: string[];
    onSuccess?: () => void;
}

const getApi = productApi(customerGetOperateLog)
// const updateApi = productApi(customerUpdateAccount)

export default ({id: customerId,Collaborator,searchType,businessOppId, name, ...rest}: EnterpriseDetailProps) => {

    const getListApi = async (params: any, sorter: any, filter: any) => {
        // const { operateTime: time } = params || {}
        // const [starTime, endTime] = time || []
        // const operateTime = starTime ? [starTime + ':00', endTime + ':00'] : undefined
        const { data } = await getApi({...params, customerId,businessOppId, searchType, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
       
    }
   
    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
            <ProTable<TableListItem>
                request={getListApi}
                columns={columnsFn()}
                options={{reload: false, density: false, setting: false, fullScreen: false}}
                toolbar={{
                    actions: [],
                }}
                rowKey="id"
                // actionRef={ref as any}
            />
    </div>)
}
