import { customerGetPhoneCount } from '@api/index'
import { message } from 'antd'
import { uniqueId } from 'lodash'
import { parse } from 'qs'
import { TreeDataItem } from 'types'

export const acceptImg = ".png, .jpg, .jpeg, .bmp, .gif" // 文件上传: 只上传图片
export const acceptExcel = ".xls, .xlsx"
export const acceptPdf = ".pdf"

export const ImgExt = /.(png|jpg|jpeg|bmp|gif)$/
export const ExcelExt = /.(xls|xlsx)$/
export const PdfExt = /.(pdf)$/

// 通用正则判断
// export const fixedPhone = RegExp(/^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/)
export const fixedPhone = RegExp(/^\d{3,4}(-)?\d{7,8}$/)
export const phoneReg = new RegExp(/^1[3456789][\d*]{5}[\d]{4}$/)
export const emailReg = new RegExp(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
export const idCardNumReg = new RegExp(/^(\d{18,18}|\d{15,15}|\d{17,17}X)$/) // 身份证号码
export const doubleNumReg = new RegExp(/^[0-9]+(.[0-9]{1,2})?$/) // 非负整数(2位小数)
export const floatNumReg = new RegExp(/^[0-9]+(.[0-9]{1,4})?$/) // 非负整数(4位小数)

// 文件后缀判断
export const imgUrlReg = new RegExp(/.(jpeg|jpg|png|gif|bmp|svg|webp|ico|tiff|tif)$/) // 图片url正则
export const docUrlReg = new RegExp(/.(doc|docx|docm|dot|dotx|dotm)$/) // word url正则
export const pptUrlReg = new RegExp(/.(ppt|pptx|pps|ppsx|pot|potx|ppam|thmx)$/) // ppt url正则
export const pdfUrlReg = new RegExp(/.(pdf)$/) // ppt url正则
export const excelUrlReg = new RegExp(/.(xls|xlsx|xlsm|xlsb|xml)$/) // ppt url正则
export const zipUrlReg = new RegExp(/.(zip|rar|7z|tar|gz|bz2|iso)$/) // 压缩包文件 url 正则
export const videoUrlReg = new RegExp(/.(mp4|avi|mkv|mov|wmv|flv|rmvb|mpg|3gp)$/) // 视频 url 正则
export const audioUrlReg = new RegExp(/.(mp3|wav|aac|m4a|flac|ogg|wma|ape|mka)$/) // 视频 url 正则

// 系统登录名正则
export const userNameReg = new RegExp(/^[0-9a-zA-Z]{1,}$/) // 字母或数字
// export const userNameReg = new RegExp(/(^([\u4e00-\u9fa5a-zA-Z\d+]+)$)/) // 用户名正则
export const illegal = new RegExp(/#|[~～]|[!！]|[?？]|\.\.|--|__|－|＿|※|▲|△| | |@/) // 非法正则(特殊符号)

// 系统登录密码正则
export const pwdReg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/) // 必须包含字母和数字 6-16位
// export const pwdReg = new RegExp(/(^([a-zA-Z\d+]+)$)/) // 密码正则校验: 只可输入英文、数字或组合
// export const pwdReg: new RegExp(/(?!^(\d+|[a-zA-Z]+|[!@#$%^&*]+)$)^[\w!@#$%^&*?]{6,20}$/) // 密码至少包含英文、数字、符号中的两种
export const homePageReg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/ // 官网正则判断
export const mobileNumberMask = (str: string) => str.replace(/(\d{3})\d*(\d{4})/, '$1****$2') // 手机号脱敏
export const imgRegex = /\.(bmp|gif|jpg|jpeg|png)$/
export const isImageFile = (ext: string) => imgRegex.test(ext)
export const charLength = (str: string) => !str ? 0 : str.replace(/[^\x00-\xff]/g, '**').length; // 判断字符串长度(中文占2个字符)

export const IsArray = Array.isArray
export function isDef<T>(val: T): val is NonNullable<T> {
    return val !== undefined && val !== null
}

export function isPlainDef(val: unknown): boolean {
    return isDef(val) && String(val).trim() !== ''
}

export function isFunction(val: unknown): val is Function {
    return typeof val === 'function'
}

export function isObject(val: unknown): val is Record<any,any> {
    return val !== null && typeof val === 'object'
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
    return isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

export function isPlainObject(val: unknown) {
    return val !== null && typeof val === 'object' && Object.keys(val as Object).length > 0
}

export function setStorage(key: string, value: any) {
    localStorage.setItem(key, value)
}

export function getStorage(key: string) {
    return localStorage.getItem(key)
}

export function removeStorage(key: string) {
    localStorage.removeItem(key)
}

export function setSession(key: string, value: any) {
    const sessStr = JSON.stringify(value)
    sessionStorage.setItem(key, sessStr)
}

export function getSession(key: string) {
    const sessStr = sessionStorage.getItem(key) as string
    return JSON.parse(sessStr)
}

export function removeSession(key: string) {
    sessionStorage.removeItem(key)
}

export function getToken() {
    return 'Bearer ' + getStorage('Admin-Token')
}

export function tranformHomePage(url: string) {
    return homePageReg.test(url) ? url : `//${url}`
}

export function getPersistenceKeyFromStorage(key: string) {
    return JSON.parse( getStorage(key) || '{}' )
}

// 解决浮点数运算bug
export function strip(num: number, precision: number = 12) {
    return +parseFloat(num.toPrecision(precision))
}

// 解决浮点数运算: 不舍四五人
export function toFixedNoRound(num: number) {
    // return Math.floor((num||0) * 100) / 100
    const pointPosi = (num + '').indexOf('.')
    return pointPosi < 0 ? num.toFixed(2) : ((num+'').substring(0, pointPosi+3) as any) * 1
}

// 金额数字千分位格式化 10000 => 10,000.00
export function amountFormat(num: number | string) {
    const amount = Number(num).toFixed(2)
    return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 请求转换为from-data格式
export function fromData(data:any) {
    let formData = new FormData()
    Object.keys(data).forEach((element:any) => {
      formData.append(element, data[element])
    })
    return formData
}

export function toFormData(data: any) {
    if (data === null) return null;
    return Object.keys(data).reduce((formData, item) => {
      if (item === 'fileList') { //特殊判断如果内容为files数组，就让里面值不用走JSON.stringify
        data[item] && data[item].forEach((curr: { originFileObj: string | Blob }) => {
            formData.append('file', curr.originFileObj);
          });
      } else {
        formData.append(item, JSON.stringify(data[item]));
      }
      return formData;
    }, new FormData());
};

/**
 * @description: 转换路由: /index/index2/index3   => ['/index', '/index/index2', '/index/index2/index3']
 * @param {string} path
 * @return {string[]}
 */
export function getPathStage(path: string) {
    const arr: string[] = [];
    let key = '';
    //@ts-ignore
    path.replace(/\/\w+(?=\/)/g, item => arr.push(key+=item))
    return arr;
}

export function openInNewTab(url: string) {
    const win = window.open(url, '_blank');
    win?.focus()
}

export function ellipsis(str='', len=50) {
    const newStr = str || ''
    const strLen = newStr.length
    return `${(newStr||'').substring(0, len)}${strLen > len ? '...' : ''}`
}

export function handleUploadFiles(files: any[]) {
    return (files||[]).map(({response, ...rest}) => response ? response.data : rest)
}

export function getFileNameByUrl(url: string) {
    if (!url) return url
    const lastIdx = url.lastIndexOf('_')
    return lastIdx > 0 ? url.substring(lastIdx+1) : url
}

export function urlsToFileLists(urls: string) {
    if (!urls) return []
    return urls.split(',').map((url: string, idx: number) => ({url, status: 'done', uid: `${url}-${idx}`}))
}

export function downLoadWithATag(url: string) {
    const elink = document.createElement('a')
    elink.style.display = 'none'
    elink.href = url
    document.body.appendChild(elink)
    elink.click()
    document.body.removeChild(elink)
}

export function treeDataToFlatArr(data: any[], withChildren: Boolean = true) {
    const arr: any[] = []
    const deps = (data: any[]) => (data||[]).forEach(v => {
        const { children, ...rest } = v
        arr.push(withChildren ? v : rest)
        v.children && deps(v.children || [])
    })
    deps(data)
    return arr
}

/**
 * @description: 获取权限 code list
 * @param {any} data
 * @return {(string|number)[]}
 */
export function getAuthCodeArr(data: any[]) {
    const arr: any[] = [-1]
    const deps = (data: any[]) => (data||[]).forEach(({menuId, children}) => {
        arr.push(menuId)
        children && deps(children||[])
    })
    deps(data)
    return arr
}

export const productApi = (fn: Function) => {
    return async (param: any, showMsg?: boolean, successCB?: Function) => {
        const { current: pageCurrent, ...rests } = param
        // 如果参数不是对象  会被转换为对象  所以添加判断
        const { code, msg, success, data, ...rest } = await fn(pageCurrent ? {pageCurrent, ...rests} : param)
        const type = success ? 'success' : 'error'
        // showMsg ? message[type](msg) : (!success && message[type](msg))
        // showMsg && message[type](msg)
        showMsg && success && message[type](msg)
        success && successCB && successCB?.(data)
        return { type, success, msg, data, ...rest }
    }
}

export const parseSearch = (search: string = '') => parse((search||'').slice(1)) as Record<string, any>;

export const handleSorter = (sorter: Record<string, string> = {}) => {
    const newSorter = {} as any
    Object.keys(sorter).map(k => newSorter[k] = sorter[k].replace('end', ''))
    return newSorter
}

// export const moneyFormatter = (value?: number | string) => {
//     return new Intl.NumberFormat('en', { useGrouping: true, minimumFractionDigits: 2 }).format(value || 0)
// }

// 处理从接口拿过来的组织架构树数据
export const handleDeptWithUserTreeData = (data: TreeDataItem[]) => {
    const deps = (data: TreeDataItem[]) => (data||[]).forEach(v => {
        const { id, name, children, whetherUser } = v || {}
        v.id = whetherUser ? `s${id}` : `d${id}`
        v._title = name
        v.key = v.id
        v.title = name
        v.value = v.id
        children && deps(children || [])
    })
   deps(data)
}

// 处理从接口拿的 treeData
export const transferTreeData = (data: TreeDataItem[], disabled?: DisabledRule | AssignObj) => {
    const arr: TreeDataItem[] = []
    const deps = (data: TreeDataItem[]) => (data||[]).forEach(v => {
        v.title = v.name
        v._title = v.name
        v.key = v.id
        v.value = v.id as any
        v.children = (v.children||[]).length ? v.children : undefined
        const disabledRes = disabled && disabled(v)
        Object.assign(v, typeof disabledRes === 'boolean' ? {disabled: disabledRes} : disabledRes)
        arr.push(v)
        v.children && deps(v.children || [])
    })
   deps(data)
   return arr
}

// treeData 禁用规则
type DisabledRule = (v: TreeDataItem) => boolean
type AssignObj = (v: TreeDataItem) => Object
export const treeDataDisabledRules = (data: any[], disabled?: DisabledRule) => {
    const newData = JSON.parse( JSON.stringify(data||'[]'))
    const deps = (data: TreeDataItem[]) => (data||[]).forEach(v => {
        const { children } = v || {}
        v.disabled = disabled && disabled(v)
        children && deps(children || [])
    })
   deps(newData)
   return newData
}

export function validatorTel(rules: any, val: string, callback: Function) {
    if(!val) {
       return Promise.reject('请填写手机号')
    }
    if(!(phoneReg.test(val) || fixedPhone.test(val))) {
       return Promise.reject('请填写正确的手机号或座机号')
    }
    return Promise.resolve()
}

// 公共业务方法
export async function validatorMobilePhone(rules: any, val: string, callback: Function) {
    if (!val) {
        return Promise.reject('此项是必填项')
    }
    if (phoneReg.test(val)) {
        const { data } = await customerGetPhoneCount({number: val}) as any
        data > 0 && message.warning(`系统中已有${data}个相同的手机号!!`)
        return Promise.resolve({ validateStatus: 'warning', extra: `系统中已有${data}个相同的手机号!!`})
    } else {
        return Promise.reject('请填写正确的手机号')
    }
}

export function validatorAliAccount(value: string) {
    const malWord = value && illegal.test(value);
    const length = charLength(value)
    const allNumber = value && /^\d+$/.test(value);

    if ((value && length < 5) || (value && length > 50) || allNumber) {
        return '阿里云账号为5-25个字符，英文5-50个字符'
    } else if (malWord) {
        return '该会员名包含了非法字符，请重新输入'
    } else {
        return false
    }
}

// 验证 账号类型+客户账号 表单
export function validatorAccountAndType(rules: any, value: any, callback: Function) {
    const { value1: accountType, value2: account } = value || {}
    if (!accountType) {
        return Promise.reject('请选择账号类型')
    } 
    if (!account) {
        return Promise.reject('请选择账号')
    }
    return Promise.resolve()
}

// 验证 多选 变为 单选（兼容老数据的检验）
export const validatorMuch2Single = (name?: string) => (rules: any, value: any, callback: Function) => {
    if (value.length > 1) {
        callback(`请选择单个${name}`)
    } else {
        callback()
    }
}

//
export const getCheckedColumnsStateByColumns = (columns: any[], persistenceKey: string) => {
    const columnsState = {} as any
    const json = getPersistenceKeyFromStorage(persistenceKey) || {};
    (columns||[]).forEach((v: any) => { columnsState[v.dataIndex] = { show: !v.hideInSetting, fixed: v.fixed } })
    Object.assign(columnsState, json)
    return columnsState
}

/**
 * 判断字符串是否为空
 * @param {*} str 
 * @returns 
 */
export const strIsEmpty = (str?: any) => {
	return str == undefined || str == null || str.length <= 0
}

/**
 * 在json数组中，查找对应的值
 * @param jsonArray 
 * @param seachField 
 * @param searchValue 
 * @param returnField 
 * @returns 
 */
export const findArrayField = (jsonArray?: any, seachField?: any, searchValue?: any, returnField?: any) => {
	let ret = '';
	if (!strIsEmpty(returnField)) {
		let node = findArrayNode(jsonArray, seachField, searchValue);
		if(node != null){
			ret = node[returnField];
		}
	}
	return ret;
}

/**
 * 在json数组中，查找对应的对象
 * @param jsonArray 
 * @param seachField 
 * @param searchValue 
 * @returns 
 */
export const findArrayNode = (jsonArray?: any, seachField?: any, searchValue?: any) => {
	let ret = null;
	if (jsonArray != null && jsonArray.length > 0 && !strIsEmpty(seachField) && !strIsEmpty(searchValue)) {
		for (let i = 0; i < jsonArray.length; i++) {
			if (searchValue === jsonArray[i][seachField]) {
				ret = jsonArray[i];
				break;
			}
		}
	}
	return ret;
}


const clacRowSpan = (dataSource: any[], fieldName: string) => {
    let len = dataSource.length
    let firstRecord = dataSource[0]
    let rowSpanName = `${fieldName}_rowSpan`
    for(let idx = 0; idx < len; idx ++) {
      const currentRecord = dataSource[idx]
      if (firstRecord[fieldName] && firstRecord[fieldName] === currentRecord[fieldName]) {
        currentRecord[rowSpanName] = 0
        rowSpanName in firstRecord ? (firstRecord[rowSpanName] += 1) : firstRecord[rowSpanName] = 0
      } else {
        firstRecord = currentRecord
        firstRecord[rowSpanName] = 1
      }
    }
  }
  
  export const clacCustomCellByDataSource = (dataSource: any[] = [], fields = []) => {
    if (!dataSource.length) return []
    const firstRecord = dataSource[0]
    const sumFields = (Array.isArray(fields) && fields.length) ? fields : Object.keys(firstRecord)
    sumFields.map(field => clacRowSpan(dataSource, field))
    return [...dataSource]
  }