import { Alert, Button, Dropdown, Modal, Space, Table } from "antd"
import ProForm, { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormRadio,ProFormDatePicker, ProFormDateTimePicker } from '@ant-design/pro-form';
import { columns as columnsFn, roleColumns, roleDataSource } from "./data";
import { useMemo } from "react";
import { useRequest } from "ahooks";
import React from "react";
import { parseSearch, productApi, request } from "@utils/index";
import { usePlusTableHook } from "@hooks/plusTableHook";
import ProTable from "@ant-design/pro-table";
import { BASEURL } from "@api/index";
import { diyDo2Get } from "@api/customer";
import { useLocation } from "react-router-dom";
import { EnterpriseDetailProps } from "@components/EnterpriseDetail/type";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Icon } from "@components/Common";

// 接口请求的另一种写法，方便 dvm 代码生成
const getApi = productApi((params: any) => request({
    url: `${BASEURL}/emphasis/jnsBusinessDecisionChain/getPage`,
    // method: 'GET',
    data: params
}))
const genderOptions = [
    {
        label: '男',
        value: 1
    },
    {
        label: '女',
        value: 0
    }
]
const trueOrFalseOptions = [
    {
        label: '是',
        value: 1
    },
    {
        label: '否',
        value: 0
    }
]
const getPageListApi = async (params: any, sorter: any) => {
    const { data } = await getApi({...params, pageSize: 10000, sorter})
    const { values, total } = data
    return {
        data: values,   // 列表数据
        total: total,   // 列表总条数
        success: true   // 请求成功，关闭表格 loading 等待
    }
}

const delApi = productApi((data?: any) => request({
    url: `${BASEURL}/emphasis/jnsBusinessDecisionChain/deleteBatch`,
    // method: 'GET',
    data,
}))
const addApi = productApi((data?: any) => request({
    url: `${BASEURL}/emphasis/jnsBusinessDecisionChain/add`,
    // method: 'GET',
    data,
}))
const updateApi = productApi((data?: any) => request({
    url: `${BASEURL}/emphasis/jnsBusinessDecisionChain/edit`,
    // method: 'GET',
    data,
}))
// 返回表单需要的数据
const getInfoApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/emphasis/jnsBusinessDecisionChain/getById`,
        method: 'GET',
        params,
    }) as any
    return data
}

// 获取页面枚举数据
const getInitDataApi = async () => {
    const { data } = await diyDo2Get({functionAlias: 'jnsBusinessDecisionChain'}) as any
    return data
}

export const RoleToolTip = (props: ModalFormProps) => {
    return (<ModalForm title="项目阶段说明" submitter={false} {...props}>
        <Table
           columns={roleColumns}
           pagination={false}
           bordered
           dataSource={roleDataSource}
        />
    </ModalForm>)
    return(<div style={{background: '#fff'}}>
        
        {/* <Row gutter={4} wrap={false}><Col flex="108px">10% 潜在商机: </Col><Col>有明确的联系人；客户存在需求；有交流意向，可约拜访</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">20% 商机确认: </Col><Col>已上门沟通交流；需求清晰明确；项目有时间节点要求；对接到项目的负责人</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">40% 方案沟通: </Col><Col flex="auto">提交并汇报对应的方案；项目决策链路清晰，并接触到关键人；客户对方案没有提出明确的异议，并存在支持方；在客户方对接到两个以上的人员；客户有测试意向，并投入一定的精力了解产品和方案</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">60% 商务谈判: </Col><Col>完成项目报价；明确存在的竞争对手，并了解其方案情况；同项目决策人做过明确的工作交流；明确客户方采购流程</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">80% 签约完成: </Col><Col>完成招投标或者业务相关决策性动作；拿到中标通知书；签订合同；客户方有明确的项目启动动作</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">100% 收入确认: </Col><Col>完成收款或部分收款</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">0% 项目失败: </Col><Col>该客户的该项目失败</Col></Row> */}
    </div>)
}

// 组件区域
const JnsBusinessDecisionChain = ({ ...rest}: EnterpriseDetailProps) => {
    // 获取地址栏参数
    const { search } = useLocation();
    const { id : customerId} = parseSearch(search);
    // 定义各类操作
    const operate = async (action: any, record: any) => {
        const { id } = record || {}
        const ids = id ? [id] : selectedRowKeys
        switch (action) {
            case '新增':
                addAction({title: '新增决策链地图', params : {customerId}})
                break;
            case '编辑':
                editAction({title: '编辑决策链地图', params: {id, customerId}})
                break;
            case '删除':
                delAction({ids: ids, customerId})
                break;
        }
    }

    const { data } = useRequest(getInitDataApi)
    const columns = columnsFn(operate, data)  // 可以将 data 枚举值 透传到 columns 中
    const {
        selectedRowKeys,
        tableProps,
        modalProps,
        addAction,
        editAction,
        delAction,
        getDvmExportParams,
        setModalProps,
        onRefresh,
    } = usePlusTableHook({
        delApi,
        updateApi,
        addApi,
        getInfoApi,
        columns
    })
    const calcDisabled = () => !selectedRowKeys.length

    return (<>
        <Alert
            message="决策链地图，有助于我们更全面地了解客户公司的组织结构、业务运营和决策流程，快速识别客户的关键决策者和执行者。（销售填写）"
            type="warning"
            closable
        />
        <ProTable
            request={getPageListApi}
            scroll={{x: 1600, y: 688}}
            toolbar={{
                actions: [
                    <Button type="primary" key="新增" onClick={() => operate?.('新增', null)}>新增</Button>,
                ]
            }}
            rowKey="id"
            params={{customerId}}
            search={false}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            {...tableProps}
            pagination={false}
            rowSelection={false}
        />
        <ModalForm<any>
            layout="horizontal"
            labelCol={{span: 6}}
            width={660}
            {...modalProps}
            // rowSelection={false}        // 行选
            // params={{businessId}}       // 新增传入参数
            // grid={true}                 // 开启栅格布局，一行允许有多列
            // initialValues={{            // 可用于新增时的默认值
            //     // roleType: 0
            // }}
        >
           <ProFormText
                label="姓名" 
                name="userName" 
                rules={[{required: true, message: '请填写'}]} 
            />
            <ProFormRadio.Group 
                width="md" 
                name="gender" 
                label="性别" 
                options={genderOptions} 
                rules={[{ required: true, message: '请选择性别',}]} 
                placeholder="请选择" 
            />
            <ProFormText
                label="年龄" 
                name="age" 
                rules={[{required: true, message: '请填写'}]} 
            />
            <ProFormText
                label="职位" 
                name="positionName" 
                rules={[{required: true, message: '请填写'}]} 
            />
            <ProFormSelect 
                label={<>角色  <RoleToolTip trigger={<Icon type="icon-ziyuan" />} /></>}
                name="participationType" 
                options={data?.uiFunctionEnum?.participationTypeList?.map(({caption: label, value}: any) => ({label, value}))} 
                fieldProps={{ mode: 'multiple' }}
                rules={[{required: true, message: '请选择'}]} 
                // tooltip={<>
                //     使用者:所要采购物品的实际使用者，通常采购某种物品的要求是由他们首先提出来的，他们在规格型号的决定上有直接作用。我们的客户中，多为运维技术方面的人<br/>
                //     影响者:企业内外直接或间接影响采购决策的人，其中技术人员是特别重要的影响者。<br/>
                //     采购者:具体执行采购任务的人员，他们负责选择供应者并与之谈判签约。在比较重要的采购工作中，通常还有企业的高层管理人员参加。<br/>
                //     决策者:企业里有权决定采购项目和供应者的人。在日常的采购中，采购者就是决策者。在复杂的采购中，决策者通常是企业的主管。<br/>
                //     控制者:可控制信息流向的人员，他们可控制外界与采购有关的信息流入企业。例如，采购代理往往有权阻止供应商的推销人员与使用者或决策者见面，还有技术人员、甚至秘书人员，也可以控制信息
                // </>}
            />
            <ProFormRadio.Group 
                label="是否建立联系" 
                name="establishContact" 
                fieldProps={{ options: trueOrFalseOptions }}
                rules={[{required: true, message: '请填写'}]} 
            />
            <ProFormSelect 
                label="建联方式" 
                name="contactMode" 
                options={data?.uiFunctionEnum?.contactModeList?.map(({caption: label, value}: any) => ({label, value}))} 
                fieldProps={{ mode: 'multiple' }}
                rules={[{required: true, message: '请选择'}]} 
            />
             <ProFormSelect 
                label="客户态度" 
                name="customerManner" 
                options={data?.uiFunctionEnum?.customerMannerList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: false, message: '请选择'}]} 
            />
            <ProFormTextArea
                label="决策流程描述" 
                name="flowDescribe" 
                fieldProps={{
                    placeholder: '请描述决策人在项目中起到的作用',
                    maxLength: 1000,
                    showCount: true,
                }}
            />
            <ProFormTextArea
                label="对接的竞争对手" 
                name="competitor" 
                fieldProps={{
                    placeholder: '如有，当前联系人对接的竞争对手，请列出是什么云或什么服务、姓名',
                    maxLength: 1000,
                    showCount: true,
                }}
                tooltip={<>如有，当前联系人对接的竞争对手，请列出是什么云或什么服务、姓名</>}
            />
            <ProFormTextArea
                label="是否有企业朋友" 
                name="companyFriend" 
                tooltip="是否有其他企业的朋友关系？如果有，分别是什么企业？能否推荐我们？"
                fieldProps={{
                    placeholder: '是否有其他企业的朋友关系？如果有，分别是什么企业？能否推荐我们？',
                    maxLength: 1000,
                    showCount: true,
                }}
            />

            <ProFormTextArea
                label="综合分析" 
                name="analysis" 
                fieldProps={{
                    placeholder: '例如，性格、兴趣爱好、专业能力、事业心、家庭关系和实力等',
                    maxLength: 1000,
                    showCount: true,
                }}
                tooltip={<>例如，性格、兴趣爱好、专业能力、事业心、家庭关系和实力等？</>}
            />
            {/* <ProFormTextArea
                label="家庭情况" 
                name="family" 
            /> */}
            <ProFormTextArea
                label="备注" 
                name="remark" 
                fieldProps={{
                    placeholder: '如客户有职位变动，可在此标明',
                    maxLength: 1000,
                    showCount: true,
                }}
            />
        </ModalForm>
    </>)
}

export default JnsBusinessDecisionChain