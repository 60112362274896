import React, { Key, useEffect, useRef, useState } from 'react';
import ProForm, { ProFormText, ProFormRadio, ProFormSelect, ProFormTextArea, ProFormCascader, ProFormDependency, ProFormMoney } from '@ant-design/pro-form';
import type { ProFormInstance } from '@ant-design/pro-form';
import { EditableProTable } from '@ant-design/pro-table';
import {customerAccountColumns, contactColumns, customerAccountItem, contactItem, SourceMaps } from './data'
import type { DataSourceCustomerType, DataSourceContactType, FormDataType } from './data'
import { Button, Form, message, Tag, TreeSelect } from 'antd';
import { BackBan, ProFormCustTreeSelect, ProFormTreeSelect, ProFormWithCache } from '@components/index';
import { customerSave, customerGetInfoById, customerNameCheck } from '@api/index'
import { parseSearch, productApi, treeDataDisabledRules } from '@utils/util';
import { useDictEnumHooks } from '@hooks/index';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateType } from '@store/index';
import { uniqBy } from 'lodash';
import { custOptions, yesNoOptions } from '@utils/index';

const customerSaveApi = productApi(customerSave)
const customerInfoApi = productApi(customerGetInfoById)
const checkCustomerNameApi = productApi(customerNameCheck)

const CustomerAccountList = ({form, pAccounts, ...rest}: any) => {
  const [accounts, setAccounts] = useState<DataSourceContactType[]>(customerAccountItem(0))
  const [accountsEditableKeys, setAccountsEditableKeys] = useState<React.Key[]>(() => accounts.map((item) => item.id))

  // 实现所有 账号, 联系人 可编辑
  useEffect(() => {
    setAccountsEditableKeys(accounts.map((item) => item.id))
  }, [accounts])

  useEffect(() => {
    setAccounts([...pAccounts])
  }, [pAccounts])

  const columns = customerAccountColumns(form)

  return (<EditableProTable<DataSourceCustomerType>
          scroll={{x: 1000}}
          columns={columns}
          rowKey="id"
          value={accounts} 
          onChange={setAccounts} 
          recordCreatorProps={{
            newRecordType: 'dataSource',
            record: customerAccountItem()[0],
          }}
          editable={{
            form,
            type: 'multiple',
            deleteText: null,
            deletePopconfirmMessage: '删除后，会影响返点订单的绑定关系',
            editableKeys: accountsEditableKeys,
            actionRender: (row, config, defaultDoms) => {
              return [defaultDoms.delete];
            },
            onValuesChange: (_, editableRows) =>  {
              setAccounts(editableRows as any)
            },
          }}
          {...rest}
      />
  )
}

const ContactList = ({form, pContacts, selectedRowKeys, selectedRowKeysOnChange,  ...rest}: any) => {
  const [contacts, setContacts] = useState<DataSourceContactType[]>(contactItem(0))
  const [contactsEditableKeys, setContactsEditableKeys] = useState<React.Key[]>(() => contacts.map((item) => item.id))

  useEffect(() => {
    const allKeys = contacts.map((item) => item.id)
    setContactsEditableKeys(allKeys)
  }, [contacts])

  useEffect(() => {
    setContacts([...pContacts])
  }, [pContacts])

  return (<EditableProTable<DataSourceContactType>
          scroll={{x: 1600}}
          columns={contactColumns()}
          rowKey="id"
          value={contacts}
          onChange={setContacts}
          recordCreatorProps={{
            newRecordType: 'dataSource',
            record: contactItem()[0],
          }}
          editable={{
            form,
            type: 'multiple',
            editableKeys: contactsEditableKeys,
            actionRender: (row, config, defaultDoms) => {
              return [defaultDoms.delete];
            },
            onValuesChange: (_, editableRows) => setContacts(editableRows),
          }}
          tableAlertRender={() => false} 
          // rowSelection={{
          //   type: 'radio',
          //   columnTitle: <span><i style={{color: 'red'}}>*</i>主要</span>,
          //   columnWidth: 80,
          //   selectedRowKeys: selectedRowKeys,
          //   onChange: (selectedRowKeys: Key[]) => {
          //     selectedRowKeysOnChange && selectedRowKeysOnChange(selectedRowKeys)
          //   }
          // }}  
      />
  )
}

// const arr = [{type: 1, customerAccount: '2'}, {type: 1, customerAccount: '2'}, {type: 1, customerAccount: '3'}, {type: 3, customerAccount: '3'}, {type: 2, customerAccount: '4'}]
const checkAccounts = (arr: any[]) => {
  const uniqMap: any = {};
  (arr||[]).forEach(({type, customerAccount}): any => {
    const key = `${type}-${(customerAccount||'').trim()}`
    uniqMap[key] = uniqMap[key] ? ++uniqMap[key] : 1
  })
  return !Object.values(uniqMap).some((num: any) => num > 1)
}

// checkAccounts(arr)

export default () => {
    const history = useHistory()
    const { cityTreeData, labelTreeData, deptWithUserTreeData } = useSelector((state: StateType) => state)
    const tagsTreeData = treeDataDisabledRules(labelTreeData as any, (v: any) => ['0'].includes(v.industryLabelStatus))
    const [statusOptions,] = useDictEnumHooks({dictId: 13}, true)
    const [customerSourceOptions,] = useDictEnumHooks({dictId: 15}, true)
    const [customerPropertyOptions,] = useDictEnumHooks({dictId: 16}, true)
    const [customerLevelOptions,] = useDictEnumHooks({dictId: 14}, true)
    const [businessLineOptions,] = useDictEnumHooks({dictId: 19}, true)
    const [customerTypeOptions,] = useDictEnumHooks({dictId: 275}, true)
    const pmTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    
    
    const formRef = useRef<ProFormInstance>()
    const [customerFormRef] = Form.useForm();
    const [contactFormRef] = Form.useForm();
    const { search } = useLocation();
    const { sourceType, customerId } = parseSearch(search)
    const [primaryContactKeys, setPrimaryContactKeys] = useState<string[]>([])
    const [checkCustomerName, setCheckCustomerName] = useState<any>({validateStatus: 'success', help: ''})
    const [accounts, setAccounts] = useState<DataSourceContactType[]>(customerAccountItem(0))
    const [contacts, setContacts] = useState<DataSourceContactType[]>(contactItem(0))
    const [stateDisabled, setStateDisabled] = useState<boolean>(false)
    const [customerInfo, setCustomerInfo] = useState<any>({})
    
    const request = async (params: object) => {
      if (!customerId) {
        return {
          // state: 1, 
          // customerSource: 7,
          isEmphasisCustomer: 0,
        }
      }
      const { data } = await customerInfoApi(params, false)
      const { customerName, customerType, state, customerSource, customerProperty, projectManager, estimatedAnnualCashIncome, isEmphasisCustomer, businessLines, customerLevel, customerLabel, regionId, detailAddress, accounts, contacts } = data || {}
      const { id: primaryContact } = (contacts||[]).find((v: any) => v.primaryContact === '1') || {}
      setCustomerInfo(data)
      setAccounts(accounts||[])
      setContacts(contacts||[])
      setPrimaryContactKeys([primaryContact]);
      [2].includes(state) && setStateDisabled(true)
      return {
        customerName, 
        customerType,
        state, 
        customerSource, 
        customerProperty, 
        businessLines: businessLines || [],
        customerLevel, 
        customerLabel, 
        regionId,
        detailAddress, 
        projectManager: projectManager?.map((id: any) => `s${id}`),
        estimatedAnnualCashIncome,
        isEmphasisCustomer
      }
    }
    const onSubmit = async (values: any) => {
      const ok1 = await customerFormRef.validateFields()
      const ok2 = await contactFormRef.validateFields()
      const accounts = customerFormRef.getFieldsValue()
      const contacts = contactFormRef.getFieldsValue()
      const checkedOk = checkAccounts(Object.values(accounts))
      if (!checkedOk) {
        return message.error(`账号类型+客户账号 不能重复!`)
      }
      if (ok1 && ok2) {
        const allContactIds = Object.keys(contacts||{})
        // if (allContactIds.length) {
        //   if ( !allContactIds.includes(primaryContactKeys[0]+ '') ) { // 找不到主要联系人的情况下
        //     return message.warning('请设置主要联系人!')
        //   }
        // }
        const newAccounts = Object.keys(accounts||{}).map(id => (id + '').startsWith('zqs-') ? accounts[id] : ({...accounts[id], id}))
        const newContacts = Object.keys(contacts||{}).map(id => {
          const newItem = (id + '').startsWith('zqs-') ? contacts[id] : ({...contacts[id], id})
          newItem.primaryContact = id + '' === (primaryContactKeys[0]+'') ? '1' : '0'
          return newItem
        })
        const { projectManager: pmIds } = values
        const projectManager = pmIds === 0 ? [] : (pmIds||[]).map((v: string) => parseInt(v.slice(1)))
        const submitParams = {
          ...values, 
          id: customerId, 
          sourceType, 
          projectManager, 
          accounts: newAccounts, 
          contacts: newContacts,
        }
        const { success } = await customerSaveApi(submitParams, true, (data: any) => {
          setTimeout(() => {
            history.goBack()
          }, 1000)
        })
        return success
      }
    }

    const calcTitle = () => {
      if (sourceType === '0') {
        return customerId ? <>编辑客户 { customerInfo?.isEmphasisCustomer ? <Tag color="#f50">重点客户</Tag> : null}</> : '新增客户'
      }
      if (sourceType === '1') {
        return customerId ? '编辑线索' : '新增线索'
      }
    }

    const onValuesChange = async (values: any) => {
      if ('customerName' in values) {
        await checkCustomerNameApi({name: values.customerName, id: customerId}, false, (data: any) => {
          const { verified, info } = data || {}
          const newCheckCustomerName = verified ? { validateStatus: 'success', help: '' } : { validateStatus: 'error', help: info }
          setCheckCustomerName(newCheckCustomerName)
        })
      }
    }

    return (
        <>
        <BackBan title={calcTitle()} style={{paddingLeft: 20}} />
        <div className="jns-add-customer-form" style={{background: '#fff', boxSizing: 'border-box', padding: '20px'}}>
            <ProFormWithCache<FormDataType>
                layout="horizontal"
                key={location.href}
                labelCol={{span: 3}}
                wrapperCol={{span: 9}}
                onFinish={onSubmit}
                params={{id: customerId}}
                request={request as any}
                // initialValues={ customerId ? {} : {state: 1, customerSource: 7} }
                formRef={formRef}
                onValuesChange={onValuesChange}
                submitter={{render: (props, doms) => [
                    <Button key="submit" type="primary" style={{marginLeft: 130}} onClick={() => props.form?.submit()}>提交</Button>,
                    // <Button key="rest" onClick={() => props.form?.resetFields()}>重置</Button>,
                ]}}
            >
                <ProFormText name="customerName" label="客户名称" hasFeedback {...checkCustomerName} fieldProps={{maxLength: 30}} rules={[{ required: true, message: '请输入客户名称',}]} placeholder="请输入客户全称"/>
                <ProFormRadio.Group width="md" hidden={false} name="isEmphasisCustomer" label="是否重点客户" options={yesNoOptions} fieldProps={{disabled: true}} rules={[{ required: true, message: '请选择是否是否重点客户',}]} placeholder="请选择" />
                <ProFormDependency name={['isEmphasisCustomer']}>
                    {({isEmphasisCustomer}) => {
                        return (isEmphasisCustomer ? <>
                          <ProFormMoney 
                              name="estimatedAnnualCashIncome" 
                              max={999999999} 
                              min={0} 
                              label="预计年Cash收入" 
                              rules={[{ required: true, message: '请输入预计年Cash收入',}]} 
                              placeholder="请输入"
                              fieldProps={{ 
                                  formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                  parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                                  defaultValue: 0,
                                  addonAfter: '元'
                              }}  
                          />
                          <ProFormCustTreeSelect 
                              name="projectManager" 
                              label="项目经理" 
                              custOptions={custOptions}
                              fieldProps={{
                                  treeData: pmTreeData, 
                                  showSearch: true,
                                  multiple: true, 
                                  treeCheckable: true, 
                                  treeNodeFilterProp: 'title', 
                                  placeholder: '请选择项目经理',
                                  style: { width: '100%' }
                              }} 
                              rules={[{required: true, message: '请选择项目经理'}]} 
                          />
                        </>: null)
                    }}
                </ProFormDependency>
                <ProFormSelect name="customerType" label="客户类型" options={customerTypeOptions} rules={[{ required: true, message: '请选择客户类型',}]} placeholder="请选择" />
                <ProFormSelect name="state" label="客户状态" disabled={stateDisabled} options={statusOptions} rules={[{ required: true, message: '请选择客户状态',}]} placeholder="请选择" />
                <ProFormSelect name="customerSource" label="客户来源" options={customerSourceOptions} rules={[{ required: true, message: '请选择客户来源',}]} placeholder="请选择"/>
                <ProFormSelect name="customerProperty" label="客户属性" options={customerPropertyOptions} rules={[{ required: true, message: '请选择客户属性',}]} placeholder="请选择" />
                <ProFormSelect name="businessLines" label="关注业务线" options={businessLineOptions} fieldProps={{ mode: 'multiple' }} rules={[{ required: true, message: '请选择关注业务线',}]} placeholder="请选择" />

                {/* <ProFormSelect name="customerLevel" label="客户等级" options={customerLevelOptions} rules={[{ required: true, message: '请选择客户等级',}]} placeholder="请选择" /> */}
                <ProFormTreeSelect name="customerLabel" label="所属行业" fieldProps={{treeData: tagsTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, showCheckedStrategy: TreeSelect.SHOW_ALL, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择所属行业',}]} placeholder="请选择" />
                <ProFormCascader name="regionId" label="所在地区" fieldProps={{options: cityTreeData, showSearch: true, fieldNames: {label: 'name', value: 'id', children: 'children'}}} rules={[{ required: true, message: '请选择所在地区',}]} placeholder="请选择" />
                <ProFormTextArea name="detailAddress" label="详细地址" />
                <Form.Item label="客户账号" wrapperCol={{span: 24}}>
                  <CustomerAccountList form={customerFormRef}  pAccounts={accounts} />
                </Form.Item>

                <Form.Item label="联系人" wrapperCol={{span: 24}}>
                  <ContactList form={contactFormRef} pContacts={contacts} selectedRowKeys={primaryContactKeys} selectedRowKeysOnChange={setPrimaryContactKeys} />
                </Form.Item>
        </ProFormWithCache>
    </div>
    </>)
}