import { ProColumns } from "@ant-design/pro-table";
import { Ellipsis } from "@components/index";
import { useDictEnumHooks } from "@hooks/index";
import { StateType } from "@store/index";
import { yesNoOptions } from "@utils/enum";
import { Descriptions, TreeSelect } from "antd";
import React from "react";
import { useSelector } from "react-redux";

export const logColumns: (operate?: any, data?: any) => ProColumns<any>[] = (operate, data) => [
    {
        title: '创建人',
        dataIndex: 'nameDate',
        onCell: (record, index) => {
            return {
                rowSpan: record[`nameDate_rowSpan`]
            }
        },
        render: (text: any, record: any) => record?.updateName
    },
    {
        title: '创建时间',
        dataIndex: 'updateTime',
        // sorter: true,
        onCell: (record, index) => {
            return {
                rowSpan: record[`updateTime_rowSpan`]
            }
        }
    },
    {
        title: '字段名称',
        dataIndex: 'updateField',
    },
    {
        title: '旧字段内容',
        dataIndex: 'lastValue',
    },
    {
        title: '新字段内容',
        dataIndex: 'newValue',
    },
]

export const descColumns: () => ProColumns<any>[] = () => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: "客户企业官方全称",
            dataIndex: 'customerName',
            key: 'customerName',
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
            render: (text: any, record: any) => <Ellipsis text={record.customerName||'-'} />
        },
        {
            title: '所属行业',
            dataIndex: 'industry',
            fieldProps: {
                // maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择',
                style: { maxWidth: 300 }
            },
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
            renderFormItem: ({fieldProps}) => <TreeSelect {...fieldProps} bordered={false} />,
        },
        {
            title: '注册资金(万)',
            valueType: 'money',
            dataIndex: 'registeredCapital',
            key: 'registeredCapital',
            fieldProps: {
                placeholder: '请输入',
                style: { backgroundColor: '#fff' }
            },
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: '企业人数',
            valueType: 'digit',
            dataIndex: 'employees',
            key: 'employees',
            fieldProps: {
                placeholder: '请输入',
                style: { backgroundColor: '#fff' }
            },
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: '成立时间',
            valueType: 'date',
            dataIndex: 'registeredDate',
            key: 'registeredDate',
            fieldProps: {
                placeholder: '请选择',
                style: {width: '100%'}
            },
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: '一句话介绍客户',
            valueType: 'textarea',
            dataIndex: 'introduceCustomer',
            key: 'introduceCustomer',
            fieldProps: {
                maxLength: 1000,
                showCount: true,
                placeholder: '用自己的话简洁描述客户主要做什么业务。不要用官网或天眼查等工具上的大段文字。'
            },
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: '客户主要股东有哪些',
            valueType: 'textarea',
            dataIndex: 'shareholder',
            key: 'shareholder',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '客户上下游公司有哪些',
            valueType: 'textarea',
            dataIndex: 'relationCompany',
            key: 'relationCompany',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '最近1年与该企业/大股东有关的PR报道/采访文章链接',
            valueType: 'textarea',
            dataIndex: 'newsLink',
            key: 'newsLink',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: 'TOP3的主营业务/产品',
            valueType: 'textarea',
            dataIndex: 'topProduct',
            key: 'topProduct',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '企业主要客户',
            valueType: 'textarea',
            dataIndex: 'mianCustomer',
            key: 'mianCustomer',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '商业模式（企业如何盈利）',
            valueType: 'textarea',
            dataIndex: 'businessModel',
            key: 'businessModel',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '官网地址',
            dataIndex: 'officialWebsite',
            key: 'officialWebsite',
            fieldProps: {
                placeholder: '请输入',
            }
        },
        {
            title: 'APP应用',
            dataIndex: 'appName',
            key: 'appName',
            fieldProps: {
                placeholder: '如有，请列出app名称',
            }
        },
        {
            title: '小程序',
            dataIndex: 'miniAppName',
            key: 'miniAppName',
            fieldProps: {
                placeholder: '如有，请列出小程序名称',
            }
        },
        {
            title: '微信公众号',
            dataIndex: 'wechatOfficialAccount',
            key: 'wechatOfficialAccount',
            fieldProps: {
                placeholder: '如有，请列出微信公众号名称',
            }
        },
        {
            title: '微博',
            dataIndex: 'weibo',
            key: 'weibo',
            fieldProps: {
                placeholder: '如有，请列出微博名称',
            }
        },
        {
            title: '抖音/快手',
            dataIndex: 'dyKs',
            key: 'dyKs',
            fieldProps: {
                placeholder: '如有，请列出抖音/快手名称',
            }
        },
        {
            title: '交易网站',
            dataIndex: 'transactionWeb',
            key: 'transactionWeb',
            fieldProps: {
                placeholder: '如有，请列出交易网站名称',
            }
        },
        {
            title: '融资阶段',
            valueType: 'select',
            dataIndex: 'financingStage',
            key: 'financingStage',
            params: {dictId: 280},
            request,
            fieldProps: {
                placeholder: '请选择',
            }
        },
        {
            title: '是否上市',
            valueType: 'select',
            dataIndex: 'listedCompany',
            key: 'listedCompany',
            params: { dictId: 281 },
            request,
            fieldProps: {
                placeholder: '请选择',
                // options: yesNoOptions
            }
        },
        {
            title: '软件著作权',
            dataIndex: 'soft',
            key: 'soft',
            fieldProps: {
                placeholder: '如有，请列明主要软著名称',
            }
        },
    
        // 潜力情报
        {
            title: '年IT总投入',
            valueType: 'textarea',
            dataIndex: 'itExpense',
            key: 'itExpense',
            belong: '潜力情报',
            fieldProps: {
                placeholder: '请详细描述客户历年IT总投入？',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '上云部分年消费',
            dataIndex: 'cloudConsume',
            key: 'cloudConsume',
            belong: '潜力情报',
            fieldProps: {
                placeholder: '请输入',
            }
        },
        {
            title: '尚未上云的主要IT系统梳理',
            valueType: 'textarea',
            dataIndex: 'yetUploadCloud',
            key: 'yetUploadCloud',
            belong: '潜力情报',
            fieldProps: {
                placeholder: '如有，请具体描述',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '正在考虑建设/优化的IT系统',
            dataIndex: 'considerOptimize',
            key: 'considerOptimize',
            belong: '潜力情报',
            fieldProps: {
                placeholder: '请输入'
            }
        },
        {
            title: '近期的业务挑战/发展重点',
            dataIndex: 'developPriority',
            key: 'developPriority',
            belong: '潜力情报',
            fieldProps: {
                placeholder: '请输入'
            }
        },
    
        // 合作情报
        {
            title: '是否在用第三方软件',
            valueType: 'textarea',
            dataIndex: 'thirdParty',
            key: 'thirdParty',
            belong: '合作情报',
            fieldProps: {
                placeholder: '如有，请列出是何系统，isv厂商是哪个',
                maxLength: 1000,
                showCount: true,
            }
        },   
        {
            title: '是否跟isv厂商建立联系',
            valueType: 'textarea',
            dataIndex: 'isvProvider',
            key: 'isvProvider',
            belong: '合作情报',
            fieldProps: {
                placeholder: '如有，请列出联系人姓名及职位',
                maxLength: 1000,
                showCount: true,
            }
        },
        {
            title: '评估是否可将厂商发展为渠道',
            dataIndex: 'assessment',
            key: 'assessment',
            belong: '合作情报',
            fieldProps: {
                placeholder: '请输入',
                maxLength: 1000,
                showCount: true,
            }
        },
    ]
}



export const LabelValueMaps: any = {
    customerName: '客户企业官方全称',
    registeredCapital: '注册资金',
    employees: '企业人数',
    registeredDate: '成立时间',
    itExpense: '一年IT占总花费规模包括所有IT投入',
    introduceCustomer: '一句话介绍客户',
    itConsider: '正在考虑建设/优化的IT系统',
    thirdParty: '是否在用第三方软件，如有请列出是何系统，isv厂商是哪个',
    recentDevelop: '近期的业务挑战/发展重点',
    cloudRecognition: '客户对云的认可度',
    yetUpCloud: '尚未上云的主要IT系统梳理'
}