import React from "react";
import { TreeSelect, Button } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { queryDeptTreeData } from "@api/index";
import { useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";

export type TableListItem = {
    key: number;
    id: string;
    attention: number | string;
    isDefault: number;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '新建线索池' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '线索池名称',
        dataIndex: 'cluePoolName',
        fixed: 'left',
        order: 100,
    },
    {
        title: '所属部门',
        dataIndex: 'dept',
        params: { type: 1 },
        request: queryDeptTreeData as any,
        fieldProps: {
          fieldNames: {title: 'name', key: 'id', children: 'children'},
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          showCheckedStrategy: TreeSelect.SHOW_ALL
        },
        renderFormItem: (_, {options}: any) => {
          const { data } = options || {}
          transferTreeData(data||[])
          return <TreeSelect treeData={data as any} />
        },
        order: 2,
        search: {
            transform: (departmentIds) => {
              return { departmentIds }
            },
        }
    },
    {
        title: '管理员',
        dataIndex: 'manager',
        order: 1,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
            transform: (ids) => {
              const managerIds = (ids||[]).map((v: string) => v.slice(1))
              return { managerIds }
            },
        },
    },
    {
        title: '最大容量',
        dataIndex: 'maximumCapacity',
        hideInSearch: true
    },
    {
        title: '线索领取上限',
        dataIndex: 'clueCollectionLimit',
        hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'cluePoolStatus',
      hideInSearch: true,
      valueEnum: {
        0: { text: '禁用', status: 'Default' },
        1: { text: '启用', status: 'Processing' },
      },
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        hideInSearch: true
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 180,
        fixed: 'right',
        render: (text, record: any) => {
          const clacDelDisabled = () => [1].includes(record.isDefault) || [0].includes(record.isCreator)
          return [
            hasAuthCode(2166) ? <Button key="warn" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('编辑', record)}>编辑</Button> : null,
            hasAuthCode(2165) ? <Button key="del" type="link" disabled={clacDelDisabled()} onClick={() => operate && operate('删除', record)}>删除</Button> : null
          ]
        },
    },
]
}
