import { Button, Col, message, Modal, Row } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {MultiLabel, MultiIcon, PreviewDoc} from '../cpnts'
import Comment from '@components/Common/Comment'
import { QuestionFrom } from "../Questionnaire/QuestionFrom"
import type { ProFormInstance } from '@ant-design/pro-form';
import { useHistory, useLocation } from "react-router-dom"
import { parseSearch, PdfExt, productApi } from "@utils/index"

import '../index.less'
import { knowledDocumentDetails,knowledSolveQuestion,knowledPublishComment,knowledGetQuestionDetail,knowledListComment, knowledDocDel, knowledGetQuestionaireDetail } from "@api/knowledge"
import { stringify } from "qs"
import QuestionnaireTemplate from '../Questionnaire/QuestionnaireTemplate'
import { BackBan } from "@components/Common"
import MyEditor from "@components/Common/MyEditor"
import OpenText from "@components/Common/OpenText"

const getApiDoc = productApi(knowledDocumentDetails)
const getApiIssue = productApi(knowledGetQuestionDetail)
const getApiKnowledListComment = productApi(knowledListComment)
const getTemplateDataApi = productApi(knowledGetQuestionaireDetail)

const addComment = productApi(knowledPublishComment)

const solveQuestion = productApi(knowledSolveQuestion)


const delApi = productApi(knowledDocDel)

// 文档详情
const DocDetail = ({id}:any) => {
    const [docData, setDocData] = useState<any>([])
    const history = useHistory()
    const multiConfig = [
        {
            type: (docData.promulgatorId === docData.registerId) ? 'delete' : '-',
            title: '删除文档',
            onClick: async ()=> {
                // await getApiDoc(id, false)
                Modal.confirm({
                    title: '确认要删除文档吗?',
                    content: '确认删除后不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        const {success} = await delApi({ids:[id]}, true)
                        if (success){
                            // getTemplateData()
                            // getData()
                            history.goBack()
                            history.goBack()
                            // history.push('/knowledge/home')
                        } 
                    },
                });
            }
        },
        {
            type: (docData.type === 2 && !PdfExt.test(docData.content)) ? 'download' : '',
            title: '下载原文件',
            onClick: ()=> {
                window.location.href = docData.content
            }
        },
        {
            type: (docData.state === 1 && docData.status === 1) ? 'Collection' : '1',
            title: `收藏${docData.collect}`,
            activation: docData.collectStatus,
            success: async ()=> {
                // getData()
                setDocData({
                    ...docData,
                    collect: docData.collect + (docData.collectStatus ? -1 : 1),
                    collectStatus: !docData.collectStatus
                })
            }
        },
        {
            type: (docData.state === 1 && docData.status === 1) ? 'good' : '1', 
            title: `有用 ${docData.praise}`,
            activation: docData.praiseStatus,
            success: async ()=> {
                // getData()
                setDocData({
                    ...docData,
                    praise: docData.praise + (docData.praiseStatus ? -1 : 1),
                    praiseStatus: !docData.praiseStatus,
                    trample: docData.trample + (docData.trample ? -1 : 0),
                    trampleStatus: false
                })
            }
            
        },
        {
            type: (docData.state === 1 && docData.status === 1) ? 'notGood' : '1',
            title: `无用${docData.trample}`,
            activation: docData.trampleStatus,
            success: async ()=> {
                // getData()
                setDocData({
                    ...docData,
                    trample: docData.trample + (docData.trampleStatus ? -1 : 1),
                    trampleStatus: !docData.trampleStatus,
                    praise: docData.praise + (docData.praise ? -1 : 0),
                    praiseStatus: false
                })
            }
            
        }
    ]
    const labelList = [
        {
            title: '相关标签',
            children: docData.labelNameStr
        },
        {
            title: '关联客户',
            children: docData.customerNameStr
        },
        {
            title: '文档分类',
            children: docData.categoryNameStr

        },
    ]
    const getData = async () => {
        const { data, success } = await getApiDoc(id, false)
        if (success) {
            setDocData(data || [])
        } else {
            history.push('/knowledge/home')
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (<>
        <div className="documentBody">
            <div className="header">
                <div className="title">
                    <div className="headerText">{docData.title}</div>
                    <div className="detailTitleChildrenDiv">
                        <div className="detailInfoSpan">
                            <span>作者：{docData.userName}</span>
                            <span>{docData.updateTime}</span>
                            <span>浏览：{docData.browse}</span>
                        </div>
                        <div className="MultiIconDiv">
                             <MultiIcon multiConfig={multiConfig} type={1} id={id}/>
                        </div>
                    </div>
                </div>
                
            </div>
           
            {/* 文档内容区域 */}
            <div className="documentDiv">
                {/* <PreviewDoc data={{
                    type:docData.type,
                    url:docData.content,
                    title: docData.title,
                    DocText: docData.content
                }} /> */}
                <PreviewDoc __html={docData.content} isLink={docData.type === 2} />
            </div>
            <MultiLabel dataList={labelList} />
        </div>
    </>)
}

// 问题详情
const IssueDetail = ({id}:any) => {
    const [issueData, setIssueData] = useState<any>([])
    const [contentData, setContentData] = useState<any>([])
    const [contentState, setContentState] = useState<boolean>(false)
    const [proTextEditValue, setProTextEditValue] = useState<any>('')
    const history = useHistory()

    
    const multiConfig = [
        {
            type: 'Collection',
            title: `收藏${issueData.collect}`,
            activation: issueData.collectStatus,
            success: async ()=> {
                // getData()
                setIssueData({
                    ...issueData,
                    collect: issueData.collect + (issueData.collectStatus ? -1 : 1),
                    collectStatus: !issueData.collectStatus
                })
            }
        },
        {
            type: 'good', 
            title: `赞 ${issueData.praise}`,
            activation: issueData.praiseStatus,
            success: async ()=> {
                // getData()
                setIssueData({
                    ...issueData,
                    praise: issueData.praise + (issueData.praiseStatus ? -1 : 1),
                    praiseStatus: !issueData.praiseStatus
                })
            }
            
        },
    ]
    const labelList = [
        {
            title: '相关标签',
            children: issueData.labelNameStr
        },
        {
            title: '关联客户',
            children: issueData.customerNameStr

        },
    ]
    const getData = async () => {
        const { data } = await getApiIssue({id}, false)
        // const { content } = data || {}
        // ref.current.innerHTML = content 
        setIssueData(data)
    }
    const getComment = async (parentId:any = undefined, callBack?:any) => {
        const { data } = await getApiKnowledListComment({docId:id, parentId}, false)
        setContentData(data)
        callBack && callBack(data)
    }
    useEffect(() => {
        getData()
        getComment()
    }, [])

    const operate = async (type:string)=> {
        if ( type === 'addComment') {
            const {success} = await addComment({content: proTextEditValue, docId: id}, false)
            if (success){
               getComment() 
               setContentState(false)
            } 
        } else if ( type === 'solve') {
            Modal.confirm({
                title: '确认已经解决了吗?',
                content: '确认后不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    const {success} = await solveQuestion({id}, true)
                    if (success){
                        getData()
                    } 
                },
            });
           
        } else if ( type === 'delete') {
            Modal.confirm({
                title: '确认要删除问题吗?',
                content: '确认删除后不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    const {success} = await delApi({ids:[id]}, true)
                    if (success){
                        // getData()
                        setTimeout(() => {
                            history.push('/knowledge/issue')
                        }, 1000);
                       
                    } 
                },
            });
           
        }
        

    }
    return <>
        <div className='questionBody'>
            <h2 className="questionTitle">{issueData.title}</h2>
            <div className="titleChildrenDiv">
                <span>作者：{issueData.author}</span>
                <span> {issueData.createTime}</span>
                <span> 浏览 {issueData.browse}</span>
                {issueData.solve ? <span style={{color: 'green'}}>已解决</span> : <span style={{color: 'red'}}>未解决</span>}
            </div>
            {/* <div className='questionDiv' ref={ref}>
              
            </div> */}
            <OpenText content={issueData.content}/>
            <div className='operationDiv'>
                <div>
                    {issueData.isCanOperate ?  <Button type="primary"  onClick={() => operate('delete')}>删除问题</Button>:null}
                    {issueData.isCanOperate ?  <Button type="primary" onClick={() =>history.push(`/knowledge/create?${stringify({type: 5, id})}`)}>编辑问题</Button>: null }
                    {(!issueData.solve && issueData.isCanOperate) ? <Button type="primary" onClick={() => operate('solve')}>确认解决</Button> : null}
                    <Button type="primary" onClick={() => setContentState(!contentState)}>发表评论</Button>
                </div>
                {(issueData.state === 1) ? <MultiIcon multiConfig={multiConfig} type={1} id={id}/>: null }
            </div>
            <div className="lineDiv"></div>
            {/* 小标签组件 */}
            <MultiLabel dataList={labelList} />
        </div>
        {contentState ? <div className="addContentDiv">
            <p className="zen-ban">发表评论</p>
            <MyEditor onChange={(value:any)=> setProTextEditValue(value)} style={{height:'300px'}}/>
            <div  style={{ padding: '15px 0', textAlign:'right'}}><Button type="primary" onClick={() => operate('addComment')}>发布</Button></div>
            
        </div> : null}
        {/* 评论组件 */}
        {contentData.commentCnt? <Comment commentDataList={contentData} updataData={getComment} docId={id}/> : null}
    </>
}

// 已答问卷模板详情
const SurveryDetail = ({customerId, docId}:any) => {
    const formRef = useRef<ProFormInstance>();
    const history = useHistory()
    const [templateData, setTemplateData ] = useState<any>([])
    const questionsList = (templateData.questionAnswers?.length > 0 ? templateData.questionAnswers.map((element: any, index: number) => {
        const { questionId, question, answer } = element
        return {
            type: 'text',
            index: ++index,
            label: question,
            valueText: answer,
            required: true,
        }
     }) : [])
    questionsList.unshift({
        type: 'relationText',
        label: '关联客户',
        valueText: templateData.customerName,
        required: true,
    })
    const getTemplateData = async () => {
        const { data, success } = await getTemplateDataApi({customerId, docId}, false)
        success && setTemplateData(data)
    }
    useEffect(() => {
        getTemplateData()
    }, [])
    return <>
        <BackBan title='已答问卷详情'/>
        <div className='questionBody' style={{marginTop: 0}}>
            <div className='topDiv'>
                <h2>{templateData.title}</h2>
                <QuestionFrom questionsList={questionsList} formRef={formRef}/>
            </div>
        </div>
    </>
}


// type: 
//  1 解决方案
//  2 客户案例 
//  3 问卷模板详情
//  3.1 已答问卷模板详情
//  4 帮助文档 
//  5 问题 

export const calcTitleDetail = (atype: string, id: string) => {
    const type = atype + ''
    if (type === '3') {
        return `问卷模板详情`
    } else if (type === '3.1') {
        return `已答问卷详情`
    }else if (type === '5') {
        return `问题详情`
    } else {
        return `文档详情`
    }
}

export default () => {
    const { search } = useLocation()
    const { id, type, docId, customerId } = parseSearch(search)
    const WhichComponent = () => {
        if (type+'' === '3') {
            return <QuestionnaireTemplate id={id}/>
        } else if (type+'' === '3.1') {
            return <SurveryDetail customerId={customerId} docId={docId}/>
        }  else if (type+'' === '5') {
            return <IssueDetail id={id}/>
        } else {
            return <DocDetail id={id}/>
        }
    }
    return(
        <div>
          { useMemo(() =><WhichComponent />, [id, type])}
        </div>
  
    )
}
