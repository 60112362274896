import React from "react"
import { Ellipsis } from "@components/index"
import { downLoadWithATag } from "@utils/util"

export const newCustomerColumns = [
    {
        title: '客户名称',
        dataIndex: 'name',
        render: (text: string) => <Ellipsis text={text} />
    },
    {
        title: '客户标签',
        dataIndex: 'labels',
        render: (text: string) => <Ellipsis text={text} />
    },
    {
        title: '所属地区',
        dataIndex: 'location',
    },
]

export const newCluesColumns = [
    {
        title: '客户名称',
        dataIndex: 'name',
    },
    {
        title: '客户标签',
        dataIndex: 'labels',
    },
    {
        title: '所属地区',
        dataIndex: 'location',
    },
]

export const newBusinessColumns = [
    {
        title: '商机标题',
        dataIndex: 'title',
    },
    {
        title: '客户名称',
        dataIndex: 'name',
    },
    {
        title: '销售阶段',
        dataIndex: 'salesStageStr',
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
    },
]

export const followCustomerColumns = [
    {
        title: '客户名称',
        dataIndex: 'name',
        with: 240,
        render: (text: string) => <Ellipsis text={text} len={30} />
    },
    {
        title: '跟进方式',
        dataIndex: 'followUpMethodStr',
    },
    // {
    //     title: '跟进反馈',
    //     dataIndex: 'feedbackStr',
    // },
    {
        title: '跟进内容',
        dataIndex: 'content',
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.content}</div>
    },
    {
        title: '下一步动作',
        dataIndex: 'nextStepContent',
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextStepContent}</div>
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
    },
]

export const attachmentColumnsFn = (operate: any) => [
    {
        title: '上传时间',
        dataIndex: 'createTime',
    },
    {
        title: '附件名称',
        dataIndex: 'fileName',
    },
    {
        title: '附件格式',
        dataIndex: 'suffix',
    },
    {
        title: '附件大小',
        dataIndex: 'fileSize',
        render: (text: string, record: any) => `${record.fileSize}KB`
    },
    {
        title: '上传人员',
        dataIndex: 'uploader',
    },
    {
        title: '操作',
        key: 'option',
        render: (text: string, record: any) => [
          ['jpg', 'png', 'gif', 'jpeg'].includes(record.suffix) ? <a key="link" style={{marginRight: 10}} onClick={() => operate && operate('预览', record)}>预览</a> : null,
          <a key="warn" onClick={() => downLoadWithATag(record.url)}>下载</a>
        ],
    },
]