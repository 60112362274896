import React from "react";
import { Button } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { useLocation } from "react-router"
import { transferTreeData, parseSearch } from "@utils/index";
import { Double } from "@components/Common";
import { useDictEnumHooks, useProductTreeDataHooks } from "@hooks/index";

export type TableListItem = {
  key: number;
  id: string;
  customerAccount: number,
  getMoneyType: string,
  status: string,
  getMoneyerType: string,
  getMoneyer: string,
  bank: string,
  bankAccount: string,
  alipayAccount: number,
  files: object,
  remark: string,
  changeRates: any,
  accountType: any,
};

export type OperateActionType = '新建返点比例' | '新建收款人' | '编辑返点比例' | '编辑收款人' | '删除收款人' | '删除返点比例'

export const columnsFn0: (operate?: Function, Collaborator?: boolean) => ProColumns<TableListItem>[] = (operate, Collaborator) => {
  const { search } = useLocation()
  const customerId = parseSearch(search).id
  const [, , request] = useDictEnumHooks({dictId: 13 }, false)
  return [
    {
      title: '消费月份',
      dataIndex: 'transTime',
      key: 'transTime',
      fixed: 'left',
      valueType: 'dateRange',
      fieldProps: { 
        picker: "month" ,
        format: 'YYYY-MM'
      },
      search: {
        transform: (transTime) => {
          return {
            transTime
          };
        },
      },
      render: (text, record: any) => `${(record||{}).transTime||''}`
    },
    {
      title: '账号类型',
      dataIndex: 'accountType',
      key: 'accountType',
      valueType: 'select',
      fieldProps: { showSearch: true },
      initialValue: 54,
      params: {dictId: 24 },
      request,
    },
    {
      title: '客户账号',
      dataIndex: 'customerAccount',
      key: 'customerAccount',
      render: (text, record: any) => `${(record||{}).customerAccountStr||''}`
    },
    {
      title: '产品',
      dataIndex: 'prodIds',
      key: 'prodIds',
      valueType: 'treeSelect',
      fieldProps: { showSearch: true },
      request: () => [],
      search: {
        transform: (ids) => {
          return {ids};
        },
      },
    },
    // {
    //   title: '合作模式',
    //   dataIndex: 'optiomName',
    //   key: 'optiomName',
    //   hideInSearch: true,
    // },
    // {
    //   title: '有无票据',
    //   dataIndex: 'hasInvoice',
    //   key: 'hasInvoice',
    //   valueEnum: {
    //     0: { text: '无' },
    //     1: { text: '有'},
    //   },
    //   hideInSearch: true,
    // },
    {
      title: '消费金额',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      sorter: true,
      search: {
        transform: (amountPaid) => {
          return { amountPaid};
        },
      },
      renderFormItem: (props) => <Double props1={{}} props2={{}} />
    },
    {
      title: '预计返点金额',
      dataIndex: 'predictRebates',
      key: 'predictRebates',
      sorter: true,
      fieldProps: { 
        width: '50%'
      },
      search: {
        transform: (predictRebates) => {
          return { predictRebates};
        },
      },
      renderFormItem: (props) => <Double props1={{}} props2={{}} />
    },
    {
      title: '实际返点金额',
      dataIndex: 'practicalRebates',
      key: 'practicalRebates',
      sorter: true,
      search: {
        transform: (practicalRebates) => {
          return { practicalRebates};
        },
      },
      renderFormItem: (props) => <Double props1={{}} props2={{}} />
    }
  ]
}

export const columnsFn1: (operate?: Function, Collaborator?: boolean) => ProColumns<TableListItem>[] = (operate, Collaborator) => {
  const [, , request] = useDictEnumHooks({dictId: 13 }, false)
  return [
    {
      title: '账号类型',
      dataIndex: 'accountType',
      key: 'accountType',
      valueType: 'select',
      fieldProps: { showSearch: true },
      initialValue: 54,
      params: {dictId: 24 },
      request,
    },
    {
      title: '客户账号',
      dataIndex: 'customerAccountStr',
      key: 'customerAccountStr',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '主维度',
      dataIndex: 'mainName',
      key: 'mainName',
      hideInSearch: true,
    },
    {
      title: '子维度',
      dataIndex: 'sonName',
      key: 'sonName',
      hideInSearch: true,
    },
    {
      title: '有无返点',
      dataIndex: 'hasRebate',
      key: 'hasRebate',
      valueEnum: {
        0: { text: '无' },
        1: { text: '有'},
      },
      hideInSearch: true,
    },
    {
      title: '返点比例',
      dataIndex: 'rebatePoint',
      key: 'rebatePoint',
      render: (text) => text + '%',
      hideInSearch: true,
    },
    {
      title: '比例变化',
      dataIndex: 'changeRates',
      render: (text, record) => record.changeRates ? record.changeRates.map((element:any) => element + '%' ).join('->') : '-',
      key: 'changeRates',
      hideInSearch: true,
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      width: 180,
      fixed: 'right',
      render: (text, record: any) => {
        return [
          Collaborator ? <Button key="warn" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('编辑返点比例', record)}>编辑</Button> : null,
          Collaborator ? <Button key="del" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('删除返点比例', record)}>删除</Button> : null,
        ]
      },
    },
  ]
}

export const columnsFn2: (operate?: Function, Collaborator?: boolean) => ProColumns<TableListItem>[] = (operate, Collaborator) => {
  const [, , request] = useDictEnumHooks({dictId: 13 }, false)
  return [
    {
      title: '账号类型',
      dataIndex: 'accountType',
      key: 'accountType',
      valueType: 'select',
      fieldProps: { showSearch: true },
      initialValue: 54,
      params: {dictId: 24 },
      request,
    },
    {
      title: '客户账号',
      dataIndex: 'customerAccountStr',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '收款人类型',
      dataIndex: 'getMoneyerTypeStr',
      hideInSearch: true,
    },
    {
      title: '收款人',
      dataIndex: 'getMoneyer',
      hideInSearch: true,
    },
    {
      title: '银行卡账号',
      dataIndex: 'bankAccount',
      hideInSearch: true,
    },
    {
      title: '开户行',
      dataIndex: 'bank',
      hideInSearch: true,
    },
    {
      title: '支付宝账号',
      dataIndex: 'alipayAccount',
      hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueEnum: {
        0: { text: '禁用', status: 'Default' },
        1: { text: '启用', status: 'Processing' },
      },
      hideInSearch: true,
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      width: 180,
      hideInSearch: true,
      fixed: 'right',
      render: (text, record: any) => {
        return [
          Collaborator ? <Button key="warn" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('编辑收款人', record)}>编辑</Button> : null,
          Collaborator ? <Button key="del" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('删除收款人', record)}>删除</Button> : null,
        ]
      },
    },
  ]
}
