import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ProForm, { ProFormText, ProFormSelect, ProFormTextArea, ProFormCascader, ProFormRadio, ProFormDependency, ProFormMoney, ProFormDatePicker, ProFormUploadButton, ProFormDateTimePicker, ModalForm } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Button, Col, Form, Row, Tooltip, Image, message, Space, TreeSelect, Cascader, Table } from 'antd';
import { BackBan } from '@components/index';
import { useCantactsByCustomerIdHooks, useDeptDataWithRoleHooks, useDictEnumHooks, useProductTreeDataHooks } from '@hooks/index';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken, handleUploadFiles, isImageFile, parseSearch, productApi, treeDataDisabledRules, validatorAccountAndType, validatorMuch2Single } from '@utils/util';
import { customerListByName, customerGetAccountsById, customerBusinessSave, customerBusinessUpdate, BASEURL } from '@api/index';
import { debounce } from 'lodash'
import { getBusDetailApi } from '../BusinessDetail';
import { handleHelperIds, HelperTagRender, resverHandleHelperIds } from '@components/Customer/List';
import moment from 'moment';
import { PreviewProps } from '@components/Common/Preview';
import { Icon, ProFormCustTreeSelect, ProFormTreeSelect, ProFormWithCache } from '@components/Common';
import { accountFieldNames, accountRequestApi } from '@components/EnterpriseDetail/cpnts/CustomerRebate';
import { salesStageColumns, salesStageDataSource } from './data';
import { custOptions, yesNoOptions } from '@utils/enum';
import { useSelector } from 'react-redux';
import { StateType } from '@store/index';

type FormDataType = {
    businessOpportunityTitle: string; // 商机标题
    customerId: string;	    // 客户名称 主键
    isNewCustomer: number;  // 是否新客户
    contactsId: string;	    // 主要联系人 
    visit: number;          // 上门拜访
    chatGroup: string;      // 建立沟通群
    followBusinessLine: string;	  // 关注业务线
    opportunityPoint: string;   // 项目机会点
    productId?: string;	     // 意向产品
    accountIsRegister?: number; // 账号是否注册
    accountId?: string;	// 预估下单账号
    synergys?: string[];    // 协同人
    estimatedSalesAmount: string;  // 预计销售金额
    estimatedCashAmount: string;   // 预计Cash收入
    estimatedPriceDiffAmount: string; // 预计差价收入
    purchase: string;               // 采购方式
    pocTest: string;               // POC测试情况
    estimatedSigningDate: string;  // 预计签单日期
    salesStage: string;            // 销售阶段
    helps: string;                 // 求助点
    remarks: string;               // 备注
    files: string;	               // 附件
}

const customerListByNameApi = productApi(customerListByName)
const customerAccountsByIdApi = productApi(customerGetAccountsById)
const addBusinessApi = productApi(customerBusinessSave)
const updateBusinessApi = productApi(customerBusinessUpdate)

type ItemRenderProps = {
    originNode: ReactNode;
    file: any;
    fileList: object[];
    actions: { download: Function, preview: Function, remove: Function };
} 

export const FileItemRender = (originNode: ReactNode, file: any, fileList: object[], actions: { download: Function, preview: Function, remove: Function }, onPreview: Function) => {
    const {name, format} = file || {}
    const { remove, preview } = actions
    const thatName = format ? `${name}.${format}` : name
    return <Row justify='space-between' className='jns-file-item'>
            <Col style={{display: 'inline-block', width: 'calc(100% - 50px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                <Icon type="icon-fujian" /><a>{thatName}</a>
            </Col>
            <Col>
                <Tooltip placement="top" title="预览"><Icon type="icon-icon_yulan" style={{marginRight: 10}} onClick={() => onPreview && onPreview(file)} /></Tooltip>
                <Tooltip placement="top" title="删除"><Icon type="icon-shanchu" onClick={() => remove() } /></Tooltip>
            </Col>
        </Row>
}

export const SalesStageToolTip = (props: ModalFormProps) => {
    return (<ModalForm title="项目阶段说明" submitter={false} {...props}>
        <Table
           columns={salesStageColumns}
           pagination={false}
           bordered
           dataSource={salesStageDataSource}
        />
    </ModalForm>)
    return(<div style={{background: '#fff'}}>
        
        {/* <Row gutter={4} wrap={false}><Col flex="108px">10% 潜在商机: </Col><Col>有明确的联系人；客户存在需求；有交流意向，可约拜访</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">20% 商机确认: </Col><Col>已上门沟通交流；需求清晰明确；项目有时间节点要求；对接到项目的负责人</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">40% 方案沟通: </Col><Col flex="auto">提交并汇报对应的方案；项目决策链路清晰，并接触到关键人；客户对方案没有提出明确的异议，并存在支持方；在客户方对接到两个以上的人员；客户有测试意向，并投入一定的精力了解产品和方案</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">60% 商务谈判: </Col><Col>完成项目报价；明确存在的竞争对手，并了解其方案情况；同项目决策人做过明确的工作交流；明确客户方采购流程</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">80% 签约完成: </Col><Col>完成招投标或者业务相关决策性动作；拿到中标通知书；签订合同；客户方有明确的项目启动动作</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">100% 收入确认: </Col><Col>完成收款或部分收款</Col></Row>
        <Row gutter={4} wrap={false}><Col flex="108px">0% 项目失败: </Col><Col>该客户的该项目失败</Col></Row> */}
    </div>)
}

export default () => {
    const { search } = useLocation()
    const history = useHistory()
    const { id, customerId, customerName, fromPath,  } = parseSearch(search)
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    // const [treeData, , getUserRoleApi] = useDeptDataWithRoleHooks({}, true) // 协同人数据
    const pmTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    const [prodTreeData ] = useProductTreeDataHooks({}, true, (v: any) => { 
        v.id = v.whetherProduct ? `d${v.id}` : `p${v.id}`
        v._title = v.name
        v.key = v.id
        v.title = v.name
        v.value = v.id
        v.disabled = !v.whetherProduct
        return v
    })
    const [cantactsOptions, , getCantactsByCusIdApi] = useCantactsByCustomerIdHooks({}, false)

    const [followBusinessLineOptions,] = useDictEnumHooks({dictId: 19}, true)
    const [salesStageOptions,] = useDictEnumHooks({dictId: 20}, true)
    const [chatGroupOptions,] = useDictEnumHooks({dictId: 266}, true)
    const [visitOptions,] = useDictEnumHooks({dictId: 267}, true)
    const [orderTypeOptions,] = useDictEnumHooks({dictId: 254}, true) // 订单类型
    const [orderModeOptions,] = useDictEnumHooks({dictId: 279}, true)
    
    const [customerOptions, setCustomerOptions] = useState([])
    const [estimatedAccountOptions, setEstimatedAccountOptions] = useState([])
    const formRef = useRef<ProFormInstance>()

    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})

    const request = async (param: object) => {
        const { data } = await getBusDetailApi(param, false, )
        // const { data: treeData } = await getUserRoleApi({}) as any
        const { 
            businessOpportunityTitle, 
            customerId, 
            customerName, 
            primaryConcat: contactsId, 
            followBusinessLine, 
            product, 
            accountType,
            accountId, 
            synergys: synergy, 
            estimatedSalesAmount, 
            estimatedSigningDate, 
            salesStage, 
            files, 
            remarks,
            whetherNewCustomer,
            whetherVisit,
            setUpGroup,
            opportunityPoint,
            accountIsNew,
            estimatedCashIncome,
            estimatedBetweenIncome,
            customerAccount,
            buyMethod,
            orderMethod,
            orderType,
            isChanceOfBeingAlone,
            isPocTest,
        } = data || {}
        onSearchCustomer(customerName)
        customerId && onValuesChange({customerId})

        return { 
            businessOpportunityTitle,
            customerId,
            customerName, 
            contactsId, 
            followBusinessLine, 
            // accountId, 
            // customerAccount: accountType ? [ `${accountType}-type`, accountId ] : [],
            customerAccount: (customerAccount || []).map((v: any) => [`${v[0]}-type`, v[1]]),
            // synergys: resverHandleHelperIds(synergy||[], treeData||[]),
            synergys: synergy ? synergy.map((v: string) => `s${v}`) : [0],
            salesStage, 
            // remarks,
            // files,
            // whetherNewCustomer: +whetherNewCustomer,
            // whetherVisit: +whetherVisit,
            // setUpGroup,

            opportunityPoint,
            estimatedSigningDate, 
            estimatedCashIncome,
            estimatedBetweenIncome,
            productId: (product||[]).map((v: string | number)=> `d${v}`),
            buyMethod,
            orderMethod,
            orderType,
            accountIsNew: +accountIsNew,
            isChanceOfBeingAlone,
            isPocTest,
            setUpGroup
        }
    }

    const onValuesChange = async (values: any) => {
        if ('customerId' in values) {
            formRef.current?.setFieldsValue({contactsId: undefined})
            getCantactsByCusIdApi(values)
            // await customerAccountsByIdApi({id: values.customerId}, false, (data: any[]) => {
            //     const newOptions = (data||[]).map(({accountName: label, id: value}) => ({label, value}))
            //     setEstimatedAccountOptions(newOptions as any)
            // })
        }
    }
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = true) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
           setCustomerOptions(newOptions as any)
        })
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const onSubmit = async ({synergys: synergysArr, productId: pids, followBusinessLine, setUpGroup, accountIsNew, customerAccount, ...rest}: any) => {
        // const synergys1 = handleHelperIds(synergysArr||[], treeData)
        // const files = handleUploadFiles(filesArr||[])
        // const [accountType, accountId ] = (customerAccount || [])
        const newCustomerAccount = (customerAccount || []).filter((v: any) => !!v[1]).map((v: any) => [parseInt(v[0]), v[1]])
        const productId = (pids||[]).map((v: string) => (v||'').slice(1))
        const whichApi = id ? updateBusinessApi : addBusinessApi
        // if (followBusinessLine.length > 1) {
        //     message.error(`请选择单个业务线!`)
        //     return 
        // }
        // if (setUpGroup.length > 1) {
        //     message.error(`请选择单个沟通群!`)
        //     return 
        // }
        const params = {
            ...rest, 
            synergys: Array.isArray(synergysArr) ? synergysArr.filter(Boolean)?.map((id: string) => id.slice(1)) : [], 
            // files, 
            productId, 
            businessOppId: id, 
            // whetherNewCustomer: !!whetherNewCustomer, 
            // whetherVisit: !!whetherVisit, 
            accountIsNew,
            customerAccount: newCustomerAccount,
            followBusinessLine,
            setUpGroup
            // accountType: parseInt(accountType),
            // accountId
        }

        const { success } = await whichApi(params, true, () => {
            setTimeout(() => {
                history.goBack()
            }, 1000)
        })
        return success
    }

    const disabledDate = (current: any) => current && current < moment().subtract (1,"days").endOf('day')

    const calcDisabledCustomerId = () => ['/customer/list'].includes(fromPath)
    useEffect(() => {
       if (['/customer/list'].includes(fromPath)) {
            onSearchCustomer(customerName, false)
            customerId && onValuesChange({customerId})
            formRef.current?.setFieldsValue({customerId: customerId * 1})
       }
    }, [fromPath])
    
    return (
        <div>
            <BackBan title={id ? '编辑商机' : '创建商机'} style={{paddingLeft: 20, position: 'sticky', top: -24, zIndex: 9}} />
            <div className="jns-add-customer-form" style={{background: '#fff', boxSizing: 'border-box', padding: '20px'}}>
                <ProFormWithCache<FormDataType>
                    key={location.href}
                    layout="horizontal"
                    labelCol={{span: 4}}
                    onFinish={onSubmit}
                    params={{businessOppId: id, customerId}}
                    request={id && request as any}
                    onValuesChange={onValuesChange}
                    formRef={formRef}
                    submitter={{render: (props, doms) => [
                        <div key="submit" 
                            style={{
                                position: 'fixed', 
                                bottom: 0, 
                                left: 0, 
                                right: 0, 
                                padding: 6, 
                                boxShadow: '0px -1px 6px 0px rgba(212,212,212,0.5)', 
                                background: '#fff', 
                                textAlign: 'center'
                            }}
                        >
                            <Button  type="primary" style={{marginLeft: 130}} onClick={() => props.form?.submit?.()}>提交</Button>
                        </div>,
                        // <Button key="rest" onClick={() => props.form?.resetFields?.()}>重置</Button>,
                    ]}}
                >
                    <ProFormText 
                        width="md" 
                        name="businessOpportunityTitle" 
                        fieldProps={{maxLength: 30}} 
                        label="商机标题" 
                        rules={[{ required: true, message: '请填写商机标题',}]} 
                        placeholder="请输入" 
                    />
                    <ProFormSelect 
                        width="md" 
                        name="customerId" 
                        disabled={calcDisabledCustomerId()} 
                        label="客户名称" 
                        options={customerOptions} 
                        fieldProps={{
                            showSearch: true, 
                            filterOption: false, 
                            onSearch: debounce(onSearchCustomer, 600)
                        }} 
                        rules={[{ required: true, message: '请选择关联客户',}]} 
                        placeholder="请输入客户名称查询" 
                    />
                    <ProFormSelect 
                        width="md" 
                        name="followBusinessLine" 
                        label="关注业务线" 
                        options={followBusinessLineOptions} 
                        fieldProps={{mode: 'multiple'}} 
                        rules={[{ required: true, message: '请选择关注业务线',}, { validator: validatorMuch2Single('业务线') }]} 
                        placeholder="请选择单个业务线"
                    />
                    <ProFormText 
                        width="md" 
                        name="opportunityPoint" 
                        fieldProps={{maxLength: 100}} 
                        label="项目机会点" 
                        rules={[{ required: true, message: '请填写项目机会点',}]} 
                        placeholder="请填写" 
                    />
                    <ProFormSelect
                        width="md" 
                        name="salesStage" 
                        label={<>项目阶段  <SalesStageToolTip trigger={<Icon type="icon-ziyuan" />} /></>}
                        options={salesStageOptions} 
                        rules={[{ required: true, message: '请选择项目阶段',}]} placeholder="请选择" 
                    />
                    <ProFormDatePicker 
                        width="md" 
                        name="estimatedSigningDate" 
                        label="预计签单日期" 
                        // fieldProps={{disabledDate}} 
                        rules={[{ required: true, message: '请选择预计签单日期',}]} 
                        placeholder="请选择"
                    />
                    <ProFormMoney 
                        width="md" 
                        name="estimatedCashIncome" 
                        fieldProps={{ 
                            formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                            defaultValue: 0,
                            addonAfter: '元'
                        }} 
                        max={999999999} 
                        min={0} 
                        label="预计Cash收入" 
                        rules={[{ required: true, message: '请输入预计Cash收入',}]} 
                        placeholder="请输入" 
                    />
                    
                    <ProFormMoney 
                        width="md" 
                        name="estimatedBetweenIncome" 
                        fieldProps={{ 
                            formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                            defaultValue: 0,
                            addonAfter: '元'
                        }} 
                        max={999999999} 
                        min={0} 
                        label="预计差价收入" 
                        rules={[{ required: true, message: '请输入预计差价收入',}]} 
                        placeholder="请输入" 
                        tooltip="预估差价收入，按照实际消费的百分比计算，具体比例请咨询主管。"
                    />
                    {/* <ProFormSelect width="md" name="contactsId" label="主要联系人" options={(cantactsOptions||[]).map(({label, ...rest}: any) => ({label: `${label}(${rest.positionStr})`, ...rest}))} rules={[{ required: false, message: '请选择主要联系人',}]} placeholder="请选择" /> */}
                    <ProFormTreeSelect width="md" name="productId" label="意向产品" fieldProps={{treeData: prodTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title', style: {width: 328}}} rules={[{ required: false, message: '请选择意向产品',}]} placeholder="请选择" />
                    <ProFormSelect width="md" name="buyMethod" label="采购方式" options={visitOptions} rules={[{ required: false, message: '请选择采购方式',}]} placeholder="请选择" />
                    <ProFormSelect width="md" name="orderMethod" label="下单方式" options={orderModeOptions} rules={[{ required: false, message: '请选择下单方式',}]} placeholder="请选择" />
                    <ProFormSelect width="md" name="orderType" label="订单类型" options={orderTypeOptions} rules={[{ required: true, message: '请选择订单类型',}]} placeholder="请选择" />

                    {/* <ProFormRadio.Group width="md" name="whetherNewCustomer" label="是否新客户" options={isNewCustomerOptions} rules={[{ required: false, message: '请选择是否新客户',}]} placeholder="请选择" /> */}
                    {/* <ProFormRadio.Group width="md" name="emphasisProject" label="是否重点项目" options={isNewCustomerOptions} rules={[{ required: false, message: '请选择是否重点项目',}]} placeholder="请选择" /> */}

                    {/* <ProFormRadio.Group width="md" name="whetherVisit" label="上门拜访" options={isNewCustomerOptions} rules={[{ required: false, message: '请选择是否上门拜访',}]} placeholder="请选择" /> */}
                    <ProFormRadio.Group width="md" name="accountIsNew" label="账号是否注册" options={yesNoOptions} rules={[{ required: true, message: '请选择账号是否注册',}]} placeholder="请选择" />
                    <ProFormRadio.Group width="md" name="isChanceOfBeingAlone" label="是否80%以上概率落单" options={yesNoOptions} rules={[{ required: true, message: '请选择是否80%以上概率落单',}]} placeholder="请选择" />
                    <ProFormRadio.Group width="md" name="isPocTest" label="是否poc测试"  options={yesNoOptions} rules={[{ required: true, message: '请选择是否poc测试',}]} placeholder="请选择" />
                    <ProFormSelect 
                        width="md" 
                        name="setUpGroup" 
                        label="建立沟通群" 
                        options={chatGroupOptions} 
                        mode='multiple' 
                        rules={[{ required: true, message: '请选择沟通群',}, {validator: validatorMuch2Single('沟通群')}]} 
                        placeholder="请选择" 
                    />
                    <ProFormCustTreeSelect
                        width="md" 
                        name="synergys" 
                        label="协同人(售前)" 
                        tooltip="请选择协同人，如，销售主管、售前等"
                        custOptions={custOptions}
                        fieldProps={{
                            treeData: pmTreeData, 
                            showSearch: true,
                            multiple: true, 
                            treeCheckable: true, 
                            treeNodeFilterProp: 'title', 
                            style: { width: 328 }
                        }} 
                        rules={[{required: true, message: '请选择协同人'}]} 
                        placeholder="请选择" 
                    />

                    
                    {/* <ProFormText width="md" name="poc" fieldProps={{maxLength: 100}} label="POC测试情况" rules={[{ required: false, message: '请填写POC测试情况',}]} placeholder="请填写" /> */}
                    {/* <ProFormTextArea width="md" name="helpPoint" label="求助点" fieldProps={{maxLength: 300, showCount: true}} rules={[{required: false, message: '请填写求助点'}, {max: 300, type:'string', message: '300字以内'}]} placeholder={`请描述求助点，例如：需要高层支持、需要架构师支持、需要研发支持、需要厂家支持、需要资金垫付支持等`} /> */}
                    {/* <ProFormUploadButton width="md" name="files" label="附件" fieldProps={{ name: 'file', listType: 'text', itemRender: (...props) => FileItemRender(...props, onPreview), headers: {Authorization: getToken()}}} action={`${BASEURL}/upload`} extra=""/>   */}
                    {/* <ProFormTextArea width="md" name="remarks" label="备注" fieldProps={{maxLength: 300, showCount: true}} rules={[{required: false, message: '请填写备注'}, {max: 300, type:'string', message: '300字以内'}]} /> */}

                    {/* v4.8.0 */}
                    
                    {/* <ProFormSelect width="md" name="relateProductId" label="意向产品" options={[]} rules={[{ required: false, message: '请选择意向产品',}]} placeholder="请选择" /> */}

                    {/* <ProFormSelect width="md" name="accountId" label="预估下单账号" options={estimatedAccountOptions} rules={[{ required: false, message: '请选择预估下单账号',}]} placeholder="请选择" /> */}
                    {/* <ProFormCustomerAccount width={329} name="customerAccount" label="预估下单账号" rules={[{required: true, validator: validatorAccountAndType}]} customerId={customerId} /> */}
                    {/* <ProFormCascader width="md" name="customerAccount" label="客户账号" fieldProps={{options: [], showSearch: true}} rules={[{ required: true, message: '请选择客户账号',}]} placeholder="请选择客户账号" /> */}

                    {/* <ProFormCascader width="md" name="customerAccount" label="客户账号" params={{customerId}} request={accountRequestApi} fieldProps={{showSearch: true, fieldNames: accountFieldNames}} rules={[{ required: true, message: '请选择客户账号',}]} placeholder="请选择客户账号" /> */}
                    {/* jns-web-v4.11.0 版本需求要求删除之前加的 【预估下单账号，预计销售金额】 */}
                    {/* <ProFormDependency name={['customerId']}>
                        {({customerId: formCustomerId}) => {
                            return (<ProFormCascader 
                                width="md" 
                                name="customerAccount" 
                                label="预估下单账号" 
                                params={{customerId: formCustomerId || customerId}} 
                                request={accountRequestApi} 
                                fieldProps={{
                                    showSearch: true, 
                                    multiple: true, 
                                    fieldNames: accountFieldNames,
                                    showCheckedStrategy: Cascader.SHOW_CHILD
                                }} 
                                rules={[{ required: false, message: '请选择客户账号',}]} 
                                placeholder="请选择客户账号" 
                                />)
                        }}
                    </ProFormDependency>
                    
                    <ProFormMoney width="md" name="estimatedSalesAmount" fieldProps={{ 
                            formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                            defaultValue: '',
                            addonAfter: '元'
                        }} max={999999999} min={0} label="预计销售金额" rules={[{ required: false, message: '请输入预计销售金额',}]} placeholder="请输入" /> */}
                    
                </ProFormWithCache>
                <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
        </div>
    </div>)
}
