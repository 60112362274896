import React from "react";
import { Input, Select, DatePicker } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { downLoadWithATag } from "@utils/index";

const { RangePicker } = DatePicker
// ts--types
export type TableListItem = {
    key: number;
    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '添加跟进' | '添加协同人'

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => [
    {
        title: '联系人',
        dataIndex: 'customerTitle',
        fixed: 'left',
        width: 120,
        hideInSearch: true,
    },
    {
        title: '职位',
        dataIndex: 'positionStr',
        fixed: 'left',
        width: 100,
        hideInSearch: true,
    },
    {
        title: '跟进方式',
        dataIndex: 'customerName',
        fixed: 'left',
        order: 100,
    },
    // {
    //     title: '跟进反馈',
    //     dataIndex: 'customerTags',
    //     order: 3,
    // },
    {
        title: '跟进内容',
        dataIndex: 'clueOrigin',
        hideInTable: true,
        // width: 120,
        order: 5,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.clueOrigin}</div>
    },
    {
        title: '下一步动作',
        dataIndex: 'nextStepContent',
        hideInSearch: true,
        // width: 120,
        order: 6,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextStepContent}</div>
    },
    // {
    //     title: '跟进时间',
    //     dataIndex: 'clueOrigin',
    //     order: 4,
    //     valueType: 'dateRange',
    //     fieldProps: { allowEmpty: [true, true]},
    //     search: {
    //         transform: (value) => {
    //         return {
    //             startTime: value[0],
    //             endTime: value[1],
    //         };
    //         },
    //     },
    // },
    // {
    //   title: '下次跟进时间',
    //   dataIndex: 'cluePool',
    //   order: 6,
    //   valueEnum: {
    //     all: { text: '全部', status: 'Default' },
    //     close: { text: '关闭', status: 'Default' },
    //     running: { text: '运行中', status: 'Processing' },
    //     online: { text: '已上线', status: 'Success' },
    //     error: { text: '异常', status: 'Error' },
    //   },
    // },
    {
        title: '跟进人',
        dataIndex: 'customerArea',
        order: 8,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      order: 1,
      valueType: 'dateRange',
      fieldProps: { allowEmpty: [true, true]},
      search: {
        transform: (value) => {
          return {
            startTime: value[0],
            endTime: value[1],
          };
        },
      },
    }
]

export const baseInfoDesList = [
    // {
    //     label: '客户名称',
    //     param: 'customerName',
    //     span: 1
    // },
    // {
    //     label: '是否新客户',
    //     param: 'whetherNewCustomer',
    //     span: 1,
    //     value: ({whetherNewCustomer}: any) => whetherNewCustomer ? '是' : '否',
    // },
    // {
    //     label: '主要联系人',
    //     param: 'primaryConcatStr',
    //     span: 1,
    //     value: ({primaryConcatStr, potition}: any) => `${primaryConcatStr}${potition ? '(' + potition + ')' : ''}`
    // },
    {
        label: '关注业务线',
        param: 'followBusinessLineStr',
        span: 1
    },
    {
        label: '项目机会点',
        param: 'opportunityPoint',
        span: 1
    },
    {
        label: '预计签单日期',
        param: 'estimatedSigningDate',
        span: 1
    },
    {
        label: '预计Cash收入(元)',
        param: 'estimatedCashIncome',
        span: 1
    },
    {
        label: '预计差价收入(元)',
        param: 'estimatedBetweenIncome',
        span: 1
    },
    {
        label: '意向产品',
        // param: 'customerSourceStr',
        value: ({productStr}: any) => (productStr||[]).toString(),
        span: 1
    },
    {
        label: '采购方式',
        param: 'buyMethodStr',
        span: 1
    },
    {
        label: '下单方式',
        param: 'orderMethodStr',
        span: 1
    },
    {
        label: '订单类型',
        param: 'orderTypeStr',
        span: 1
    },
    {
        label: '账号是否注册',
        param: 'accountIsNew',
        span: 1,
        value: ({accountIsNew}: any) => accountIsNew ? '是' : '否',
    },
    {
        label: '是否80%以上概率落单',
        param: 'isChanceOfBeingAloneStr',
        span: 1
    },
    {
        label: '是否poc测试',
        param: 'isPocTestStr',
        span: 1
    },
    {
        label: '建立沟通群',
        param: 'setUpGroupStr',
        span: 1
    },
    // {
    //     label: '负责人',
    //     param: 'name',
    //     span: 1
    // },
    {
        label: '协同人(售前)',
        param: 'synergName',
        span: 1,
        value: (record: any) => record?.synergName||'无',
    }
]

export const nextActionDesList = [
    {
        label: '上门拜访',
        param: 'whetherVisit',
        span: 1,
        value: ({whetherVisit}: any) => whetherVisit ? '是' : '否',
    },
    {
        label: '建立沟通群',
        param: 'setUpGroupStr',
        span: 1
    },
    {
        label: '账号是否注册',
        param: 'accountIsNew',
        span: 1,
        value: ({accountIsNew}: any) => accountIsNew ? '是' : '否',
    },
    // {
    //     label: '预估下单账号',
    //     param: 'accountStr',
    //     span: 1
    // },
    // {
    //     label: '预计销售金额(元)',
    //     param: 'estimatedSalesAmount',
    //     span: 1
    // },
    {
        label: '预计Cash收入(元)',
        param: 'estimatedCashIncome',
        span: 1
    },
    {
        label: '预计差价收入(元)',
        param: 'estimatedBetweenIncome',
        span: 1
    },
    {
        label: '采购方式',
        param: 'buyMethodStr',
        span: 1
    },
    {
        label: 'POC测试情况',
        param: 'poc',
        span: 1
    }
]

export const otherInfoDesList = [
    {
        label: '预计签单日期',
        param: 'estimatedSigningDate',
        span: 1
    },
    {
        label: '销售阶段',
        param: 'salesStageStr',
        span: 1
    },
    {
        label: '求助点',
        param: 'helpPoint',
        span: 1
    },
    {
        label: '备注',
        param: 'remarks',
        span: 1
    }
]

export const affixColumnsFn: (operate?: Function) => any[] = (operate) => [
    {
        title: '附件名称',
        dataIndex: 'name',
        fixed: 'left',
    },
    {
        title: '操作',
        dataIndex: 'option',
        fixed: 'left',
        width: 100,
        render: (text: string, record: any) => <>
            <a onClick={() => operate && operate(record, '预览')}>预览</a>&nbsp;&nbsp;
            <a download={record?.url} href={record?.url} target="_blank" rel="noreferrer">下载</a>
            {/* <a key="warn" onClick={() => downLoadWithATag(record.url)}>下载</a> */}
            </>
    }
]