import React, { ReactNode, useEffect, useState } from "react"
import { BackBan, FollowRecord, Icon } from "@components/index"
import { affixColumnsFn, baseInfoDesList, columnsFn, nextActionDesList, otherInfoDesList } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import { Button, Col, Descriptions, Row, Card, Tabs, Upload, Tooltip, Image, message, Table } from "antd";
import { AddFollowRecord, AddHelper, AddStaff } from "@components/Customer/List";
import { Link, useLocation } from "react-router-dom";
import { customerBusinessDetail } from '@api/index'
import { isImageFile, parseSearch, productApi } from "@utils/util";
import { useHasAuthCode } from "@hooks/index";
import { MyTabs } from "@components/EnterpriseDetail";
import { EnterpriseDetailProps } from "@components/EnterpriseDetail/type";
import { useBaseInfo } from "@components/EnterpriseDetail/data";
import OperationRecord from "@components/EnterpriseDetail/cpnts/OperationRecord";
import { DescriptionsList } from '@components/EnterpriseDetail'
import { PreviewProps } from "@components/Common/Preview";
// import BusinessMap from "../BusinessMap";
// import ServiceMap from "../ServiceMap";
// import DecisionChainMap from "../DecisionChainMap";
// import SurveySheet from "../SurveySheet";
import './index.less'

type DataObj = {
    businessOpportunityTitle: string;
    salesStageStr: string;
    customerName: string;
    followBusinessLineStr: string;
    salesStage: string;
    estimatedSalesAmount: string;
    estimatedSigningDate: string;
    name: string;
    customerId: string;
    isSyn: number;
    synergys: {id: string; name: string}[];
    estimatedAccount: string;
    productStr: string;
    accountStr: string;
    files?: any[];
    whetherNewCustomer?: boolean;
    emphasisProject?: boolean;
    primaryConcatStr?: string;
    position?: string;
    isEmphasisCustomerStr?: string;
    createTime?: any;
    synergName?: any;
}
type TableExtraRenderProps = {
    operate?: (type: OperateActionType, record?: TableListItem | null, values?: any) => void;
    dataObj: DataObj;
}

const tableStyle = {textAlign: 'center'};
const { TabPane } = Tabs
export const getBusDetailApi = productApi(customerBusinessDetail)

const TableExtra = ({dataObj}: TableExtraRenderProps) => {
    const { customerName, salesStageStr, createTime, isEmphasisCustomerStr, primaryConcatStr, position, estimatedSalesAmount, estimatedSigningDate, name, synergName, followBusinessLineStr, productStr, accountStr } = dataObj || {}
    return (<Card><Descriptions bordered column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        <Descriptions.Item label="客户名称">{customerName}</Descriptions.Item>
        {/* <Descriptions.Item label="是否新客户">{whetherNewCustomer ? '是' : '否'}</Descriptions.Item> */}
        <Descriptions.Item label="重点客户">{isEmphasisCustomerStr}</Descriptions.Item>
        <Descriptions.Item label="项目阶段">{salesStageStr}</Descriptions.Item>
        <Descriptions.Item label="创建人">{name}</Descriptions.Item>
        <Descriptions.Item label="协同人(售前)">{synergName}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{createTime}</Descriptions.Item>
        {/* <Descriptions.Item label="主要联系人">{primaryConcatStr}{position ? `(${position})` : ''}</Descriptions.Item> */}
        {/* <Descriptions.Item label="关注业务线">{followBusinessLineStr}</Descriptions.Item> */}
        
        {/* <Descriptions.Item label="预计销售金额">¥{estimatedSalesAmount}</Descriptions.Item>
        <Descriptions.Item label="预计签单日期">{estimatedSigningDate}</Descriptions.Item>
        <Descriptions.Item label="协同人">{(synergys||[]).map(v => v.name).toString()}</Descriptions.Item>
        <Descriptions.Item label="意向产品">{(productStr||[]).toString()}</Descriptions.Item>
        <Descriptions.Item label="预估下单账号">{accountStr}</Descriptions.Item> */}
      </Descriptions></Card>)
}

const TableExtraRender = ({operate, dataObj}: TableExtraRenderProps) => {
    const { search } = useLocation()
    const [, hasAuthCode] = useHasAuthCode()
    const { businessOpportunityTitle, customerId, isSyn } = dataObj || {}
    return <>
        <BackBan title={businessOpportunityTitle} actions={[
            hasAuthCode(2128) && [0].includes(isSyn) ? <Button key="edit" type="primary"><Link key="update" to={`/customer/businessAdd${search}`}>编辑商机</Link></Button> : null,
            hasAuthCode(2186) && [0].includes(isSyn) ? <AddHelper key="bnt1" title="添加协同人" selectedRowKeys={[customerId]} type={2} trigger={<Button style={{marginLeft: 10}}>添加协同人</Button>} onSuccess={() => operate && operate('添加协同人')} /> : null,
        ]} />
        <TableExtra dataObj={dataObj} />
        </>
}

const defaultFileList = [
    {
      uid: '1',
      name: 'xxx.png',
      status: 'done',
      response: 'Server Error 500', // custom error message to show
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '2',
      name: 'yyy.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
    {
      uid: '3',
      name: 'zzz.png',
      format: 'png',
      status: 'error',
      response: 'Server Error 500', // custom error message to show
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
  ]

// export const FileItemRender = (originNode: ReactNode, file: any, fileList: object[], actions: { download: Function, preview: Function, remove: Function }, onPreview: Function) => {
//     const {name, format} = file || {}
//     const { remove, preview, download } = actions
//     const thatName = format ? `${name}.${format}` : name
//     return <Row justify='space-between' className='jns-file-item'>
//             <Col><Icon type="icon-fujian" /><a>{thatName}</a></Col>
//             <Col>
//             <Tooltip placement="top" title="预览"><Icon type="icon-icon_yulan" style={{marginRight: 10}} onClick={() => onPreview && onPreview(file)} /></Tooltip>
//             <Tooltip placement="top" title="下载"><Icon type="icon-xiazai" onClick={() => download() } /></Tooltip>
//             </Col>
//         </Row>
// }  

const BusinessInfo = ({dataObj}: TableExtraRenderProps) => {
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const onPreview = (file: any) => {
        // const { response } = file || {}
        // const {name, format, url, } = response ? (response||{}).data : file
        const {name, format, url, } = file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    const operate = (record: any, action: string) => {
        if (action === '预览') {
            onPreview(record)
        }
    }

    return (<div style={{padding: '20px 20px', background: '#fff'}}>
      {/* <div className="jns-ban-title">基本信息</div> */}
      <DescriptionsList descList={baseInfoDesList} data={dataObj} />
      {/* <div className="jns-ban-title">下一步动作</div>
      <DescriptionsList descList={nextActionDesList} data={dataObj} />
      <div className="jns-ban-title">附加信息</div>
      <DescriptionsList descList={otherInfoDesList} data={dataObj} /> */}
      {/* <div className="jns-ban-title">附件</div> */}
      {/* <Upload 
        className="attachment" 
        defaultFileList={defaultFileList} 
        showUploadList={{showPreviewIcon: true, showDownloadIcon: true, showRemoveIcon: false}}
        itemRender={(...props) => FileItemRender(...props, onPreview)}
      ></Upload> */}
      {/* <Table style={{margin: '0 20px'}} size="small" pagination={false} columns={affixColumnsFn(operate)} dataSource={dataObj.files || []} /> */}
      <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </div>)
}

export default () => {
    const { search } = useLocation()
    const { id, customerId, customerName, emphasisProject } = parseSearch(search)
    const [dataObj, setDataObj] = useState({})
    const getDetailApi = async (param: any) => {
        await getBusDetailApi(param, false, (data: object) => {
            setDataObj(data)
        })
    }

    const mainTabPans = ({dataObj, ...rest}: EnterpriseDetailProps) => {
        const { isSyn, isManager, businessOpportunityTitle } = dataObj || {}
        return [
            {
                Tab: () => <div style={tableStyle as any}>商机详情</div>,
                Content: () => <BusinessInfo dataObj={dataObj} />,
            },
            {
                Tab: () => <div style={tableStyle as any}>跟进记录</div>,
                Content: () => <FollowRecord id={customerId} name={customerName} businessOppId={id} businessOpportunityTitle={businessOpportunityTitle} />,
                // hidden: !ownerName
            },
            {
                Tab: () => <div style={tableStyle as any}>操作记录</div>,
                Content: () => <OperationRecord searchType={2} businessOppId={id} Collaborator={![1].includes(isSyn)} />,
                // hidden: !ownerName
            },
            // {
            //     Tab: () => <div style={tableStyle as any}>客户业务调研表</div>,
            //     Content: () => <SurveySheet />,
            //     // hidden: !['1'].includes(emphasisProject)
            // },
            // {
            //     Tab: () => <div style={tableStyle as any}>决策链地图</div>,
            //     Content: () => <DecisionChainMap />,
            //     // hidden: !['1'].includes(emphasisProject)
            // },
            // {
            //     Tab: () => <div style={tableStyle as any}>商务地图</div>,
            //     Content: () => <BusinessMap />,
            //     // hidden: !['1'].includes(emphasisProject)
            // },
            // {
            //     Tab: () => <div style={tableStyle as any}>业务地图</div>,
            //     Content: () => <ServiceMap />,
            //     // hidden: !['1'].includes(emphasisProject)
            // },
        ]
    };

    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        if (type === '添加协同人') {
            getDetailApi({businessOppId: id, customerId})
        }
    }

    useEffect(() => {
        getDetailApi({businessOppId: id, customerId})
    }, [id])
    return <div className="businessMain">
        <div style={{boxSizing: 'border-box'}}>
            <TableExtraRender operate={operate} dataObj={dataObj as DataObj} />
            <MyTabs tabPanes={mainTabPans({businessOppId: id, dataObj})} type="card" />
        </div>
    </div>
}
