import { useTableHooks } from "@hooks/index"
import { Button, Col, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { businessRecordColumnsFn } from '../../data'
import { customerBusinessList } from '@api/index'
import { productApi } from "@utils/util"
import { EnterpriseDetailProps } from "../../type"
import { stringify } from "qs"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { AddFollowRecord, AddFollowRecordProps } from "@components/Customer/List"

const getApi =  productApi(customerBusinessList)

export default ({id: customerId, name, readOnly, ...rest}: EnterpriseDetailProps) => {
    // const {tableProps, fetchApi } = useTableHooks(customerBusinessList, {customerId, ...rest})
    const ref = useRef<ActionType>(null);
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId, customerName: name, businessOppId: undefined})
    
    const operate = (type: string, record: any) => {
        const { businessOppId } = record || {}
        if (type === '新建跟进') {
            setAddFollowProps({
                ...addFollowProps,
                title: type,
                visible: true,
                customerId,
                customerName: name,
                businessOppId,
                request: undefined,
                onSuccess: () => {
                    reFush()
                    setAddFollowProps({...addFollowProps, visible: false})
                    return true
                }
            })
        }
    }

    const columns = readOnly ? businessRecordColumnsFn(operate).filter(({readOnly}) => !readOnly) : businessRecordColumnsFn(operate)

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await customerBusinessList({...params, sorter}) as any
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
        <ProTable<any>
            request={getListApi}
            scroll={{x: 2500}}
            params={{customerId, ...rest}}
            columns={columns}
            search={false}
            actionRef={ref as any}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    !readOnly ? <Button type="primary"><Link to={`/customer/businessAdd?${stringify({customerName: name, customerId, fromPath: '/customer/list'})}`}>新建商机</Link></Button> : null
                ],
            }}
            rowKey="businessOppId"
        />
        <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false, destroyOnClose: true}} />
    </div>)
}
