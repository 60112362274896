import React, { ReactNode, useMemo, useState } from "react";
import { TreeSelect, Button, Select, Checkbox, Form, Row, Col, Tag, Tooltip, DatePicker, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { BASEURL, LQBASEURL, queryDeptTreeData } from "@api/index";
import { useCommonApiHooks, useHasAuthCode, useLqSearchDataHooks } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData, treeDataToFlatArr } from "@utils/index";
import { Double, Icon, SearchBox } from "@components/Common";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";

const { RangePicker } = DatePicker;

const Label = (label: ReactNode) => <span style={{display: 'inline-block', width: 70}}>{label}</span>

const OperateStatusToolTip = () => (<div>
    1.存续（在营、开业、在业）: 一般是指企业依法存在并继续正常运营。<br/>
    2.吊销: 一般是指公司登记主管机关根据有关法律法规的规定，对有严重违法违规行为的企业依法以强制手段剥夺其经营资格的一种行政处罚。<br/>
    3.注销: 一般是指企业自行通过法定流程申请注销营业执照以终止公司法人资格。 <br/>
    4.停业: 一般是指由某种原因，企业在期末处于停止生产经营活动待条件改变后仍恢复生产。包括自行停业和责令停产停业。 <br/>
    5.迁入: 一般是指企业登记主管机关的变更，迁入新主管机关。 <br/>
    6.迁出: 一般指企业登记主管机关的变更，迁出新主管机关。<br/>
    7.清算: 一般是指企业按章程规定解散以及由于破产、被吊销等其他原因宣布终止经营后，对企业的财产、债权、债务进行全面清查，并进行收取债权，清偿债务和分配剩余财产的经济活动。
</div>)

export type TableListItem = {
    key: number;
    id: string;
    lqId: string;
    customerName?: string;
    companyName: string;
    businessStatus: string;
    registDate: string;
    leaderPerson: string;
    registCapital: string;
    companyAdress: string;
    isReceive: 0 | 1;
};

export type OperateActionType = '新建线索池' | '编辑' | '删除' 
type LqCompanyTableRenderProps = {
    hasAuth?: boolean;
    dataObj?: TableListItem;
    search?: Record<string, any>;
  }
export const LqCompanyTableRender = ({dataObj, search}: LqCompanyTableRenderProps) => {
    const [, hasAuthCode] = useHasAuthCode()
    const history = useHistory()
    const {companyName, businessStatus, registDate, leaderPerson, registCapital, companyAdress} = dataObj || {}
    const linkTo = (e: React.MouseEvent) => {
        if (true) { // 权限先开放
            const url = `/lq/companyDetail?${stringify(search||{})}`
            e.ctrlKey ? window.open(url, '_blank') : history.push(url)
        } else {
            message.warning('暂无此权限,请联系管理员!')
        }
    }
    return (<Row gutter={24}>
        <Col span={24} style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
            <a style={{fontSize: '18px'}} onClick={linkTo}>{companyName||''}</a> &nbsp; {businessStatus ? <Tag color="orange">{businessStatus}</Tag> : null}
        </Col>
        <Col span={6}><span style={{color: '#888'}}>法人代表: </span>{leaderPerson}</Col>
        <Col span={8}><span style={{color: '#888'}}>成立日期: </span>{registDate} </Col>
        <Col span={10}><span style={{color: '#888'}}>注册资本: </span>{registCapital}</Col>
        <Col span={24} style={{marginTop: '8px'}}><span style={{color: '#888'}}>经营地址: </span>{companyAdress}</Col>
    </Row>)
}

export const columnsFn: (search: object, operate?: Function) => ProColumns<TableListItem>[] = (search = {}, operate) => {
  return [
    {
        title: '企业信息',
        dataIndex: 'cluePoolName',
        hideInSearch: true,
        fixed: 'left',
        order: 100,
        width: 520,
        render: (text: any,record: any) => <LqCompanyTableRender dataObj={record} search={{name: record.companyName, lqId: record.id, type: 1, module: 1, routeName: '企业详情', fromPath: '/lq/search', ...search}} />
    },
    {
        title: '客户来源',
        hideInSearch: true,
        dataIndex: 'companySource',
    },
    {
        title: '云服务供应商',
        hideInSearch: true,
        dataIndex: 'cloudServiceProvider',
    },
    {
        title: '所属行业',
        hideInSearch: true,
        dataIndex: 'companyIndustry',
    },
    {
        title: '参保人数',
        hideInSearch: true,
        dataIndex: 'companyCbzz',
        sorter: true
    },
    {
        title: '有无ICP备案',
        hideInSearch: true,
        dataIndex: 'isIcp',
        render: (text: any, record: any) => text ? '有' : '无'
    },
    {
        title: '高新技术企业',
        hideInSearch: true,
        dataIndex: 'isTechnology',
        render: (text: any, record: any) => text ? '是' : '否'
    },
    {
        title: '有无公安备案',
        hideInSearch: true,
        dataIndex: 'isRecordno',
        render: (text: any, record: any) => text ? '有' : '无'
    },
    {
        title: '最新招聘时间',
        hideInSearch: true,
        dataIndex: 'positionTime',
        sorter: true,
    },
    {
        title: '领取状态',
        hideInSearch: true,
        dataIndex: 'bz',
        fixed: 'right',
        width: 100,
        render: (text: any, {bz}: any) => bz
    }
  ]
}

export const sortOptions = [
    {
        label: '发布时间降序',
        value: 'public-desc'
    },
    {
        label: '发布时间升序',
        value: 'public-asc'
    },
    {
        label: '成立时间降序',
        value: 'setUp-desc'
    },
    {
        label: '成立时间升序',
        value: 'setUp-asc'
    },
    {
        label: '注册资本降序',
        value: 'registerCapital-desc'
    },
    {
        label: '注册资本升序',
        value: 'registerCapital-asc'
    }
]

export const searchTypeEnums = [
    {
        label: '智能搜索',
        value: 1,
        placeholder: '请输入企业名、公司简介、产品名、经营范围等任何关键词，多个关键词用空格隔开，如:“杭州 卓见云”',
    },
    {
        label: '企业名',
        value: 2,
        placeholder: '请输入企业名、曾用名',
    },
    {
        label: '产品',
        value: 3,
        placeholder: '请输入产品、APP名称、竞品名称',
    },
    {
        label: '招聘',
        value: 4,
        placeholder: '请输入职位名称',
    },
    {
        label: '地址',
        value: 5,
        placeholder: '请输入地址或地区',
    },
    {
        label: '经营范围',
        value: 6,
        placeholder: '请输入主营业务、经营范围、公司简介等关键词',
    },
    {
        label: '联系方式',
        value: 7,
        placeholder: '请输入联系方式，包括：手机号、固话、邮箱、微信、钉钉',
    },
    {
        label: '人员',
        value: 8,
        placeholder: '请输入法人、股东、高管等主要人员完整姓名',
    },
]

const multipleSelectProps = (placeholder: string) => ({
    mode: 'multiple',
    showArrow: true, 
    maxTagCount: 0,
    maxTagPlaceholder: () => placeholder
})

export const OtherConditions = () => {
    const { lqSearchConditionData } = useSelector((state: StateType) => state)
    const { receiveStatus, contactWay, contactWaySource, personScale, labelMessage, patentInformation, officialWebsiteMessage, highTechEnterprise, biddingInvestment,
        workCopyRight, softCopyRight, icp, websiteDetection, publicSecurityRecord, cloudServiceProvider, qualificationCertificate, jobInformation, cooperationClient, 
        productInformation, customerSource
    } = lqSearchConditionData;

    return [
        {
            text: '领取状态',
            name: 'receiveStatus',
            rules: [],
            colProps: { span: 4, },
            options: receiveStatus,
            Item: (props: any) => <Select placeholder="领取状态" allowClear showArrow bordered={false} options={receiveStatus} {...props} />
        },
        {
            text: '联系方式',
            name: 'contactWay',
            rules: [],
            colProps: { span: 4 },
            options: contactWay,
            Item: (props: any) => <Select placeholder="联系方式" allowClear bordered={false} options={contactWay} {...multipleSelectProps('联系方式')} {...props} />
        },
        {
            text: '号码来源',
            name: 'contactWaySource',
            rules: [],
            colProps: { span: 4 },
            options: contactWaySource,
            Item: (props: any) => <Select placeholder="号码来源" allowClear bordered={false} options={contactWaySource} {...multipleSelectProps('号码来源')} {...props} />
        },
        {
            text: '人员规模',
            name: 'personScale',
            rules: [],
            colProps: { span: 4 },
            options: personScale,
            Item: (props: any) => <Select placeholder="人员规模" allowClear bordered={false} options={personScale} {...multipleSelectProps('人员规模')} {...props} />
        },
        {
            text: '商标信息',
            name: 'labelMessage',
            rules: [],
            colProps: { span: 4 },
            options: labelMessage,
            Item: (props: any) => <Select placeholder="商标信息" allowClear bordered={false} options={labelMessage} {...props} />
        },
        {
            text: '专利信息',
            name: 'patentInformation',
            rules: [],
            colProps: { span: 4 },
            options: patentInformation,
            Item: (props: any) => <Select placeholder="专利信息" allowClear bordered={false} options={patentInformation} {...props} />
        },
        {
            text: '官网信息',
            name: 'officialWebsiteMessage',
            rules: [],
            colProps: { span: 4 },
            options: officialWebsiteMessage,
            Item: (props: any) => <Select placeholder="官网信息" allowClear bordered={false} options={officialWebsiteMessage} {...props} />
        },
        {
            text: '高新企业',
            name: 'highTechEnterprise',
            rules: [],
            colProps: { span: 4 },
            options: highTechEnterprise,
            Item: (props: any) => <Select placeholder="高新企业" allowClear bordered={false} options={highTechEnterprise} {...props} />
        },
        {
            text: '招投标',
            name: 'biddingInvestment',
            rules: [],
            colProps: { span: 4 },
            options: biddingInvestment,
            Item: (props: any) => <Select placeholder="招投标" allowClear bordered={false} options={biddingInvestment} {...props} />
        },
        {
            text: '作品著作权',
            name: 'workCopyRight',
            rules: [],
            colProps: { span: 4 },
            options: workCopyRight,
            Item: (props: any) => <Select placeholder="作品著作权" allowClear bordered={false} options={workCopyRight} {...props} />
        },
        {
            text: '软件著作权',
            name: 'softCopyRight',
            rules: [],
            colProps: { span: 4 },
            options: softCopyRight,
            Item: (props: any) => <Select placeholder="软件著作权" allowClear bordered={false} options={softCopyRight} {...props} />
        },
        {
            text: 'ICP备案',
            name: 'icp',
            rules: [],
            colProps: { span: 4 },
            options: icp,
            Item: (props: any) => <Select placeholder="ICP备案" allowClear bordered={false} options={icp} {...props} />
        },
        {
            text: '网站检测',
            name: 'websiteDetection',
            rules: [],
            colProps: { span: 4 },
            options: websiteDetection,
            Item: (props: any) => <Select placeholder="网站检测" allowClear bordered={false} options={websiteDetection} {...props} />
        },
        {
            text: '公安备案',
            name: 'publicSecurityRecord',
            rules: [],
            colProps: { span: 4 },
            options: publicSecurityRecord,
            Item: (props: any) => <Select placeholder="公安备案" allowClear bordered={false} options={publicSecurityRecord} {...props} />
        },
        {
            text: '云服务供应商',
            name: 'cloudServiceProvider',
            rules: [],
            colProps: { span: 4 },
            options: cloudServiceProvider,
            Item: (props: any) => <Select placeholder="云服务供应商" allowClear bordered={false} options={cloudServiceProvider} {...multipleSelectProps('云服务供应商')} {...props} />
        },
        {
            text: '资质证书',
            name: 'qualificationCertificate',
            rules: [],
            colProps: { span: 4 },
            options: qualificationCertificate,
            Item: (props: any) => <Select placeholder="资质证书" allowClear bordered={false} options={qualificationCertificate} {...props} />
        },
        {
            text: '招聘信息',
            name: 'jobInformation',
            rules: [],
            colProps: { span: 4 },
            options: jobInformation,
            Item: (props: any) => <Select placeholder="招聘信息" allowClear bordered={false} options={jobInformation} {...props} />
        },
        {
            text: '合作客户',
            name: 'cooperationClient',
            rules: [],
            colProps: { span: 4 },
            options: cooperationClient,
            Item: (props: any) => <Select placeholder="合作客户" allowClear bordered={false} options={cooperationClient} {...props} />
        },
        {
            text: '产品信息',
            name: 'productInformation',
            rules: [],
            colProps: { span: 4 },
            options: productInformation,
            Item: (props: any) => <Select placeholder="产品信息" allowClear bordered={false} options={productInformation} {...multipleSelectProps('产品信息')} {...props} />
        },
        {
            text: '客户来源',
            name: 'customerSource',
            rules: [],
            colProps: { span: 4 },
            options: customerSource,
            Item: (props: any) => <Select placeholder="客户来源" allowClear bordered={false} options={customerSource} {...props} />
        },
    ]
}

const FormItemDateRange = ({ value: pValue, onChange, ...rest}: any) => {
    const [value, setValue] = useState(pValue)
    const onClick = () => { onChange && onChange(value)}
    return <><RangePicker size="small" value={value} onChange={setValue} {...rest} style={{marginRight: 1}} /><Button size="small" onClick={onClick}>确定</Button></>
}

const FormItemDouble = ({ value: pValue, onChange, ...rest}: any) => {
    const [value, setValue] = useState(pValue)
    const onClick = () => { onChange && onChange(value)}
    return <><Double props1={{size: 'small'}} props2={{size: 'small'}} style={{width: '140px', display: 'inline-block'}} value={value} onChange={setValue} {...rest} /><Button size="small" onClick={onClick}>确定</Button></>
}


export const searchConfig = () => {
    const [labelTreeData] = useCommonApiHooks({url: `${LQBASEURL}/enterpriseSearch/hy`, method: 'POST'}, {}, true)
    const { cityTreeData, lqSearchConditionData } = useSelector((state: StateType) => state)
    const { operateStatus, foundYears, registerCapital, enterpriseType, organizationType, insurePersons, financeStage, employTime } = lqSearchConditionData
    return [
        {
            label: Label('所属地区'),
            text: '所属地区',
            name: 'area',
            rules: [],
            colProps: { span: 24 },
            labelCol: {span: 4},
            options: treeDataToFlatArr(cityTreeData as any),
            Item: useMemo(() => (props: any) => {
                return <TreeSelect 
                    showArrow={true} 
                    allowClear={true} 
                    placeholder="请选择地区" 
                    treeData={cityTreeData} 
                    maxTagCount={2} 
                    treeCheckable 
                    showSearch 
                    treeNodeFilterProp='title' 
                    style={{width: '20%'}} 
                    bordered={false} 
                    {...props}
                />
            }, [])
        },
        {
            label: Label('所属行业'),
            text: '所属行业',
            name: 'hy',
            rules: [],
            colProps: { span: 24 },
            options: treeDataToFlatArr(transferTreeData((labelTreeData || []) as any)),
            Item: useMemo(() => (props: any) => <TreeSelect showArrow={true} allowClear={true} placeholder="请选择行业" treeData={labelTreeData} maxTagCount={2} treeCheckable showSearch treeNodeFilterProp='title' style={{width: '20%'}} bordered={false} {...props} />, [labelTreeData]) 
    },
        {
            label: Label(<>经营状态&nbsp;<Tooltip title={<OperateStatusToolTip />}><Icon type="icon-ziyuan"></Icon></Tooltip></>),
            text: '经营状态',
            name: 'operateStatus',
            rules: [],
            colProps: { span: 24 },
            options: operateStatus,
            Item: (props: any) => <Checkbox.Group options={operateStatus} {...props} />
        },
        {
            label: Label('成立时间'),
            text: '成立时间',
            name: 'foundYears',
            rules: [],
            colProps: { span: 24 },
            options: foundYears,
            Item: useMemo(() => (props: any) => {
                return(<Row gutter={24}>
                    <Col><Form.Item name="foundYears" style={{marginRight: 0}}><Checkbox.Group options={foundYears} /></Form.Item></Col>
                    <Col style={{display: 'inline-flex', alignItems: 'center', paddingLeft: 0}}>自定义
                        <Form.Item name="cust-foundYears" style={{marginRight: 0}}>
                            <FormItemDateRange />
                        </Form.Item>
                    </Col>
                </Row>)
            }, [])
        },
        {
            label: Label('招聘发布'),
            text: '招聘发布',
            name: 'employTime',
            rules: [],
            colProps: { span: 24 },
            options: employTime,
            Item: useMemo( () => (props: any) => {
                return(<Row gutter={24}>
                    <Col><Form.Item name="employTime" style={{marginRight: 0}}><Checkbox.Group options={employTime||[]} /></Form.Item></Col>
                    <Col style={{display: 'inline-flex', alignItems: 'center', paddingLeft: 0}}>自定义
                        <Form.Item name="cust-employTime" style={{marginRight: 0}}>
                            <FormItemDateRange />
                        </Form.Item>
                    </Col>
                </Row>)
            }, [])
        },
        {
            label: Label('注册资本'),
            text: '注册资本',
            name: 'registerCapital',
            rules: [],
            colProps: { span: 24 },
            options: registerCapital,
            Item: useMemo(() => (props: any) => {
                return(<Row gutter={24}>
                    <Col><Form.Item name="registerCapital" style={{marginRight: 0}}><Checkbox.Group options={registerCapital} /></Form.Item></Col>
                    <Col style={{display: 'inline-flex', alignItems: 'center', paddingLeft: 0}}>自定义
                        <Form.Item name="cust-registerCapital" style={{marginRight: 0}}>
                            <FormItemDouble/>
                        </Form.Item>
                    </Col>
                </Row>)
            }, [])
        },
        {
            label: Label('企业类型'),
            text: '企业类型',
            name: 'enterpriseType',
            rules: [],
            colProps: { span: 24 },
            options: enterpriseType,
            Item: (props: any) => <Checkbox.Group options={enterpriseType} {...props} />
        },
        {
            label: Label('机构类型'),
            text: '机构类型',
            name: 'organizationType',
            rules: [],
            colProps: { span: 24 },
            options: organizationType,
            Item: (props: any) => <Checkbox.Group options={organizationType} {...props} />
        },
        {
            label: Label('参保人数'),
            text: '参保人数',
            name: 'insurePersons',
            rules: [],
            colProps: { span: 24 },
            options: insurePersons,
            Item:useMemo(() => (props: any) => {
                return(<Row gutter={24}>
                    <Col><Form.Item name="insurePersons" style={{marginRight: 0}}><Checkbox.Group options={insurePersons} /></Form.Item></Col>
                    <Col style={{display: 'inline-flex', alignItems: 'center', paddingLeft: 0}}>自定义
                        <Form.Item name="cust-insurePersons" style={{marginRight: 0}}>
                            <FormItemDouble />
                        </Form.Item>
                    </Col>
                </Row>)
            }, [])
        },
        {
            label: Label('融资阶段'),
            text: '融资阶段',
            name: 'financeStage',
            rules: [],
            colProps: { span: 24 },
            options: financeStage,
            Item: (props: any) => <Checkbox.Group options={financeStage} {...props} />
        },
        {
            label: Label('其他筛选'),
            text: '',
            name: 'others',
            rules: [],
            colProps: { span: 24 },
            options: [],
            Item: (props: any) => {
                return (<Row gutter={24} className="other-conditions">
                        {
                            OtherConditions().map(v => <Col key={v.name} {...v.colProps}>
                                <Form.Item name={v.name} style={{marginRight: 0}}>
                                    <v.Item></v.Item>
                                </Form.Item>
                            </Col>)
                        }
                    </Row>)
            }
        },
    ]
}