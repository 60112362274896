
import React, { useEffect, useRef } from "react"
import { Icon } from "@components/index"
import { Button, Form, Input, Modal, Table, Tabs, Tooltip, message } from "antd"
import { columnsFn1, columnsFn2, columnsFn3, ActionType, dataSource2Item, dataSource3Item, reportColumns1, reportColumns2 } from './data'
import { sysRuleBackSave, sysRuleBackIsUse, sysRuleAutoList, sysRuleBackList, sysRuleAutoDel, sysRuleAutoIsUse, sysRuleAutoIsUseClose, sysRuleAutoSaveUpdata, sysRuleAutoSaveAdd, sysRuleCustList, sysRuleCustDel, sysRuleCustIsUse,sysRuleCustIsUseClosecust, sysRuleCustSaveAdd, sysRuleCustSaveUpdata, sysRuleAuditReportAddUpdate, sysRuleAuditReportDel, sysRuleSumReportDel, sysRuleSumReportAddUpdate, sysRuleGetAuditReport, sysRuleGetSumReport } from '@api/index'
import { productApi, fromData, isPlainDef } from "@utils/util"
import { useHasAuthCode, useTableHooks } from "@hooks/index"
import { IssueTooltip } from "@components/Common/Icon"
import { EditableProTable } from "@ant-design/pro-table"
import type { ProFormInstance } from '@ant-design/pro-form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ContractAuditRule, RebackAuditRule } from './cpnts'

type OperateActionType = '领回-启用禁用' | '领回-保存' | '领回-修改' | '划入-启用禁用' | '划入-删除' | '划入-添加' | '划入-保存' | '划入-修改' | '数量-启用禁用' | '数量-删除' | '数量-添加' | '数量-修改' | '数量-保存' | '报告审批-删除' | '报告统计-删除' | '报告审批-保存' | '报告统计-保存'

const { TabPane } = Tabs
const backSaveApi = productApi(sysRuleBackSave)
const backUseApi = productApi(sysRuleBackIsUse)
const backListApi = productApi(sysRuleBackList)
const autoListApi = productApi(sysRuleAutoList)
const autoDelApi = productApi(sysRuleAutoDel)
const autoSaveApiAdd = productApi(sysRuleAutoSaveAdd)
const autoSaveApiUpdata = productApi(sysRuleAutoSaveUpdata)
const autoUseApi = productApi(sysRuleAutoIsUse)
const autoUseApiClose = productApi(sysRuleAutoIsUseClose)

const custListApi = productApi(sysRuleCustList)
const custDelApi = productApi(sysRuleCustDel)
const custUseApi = productApi(sysRuleCustIsUse)
const custUseApiClose = productApi(sysRuleCustIsUseClosecust)

const custSaveApi = productApi(sysRuleCustSaveAdd)
const custSaveApiUpdata = productApi(sysRuleCustSaveUpdata)

const getAuditReportApi = productApi(sysRuleGetAuditReport)
const getSumReportApi = productApi(sysRuleGetSumReport)

const auditReportAddUpdateApi = productApi(sysRuleAuditReportAddUpdate)
const auditReportDelApi = productApi(sysRuleAuditReportDel)
const sumReportDelApi = productApi(sysRuleSumReportDel)
const sumReportAddUpdateApi = productApi(sysRuleSumReportAddUpdate)

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};

const AuditForm = () => {
    return (<Form 
        {...formItemLayoutWithOutLabel}
        >
        <Form.List
            name="names"
            rules={[
            {
                validator: async (_, names) => {
                if (!names || names.length < 2) {
                    return Promise.reject(new Error('最少一个审批人'));
                }
                },
            },
            ]}
        >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...formItemLayout}
                label={`${index + 1}级审批人`}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "请选择审批人",
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="请选择" style={{ width: '60%' }} />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                添加审批层级
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
        </Form.List>
    </Form>)
}

export default () => {
    const {tableProps: {dataSource: dataSource1}, setDataSource: setDataSource1, fetchApi: backFetchApi } = useTableHooks(backListApi, {}, false)
    const {tableProps: {dataSource: dataSource2}, setDataSource: setDataSource2, fetchApi: autoFetchApi } = useTableHooks(autoListApi)
    const {tableProps: {dataSource: dataSource3}, setDataSource: setDataSource3, fetchApi: custFetchApi } = useTableHooks(custListApi)

    const {tableProps: {dataSource: dataSource4}, setDataSource: setDataSource4, fetchApi: dataSource4FetchApi } = useTableHooks(getAuditReportApi)
    const {tableProps: {dataSource: dataSource5}, setDataSource: setDataSource5, fetchApi: dataSource5FetchApi } = useTableHooks(getSumReportApi)
    const [, hasAuthCode] = useHasAuthCode()

    const operate = async (type: OperateActionType, record?: any, idx?: number) => {
        const { id } = record
        if (type === '领回-保存') {
            const { rule } = record || {}
            if (rule === null) {
                message.warning('请把信息填写完整');
                return
            }
            await backSaveApi(fromData({rule}), true, () => {
                backFetchApi({}, (data: any) => ({values: [data], total: 1}))
            })
        } else if (type === '领回-启用禁用') {
            const { status } = record || {}
            await backUseApi(fromData({status}), true, () => {
                backFetchApi({}, (data: any) => ({values: [data], total: 1}))
            })
        } else if (type === '划入-添加') {
            setDataSource2([...dataSource2, dataSource2Item()])
        } else if (type === '划入-删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    if (id) {
                        await autoDelApi(fromData({autoRuleId: id}), true, () => {
                            autoFetchApi({})
                        })
                    } else { 
                        dataSource2.splice(idx as number, 1)
                        setDataSource2([...dataSource2])
                    }
                },
            });
        } else if (type === '划入-保存') {
            const { id, rule, roleIds, time: salespersonReminderTime, days: salespersonReminderDay, custType: customerType, suitSysScopeId: systemScope, autoTransferTime } = record || {}
            if (!isPlainDef(salespersonReminderTime) || !isPlainDef(autoTransferTime) || record.custType.length === 0) {
                return message.warning('请把信息填写完整');
            } else if (id) {
                await autoSaveApiUpdata(
                    {id,
                    rule,
                    roleIds,
                    salespersonReminderTime,
                    salespersonReminderDay,
                    customerType,
                    systemScope,
                    autoTransferTime
                    }, true, () => {
                        autoFetchApi({})
                })
            } else { 
                await autoSaveApiAdd(
                    {rule,
                    roleIds,
                    salespersonReminderTime,
                    salespersonReminderDay,
                    customerType,
                    systemScope,
                    autoTransferTime
                }, true, () => {
                    autoFetchApi({})
                })
            }
        } else if (type === '划入-启用禁用') {
            const { id, poolSettingStatus} = record || {}
            if (id === undefined) {
                message.warning('请先保存当前信息后操作');
            } else if (poolSettingStatus === '0') {
                await autoUseApi(fromData({autoRuleId: id}), true, () => {
                    autoFetchApi({})
                })
            } else {
                await autoUseApiClose(fromData({autoRuleId: id}), true, () => {
                    autoFetchApi({})
                })
            } 
        } else if (type === '数量-保存') {
            const { roleIds = [], suitSysScopeId:suitScope, rule:custRule = '' } = record || {}
            if (!isPlainDef(custRule) || !isPlainDef(suitScope) || roleIds.length === 0) { 
                return message.warning('请把信息填写完整');
            } else if (id) {
                await custSaveApiUpdata({ cusId: id, roleIds, suitScope, custRule}, true, () => {
                    custFetchApi({})
                })
            } else { 
                await custSaveApi({ roleIds, suitScope, custRule}, true, () => {
                    custFetchApi({})
                })
            }
        } else if (type === '领回-修改') {
            record.editState = true
            setDataSource1([...dataSource1])
        } else if (type === '划入-修改') {
            record.editState = true
            setDataSource2([...dataSource2])
        } else if (type === '数量-修改') {
            record.editState = true
            setDataSource3([...dataSource3])
        } else if (type === '数量-删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    if (id) {
                        await custDelApi(fromData({cusRuleId: id}), true, () => {
                            custFetchApi({})
                        })
                    } else {
                        dataSource3.splice(idx as number, 1)
                        setDataSource3([...dataSource3])
                    }
                },
            });
        } else if (type === '数量-启用禁用') {
            const { id, status} = record || {}
            if (id === undefined) {
                message.warning('请先保存当前信息后操作');
            } else if (status === '0') {
                await custUseApi(fromData({cusRuleId: id}), true, () => {
                    custFetchApi({})
                })
            } else {
                await custUseApiClose(fromData({cusRuleId: id}), true, () => {
                    custFetchApi({})
                })
            } 
        } else if (type === '数量-添加') {
            setDataSource3([...dataSource3, dataSource3Item()])
        } else if (type === '报告审批-删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: () => auditReportDelApi({ ids: [id] }, true, () => {
                    setDataSource4(dataSource4.filter(v => v.id !== id))
                }),
            });
        } else if (type === '报告统计-删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: () => sumReportDelApi({ ids: [id] }, true, () => {
                    setDataSource5(dataSource5.filter(v => v.id != id))
                }),
            });
        }
    }

    const dataSourceChange = (type: ActionType, newRecord: never, idx: number) => {
        if (type === 'dataSource1') {
            dataSource1[idx] = newRecord
            setDataSource1([...dataSource1])
        } else if (type === 'dataSource2') {
            dataSource2[idx] = newRecord
            setDataSource2([...dataSource2])
        } else if (type === 'dataSource3') {
            dataSource3[idx] = newRecord
            setDataSource3([...dataSource3])
        }
    }

    const columns1 = columnsFn1(operate, dataSourceChange)
    const columns2 = columnsFn2(operate, dataSourceChange)
    const columns3 = columnsFn3(operate, dataSourceChange)

    useEffect(() => {
        backFetchApi({}, (data: any) => ({values: [data], total: 1}))
    }, [])

    return <div style={{background: '#fff', boxSizing: 'border-box', padding: '10px 20px'}}>
        <Tabs defaultActiveKey="1">
            <TabPane tab="线索池规则" key="1">
                {
                    hasAuthCode(2146) ? <>
                        <div className="jns-ban-title">领回规则</div>
                        <div style={{borderRadius: '4px', marginTop: '12px', border: '1px solid #E8E8E8', boxSizing: 'border-box', padding: '16px 20px'}}>
                            <div>客户领回限制 <Tooltip placement="top" title="客户被转移或系统定时划入线索池后，前负责人在N天内不能领回"><Icon type="icon-ziyuan" /></Tooltip></div>
                            <Table columns={columns1} dataSource={dataSource1} size="small" pagination={false} bordered  style={{marginTop: '12px'}} />
                        </div>
                    </> : null
                }
                {
                    hasAuthCode(2137) ? <>
                        <div className="jns-ban-title" style={{marginTop:'24px'}}>自动划入规则</div>
                        <div style={{borderRadius: '4px', marginTop: '12px', border: '1px solid #E8E8E8', boxSizing: 'border-box', padding: '16px 20px'}}>
                            <div>{`超过N天"未跟进"的客户，由系统定时划入线索池`} <Tooltip placement="top" title={`规则：超过N天"未跟进"的客户，由系统定时划入线索池；\n 跟进说明：从获得客户（新增、转移、领取客户）开始，对客户创建了跟进记录视为跟进了客户。`}><Icon type="icon-ziyuan" /></Tooltip></div>
                            <Table columns={columns2} dataSource={dataSource2} size="small" pagination={false} bordered style={{marginTop: '12px'}} />
                        </div>
                    </> : null
                }
            </TabPane>
            <TabPane tab="客户数量规则" key="2">
                {
                    hasAuthCode(2143) ? <>
                        <div className="jns-ban-title" style={{marginTop:'24px'}}>限制每个员工拥有的最大客户数</div>
                            <div style={{borderRadius: '4px', marginTop: '12px', border: '1px solid #E8E8E8', boxSizing: 'border-box', padding: '16px 20px'}}>
                            <div>1. 当员工拥有客户数达到规定上限时，新增客户无法成功</div>
                            <div>2. 如果在设置最大客户数前，员工所拥有客户数已超过上限，则原有客户不会影响，但是该员工无法再继续添加新客户</div>
                            <Table columns={columns3} dataSource={dataSource3} size="small" pagination={false} bordered style={{marginTop: '12px'}} />
                        </div>
                    </> : null
                }
            </TabPane>
            <TabPane tab="工作报告规则" key="3">
                <>
                   <div className="jns-ban-title">审批规则 <IssueTooltip title="设置指定角色的负责人审批指定角色的下属的报告（每个部门负责人只能审批当前部门及下级部门人员的报告）" /></div>
                   <EditableProTable 
                       columns={reportColumns1(operate)} 
                       value={dataSource4} 
                       onChange={setDataSource4}
                       controlled
                       size="small" 
                       rowKey="id"
                       pagination={false} 
                       bordered  
                       style={{marginTop: '12px'}} 
                       recordCreatorProps={{
                          newRecordType: 'dataSource',
                          record: (index) => {
                            return { id: `zqs-${index}` };
                          },
                       }}
                       editable={{
                          type: 'single',
                          actionRender: (row, config, defaultDom) => [
                            hasAuthCode(2434) ? <a key="save" onClick={async () => {
                                const { form, recordKey } = config
                                const record = form.getFieldValue(recordKey)
                                await auditReportAddUpdateApi({...record, id: (recordKey+'').startsWith('zqs-') ? undefined : recordKey}, true, () => {
                                    config?.cancelEditable(recordKey)
                                    dataSource4FetchApi()
                                })
                            }}>保存</a> : null
                          ],
                       }}
                    />

                   <div className="jns-ban-title" style={{marginTop:'24px'}}>报告统计规则 <IssueTooltip title="设置统计指定角色的人员的报告，以及是否统计周末" /></div>
                   <EditableProTable 
                       columns={reportColumns2(operate)} 
                       value={dataSource5} 
                       onChange={setDataSource5}
                       recordCreatorProps={{
                          newRecordType: 'dataSource',
                          record: (index) => {
                            return { id: `zqs-${index}` };
                          },
                       }}
                       editable={{
                        type: 'single',
                        actionRender: (row, config, defaultDom) => [
                            hasAuthCode(2431) ? <a key="save" onClick={async () => {
                                const { form, recordKey } = config
                                const record = form.getFieldValue(recordKey)
                                await sumReportAddUpdateApi({...record, id: (recordKey+'').startsWith('zqs-') ? undefined : recordKey}, true, () => {
                                    config?.cancelEditable(recordKey)
                                    dataSource5FetchApi()
                                })
                            }}>保存</a> : null
                        ],
                      }}
                       size="small" 
                       rowKey="id"
                       pagination={false} 
                       bordered  
                       style={{marginTop: '12px'}} 
                    />
                </>
            </TabPane>
            <TabPane tab="账务审批设置" key="4">
                <div className="jns-ban-title">合同审批层级设置 <IssueTooltip title="如果有待审批的合同，不允许添加、修改、删除审批规则。" /></div>
                <div style={{marginTop: 20}}>
                    <ContractAuditRule />
                </div>
                <div className="jns-ban-title">回款审批层级设置 <IssueTooltip title="如果有待审批的回款，不允许添加、修改、删除审批规则。" /></div>
                <div style={{marginTop: 20}}>
                    <RebackAuditRule />
                </div>
            </TabPane>
        </Tabs>
    </div>
}