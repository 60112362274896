import ProTable, { ActionType } from "@ant-design/pro-table";
import { Button, Modal, Popconfirm, message } from "antd";
import React, { useRef, useState, useEffect } from "react"
import { columnsFn, OperateActionType, TableListItem } from "./data";
import { Icon, ProFormTreeSelect, WithSearchTree } from '@components/index'
import { WithSearchTreeWarp } from "@components/Common/WithSearchTree";
import ProForm, { ModalForm, ProFormText, ProFormCascader, ProFormSelect, ProFormTextArea, ProFormRadio, ProFormDatePicker } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { useDeptDataHooks, useDictEnumHooks, useHasAuthCode, useRoleListHooks } from "@hooks/index";
import { userListByDeptId, deptAdd, deptUpdata, deptDel, checkIsMember, userAdd, getUserUpdate, userUpdate, userDel, getNewPwd, resetUserPwd, userStatusUpdate } from '@api/index'
import { productApi, fromData, emailReg, toFormData,  phoneReg, userNameReg, treeDataDisabledRules  } from "@utils/util";
import copy from 'copy-to-clipboard';
import './index.less'
import { useDispatch, useSelector } from "react-redux";
import { asyncSetDeptWithUserTreeData } from "@store/actions";
import { StateType } from "@store/index";
const userListApi = productApi(userListByDeptId)
const userAddApi = productApi(userAdd)
const userUpdateApi = productApi(userUpdate)
const userDelApi = productApi(userDel)
const userGetUserInfoApi = productApi(getUserUpdate)
const useResetUserPwd = productApi(resetUserPwd)

const userDeptNew = productApi(deptAdd)
const userDeptEdit = productApi(deptUpdata)
const userDeptDel = productApi(deptDel)
const userCheckIsMember = productApi(checkIsMember)


const userStatusUpdateApi = productApi(userStatusUpdate)

export interface AddDepartmentStaffProps extends ModalFormProps{
    params?: Record<string, any>; // 添加时 { deptId }; 编辑时: { id }
    onSuccess?: () => void;
}
interface AddDepartmentProps extends ModalFormProps{
    params?: Record<string, any>; // 添加时 { parentId }; 修改时 { id, name, directorId }
    hideFields?: string[]; //隐藏的字段域
    onSuccess?: () => void;
}
export const AddDepartment = ({params, hideFields, onSuccess, ...rest}: AddDepartmentProps) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const addDeptFormRef = useRef<ProFormInstance>()
    const [, , request] = useDictEnumHooks({}, false)
    // 查询组织架构人员树结构
    // const [treeData,,getApi] = useDeptDataHooks({type: 2}, false, (v: any) => !v.whetherUser)
    const treeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)

    const onVisibleChange = async (visible: boolean)=>{
        if (visible) {
            addDeptFormRef.current?.resetFields()
            const { parentId, id, directorId, name, position } = params || {}
            id && addDeptFormRef.current?.setFieldsValue({ name, position, directorId: directorId ? `s${directorId}` : undefined })
            // await getApi({type: 2})
        }
    }

    const onFinish = async (values: any) => {
        const { parentId, id, name, ...rest } = params || {}
        const whichApi = id ? userDeptEdit : userDeptNew
        const otherParam = id ? {id} : { id: parentId }
        const { directorId: adminId } = values || {}
        const directorId = (adminId||'').slice(1)
        const { success } = await whichApi(fromData({...values, directorId, ...otherParam}), true, onSuccess)
        return success
    }
    return (<ModalForm<any> formRef={addDeptFormRef} modalProps={{maskClosable: false}} onVisibleChange={onVisibleChange} onFinish={onFinish} layout="horizontal" width={500} labelCol={{span: 4}} {...rest}>
            <ProFormTextArea fieldProps={{ rows:1, showCount: true,  maxLength: 20 }} name="name" label="部门名称" rules={[{required: true, message: '请填写部门名称'}]} />
            {hideFields?.includes('directorId') ? null : <ProFormTreeSelect  fieldProps={{treeData, showSearch: true, treeNodeFilterProp: 'title',allowClear:true}}  name="directorId" label="负责人" rules={[{required: true, message: '请选择负责人'}]} />}
            {hideFields?.includes('directorId') ? null : <ProFormSelect params={{dictId: 268}} request={request} name="position" label="职位" rules={[{required: true, message: '请选择职位'}]} />}
        </ModalForm>)
}


export const AddDepartmentStaff = ({params, onSuccess, ...rest}: AddDepartmentStaffProps) => {
    const addStaffFormRef = useRef<ProFormInstance>()
    const [, hasAuthCode] = useHasAuthCode()
    const { id, deptId } = params || {}
     // 获取组织架构树
    const [treeData,, getApi ] = useDeptDataHooks({}, false)
    // 获取角色list
    const [userTreeData ] = useRoleListHooks({}, true)
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            addStaffFormRef.current?.resetFields()
            await getApi({type: 1})
            if (id) {
                getUserInfoApi({userId: id})
            } else {
                addStaffFormRef.current?.setFieldsValue({password: 123456, deptId})
            }
        }
    }
    // 编辑回显查询事件
    const getUserInfoApi = async (param: Record<string, any>) => {
        userGetUserInfoApi(param, false, (data: any) => {
            const { roleIds: roles, ...rest } = data || {}
            addStaffFormRef.current?.setFieldsValue({
                roles,
                ...rest
            })
        })
    } 
    // 点击复制之后的事件
    const copyPassword = async () => {
        if(copy('您的密码为：' + addStaffFormRef.current?.getFieldValue('password'))){
            message.info('密码已经复制到剪切板！');
        } else {
            message.info('浏览器不支持复制哦！');
        } 
    }
    // 重置密码事件
    const resetPassword = async (userId:any = '') => {
        const { data }:any = await getNewPwd({userId})
        // const formData = toFormData()
        await useResetUserPwd({userId, newPwd: data}, false, ()=>{
            addStaffFormRef.current?.setFieldsValue({password: data})
            if(copy('您的新密码为：' + data)){
                message.info('用户密码已经重置成功，密码已经复制到剪切板！');
            }else{
                message.info('用户密码重置成功，浏览器不支持复制哦！');
            }
        })
    }
   
    // 新增、编辑员工提交事件
    const onFinish = async (values: any) => {
        const whichApi = id ? userUpdateApi : userAddApi
        const { success } = await whichApi({...values, id, ...params}, false, onSuccess)
        return success
    }
     // 重置密码的内容
     const resetButton = (
        <Popconfirm title="确定重置密码吗？" onConfirm={ ()=> resetPassword(id)} okText="确认" cancelText="取消">
           <Button type="primary" ghost>重置</Button>
        </Popconfirm>
    );

    return (<ModalForm<any> layout="horizontal" width={500} formRef={addStaffFormRef} onVisibleChange={onVisibleChange} onFinish={onFinish} labelCol={{span: 4}} {...rest}>
            <ProFormText name="userName" label="登录账号" disabled={id} fieldProps={{maxLength: 20}} rules={[{required: true, }, { pattern: userNameReg , message: '名称只允许包含数字、字母' }]} />
            <ProFormText name="password" width={300} fieldProps={{ placeholder:"密码不可编辑"}} disabled label="登录密码" addonAfter={ id ? resetButton : <Button type="primary" onClick={ ()=> copyPassword()} ghost>复制</Button>} rules={[{required: false, message: '请填写登录密码'}]} />
            <ProFormText name="name" label="姓名" rules={[{required: true, message: '请填写姓名'}, {max: 30, type:'string', message: '30字以内'}]} />
            <ProFormRadio.Group name="gender" label="性别" initialValue={'0'} options={[{label: '男', value: '0'}, {label: '女', value: '1'}]} rules={[{required: true, message: '请选择性别'}]} />
            <ProFormText name="mobilePhoneNumber" fieldProps={{maxLength: 11}}  label="手机号" rules={[{required: true, message: '请填写手机号'}, { pattern: phoneReg, message: '请填写正确手机号格式' }]} />
            <ProFormText name="email" label="邮箱" rules={[{required: false, message: '请填写邮箱'}, { pattern: emailReg , message: '请填写正确邮箱格式' }, {max: 50, type:'string', message: '50字以内'}]} />
            <ProFormTreeSelect name="deptId" label="所属部门" fieldProps={{treeData}} rules={[{required: true, message: '请选择所属部门'}]} />
            <ProFormSelect name="roles" label="所属角色" fieldProps={{options: userTreeData, mode: 'multiple'}}   rules={[{required: true, message: '请选择所属角色'}]} />
            <ProFormDatePicker name="inductTime" label="入职时间" rules={[{required: true, message: '请填写入职时间'}]} />
            <ProFormSelect name="userInfoStatus" label="状态" initialValue={'0'} options={[{label: '在职', value: '0'}, {label: '离职', value: '1'},{label: '禁用', value: '2'}]} rules={[{required: true, message: '请选择状态'}]} />
        </ModalForm>)
}

export default () => {
    const dispatch = useDispatch();
    // 组织架构树结构方法使用
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    // 保存当前表格多选选中的值
    const [currentTreeDataItem, setCurrentTreeDataItem] = useState<any>({id:null})
    const [treeData, setTreeData, getTreeData] = useDeptDataHooks({}, true)
    const [addStaffProps, setAddStaffProps] = useState<AddDepartmentStaffProps>({title: '新增员工', visible: false})
    const [addDeptProps, setAddDeptProps] = useState<AddDepartmentProps>({title: '新增部门', visible: false,})

    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { id } = currentTreeDataItem || {}
        const { data } = await userListApi({...params, deptId: id}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }
    // 新建员工、批量删除、表格操作等事件
    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const {id, userInfoStatus} = record || {}
        if(['批量删除', '删除'].includes(type)) {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await userDelApi({userId: id}, true, () => {
                        dispatch(asyncSetDeptWithUserTreeData())
                        ref.current?.reload()
                    })
                },
            });
        } else if(type === '离职复职') {
            Modal.confirm({
                title: '确认要修改该数据吗?',
                content: '人员离职后，该账号不可登录系统！',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await userStatusUpdateApi({userId: id, status: Number(userInfoStatus) === 1 ? 0 : 1}, true, () => {
                        dispatch(asyncSetDeptWithUserTreeData())
                        ref.current?.reload()
                    })
                },
            });
        } else if(type === '禁用启用') {
            Modal.confirm({
                title: '确认要禁用该数据吗?',
                content: '禁用后，该账号不可登录系统！',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await userStatusUpdateApi({userId: id, status: Number(userInfoStatus) === 2 ? 0 : 2}, true, () => {
                        dispatch(asyncSetDeptWithUserTreeData())
                        ref.current?.reload()
                    })
                },
            });
        } else if (type === '新建员工') {
            const { id: deptId } = currentTreeDataItem || {}
            if (!deptId) {
                return message.warning('请先选择一个部门');
            }
            setAddStaffProps({
                ...addStaffProps,
                title: '新建员工',
                // 新增员工需要传depId(部门id)
                params: { deptId },
                visible: true,
                onSuccess: async () => {
                    setAddStaffProps({...addStaffProps, visible: false})
                    dispatch(asyncSetDeptWithUserTreeData())
                    ref.current?.reload()
                }
            })
        } else if (type === '编辑员工') {
            setAddStaffProps({
                ...addStaffProps,
                title: '编辑员工',
                params: { id },
                visible: true,
                onSuccess: async () => {
                    setAddStaffProps({...addStaffProps, visible: false})
                    dispatch(asyncSetDeptWithUserTreeData())
                    ref.current?.reload()
                }
            })
        }
    }
    const columns = columnsFn(operate)
    const treeSearch = ({filterData, expandedKeys }: any) => {
        setTreeData(filterData)
        setExpandedKeys(expandedKeys)
    }
    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
    }
    // 左侧组织架构树的操作事件
    const titleOperate = async (nodeData: any, type: string) => {
        const { id, name, principalUserId: directorId, position } = nodeData || { id: '0' }
        if (type === 'add') {
            setAddDeptProps({
                ...addDeptProps,
                title: '添加部门',
                params: { parentId: id },
                hideFields: ['directorId'],
                visible: true,
                onSuccess: () => {
                    setAddDeptProps({...addDeptProps, visible: false})
                    getTreeData({})
                    dispatch(asyncSetDeptWithUserTreeData())
                }
            })
        } else if (type === 'update') {
                setAddDeptProps({
                    ...addDeptProps,
                    title: '修改部门',
                    hideFields: [],
                    params: { id, directorId, name, position },
                    visible: true,
                    onSuccess: () => {
                        setAddDeptProps({...addDeptProps, visible: false})
                        getTreeData({})
                        dispatch(asyncSetDeptWithUserTreeData())
                    }
                })
        } else if (type === 'del') {
            // 先校验组织架构下是否有员工
            userCheckIsMember(fromData({id}), false, () => {
                Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        await userDeptDel(fromData({id}), true, () => {
                            getTreeData({})
                            dispatch(asyncSetDeptWithUserTreeData())
                        })
                    },
                });
            })
            
        } else if (type === 'titleClick') {
            //选中部门树以及取消选择
            (nodeData?.id === currentTreeDataItem?.id) ? setCurrentTreeDataItem('') : setCurrentTreeDataItem(nodeData)
        }
    }

    const calcShowIcons = () => {
        const add = hasAuthCode(2192) ? 'add' : undefined
        const update = hasAuthCode(2193) ? 'update' : undefined
        const del = hasAuthCode(2194) ? 'del' : undefined
        return [add, update, del].filter(Boolean)
    }

    useEffect(() => {
        ref?.current?.reload()
    }, [currentTreeDataItem])

    return <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: '265px', marginRight: '16px', position: 'sticky', top: '130px'}}>
                <WithSearchTreeWarp title="组织架构" Icon={hasAuthCode(2192) ? false : ' '} onOperate={() => titleOperate(null, 'add')}>
                    <WithSearchTree 
                        style={{marginTop: '12px'}} 
                        treeData={treeData} 
                        blockNode 
                        showIcons={calcShowIcons()}
                        expandedKeys={expandedKeys} 
                        autoExpandParent={true} 
                        onExpand={onExpand} 
                        onSearch={treeSearch} 
                        titleOperate={titleOperate} 
                    />
                </WithSearchTreeWarp>
            </div>
            <ProTable<TableListItem>
                style={{flex: 1}}
                columns={columns}
                className="organicationRightDiv"
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                       hasAuthCode(1002) ? <Button key="btn6" type="primary" onClick={() => operate('新建员工')}>新建员工</Button> : null,
                    ],
                }}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
            />
            <AddDepartment {...addDeptProps} modalProps={{onCancel: () => setAddDeptProps({...addDeptProps, visible: false}), maskClosable: false}} />
            <AddDepartmentStaff {...addStaffProps} modalProps={{onCancel: () => setAddStaffProps({...addStaffProps, visible: false}), maskClosable: false}} />
   </div>
}