import React from "react";
import { Input, Select, DatePicker, TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { useDeptDataHooks } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";

type EnclosureAddressItem = {
  time: string;
  name: string;
  format: string;
  size: number;
  uploader: string;
  url: string;
}
// ts--types
export interface TableListItem {
    id: string;
    parentUserId: string;
    userId: string;
    time: string;
    key: number;
    createrId: string | number;
    dailyTime: string;
    createTime: string;
    title: string;
    status: string;
    parentName: string;
    todaySummary: string;
    tomorrowPlan: string;
    review: string;
    reviewTime: string;
    creator: string;
    canView: number;
    enclosureAddress: EnclosureAddressItem[];
    

    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    lastUpdateTime: string;
    notFollowDays: string;
    type: any;
};

export type OperateActionType = '行点击' | '写日报'

export const reportStatus: any = {
  // '0': { text: '未提交', status: 'Default' },
  '1': { text: '待批阅', status: 'Processing' },
  '2': { text: '合格', status: 'Success' },
  '3': { text: '不合格', status: 'Error' },
}

export const reportTypeOptions = [{label: '日报', value: 1}, {label: '周报', value: 2}]

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  return [
    {
        title: '报告时间',
        dataIndex: 'time',
        order: 2,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (value) => {
            return {
              dailyTimeFrom: value[0] ? value[0] + ' 00:00:00' : value[0],
              dailyTimeTo: value[1] ? value[1] + ' 23:59:59' : value[1],
            };
          },
        },
        render: (text: any, record: any) => record.time
    },
    {
        title: '报告标题',
        dataIndex: 'title',
        order: 100,
    },
    {
        title: '提交人',
        dataIndex: 'creatorIds',
        hideInTable: true,
        order: 5,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const creatorIds = (ids||[]).map((v: string) => v.slice(1))
            return { creatorIds }
          },
        },
    },
    {
        title: '审批人',
        dataIndex: 'parentIds',
        hideInTable: true,
        order: 4,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const parentIds = (ids||[]).map((v: string) => v.slice(1))
            return { parentIds }
          },
        },
    },
    {
        title: '状态',
        dataIndex: 'status',
        order: 3,
        valueEnum: reportStatus,
    },
    {
      title: '提交时间',
      dataIndex: 'createTime',
      hideInTable: true,
      order: 1,
      valueType: 'dateRange',
      fieldProps: { allowEmpty: [true, true]},
      search: {
        transform: (value) => {
          return {
            createTimeFrom: value[0] ? value[0] + ' 00:00:00' : value[0],
            createTimeTo: value[1] ? value[1] + ' 23:59:59' : value[1],
          };
        },
      },
    }
]
}
