import React, { useEffect, useRef, useState } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import type { ProFormInstance } from '@ant-design/pro-form'
import { Button, Modal } from "antd";
import { customerFollowAllList, BASEURL, customerFollowDel } from '@api/index' 
import { getCheckedColumnsStateByColumns, productApi } from "@utils/util";
import { ExportButton, ProTableWithFormSetting, } from "@components/index";
import { useHasAuthCode } from "@hooks/index";
import moment from "moment";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";
import { MyTabs } from "@components/EnterpriseDetail";
import { useHistory } from "react-router-dom";
import { AddFollowRecord, AddFollowRecordProps } from "../List";
import { stringify } from "qs";

const getApi = productApi(customerFollowAllList)
const delApi = productApi(customerFollowDel)

export const tabPanes = [
    {
        Tab: '全部',
        key: ' ',
        Content: null
    },
    {
        Tab: '我的',
        key: 1,
        Content: null
    },
    {
        Tab: '下属的',
        key: 2,
        Content: null
    },
]

const persistenceKey = 'customer/follow'

export default () => {
    const history = useHistory()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    const [view, setView] = useState<any>(' ')
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: undefined, businessOppId: undefined})
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        switch (type) {
            case '批量删除':
                Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await delApi({ids: selectedRowKeys}, true, reFush)
                        setSelectedRowKeys([])
                    },
                })
                break;
            case '新建跟进':
                setAddFollowProps({
                    ...addFollowProps,
                    visible: true,
                    onSuccess: () => {
                        reFush()
                        setAddFollowProps({...addFollowProps, visible: false})
                        return true
                    }
                })
                break;
            case '跟进详情':
                history.push(`/customer/followDetail?${stringify({customerId: record?.customerId, customerName: record?.customerName, fromPath: '/customer/followRecord'})}`)
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        // const followUpTimeTo = moment().format('YYYY-MM-DD')
        // const followUpTimeFrom = moment().add(-7, 'days').format('YYYY-MM-DD')
        const { data } = await getApi({ ...params, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const columns = columnsFn(operate)
    const params = { view }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            ids: selectedRowKeys,
            fields,
            ...params
        })
    }

    const calcDisabled = () => !selectedRowKeys.length

    const reFush = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }
     
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <MyTabs 
                tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
                style={{background: '#fff'}} 
                tabPanes={tabPanes} 
                activeKey={view} 
                onChange={setView}
            />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 1200}}
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getListApi}
                options={{reload: false, density: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(-1) ? <Button key="btn6" type="primary" onClick={() => operate('新建跟进')}>新建跟进</Button> : null,
                        hasAuthCode(2200) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/followup/export`}></ExportButtonWithBatch> : null,
                        hasAuthCode(2201) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate('批量删除')}>批量删除</Button> : null,
                    ],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)},
                }}
                onChange={(pageInfo,_, columnStatus,{action}) => {
                    if (action === 'sort') {
                        ref.current?.reload(1 as any)
                    }
                }}
                columnsState={{ persistenceKey: 'customer/follow', persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: 'customer/follow:searchState', persistenceType: 'localStorage' }}
            />
            <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
        </div>
    </div>
}
