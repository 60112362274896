// const OLDPRODURL = `https://newjns.juncdt.com/crmApi`
// const PRODURL = `https://jns.juncdt.com/customerApi`
const PRODBASEURL = `https://jns.juncdt.com`
// const PRODBASEURL = `http://115.227.1.198:8081`
const TESTBASEURL = `http://192.168.8.145:8181`
// const TESTBASEURL = `http://localhost:8181`

// const PRODBASEURL = ``
// const TESTBASEURL = ``

const DEMOBASEURL = `https://demojns.juncdt.com:8182`
const NJBASEURL = `https://nbjns.juncdt.com`
const YBB = `http://192.168.2.93:8182`
const CX = `http://192.168.1.111:8181`

// 测试URL
const TESTURL = `${TESTBASEURL}/customer`
const TESTLQURL = `${TESTBASEURL}/lieqi`


// 晨曦本地
const CXURL = `${CX}/customerApi`
const CXLQURL = `${CX}/lieqi`

// 正式URL
const PRODURL = `${PRODBASEURL}/customerApi`
const PRODLQURL = `${PRODBASEURL}/lieqi`

// 演示环境
const DEMOURL = `${DEMOBASEURL}/customerApi`
const DEMOLQURL = `${DEMOBASEURL}/lieqi`

// 宁波区域
const NBURL = `${NJBASEURL}/customerApi`
const NBLQURL = `${NJBASEURL}/lieqi`

const MockUrl = `https://www.fastmock.site/mock/2e345021b19adda0b3c82c9fa38ed89b/customer`
const LqMockUrl = `https://fastmock.site/mock/8c25ce310ca9c798ee8a6e49fef356b0/lieqi`


const BASEURL_MAP: any = {
    "development": { BASEURL: TESTURL, BASEURL_LQ: TESTLQURL },
    "demo": { BASEURL: DEMOURL, BASEURL_LQ: DEMOLQURL } ,
    "production": { BASEURL: PRODURL, BASEURL_LQ: PRODLQURL } 
}

const mode = 'development'

export const BASEURL = BASEURL_MAP[process.env.BASEURL||mode].BASEURL 
export const LQBASEURL = BASEURL_MAP[process.env.BASEURL||mode].BASEURL_LQ
// export const BASEURL = BASEURL_MAP['production'].BASEURL 
// export const LQBASEURL = BASEURL_MAP['production'].BASEURL_LQ
