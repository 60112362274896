import { Button, Card, Dropdown, Input, Modal, Space, Image, message, Form, Col, Row, Alert, Tooltip } from "antd"
import ProForm, { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormUploadButton, ProFormUploadDragger } from '@ant-design/pro-form';
import { columns } from "./data";
import { useMemo, useRef, useState } from "react";
import { useRequest } from "ahooks";
import React from "react";
import { acceptImg, getToken, handleUploadFiles, isImageFile, parseSearch, productApi, request, urlsToFileLists } from "@utils/index";
import { usePlusTableHook } from "@hooks/plusTableHook";
import ProTable, { EditableProTable } from "@ant-design/pro-table";
import { BASEURL, customerBusinessGetLogicById, customerBusinessGetPictureById } from "@api/index";
import { customerBusinessSaveLogic, customerBusinessSavePicture, diyDo2Get } from "@api/customer";
import { useLocation } from "react-router-dom";
import { PreviewProps } from "@components/Common/Preview";
import { debounce } from "lodash";
import demo2 from '@/assets/images/demo2.png';
import demo3 from '@/assets/images/demo3.png';
import demo4 from '@/assets/images/demo4.jpg';
import { EnterpriseDetailProps } from "@components/EnterpriseDetail/type";
import { Icon } from "@components/Common";

const { TextArea } = Input;

const getApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/getPage`,
    // method: 'GET',
    data,
})) 

// 接口请求的另一种写法，方便 dvm 代码生成 
const getPageListApi = async (params: any, sorter: any) => {
    const { data } = await getApi({...params, pageSize: 10000, sorter})
    const { values, total } = data
    return {
        data: values,  // 列表数据
        total: total,   // 列表总条数
        success: true   // 请求成功，关闭表格 loading 等待
    }
}

const delApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/deleteBatch`,
    // method: 'GET',
    data,
})) 
const addApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/save`,
    // method: 'GET',
    data,
}))
const updateApi = productApi((data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/save`,
    // method: 'GET',
    data,
}))
// 返回表单需要的数据
const getInfoApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/business/jnsBusinessMapResource/getById`,
        method: 'GET',
        params,
    }) as any
    return {
        ...data,
        files: urlsToFileLists(data.accessory)
    }
}

// 获取页面枚举数据
const getInitDataApi = async () => {
    const { data } = await diyDo2Get({functionAlias: 'jnsBusinessMapResource'}) as any
    return data
} 

const saveBusinessDescApi = productApi(customerBusinessSaveLogic)
const saveBusinessPicApi = productApi(customerBusinessSavePicture)


const BusinessDesc = ({onPreview}: any) => {
    const { pathname, search } = useLocation();
    const [disabled, setDisabled] = useState(true)
    const { id: customerId } = parseSearch(search)
    const params = { customerId }
    const request = async (params: any) => {
        const { data: logicText } = await customerBusinessGetLogicById(params) as any
        const { data: files } = await customerBusinessGetPictureById(params) as any
        return {
            logicText,
            files: urlsToFileLists(files)
        }
    }
    const onSave = async (e: any) => {
        const key = e.target.id
        const value = e.target.value
        const whichApi = key === 'logicText' ? saveBusinessDescApi : saveBusinessPicApi
        const { success } = await whichApi({[key]: value, customerId}, true)
        return success
    }

    const onChange = async ({fileList, file}: any) => {
        const [ targetPicture ] = handleUploadFiles([file]).map((f: any) => f.url)
        const isUploadOver = !fileList.map((file: any) => file.status === 'done').includes(false)
        if (isUploadOver) {
            const picture = handleUploadFiles(fileList).map((file: any) => file.url).toString()
            const { success } = await saveBusinessPicApi({picture, customerId, targetPicture}, true)
        }
    }

    const onFinish = async ({logicText}: any) => {
        const success = await onSave({target: { id: 'logicText', value: logicText }})
        success && setDisabled(!disabled)
    }

    return (<ProForm 
                request={request} 
                params={params} 
                onFinish={onFinish}
                // submitter={false}
                submitter={ !disabled ? {render: (props, doms) => [
                    <div key="submit" 
                        style={{
                            position: 'fixed', 
                            bottom: 0, 
                            left: 0, 
                            right: 0, 
                            zIndex: 99,
                            padding: 6, 
                            boxShadow: '0px -1px 6px 0px rgba(212,212,212,0.5)', 
                            background: '#fff', 
                            textAlign: 'center'
                        }}
                    >
                        <Button  type="primary" style={{marginLeft: 130}} onClick={() => props.form?.submit?.()}>提交</Button>
                    </div>,
                ]} : false}
            >
                <Card title={<div className="zen-ban">业务逻辑介绍 <Tooltip title="编辑业务逻辑介绍"> <Icon type="icon-web-icon-" style={{ color: '#0479FE', marginLeft: 6, cursor: 'pointer'}} onClick={() => setDisabled(!disabled)} /> </Tooltip></div>} size="small" bordered={false} style={{marginTop: 20}}>
                    <ProFormTextArea 
                        name="logicText" 
                        fieldProps={{ 
                            // onBlur: onSave
                            disabled
                        }} 
                    />
                </Card>
                <Card title={<div className="zen-ban">应用系统架构图</div>} size="small" bordered={false} style={{marginTop: 20}}>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <Image width={160} height={75} src={demo3}/>
                            <h2 style={{textAlign: 'center'}}>示例图</h2>
                        </Col>
                        <Col>
                            <Image width={160} height={75} src={demo4}/>
                            <h2 style={{textAlign: 'center'}}>示例图</h2>
                        </Col>
                        <Col>
                            <ProFormUploadButton
                                style={{width: 200}}
                                name="files" 
                                max={5} 
                                fieldProps={{ 
                                    onPreview, 
                                    name: 'file', 
                                    accept: acceptImg, 
                                    listType: "picture-card", 
                                    headers: {Authorization: getToken()},
                                    onRemove: () => new Promise((resolve, reject) => {
                                        Modal.confirm({
                                            title: '温馨提示',
                                            content: '确认要删除该图片吗?',
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk: () => resolve(true)
                                        })
                                    })
                                }} 
                                action={`${BASEURL}/upload`} 
                                extra="" 
                                rules={[{ required: false, message: '请上传凭证',}]} 
                                onChange={debounce(onChange)}
                            />
                        </Col>
                    </Row>
                </Card>
    </ProForm>)
}


// 组件区域
const JnsBusinessMapResource = (props: EnterpriseDetailProps) => {
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    // 获取地址栏参数
    const { search } = useLocation();
    const { id : customerId} = parseSearch(search);
    // 定义各类操作
    const operate = async (action: any, record: any) => {
        const { id : id } = record || {}
        const ids = id ? [id] : selectedRowKeys
        switch (action) {
            case '新增':
                addAction({params: {customerId}})
                break;
            case '编辑':
                editAction({params: {id, customerId}})
                break;
            case '删除':  
                delAction({ids: ids})
                break;
        }
    }

    const { data } = useRequest(getInitDataApi)
    // 新增编辑提交时参数转换
    const transform = ({files, ...rest}: any) => {
        return {
            ...rest,
            accessory: handleUploadFiles(files).map(v => v.url).toString()
        }
    }

    const { 
        selectedRowKeys,
        tableProps, 
        modalProps, 
        addAction, 
        editAction, 
        delAction,
        getDvmExportParams,
        setModalProps,
        onRefresh,
    } = usePlusTableHook({
        delApi,
        updateApi,
        addApi,
        getInfoApi,
        transform,
        // columns: useMemo(() => columns(operate, data), [data])  // 可以将 data 枚举值 透传到 columns 中
        columns: columns(operate, data) // 可以将 data 枚举值 透传到 columns 中
    })

    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(format ? `.${format}` : url) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    
    const calcDisabled = () => !selectedRowKeys.length

    return (<>
        <Alert
            message={<>
                <div>业务地图，包含3张图“资源表、业务逻辑介绍、应用系统架构图”。（售前填写）</div>
                <div>*应用系统架构图，包括源端架构、目的端架构、业务系统关系。包含线上、线下、各个云的应用系统部署情况等。</div>
            </>}
            type="warning"
            closable
        />
        <ProTable
            request={getPageListApi}
            scroll={{x: 1300}}
            headerTitle={<div className="zen-ban">资源表</div>}
            toolbar={{
                actions: [
                    <Button type="primary" key="新增" onClick={() => operate?.('新增', null)}>新增</Button>,
                ]
            }}
            rowKey="id"
            {...tableProps}
            rowSelection={false}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            search={false}
            pagination={false}
            params={{customerId}}
        />
        
        <BusinessDesc onPreview={onPreview} />

        <ModalForm<any>
            layout="horizontal"
            labelCol={{span: 5}}
            width={800}
            {...modalProps}
            grid={true}  // 开启栅格布局，一行允许有多列
        >
            <Row gutter={[16, 16]}>
                <Col span={16}>
                    <ProFormText
                        label="模块" 
                        name="model" 
                        rules={[{required: true, message: '请填写'}]} 
                    />
                    <ProFormText
                        label="业务系统" 
                        name="businessSysName" 
                        rules={[{required: true, message: '请填写'}]} 
                    />
                    <ProFormText
                        label="资源" 
                        name="cloudResource" 
                        rules={[{required: true, message: '请填写'}]} 
                    />
                    <ProFormText
                        label="资源详情" 
                        name="resourceDetail" 
                        rules={[{required: true, message: '请填写'}]} 
                    />
                    <ProFormText
                        label="资源所用平台" 
                        name="resourcePlat" 
                        rules={[{required: true, message: '请填写'}]} 
                    />
                    {/* <ProFormText
                        label="云资源" 
                        name="cloudResource" 
                        rules={[{required: true, message: '请填写'}]} 
                    />
                    <ProFormTextArea
                        label="备注" 
                        name="remark" 
                        rules={[{required: true, message: '请填写'}]} 
                    /> */}
                    <ProFormUploadButton 
                        label="附件" 
                        name="files" 
                        max={5} 
                        fieldProps={{ 
                            onPreview, 
                            name: 'file', 
                            // maxCount: 5,
                            // accept: acceptImg, 
                            listType: "picture-card", 
                            headers: {Authorization: getToken()} 
                        }} 
                        action={`${BASEURL}/upload`} 
                        extra="" 
                        rules={[{ required: true, message: '请上传凭证',}]} 
                    />
                </Col>
                <Col span={8}>
                   <h2 style={{textAlign:'center'}}>示例图:</h2>
                   <Image
                        src={demo2}
                    />
                </Col>
            </Row>
            
        </ModalForm>
        <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}} />
    </>)
}

export default JnsBusinessMapResource
