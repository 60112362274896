import {SET_COMMON_DEPTWITHUSERDATR, SET_CITY_TREEDATA, SET_LABEL_TREEDATA, SET_LQ_SEARCHCONDITION, SET_USER_PROCESS, SET_USER_MESSAGES, SET_USER_MESSAGE_NUMBER, SET_DOWNLOAD_URLMAP} from '../contants'

// 获取组织架构树(带员工)数据
export const deptWithUserTreeData = (state = [], action: { type?: any; deptWithUserData?: any[] }) => {
    const { deptWithUserData } = action
    switch(action.type) {
        case SET_COMMON_DEPTWITHUSERDATR:
            return deptWithUserData
        default: 
            return state
    }
}

// 获取省市区数据
export const cityTreeData = (state = [], action: { type?: any; cityTreeData?: any[] }) => {
    const { cityTreeData } = action
    switch(action.type) {
        case SET_CITY_TREEDATA:
            return cityTreeData
        default: 
            return state
    }
}

// 客户标签数据
export const labelTreeData = (state = [], action: { type?: any; labelTreeData?: any[] }) => {
    const { labelTreeData } = action
    switch(action.type) {
        case SET_LABEL_TREEDATA:
            return labelTreeData
        default: 
            return state
    }
}

// 设置模版下载url 地址map
export const downLoadUrlMap = (state = {}, action: { type?: any; downLoadUrlMap?: any }) => {
    const { downLoadUrlMap } = action
    switch(action.type) {
        case SET_DOWNLOAD_URLMAP:
            return downLoadUrlMap
        default: 
            return state
    }
}

//猎奇模块
// 猎奇搜索条件
export const lqSearchConditionData = (state = [], action: { type?: any; lqSearchConditionData?: any[] }) => {
    const { lqSearchConditionData } = action
    switch(action.type) {
        case SET_LQ_SEARCHCONDITION:
            return lqSearchConditionData
        default: 
            return state
    }
}

// 保存用户正在导出、导入的信息
export const taskObj = (state = {}, action: { type?: any; taskObj?: Object }) => {
    const { taskObj } = action
    switch(action.type) {
        case SET_USER_PROCESS:
            return taskObj
        default: 
            return state
    }
}

// 保存用户接收到的消息队列
export const messages = (state = {}, action: { type?: any; messages?: any[]}) => {
    const { messages } = action
    switch(action.type) {
        case SET_USER_MESSAGES:
            return messages
        default: 
            return state
    }
}

// 保存用户接收到的消息队列
export const messageNumber = (state = 0, action: { type?: any; messageNumber?:number}) => {
    const { messageNumber } = action
    switch(action.type) {
        case SET_USER_MESSAGE_NUMBER:
            return messageNumber
        default: 
            return state
    }
}
