import { combineReducers } from 'redux'
import { userInfo, userRoleData } from './userInfo'
import { deptWithUserTreeData, cityTreeData, labelTreeData, lqSearchConditionData, taskObj, messages, messageNumber, downLoadUrlMap } from './common'

export default combineReducers({
    userInfo,
    userRoleData,
    deptWithUserTreeData,
    cityTreeData,
    taskObj,
    messages,
    messageNumber,
    labelTreeData,
    lqSearchConditionData,
    downLoadUrlMap
})
