import { request } from '@utils/index'
import {stringify} from 'qs';
import { BASEURL, LQBASEURL } from './baseUrl'
export { BASEURL, LQBASEURL } from './baseUrl'
export * from './bi'
export * from './lq'
export * from './system'
export * from './oa'
export * from './customer'
export * from './user'
export * from './product'
export * from './rebate'
export * from './knowledge'
export * from './finance'

/*-------------------------------------登录鉴权接口-----------------------------------*/
// 登录
export const login  = (data?: object) => request({
    url:`${BASEURL}/login`,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data
})

// 退出登录
export const logout  = (data?: object) => request({
    url:`${BASEURL}/logout`,
    data
})

// 用户信息
export const getInfo = (data?: any) => request({
    url: `${BASEURL}/getInfo`,
    method: 'GET',
    data,
})

// 获取路由菜单
export const getRouters = (data?: any) => request({
    url: `${BASEURL}/getRouters`,
    method: 'GET',
    data,
})

// 部门人员列表(树状层级)
export const depListTree = (data?: any) => request({
    url: `${BASEURL}/company/base/deptlist`,
    method: 'GET',
    data,
})

// 人员角色列表(树状层级)
export const roleListTree = (data?: any) => request({
    url: `${BASEURL}/company/base/rolelist`,
    method: 'GET',
    data,
})

// 根据 userid 查询角色列表
export const getRoleListByUserId = (data?: any) => request({
    url: `${BASEURL}/system/role/getRoleListByUserId`,
    data,
})

// 获取组织架构树数据
export const queryDeptTreeData = (data?: any) => request({
    url: `${BASEURL}/common/queryDeptList`,
    method: 'GET',
    data,
})

// 获取组织架构树数据-带员工角色
export const queryDeptTreeDataWithRole = (data?: any) => request({
    url: `${BASEURL}/getUserRoleTree`,
    method: 'GET',
    data,
})

// 通用 oss 上传接口
export const ossUpload = (data?: any) => request({
    url: `${BASEURL}/upload`,
    data,
})

// 通用 oss 批量导入
export const ossImport = (data?: any) => request({
    url: `${BASEURL}/importResource`,
    data,
})

// 通用下载文件
export const downLoadFile = (data?: any) => request({
    url: `${BASEURL}/downloadFile`,
    headers: {'Content-Type': 'multipart/form-data'},
    data,
})

// 绑定小号
export const bindPhone = (data?: any) => request({
    url: `${BASEURL}/bindPhone`,
    data,
})

// 获取模版下载url
export const getDownLoadUrlMap = (data?: any) => request({
    url: `${BASEURL}/common/getDownloadFileUrlMapping`,
    method: 'GET',
    data,
})

// 按id获取跟进记录
export const getFollowUpById = (data?: any) => request({
    url: `${BASEURL}/followup/getFollowUpById`,
    method: 'GET',
    data,
})

// 按商机id获取跟进记录
export const getFollowUpByBusinessId = (data?: any) => request({
    url: `${BASEURL}/followup/getFollowUpByBusinessId`,
    method: 'GET',
    data,
})
