import { useHasAuthCode } from "@hooks/index"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Modal, message } from "antd"
import { stringify } from "qs"
import { CLOUD_VENDOR, productApi } from "@utils/index"
import { rebateList, rebateClaim, rebateClaimAudit, rebateClaimAuditInfo, rebateRecover, rebateRecoverAudit, rebateRecoverAuditInfo, rebateSurePay, rebateUpdatePayerList, rebateStop, rebateMuchSure, rebateMuchReview, rebateReviewInfo, rebateReview, rebateAdd, rebateMuchUndo } from "@api/rebate"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { ClaimAuditDetailProps, ClaimAuditProps, ClaimProps, ConfirmPayProps, RecoverProps, ReviewInfoProps } from "./Ali"

// mock api
const listPageApi = productApi(rebateList) // 分页列表
const claimApi = productApi(rebateClaim)  // 认领api
const claimAuditApi = productApi(rebateClaimAudit) // 认领审核
const claimAuditInfoApi = productApi(rebateClaimAuditInfo) // 认领审核详情
const recoverApi = productApi(rebateRecover) // 追回
const recoverAuditApi = productApi(rebateRecoverAudit) // 追回审核
const recoverAuditInfoApi = productApi(rebateRecoverAuditInfo) // 追回审核详情
const infoByIdApi = productApi(rebateClaim) // 根据id 获取详情信息
const surePayApi = productApi(rebateSurePay) // 确认打款
const updatePayerListApi = productApi(rebateUpdatePayerList) // 修改打款人信息
const stopApi = productApi(rebateStop) // 截至确认
const muchSureApi = productApi(rebateMuchSure) // 批量确认
const muchReviewApi = productApi(rebateMuchReview) //批量复核
export const reviewInfoApi = productApi(rebateReviewInfo) // 复核信息详情
export const reviewApi = productApi(rebateReview) // 复核信息
const rebateAddApi = productApi(rebateAdd) // 追加返点
const muchUndoApi = productApi(rebateMuchUndo) // 批量撤销

export type OperateActionType = '截至确认' | '认领' | '追回' | '确认信息' | '批量确认信息' | '复核信息' | '批量复核信息' | '撤销' | '批量撤销' | '确认打款' | '修改信息' | '审核-追回' | '审核-认领' | '详情' | '审核详情-认领' | '审核详情-追回' | '追加返点'

export type DataSourcePayerType = {
    id: number;
}


// 返点操作 hooks 封装
export const useOperateHooks = (cloudVendor: CLOUD_VENDOR = CLOUD_VENDOR.ALI) => {
    const history = useHistory()
    const [, hasAuthCode] = useHasAuthCode()

    const { downLoadUrlMap } = useSelector((state: StateType) => state)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    
    const [recoverProps, setRecoverProps] = useState<RecoverProps>({visible: false, disabled: false })
    const [recoverAuditProps, setRecoverAuditProps] = useState<RecoverProps>({visible: false})
    const [claimProps, setClaimProps] = useState<ClaimProps>({visible: false})
    const [claimAuditProps, setClaimAuditProps] = useState<ClaimAuditProps>({visible: false})
    const [claimAuditDetailProps, setClaimAuditDetailProps] = useState<ClaimAuditDetailProps>({visible: false})
    const [confirmPayProps, setConfirmPayProps] = useState<ConfirmPayProps>({visible: false})
    const [reviewInfoProps, setReviewInfoProps] = useState<ReviewInfoProps>({visible: false})

    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const {customerAccount, consumeAmount, id, proceedsMessageRespList, state } = record || {}

        // 认领 行为
        const claimAction = (props: Object = {}) => setClaimProps({
            ...claimProps,
            title: '认领',
            visible: true,
            initialValues: {
                customerAccount
            },
            params: {id},
            onSuccess: () => {
                setClaimProps({visible: false})
                onSuccess?.()
            },
            ...props
        })

        // 审核-认领
        const claimAuditAction = (props: Object = {}) => setClaimAuditProps({
            ...claimAuditProps,
            title: '认领审核',
            visible: true,
            params: { id },
            initialValues: {
                recoverAuditResult: 1,
            },
            onSuccess: () => {
                setClaimAuditProps({visible: false})
                onSuccess?.()
            },
            ...props
        })

        // 审核详情-认领
        const claimAuditDetailAction = (props: Object = {}) => setClaimAuditDetailProps({
            ...claimAuditDetailProps,
            title: '认领审核详情',
            visible: true,
            params: { id },
            onSuccess: () => {
                setClaimAuditDetailProps({visible: false})
                onSuccess?.()
            },
            ...props
        })

        // 追回
        const recoverAction = (props: Object = {}) => setRecoverProps({
            ...recoverProps,
            title: '追回',
            disabled: false,
            visible: true,
            initialValues: {
                consumeAmount,
            },
            params: {id},
            onSuccess: () => {
                setRecoverProps({visible: false})
                onSuccess?.()
            },
            ...props
        })
        // 审核-追回
        const recoverAuditAction = (props: Object = {}) => setRecoverAuditProps({
            ...recoverAuditProps,
            title: '追回审核',
            disabled: false,
            visible: true,
            initialValues: {},
            params: {id},
            onSuccess: () => {
                setRecoverAuditProps({visible: false})
                onSuccess?.()
            },
            ...props
        }) 

        // 审核详情-追回
        const recoverAuditDetailAction = (props: Object = {}) => setRecoverAuditProps({
            ...recoverAuditProps,
            title: '追回审核详情',
            disabled: true,
            visible: true,
            params: {id},
            onSuccess: () => {
                setRecoverAuditProps({visible: false})
                onSuccess?.()
            },
            ...props
        })

        // ['确认打款', '修改信息']
        const confirmPayAction = (props: Object = {}) => setConfirmPayProps({
            ...confirmPayProps,
            // title: type,
            // type: type === '修改信息' ? 'edit' : 'sure',
            visible: true,
            dataSource: proceedsMessageRespList,
            params: { id },
            onSuccess: () => {
                setConfirmPayProps({visible: false})
                onSuccess?.()
            },
            ...props
        })

        // ['撤销', '批量撤销']
        const undoAction = (params: Object = {}) => Modal.confirm({
            title: '温馨提示',
            content: '确定执行此操作？撤销之后，状态将改为待确认.',
            cancelText: '取消',
            onOk: () => muchUndoApi(params, true, onSuccess),
        })

        // ['确认信息', '追加返点']
        const toRebateConfirmAction = (params: Object = {}) => history.push(`/rebate/confirm?${stringify({id, fromPath: '/rebate/list', ...params})}`)

        // 批量确认信息
        const muchSureAction = (params: Object = {}) => Modal.confirm({
            title: '温馨提示',
            content: '系统只对表单填写完整的返点订单执行此操作，操作成功后，返点状态将变为{待复核}。',
            cancelText: '取消',
            onOk: () => muchSureApi(params, true, onSuccess),
        })

        // 批量复核信息
        const muchReviewAction = (params: Object = {}) => Modal.confirm({
            title: '温馨提示',
            content: '确定执行此操作？操作成功后，返点状态将变为{待打款}。',
            cancelText: '取消',
            onOk: () => muchReviewApi(params, true, onSuccess),
        })

        // 详情
        const detailAction = () => {
            if (hasAuthCode(2293)) {
                history.push(`/rebate/detail?${stringify({routeName: '返点详情', fromPath: '/rebate/list', id, cloudVendor, state: Number(state)})}`)
            } else {
                message.info('您暂无查看详情权限!!!')
            }
        }

        // 截至确认
        const stopSureAction = (params: Object = {}) => Modal.confirm({
            title: '温馨提示',
            content: '确定执行此操作？截止确认后，{待确认}订单返点状态将变为{待复核}，{待认领}订单将不能认领。',
            cancelText: '取消',
            onOk: () => stopApi({...params}, true, onSuccess),
        })

        // 复核信息
        const reviewInfoAction = (props: Object = {}) => setReviewInfoProps({
            title: '复核信息',
            visible: true,
            params: { id },
            onSuccess: () => {
                setReviewInfoProps({visible: false})
                onSuccess?.()
            }
        }) 

        return {
            claimAction,
            claimAuditAction,
            claimAuditDetailAction,
            recoverAction,
            recoverAuditAction,
            recoverAuditDetailAction,
            confirmPayAction,
            undoAction,
            toRebateConfirmAction,
            muchSureAction,
            muchReviewAction,
            detailAction,
            stopSureAction,
            reviewInfoAction,
        }
    }

    const createOperate = (record: any, onSuccess?: Function) => {
        const { id } = record || {}
        const { 
            claimAction,
            claimAuditAction,
            claimAuditDetailAction,
            recoverAction,
            recoverAuditAction,
            recoverAuditDetailAction,
            confirmPayAction,
            undoAction,
            toRebateConfirmAction,
            muchSureAction,
            muchReviewAction,
            detailAction,
            stopSureAction,
            reviewInfoAction,
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            switch (type) {
                case '认领':
                    return claimAction()
                case '审核-认领':
                    return claimAuditAction()
                case '审核详情-认领':
                    return claimAuditDetailAction()
                case '追回':
                    return recoverAction()
                case '审核-追回':
                    return recoverAuditAction()
                case '审核详情-追回':
                    return recoverAuditDetailAction()
                case '确认打款':
                    return confirmPayAction({title: type, type: 'sure'})
                case '修改信息':
                    return confirmPayAction({title: type, type: 'edit'})
                case '撤销':
                    return undoAction({ids: [id]})
                case '批量撤销':
                    return undoAction({ids: selectedRowKeys, orderSource: cloudVendor})
                case '确认信息':
                    return toRebateConfirmAction({routeName: type, cloudVendor})
                case '追加返点':
                    return toRebateConfirmAction({routeName: type, cloudVendor})
                case '批量确认信息':
                    return muchSureAction({ids: selectedRowKeys})
                case '批量复核信息':
                    return muchReviewAction({ids: selectedRowKeys, orderSource: cloudVendor})
                case '详情':
                    return detailAction()
                case '截至确认':
                    return stopSureAction({orderSource: cloudVendor})
                case '复核信息':
                    return reviewInfoAction()
            }
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    return {
        history,
        hasAuthCode,
        downLoadUrlMap,
        selectedRowKeys,
        setSelectedRowKeys,
        calcDisabled,

        recoverProps,
        setRecoverProps,
        recoverAuditProps,
        setRecoverAuditProps,
        claimProps,
        setClaimProps,
        claimAuditProps,
        setClaimAuditProps,
        claimAuditDetailProps,
        setClaimAuditDetailProps,
        confirmPayProps,
        setConfirmPayProps,
        reviewInfoProps,
        setReviewInfoProps,
        createAction,
        createOperate
    }
}
